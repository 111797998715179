<template>
  <div class="toggle-switch-container">
    <input
      type="checkbox"
      v-model="toggleValue"
    >
  </div>
</template>

<script>
  export default {
    name: 'ToggleSwitch',

    data() {
      return {
        toggleValue: this.value,
      };
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      }
    },

    watch: {
      value(value) {
        this.toggleValue = value;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .toggle-switch-container {
    display: flex;

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      border: none;
      position: relative;
      border: 0;
      outline: 0;
      cursor: pointer;
      display: flex;

      &:after {
        content: '';
        width: 40px;
        height: 20px;
        background: #DADADA;
        border-radius: 15px;
      }

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        top: 2px;
        left: 3px;
        border-radius: 50%;
        background: $white-color;
      }

      &:checked:before {
        left: 21px;
      }

      &:checked:after {
        background: $second-color;
      }

      &:before,
      &:after,
      &:checked:before,
      &:checked:after {
        transition: ease .3s;
        -webkit-transition: ease .3s;
        -moz-transition: ease .3s;
        -o-transition: ease .3s;
      }
    }
  }
</style>