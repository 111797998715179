<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :name="inputName"
    :rules="rules"
    class="input-field-container"
    :class="{ 'has-icon': !!lastIcon || isPassword() && !!innerValue, 'disable-floating-label': disableFloatingLabel, 'disable-focus': disableFocusStyle }"
    v-slot="{ errors }"
  >
    <div class="input-container">
      <input
        v-if="hasBrand"
        ref="input"
        @focus="handleFocus"
        @blur="handleBlur"
        :class="{ 'error': errors[0], 'default': !errors[0], 'disabled': isDisabled }"
        :type="inputType"
        v-mask="mask"
        :placeholder="defaultPlaceholder"
        :value="value"
        autocomplete="off"
        v-model="innerValue"
        :disabled="isDisabled"
        :style="{ 'background': brandImage }"
      />

      <input
        v-else-if="mask"
        ref="input"
        @focus="handleFocus"
        @blur="handleBlur"
        :class="{ 'error': errors[0], 'default': !errors[0], 'disabled': isDisabled, 'hide-caret': !hasCaret }"
        v-mask="mask"
        :type="inputType"
        :placeholder="disableFloatingLabel ? placeholder : defaultPlaceholder"
        :value="value"
        :maxlength="maxLength"
        autocomplete="off"
        v-model="innerValue"
        :disabled="isDisabled"
      />

      <money
        v-else-if="isMoneyInput()"
        ref="input"
        prefix="R$"
        decimal=","
        thousands="."
        :precision="2"
        @focus="handleFocus"
        @blur="handleBlur"
        :class="{ 'error': errors[0], 'default': !errors[0], 'disabled': isDisabled }"
        :style="{ 'caret-color': 'transparent', 'user-select': 'none' }"
        :maxlength="maxLength"
        autocomplete="off"
        v-model="innerValue"
        :placeholder="defaultPlaceholder"
        :disabled="isDisabled"
        @keydown.native="$event.key === '-' ? $event.preventDefault() : null"
      />

      <input
        v-else
        ref="input"
        @focus="handleFocus"
        @blur="handleBlur"
        :class="{ 'error': errors[0], 'default': !errors[0], 'disabled': isDisabled, 'hide-caret': !hasCaret }"
        :type="inputType"
        :value="value"
        :placeholder="disableFloatingLabel ? placeholder : defaultPlaceholder"
        :maxlength="maxLength"
        autocomplete="off"
        v-model="innerValue"
        :disabled="isDisabled"
        @keyup.enter="enterSubmit"
      />

      <label v-if="!disableFloatingLabel" :class="{ 'disabled': isDisabled }">{{ placeholder }}</label>

      <div v-if="isPassword() && !!innerValue" class="icon-container" @click="toggleVisibility">
        <i :class="visibility ? 'icon-eye-slash' : 'icon-eye'" />
      </div>

      <div v-if="!!lastIcon" class="icon-container" @click="enterSubmit">
        <i :class="lastIcon"/>
      </div>
    </div>

    <div v-if="passwordValidation && !!innerValue" class="password-validation">
      <div class="left">
        <div class="validation" :class="hasSize">
          <i class="icon-circle" />

          <p>Mínimo de 6 caracteres</p>
        </div>

        <div class="validation" :class="hasInvalidCombination">
          <i class="icon-circle" />

          <p>Combinação válida</p>
        </div>
      </div>

      <div class="right">
        <div class="validation" :class="hasNumber">
          <i class="icon-circle" />

          <p>Números</p>
        </div>

        <div class="validation" :class="hasUpperLowCase">
          <i class="icon-circle" />

          <p>Letras maiúsculas e minúsculas</p>
        </div>
      </div>
    </div>

    <div v-if="!passwordValidation && !focusStatus && !isDisabled" class="errors">
      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import { Money } from 'v-money';
  import MasterDefault from '@/assets/brand/MasterDefault.png';
  import Master from '@/assets/brand/Master.png';
  import Elo from '@/assets/brand/Elo.png';
  import Visa from '@/assets/brand/Visa.png';
  import Aura from '@/assets/brand/Aura.png';
  import Jcb from '@/assets/brand/Jcb.png';
  import Amex from '@/assets/brand/Amex.png';
  import Hiper from '@/assets/brand/Hiper.png';
  import HiperCard from '@/assets/brand/HiperCard.png';
  import BanesCard from '@/assets/brand/BanesCard.png';

  export default {
    name: 'InputField',

    props: {
      type: {
        type: String,
        default: 'text',
        validator(value) {
          return [
            'url',
            'text',
            'password',
            'tel',
            'search',
            'number',
            'email',
            'money'
          ].includes(value);
        },
      },

      vid: {
        type: String,
        default: undefined
      },

      placeholder: {
        type: String,
        default: 'Placeholder'
      },

      mask: {
        type: [Array, String],
        required: false
      },

      rules: {
        type: [Object, String],
        default: ''
      },

      passwordValidation: {
        type: Boolean,
        default: false
      },

      value: {
        type: [String, Number],
        default: ''
      },

      maxLength: {
        type: Number,
        required: false
      },

      name: {
        type: String,
        required: false
      },

      isDisabled: {
        type: Boolean,
        default: false
      },

      isCurrency: {
        type: Boolean,
        default: false
      },

      hasCaret: {
        type: Boolean,
        default: true
      },

      inputSleep: {
        type: Number,
        default: 750
      },

      hasBrand: {
        type: Boolean,
        default: false
      },

      disableFloatingLabel: {
        type: Boolean,
        default: false
      },

      disableFocusStyle: {
        type: Boolean,
        default: false
      },

      hasIconFetch: {
        type: Boolean,
        required: false
      },

      lastIcon: {
        type: String,
        required: false
      }
    },

    components: {
      ValidationProvider,
      Money
    },

    data() {
      return {
        innerValue: '',
        visibility: false,
        inputType: 'text',
        focusStatus: false,
        content: this.value,
        defaultPlaceholder: ' ',
        timer: 0,
        inputName: '',
        hasSize: null,
        hasNumber: null,
        hasSpecialCharacter: null,
        hasUpperLowCase: null,
        hasInvalidCombination: null,

        path: MasterDefault,
        master: Master,
        visa: Visa,
        elo: Elo,
        aura: Aura,
        jcb: Jcb,
        amex: Amex,
        hiper: Hiper,
        hiperCard: HiperCard,
        banesCard: BanesCard,
        masterDefault: MasterDefault
      };
    },

    created() {
      this.inputType = this.type;

      if (this.isMoneyInput()) {
        this.inputType = 'text';
      }

      if (this.value) {
        this.innerValue = this.value;
      }
    },

    mounted() {
      if(this.name) this.inputName = this.name;
      else this.inputName = this.placeholder.toLowerCase();
    },

    computed: {
      brandImage() {
        return 'url(' + `${this.path}` + ') #FFF no-repeat 95%';
      }
    },

    watch: {
      innerValue(value) {
        this.$emit('input', value);

        if (this.hasBrand) {
          let currentCardValidation = this.$service.cardValidation(value);
          this.path = this.identifyBrand(currentCardValidation ? currentCardValidation.name : '');
        }

        if (this.hasIconFetch) {
          clearTimeout(this.timer);

          this.timer = setTimeout(() => {
            this.iconEmit();
          }, this.inputSleep);
        }

        if (this.passwordValidation) {
          this.verifyPassword(value);
        }
      },

      value(value) {
        if (value !== this.innerValue) {
          this.innerValue = value;
        }
      }
    },

    methods: {
      enterSubmit() {
        if (this.hasIconFetch) {
          clearTimeout(this.timer);

          this.iconEmit();
        }
      },

      iconEmit() {
        this.$emit('iconClick');
      },

      verifyPassword(value) {
        let regNumber = /[0-9]/;
        let regSpecialCharacter = /^(?=.*[@!#$%^&*()/\\])/;
        let regUpperLowCase = /[A-Z]/;
        let regInvalidCombination = /^(?=.*\d(?=.*\d))(?=.*[a-zA-Z](?=.*[a-zA-Z])).{6,}$/;

        if (value.length >= 6) this.hasSize = 'success';
        else this.hasSize = 'error';

        if (regNumber.test(value)) this.hasNumber = 'success';
        else this.hasNumber = 'error';

        if (regSpecialCharacter.test(value)) this.hasSpecialCharacter = 'success';
        else this.hasSpecialCharacter = 'default';

        if (regUpperLowCase.test(value)) this.hasUpperLowCase = 'success';
        else this.hasUpperLowCase = 'default';

        if (regInvalidCombination.test(value)) this.hasInvalidCombination = 'success';
        else this.hasInvalidCombination = 'error';
      },

      isMoneyInput() {
        return this.type == 'money';
      },

      handleFocus() {
        this.focusStatus = true;
      },

      handleBlur() {
        this.focusStatus = false;
        this.$emit('blur', true);
      },

      isPassword() {
        return this.type == 'password';
      },

      toggleVisibility() {
        if (this.visibility) {
          this.visibility = false;
          this.inputType = 'password';
        } else {
          this.visibility = true;
          this.inputType = 'text';
        }
      },

      identifyBrand(brand) {
        switch (this.$service.toUpperCase(brand)) {
          case 'VISA':
            return this.visa;
          case 'MASTERCARD':
            return this.master;
          case 'ELO':
            return this.elo;
          case 'AURA':
            return this.aura;
          case 'JCB':
            return this.jcb;
          case 'AMERICANEXPRESS':
            return this.amex;
          case 'BANESCARD':
            return this.banesCard;
          case 'HIPER':
            return this.hiper;
          case 'HIPERCARD':
            return this.hiperCard;
          default:
            return this.masterDefault;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .input-field-container {
    margin-bottom: 1rem;
    width: 100%;

    &.has-icon {
      .input-container {
        input {
          padding: 17px 45px 0 16px;
        }
      }
    }

    &.disable-floating-label {
      .input-container {
        input {
          padding: 0 45px 0 16px;

          &::-webkit-input-placeholder {
            color: #DADADA
          }
        }
      }
    }

    &.disable-focus {
      .input-container {
        input {
          &:focus {
            border-color: #8E8E8E;
            box-shadow: none;
          }
        }
      }
    }

    .input-container {
      position: relative;

      .icon-container {
        padding: 0 0 0 10px;
        position: absolute;
        top: 14px;
        right: 14px;

        i {
          cursor: pointer;
          font-size: 22px;
          color: #8E8E8E;
        }
      }

      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: #333333;
        font-size: 14px;
        display: block;
        border-radius: 6px;
        height: 48px;
        width: 100%;
        background: transparent;
        border: 1px solid;
        padding: 17px 16px 0 16px;
        font-weight: 300;
        caret-color: $second-color;

        &:-webkit-autofill {
          font-family: 'Poppins', sans-serif;

          & ~ label {
            top:-20px;
          }
        }

        .hide-caret {
          caret-color: transparent;
        }

        &.error {
          border-color: $red-color;
          box-shadow: 0 0 0 1px $red-color, 0 0 0 3px $red-color-light;
        }

        &.success {
          border-color: #07a96e;
          box-shadow: 0 0 0 1px #12B16E, 0 0 0 3px #e7f8f2;
        }

        &.default {
          border-color: #DADADA;

          &.disabled {
            opacity: 0.8;
            cursor: not-allowed;
            border-color: #DADADA;
            background: #F9F9F9;
          }
        }

        &:focus {
          outline: none;
          border-color: $second-color;
          box-shadow: 0 0 0 1px $second-color, 0 0 0 3px $second-color-light-1;
        }

        &[type=number]::-webkit-inner-spin-button,
        &[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      label {
        color: #333;
        font-size: 14px;
        position: absolute;
        pointer-events: none;
        left: 17px;
        top: 15px;
        white-space: nowrap;
        overflow: hidden;
        max-width: 80%;
        font-weight: 300;
        user-select: none;
        transition: 0.07s ease all;
        -moz-transition: 0.07s ease all;
        -webkit-transition: 0.07s ease all;

         &.disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }

      input:focus ~ label, input:not(:placeholder-shown) ~ label {
        color: #8E8E8E;
        font-weight: 400;
        font-size: 12px;
        font-weight: 400;
        top: 5px;

        &.error {
          color: $red-color;
        }

        &.success {
          color: #12B16E;
        }
      }
    }

    .password-validation {
      display: flex;
      margin-top: 1rem;

      .right {
        margin-left: auto;
      }

      .validation {
        margin-bottom: 0.5rem;
        display: flex;

        i {
          font-size: 0.4rem;

          &.icon-circle {
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
          }
        }

        p {
          font-size: 12px;
        }

        &.success {
          color: #12B16E;
        }

        &.error {
          color: $red-color;
        }

        &.default {
          color: #8E8E8E;
        }
      }
    }

    .errors {
      span {
        color: $red-color;
        font-size: 12px;
        margin-top: 0.5rem;
        font-weight: 400;
      }
    }
  }

  @media (max-width: 1500px) {
    .input-field-container {
      .password-validation {
        flex-direction: column;

        .right {
          margin: 0;
        }
      }
    }
  }
</style>