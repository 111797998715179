<template>
  <div
    class="filter-transaction"
    :class="{ 'disabled': isDisabled, 'has-icon': data.icon }"
    @click="open"
  >
    <div class="icon-container">
      <i :class="data.icon"/>
    </div>

    <p>{{ data.text }}</p>
  </div>
</template>

<script>
  export default {
    name: 'FilterButton',

    props: {
      data: {
        type: Object,
        default() {
          return {
            icon: 'icon-close',
            text: 'Mais filtros'
          };
        }
      },

      isDisabled: {
        type: Boolean,
        default: true
      }
    },

    methods: {
      open() {
        if (!this.isDisabled) {
          this.$emit('open');
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .filter-transaction {
    border: 1px solid #DADADA;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
    cursor: pointer;

    &.disabled {
      background: #EFEFEF !important;
      opacity: 0.6;
      cursor: not-allowed;
    }

    &:hover {
      background: #8e8e8e47;
    }

    &.has-icon {
      padding: 0.5rem;
      width: 90px;
      height: 56px;

      p {
        color: #333;
        font-size: 12px;
        margin-top: 0.3rem;
      }
    }

    .icon-container {
      color: #8E8E8E;
      text-align: center;

      i {
        font-size: 1.2rem;

        &.icon-align {
          font-size: 0.8rem;
        }
      }
    }

    p {
      font-size: 14px;
    }
  }
</style>
