<template>
  <div class="simple-view-container" :class="{ 'has-modal': hasModal }">
    <slot name="alert"/>

    <slot name="modal"/>

    <div class="container">
      <div class="header">
        <slot name="header"/>
      </div>

      <slot name="content"/>

      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SimpleDefaultView',

    props: {
      hasModal: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss" scoped>
 .simple-view-container {
    &.has-modal {
      overflow: hidden;
      position: fixed;
      width: 100vw;
      min-width: 320px;
    }

    &.has-modal > *:not(.modal) {
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }

    .container {
      height: 100%;
      min-height: 100vh;

      .header {
        min-height: 80px;
        padding: 0 1.5rem;
        background: #F9F9F9;
        display: flex;

        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: auto;
          color: #333;
          cursor: pointer;

          i {
            color: #979797;
          }
        }
      }
    }
 }
</style>