<template>
  <modal
    ref="modal"
    class="modal"
    title=" "
    :showModal="true"
    @close="closeModal"
  >
    <template v-slot:body>
      <div class="content">
        <h3>Data</h3>

        <div class="field">
          <div class="day-container">
            Últimos:

            <simple-card-component
              class="simple-card-component"
              :data="lastDateFilter"
              @new="setLastDateFilter"
            />
          </div>

          <input-date-field
            class="input-date-field"
            :hasCalendarField="false"
            :rangeProp="data.dateRange"
            @click.native="openCalendarField"
          />

          <calendar-field-component
            v-if="showCalendarInputField"
            class="calendar-field-component"
            :rangeProp="data.dateRange"
            @close="closeCalendarField"
            v-click-outside="closeCalendarField"
          />
        </div>

        <div class="line"></div>

        <h3>Valor bruto</h3>

        <div class="field">
          <input-field
            class="input-field"
            placeholder="Mínimo"
            name="min"
            type="money"
            :hasCaret="false"
            :maxLength="24"
            v-model="
              // eslint-disable-next-line
              data.amountRange.min
            "
          />

          <input-field
            class="input-field"
            placeholder="Máximo"
            name="max"
            type="money"
            :hasCaret="false"
            :maxLength="24"
            v-model="
              // eslint-disable-next-line
              data.amountRange.max
            "
          />
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="footer-slot-container">
        <div class="reset-button" @click="resetModalFilter">
          <i class="icon-trash"/>

          <p>
            Limpar
          </p>
        </div>

        <button-component
          class="button-component"
          text="Filtrar"
          @click.native="applyFilter"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '@/components/Modal';
  import Button from '@/components/Button';
  import InputField from '@/components/InputField';
  import SimpleCard from '@/components/Table/SimpleCard';
  import InputDate from '@/components/InputDate';
  import CalendarField from '@/components/Calendar/CalendarField';

  export default {
    name: 'filterModal',

    data() {
      return {
        lastDateFilter: this.initialLastDateFilterData(),
        showCalendarInputField: false,
      };
    },

    components: {
      'modal': Modal,
      'button-component': Button,
      'input-field': InputField,
      'simple-card-component': SimpleCard,
      'calendar-field-component': CalendarField,
      'input-date-field': InputDate,
    },

    created() {
      this.resetModalFilter();
    },

    props: {
      data: {
        type: Object,
        default() {
          return {
            amountRange: {
              min: 0,
              max: 0
            },
            dateRange: {
              start: null,
              end: null
            }
          };
        }
      }
    },

    methods: {
      closeModal() {
        this.$emit('close');
      },

      initialLastDateFilterData() {
        return {
          isUnique: true,
          items: [
            {
              label: '3 dias',
              value: -3,
              isActive: false
            },

            {
              label: '7 dias',
              value: -7,
              isActive: false
            },

            {
              label: '30 dias',
              value: -30,
              isActive: false
            },

            {
              label: '45 dias',
              value: -45,
              isActive: false
            }
          ]
        };
      },

      setLastDateFilter() {
        const currentItem = this.lastDateFilter.items.filter(item => item.isActive)[0];

        // eslint-disable-next-line vue/no-mutating-props
        this.data.dateRange.end = this.$service.toDate(this.$service.currentDateEN());

        // eslint-disable-next-line vue/no-mutating-props
        this.data.dateRange.start = this.$service.toDate(this.$service.getDateDay(this.data.dateRange.end, currentItem.value));
      },

      openCalendarField() {
        this.showCalendarInputField = true;
      },

      closeCalendarField() {
        this.showCalendarInputField = false;
      },

      initialRangeDateFilterData() {
        return {
          start: null,
          end: null
        };
      },

      applyFilter() {
        this.$emit('clickApplyFilter');
        this.closeModal();
      },

      resetModalFilter() {
        // eslint-disable-next-line vue/no-mutating-props
        this.data.amountRange.min = 0;

        // eslint-disable-next-line vue/no-mutating-props
        this.data.amountRange.max = 0;

        // eslint-disable-next-line vue/no-mutating-props
        this.data.dateRange.start = null;

        // eslint-disable-next-line vue/no-mutating-props
        this.data.dateRange.end = null;

        this.lastDateFilter = this.initialLastDateFilterData();
      },
    }
  };
</script>

<style lang="scss" scoped>
  .modal {
    .content {
      margin-top: 1.5rem;

      .line {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        height: 1px;
        background: #EFEFEF;
        width: 100%;
      }

      .field {
        display: flex;
        margin-top: 1rem;
        gap: 16px;

        .input-date-field {
          margin-left: auto;
        }
      }
    }

    .footer-slot-container {
      display: flex;
      padding: 0.75rem 1rem;
      box-shadow: 0px -1px 6px rgb(0 0 0 / 8%);


      .reset-button {
        display: flex;
        align-items: center;
        color: #8E8E8E;
        cursor: pointer;
        margin-right: 3rem;

        p {
          margin-left: 0.5rem;
          font-weight: 600;
        }
      }

      .button-component {
        height: 48px;
        width: 110px;
        margin-left: auto;
      }
    }
  }

  @media (max-width: 635px) {
    .modal {
      .content {
        .field {
          flex-direction: column;

          .input-date-field {
            margin-top: 1rem;
            margin-left: unset;
          }
        }
      }
    }
  }
</style>