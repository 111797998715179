<template>
  <div v-click-outside="close">
    <div class="input-checkbox-field" :class="{ 'active': isActive, 'disabled': isDisabled }" @click="toggleState">
      <div class="input-content">
        <label>Status</label>

        <p>{{ status }}</p>
      </div>

      <i v-if="isActive" class="icon-arrow-up-filled" />
      <i v-else class="icon-arrow-down-filled" />
    </div>

    <div class="container">
      <div v-if="isActive" class="content" >
        <div class="items">
          <div v-for="(item, index) in data" :key="index" class="item">
            <radio-component
              class="radio-component"
              :value="item.isChecked"
              @click.native="checkedItem(item)"
            />

            {{ item.label }}
          </div>
        </div>

        <div class="footer">
          <div class="reset" @click="reset">
            <i class="icon-trash" />

            <p>Limpar</p>
          </div>

          <button-component
            class="button"
            text="Aplicar"
            @click.native="apply"
          />
        </div>
      </div>

      <div class="spacer"></div>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/Button';
  import Radio from '@/components/Radio';

  export default {
    data() {
      return {
        isActive: false,
        status: 'Todos',
        currentFilterName: '',
      };
    },

    components: {
      'button-component': Button,
      'radio-component': Radio
    },

    props: {
      data: {
        type: Array,
        default: () => [
          {
            label: 'Default',
            name: 'default',
            isChecked: false
          },

          {
            label: 'Defualt 2',
            name: 'default_2',
            isChecked: false
          }
        ]
      },

      isDisabled: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      toggleState() {
        if (!this.isDisabled) {
          this.isActive = !this.isActive;
        }
      },

      close() {
        this.isActive = false;

        this.reset();

        if (!this.$service.isBlank(this.currentFilterName)) {
          let filterCurrentItem = this.data.filter(item => this.currentFilterName == item.name)[0];
          this.checkedItem(filterCurrentItem);
        }
      },

      checkedItem(item) {
        this.reset();

        item.isChecked = true;
        this.status = item.label;
      },

      reset() {
        this.data.map(item => item.isChecked = false);
        this.status = 'Todos';
      },

      apply() {
        let currentFilter = this.data.filter(item => item.isChecked)[0];
        this.currentFilterName = this.$service.isUndefined(currentFilter) ? '' : currentFilter.name;

        this.$emit('clickApplyFilter');
        this.close();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .input-checkbox-field {
    display: flex;
    height: 48px;
    width: 175px;
    border: 1px solid #DADADA;
    border-radius: 6px;
    align-items: center;
    padding: 0 1rem;
    margin-right: 1rem;
    cursor: pointer;
    color: #333;

    &.disabled {
      background: #EFEFEF;
      cursor: not-allowed;
      opacity: 0.6;
    }

    i {
      font-size: 0.3rem;
      margin-left: auto;
      color: #8E8E8E;
      padding: 0 0 0 0.2rem;
    }

    .input-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      label {
        font-size: 12px;
        color: #6D6F6F;
        font-weight: 400;
      }

      p {
        font-size: 14px;
      }
    }

    &.active {
      border-color: $second-color;
      box-shadow: 0 0 0 1px $second-color, 0 0 0 3px $second-color-light-1;
    }
  }

  .container {
    position: absolute;

    .content {
      background: $white-color;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      min-width: 250px;
      margin-top: 1rem;

      .items {
        padding: 1rem;

        .item {
          display: flex;
          align-items: center;
          margin-bottom: 0.7rem;

          .checkbox-component {
            margin-right: 10px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .footer {
        height: 56px;
        padding: 0.5rem 1rem;
        box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);
        display: flex;

        .reset {
          display: flex;
          align-items: center;
          color: #8E8E8E;
          cursor: pointer;
          margin-right: 3rem;

          p {
            margin-left: 0.5rem;
            font-weight: 600;
          }
        }

        .button {
          height: 100%;
        }
      }
    }

    .spacer {
      height: 3rem;
    }
  }
</style>