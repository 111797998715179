import { extend } from 'vee-validate';
import Service from '@/services/service';
import { required, email, confirmed, min } from 'vee-validate/dist/rules';
import moment from 'moment';

extend('min', {
  ...min,
  message: 'Campo deve ter {length} caracteres',
});

extend('required', {
  ...required,
  message: 'Campo obrigatório',
});

extend('email', {
  ...email,
  message: 'E-mail inválido',
});

extend('mail', {
  message: 'E-mail inválido',
  validate: value => {
    if (value != null) {
      let regEmail = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

      return !!value.match(regEmail);
    }
  }
});

extend('confirmed', {
  ...confirmed,
  message: 'Senhas não conferem',
});

extend('cep', {
  message: 'CEP inválido',
  validate: value => {
    return Service.removeCharacter(value).length === 8;
  }
});

extend('checkboxRequired', {
  message: 'Campo obrigatório',
  validate: value => {
    return value;
  }
});

extend('phone', {
  message: 'Número inválido',
  validate: value => {
    return Service.removeCharacter(value).length == 10 || Service.removeCharacter(value).length == 11;
  }
});

extend('cardExpirationData', {
  message: 'Data inválido',
  validate: value => {
    let formattedDate = Service.removeCharacter(value);

    if (formattedDate.length == 4) {
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = new Date().getFullYear().toString().substr(-2);

      let validateMonth = formattedDate.toString().substr(0, 2);
      let validateYear = formattedDate.toString().substr(-2);

      if ((validateMonth >= currentMonth && validateYear >= currentYear)
        || (validateYear > currentYear)) {
        return moment(formattedDate, "MM-YY").isValid();
      }
    } else {
      return false;
    }
  }
});

extend('futureDate', {
  message: 'Data futura inválida',
  validate: value => {
    if (value.length == 10) {
      return moment(Service.dateFormat(value, 'DD/MM/YYYY')).isAfter(Service.currentDateEN());
    }
  }
});

extend('maxYearDate', {
  message: (_, args) => 'Vencimento máximo ' + args[0] + ' anos',
  validate: (value, args) => {
    if (value.length == 10) {
      let currentDate = Service.currentDateEN();
      let futureArgsYear = moment(currentDate).add(args[0], 'y').format('YYYY-MM-DD');

      return moment(Service.dateFormat(value, 'DD/MM/YYYY')).isBefore(futureArgsYear);
    }
  }
});

extend('maxEqualMoney', {
  message: (_, args) => 'Valor máximo ' + Service.formatBrlAmountSymbol(args[0].split(':')[0]),
  validate: (_, args) => {
    let splitString = args[0].split(':');

    return parseFloat(splitString[1]) <= parseFloat(splitString[0]);
  }
});


extend('maxMoney', {
  message: (_, args) => 'Valor máximo ' + Service.formatBrlAmountSymbol(args[0].split(':')[0]),
  validate: (_, args) => {
    let splitString = args[0].split(':');

    return parseFloat(splitString[1]) < parseFloat(splitString[0]);
  }
});

extend('passwordCombination', {
  message: () => 'Combinação de senha inválida',
  validate: (value) => {
    return !!value.match(/^(?=.*\d(?=.*\d))(?=.*[a-zA-Z](?=.*[a-zA-Z])).{6,}$/);
  }
});