<template>
  <simple-default-view-component class="simple-default-view-component">
    <template v-slot:header>
      <div class="title">
        <h2>Nova venda</h2>
      </div>

      <div class="icon-container">
        <i class="icon-close" @click="close"/>
      </div>
    </template>

    <template v-slot:content>
      <div class="content">
        <div class="column">
          <h3>Descrição</h3>

          <p>Alguma descrição adicional?</p>

          <textarea-component
            class="textarea-component"
            placeholder="Digite a descrição dessa venda aqui..."
            v-model="resumeDescription"
            maxHeight="200px"
            minHeight="110px"
            maxWidth="455px"
            minWidth="455px"
          />

          <div class="image-container">
            <p>Antes de finalizar, confira o resumo da venda...</p>

            <img src="@/assets/images/illustration-description.png" />
          </div>
        </div>

        <div class="vertical-line">
          <div class="line"></div>
        </div>

        <div class="column">
          <h3>Resumo</h3>

          <div class="items-container">
            <div v-for="(item, index) in resumeData" :key="index" class="item-content">
              <div class="item">
                <p class="label">{{ item.label }}</p>

                <div v-if="item.values" class="values">
                  <p class="value" v-for="(item, index) in item.values" :key="index">{{ item.value }}</p>
                </div>

                <div v-else-if="item.params" class="params">
                  <p class="label">{{ item.params.label }}</p>

                  <p class="value">{{ item.params.value }}</p>
                </div>

                <p v-else class="value">{{ item.value }}</p>
              </div>

              <div v-if="item.items" class="items">
                <div v-for="(item, index) in item.items" :key="index" class="item">
                  <p class="label">{{ item.label }}</p>

                  <div v-if="Array.isArray(item.params)" class="params text">
                    <p class="value" v-for="(item, index) in item.params" :key="index">{{ item.value }}</p>
                  </div>

                  <div v-else-if="item.params && item.params.label" class="params">
                    <p class="label">{{ item.params.label }}</p>

                    <p class="value">{{ item.params.value }}</p>
                  </div>

                  <p v-else class="value">{{ item.value }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="footer-container">
        <button-component
          class="button-back-component"
          iconLeft="icon-arrow-left"
          styles="secondary"
          text="Voltar"
          @click.native="toNew"
          :isDisabled="hasRequest"
          v-ripple="'rgba(255, 139, 0, 0.2)'"
        />

        <button-component
          class="button-component"
          text="Próximo"
          :hasLoader="hasRequest"
          :isDisabled="hasRequest"
          @click.native="toSuccess"
          v-ripple="'rgba(255, 255, 255, 0.35)'"
        />
      </div>
    </template>
  </simple-default-view-component>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SimpleDefaultView from '@/components/Views/SimpleDefaultView';
  import TextArea from '@/components/TextAreaField';
  import Button from '@/components/Button';

  export default {
    name: 'NewSaleResume',

    data() {
      return {
        hasRequest: false,
        customerData: {},
        resumeDescription: '',

        resumeData: []
      };
    },

    components: {
      'simple-default-view-component': SimpleDefaultView,
      'textarea-component': TextArea,
      'button-component': Button
    },

    computed: {
      ...mapGetters({
        getCurrentSellerData: 'getCurrentSellerData',
        getSaleProgress: 'newSaleModule/getProgressStatus',
        getSaleData: 'newSaleModule/getData',
        getResumeDescription: 'newSaleModule/getResumeDescription'
      })
    },

    created() {
      this.resumeData = this.formatData();

      this.resumeDescription = this.getResumeDescription;

      if (!this.$route.params?.hasNewSale && !this.getSaleProgress) {
        this.toNew();
      } else if (!this.getCurrentSellerData.is_sale_enabled && !this.getSaleProgress) {
        this.close();
      }
    },

    methods: {
      close() {
        this.$router.push('/dashboard');

        this.$store.commit('newSaleModule/resetData');
      },

      toNew() {
        this.$router.push({
          name: 'new-sale',
          params: {
            hasNewSale: true
          }
        });
      },

      formatData() {
        var saleItem = this.getSaleData.items.map(item => {
          return {
            label: item.description,
            value: this.$service.formatBrlAmountSymbol(item.price)
          };
        });

        var paymentMethodItem = this.getSaleData.paymentTypeData.reduce((res, item) => {
          if (item.isActive) {
            let isCredit = item.value == 'credit';

            res.push({
              label: item.text,
              params: {
                label: isCredit ? 'Até' : '',
                value: isCredit ? this.getSaleData.maxInstallments + 'x de ' + this.$service.formatBrlAmountSymbol(this.getSaleData.totalAmount) : ''
              }
            });
          }

          return res;
        }, []);

        var data = [
          {
            key: 'paymentType',
            label: 'Como você quer vender',
            value: this.isCheckout() ? 'Link avulso' : 'Boleto'
          },

          {
            key: 'customer',
            label: 'Cliente',
            values: [
              {
                value: this.getSaleData.selectedCustomer?.name
              },

              {
                value: this.$service.formatTaxpayerId(this.getSaleData.customer.taxpayerId)
              }
            ]
          },

          {
            key: 'saleItem',
            label: 'O que você esta vendendo',

            params: {
              label: 'Valor total:',
              value: this.$service.formatBrlAmountSymbol(this.formatTotalAmount())
            },

            items: saleItem
          },

          {
            key: 'paymentMethod',
            label: 'Qual a opção de pagamento',
            items: paymentMethodItem
          },

          {
            key: 'paymentInterestAndFine',
            label: 'Instruções de cobrança',
            items: [
              {
                label: 'Multa',
                value: this.getSaleData.lateFeeRate ? this.getSaleData.lateFeeRate + '%' : 'Nenhum'
              },

              {
                label: 'Juros',
                value: this.getSaleData.dailyInterestRate ? this.getSaleData.dailyInterestRate + '% ao mês' : 'Nenhum'
              },

              {
                label: 'Desconto',
                value: this.getSaleData.discount ? this.$service.formatBrlAmountSymbol(this.getSaleData.discount) : 'Nenhum'
              }
            ]
          },

          {
            key: 'dueDate',
            label: 'Quando vai vencer',
            value: this.$service.dateFormat(this.getSaleData.dueDate, 'YYYY-MM-DD', 'DD/MM/YYYY')
          }
        ];

        if (!this.isCheckout()) {
          data = data.filter(item => item.key != 'paymentMethod');
        }

        return data;
      },

      async createSlip() {
        var data = {
          amount: Math.round(this.formatTotalAmount() * 100),
          metadata: {
            resource: 'boletoImPay'
          },
          customer: {
            taxpayer_id: this.getSaleData.customer.taxpayerId,
            first_name: this.getSaleData.customer.firstName,
            last_name: this.getSaleData.customer.lastName,
            address: {
              line1: this.getSaleData.customer.addressLine1,
              neighborhood: this.getSaleData.customer.addressNeighborhood,
              city: this.getSaleData.customer.addressCity,
              state_code: this.getSaleData.customer.addressStateCode,
              postal_code: this.getSaleData.customer.addressPostalCode
            }
          },
          payment: {
            method: 'Boleto',
            boleto: {
              due_date: this.$service.dateFormat(this.getSaleData.dueDate),
              billing_instructions: {}
            }
          },
        };

        if (this.getSaleData.lateFeeRate) {
          data.payment.boleto.billing_instructions.late_fee = {
            percentage: this.getSaleData.lateFeeRate
          };
        }

        if (this.getSaleData.dailyInterestRate) {
          data.payment.boleto.billing_instructions.interest = {
            percentage: this.getSaleData.dailyInterestRate
          };
        }

        if (this.getSaleData.discount) {
          data.payment.boleto.billing_instructions.discount = {
            amount: this.getSaleData.discount * 100
          };
        }

        await this.$store.dispatch('postTransaction', JSON.stringify(data))
          .then(res => {
            this.$store.commit('newSaleModule/setUrl', res.data.payment.boleto.url);
          })
          .catch(err => {
            console.log(err);
          });
      },

      async createCheckout() {
        await this.$store.dispatch('paymentModule/postCheckout', JSON.stringify({
            customer_name: this.getSaleData.selectedCustomer.name,
            description: 'Link pagamento',
            metadata: {
              resource: 'checkoutImPay'
            },
            items: this.formatCheckoutItems(),
            is_pix_enabled: this.getSaleData.paymentTypeData.find(item => item.value == 'pix')?.isActive,
            is_credit_enabled: this.getSaleData.paymentTypeData.find(item => item.value == 'credit')?.isActive,
            due_date: this.$service.dateFormat(this.getSaleData.dueDate),
            late_fee_rate: this.getSaleData.lateFeeRate,
            daily_interest_rate: this.getSaleData.dailyInterestRate
          }))
          .then(res => {
            this.$store.commit('newSaleModule/setUrl', process.env.VUE_APP_BASE_CHECKOUT_URL + '/checkout/' + res.data.id);
          })
          .catch(err => {
            console.log(err);
          });
      },

      formatCheckoutItems() {
        var arr = [];

        this.getSaleData.items.forEach(item => {
          arr.push({
            price: item.price,
            quantity: 1,
            description: item.description,
            max_installments: this.getSaleData.maxInstallments,
          });
        });

        return arr;
      },

      formatTotalAmount() {
        var total = 0;

        this.getSaleData.items.forEach(item => {
          total += item.price;
        });

        return total;
      },

      async toSuccess() {
        this.hasRequest = true;

        if (this.isCheckout()) {
          await this.createCheckout();
        } else {
          await this.createSlip();
        }

        this.$router.push({
          name: 'new-sale-success',
          params: {
            hasNewSale: true
          }
        });

        this.hasRequest = false;
      },

      isCheckout() {
        return this.getSaleData.paymentType == 'checkout';
      }
    },

    beforeDestroy() {
      this.$store.commit('newSaleModule/setResumeDescription', this.resumeDescription);
    }
  };
</script>

<style lang="scss" scoped>
  .simple-default-view-component {
    .title {
      display: flex;
      align-items: center;

      h2 {
        color: #333333;
      }
    }

    .content {
      display: flex;
      padding: 1.5rem;
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - 160px);

      .vertical-line {
        padding: 0 2rem;

        .line {
          width: 1px;
          height: calc(100% - 210px);
          position: absolute;
          background: #EFEFEF;
        }
      }

      .column {
        width: 100%;

        &:first-child {
          margin-right: 2rem;
        }

        h3 {
          margin-bottom: 0.5rem;
        }

        .image-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background: #F9F9F9;
          padding: 2rem;
          border-radius: 6px;
          margin-top: 3rem;

          p {
            margin-bottom: 1rem;
            font-weight: 600;
            color: #333;
            font-size: 16px;
            text-align: center;
          }
        }

        .textarea-component {
          margin-top: 1rem;
        }

        .items-container {
          .item-content {
            display: flex;
            flex-direction: column;
            margin-bottom: 3rem;
            margin-top: 1rem;

            .item {
              display: flex;
              width: 100%;

              .label {
                font-weight: 500;
              }

              .value {
                display: flex;
                margin-left: auto;
              }

              .values {
                flex-direction: column;
                margin-left: auto;

                .value {
                  justify-content: end;
                }
              }

              .params {
                display: flex;
                margin-left: auto;

                .label {
                  font-weight: 300;
                }

                .value {
                  margin-left: 0.5rem;
                  font-weight: 500;
                }
              }
            }

            .items {
              .item {
                margin-top: 0.5rem;

                .params {
                  &.text {
                    flex-direction: column;

                    .value {
                      font-weight: 300;
                    }
                  }
                }

                .label {
                  font-weight: 300;
                }
              }
            }
          }
        }
      }
    }

    .footer-container {
      height: 80px;
      display: flex;
      padding: 1rem;
      align-items: center;
      box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);
      width: 100%;
      background: $white-color;
      position: fixed;
      bottom: 0;
      z-index: 10;

      .button-component {
        height: 48px;
        width: 180px;
        margin-left: auto;
      }

      .button-back-component {
        height: 48px;
      }
    }
  }

  @media (max-width: 945px) {
    .simple-default-view-component {
      .content {
        flex-direction: column;

        .column {
          &:first-child {
            margin-right: 0;
            margin-bottom: 1.5rem;
          }

          .image-container {
            display: none;
          }

          .items-container {
            .item-content {
              margin-bottom: 2rem;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .vertical-line {
          display: none;
        }
      }
    }
  }

  @media (max-width: 440px) {
    .simple-default-view-component {
      .content {
        .column {
          .items-container {
            .item-content {
              .item {
                flex-direction: column;

                .label {
                  margin-bottom: 0.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
</style>