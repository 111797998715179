<template>
  <div>
    <div v-if="hasRequest">
      <spinner-component fontSize="1.5rem" :color="$theme.secondColor"/>
    </div>

    <p v-else-if="isDisabled" class="pagination-table-button" v-ripple="$theme.secondColorTransparent" @click="onClick">
      Mostrando {{ data.countItems }} de {{ data.totalCountItems }}
    </p>

    <p v-else class="pagination-table-button disabled">
      Mostrando {{ data.countItems }} de {{ data.totalCountItems }}
    </p>
  </div>
</template>

<script>
  import Spinner from '@/components/Spinner';

  export default {
    name: 'PaginationTableButton',
    props: {
      data: {
        type: Object,
        default() {
          return {
            countItems: 'x',
            totalCountItems: '2x'
          };
        }
      },

      isDisabled: {
        type: Boolean,
        default: false
      },

      hasRequest: {
        type: Boolean,
        default: true
      }
    },

    components: {
      'spinner-component': Spinner
    },

    methods: {
      onClick() {
        this.$emit('onClick');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .pagination-table-button {
    width: fit-content;
    font-weight: 500;
    color: $second-color;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    padding: 0.5rem 1rem;
    border-radius: 6px;

    &.disabled {
      cursor: not-allowed;
      color: #EFEFEF;
      opacity: 0.6;

      &:hover {
        background: transparent;
      }
    }

    &:hover {
      background: $second-color-transparent;
    }
  }
</style>