<template>
  <div
    class="default-data"
    :class="{ 'blue': hasDashboard }"
    :style="{
      'background-color': colors.primaryColor,
      'background-image': `linear-gradient(-90deg, ${colors.primaryColor} 0%, ${colors.secondColor} 50%, ${colors.primaryColor} 100%)`
    }">
  </div>
</template>

<script>
  export default {
    name: 'default-data',

    props: {
      hasDashboard: {
        type: Boolean,
        default: false
      },

      colors: {
        type: Object,
        default() {
          return {
            primaryColor: '#f6f8f9',
            secondColor: '#efefef'
          };
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .default-data {
    border-radius: 6px;
    animation: loadingGradient 1.5s linear infinite;
    background-position: -300px 0;
    background-repeat: no-repeat;
    background-size: 300px 100%;
  }

  @keyframes loadingGradient {
    0% {
      background-position: -300px 0
    }

    100% {
      background-position: 800px 0
    }
  }

  @media (max-width: 1270px) {
    .default-data {
      &.blue {
        background-color: $first-color-dark-2 !important;
        background-image: linear-gradient(-90deg, $first-color-dark-2 0%, $first-color 50%, $first-color-dark-2 100%) !important;
      }
    }
  }
</style>