<template>
  <ValidationProvider
    tag="div"
    ref="selectComponent"
    class="select-container"
    :name="inputName"
    :rules="mountedRules"
    v-slot="{ errors }"
  >
    <div class="select-content" :class="{ 'open': open, 'disabled': isDisabled }" v-click-outside="clickOutside">
      <div class="select" :class="{ 'open': open, 'error': errors[0], 'default': !errors[0] }" @click="toggleState">
        <div class="selected">
          <label :class="selected ? 'placeholder' : ''">{{ placeholder }}</label>

          <label v-if="!open" class="option-selected">{{ selected.label }}</label>
        </div>

        <div class="icon-container">
          <i v-if="open" class="icon-arrow-up-filled"/>
          <i v-else class="icon-arrow-down-filled"/>
        </div>
      </div>

      <div class="options-container" :class="{ 'hide': !open }">
        <div v-if="search" class="search-container">
          <input type="text" v-model="searchQuery" placeholder="Procurar...">

          <div class="icon-container">
            <i :class="searchQuery == '' ? 'icon-search' : 'icon-close'" @click="cleanSearchQuery" />
          </div>
        </div>

        <div v-if="hasOption && !!options" class="options" :class="{ 'search': search }">
          <div class="options-background">
            <div
              v-for="(itemCategory, index) in formattedCategories"
              :key="index"
            >
              <div v-if="orderByCategory" class="category-container">
                <div v-if="searchQuery == ''" class="category">
                  {{ itemCategory }}
                </div>
              </div>

              <div
                v-for="(item, index) in filteredItems"
                :key="index"
              >
                <div
                  v-if="itemCategory == item.category"
                  class="option"
                  :class="{ 'actived': item.isActive }"
                  @click="
                    selected = item;
                    open = false;
                    $emit('input', item.value);
                    activeOption(item);
                  "
                >
                  {{ item.label }}
                </div>
              </div>
            </div>

            <div v-if="!filteredItems.length" class="option disable">
              Nenhum resultado!
            </div>
          </div>
        </div>

        <div v-else-if="!hasOption && !!options" class="options" :class="{ 'search': search }">
          <div v-if="search" class="options-background">
            <div class="options-background">
              <div
                v-for="(item, index) in filteredItems"
                :key="index"
              >
                <div
                  class="option"
                  :class="{ 'actived': item.isActive }"
                  @click="
                    selected = item;
                    open = false;
                    $emit('input', item.value);
                    activeOption(item);
                  "
                >
                  {{ item.label }}
                </div>
              </div>

              <div v-if="!filteredItems.length" class="option disable">
                Nenhum resultado!
              </div>
            </div>
          </div>

          <div v-else>
            <div class="options-background">
              <div
                v-for="(item, index) in options"
                :key="index"
              >
                <div
                  class="option"
                  :class="{ 'actived': item.isActive }"
                  @click="
                    selected = item;
                    open = false;
                    $emit('input', item.value);
                    activeOption(item);
                  "
                >
                  {{ item.label }}
                </div>
              </div>

              <div v-if="!options.length" class="option disable">
                Nenhum resultado!
              </div>
            </div>
          </div>
        </div>

        <div v-else class="options" :class="{ 'search': search }">
          <div class="options-background">
            <div class="option disable">
              Carregando informações...
            </div>
          </div>
        </div>
      </div>

      <span v-if="!!errors[0] && selected == ''">{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';

  export default {
    name: 'SelectField',

    props: {
      placeholder: {
        type: String,
        required: true,
      },

      options: {
        type: Array,
        required: false,
      },

      rules: {
        type: String,
        default: '',
      },

      value: {
        type: String,
        required: false,
      },

      search: {
        type: Boolean,
        default: false,
      },

      openSelect: {
        type: Boolean,
        required: false,
      },

      orderByCategory: {
        type: Boolean,
        default: false
      },

      isDisabled: {
        type: Boolean,
        default: false
      }
    },

    components: {
      ValidationProvider,
    },

    data() {
      return {
        oldItem: {},
        currentValue: null,
        open: false,
        mountedRules: '',
        selected: '',
        searchQuery: '',
        categories: [],
        hasValue: false,
        inputName: '',
        hasOption: false,
      };
    },

    computed: {
      formattedCategories() {
        this.options.forEach(item => {
          if (!this.categories.includes(item.category)) this.categories.push(item.category);
        });

        return this.categories;
      },

      filteredItems() {
        if (!this.searchQuery.toLowerCase().trim()) return this.options;

        return this.options.filter(item => {
          return item.label.toLowerCase().indexOf(this.searchQuery.toLowerCase().trim()) > -1;
        });
      },
    },

    mounted() {
      if(this.currentValue) this.$emit('input', this.selected);

      if(this.value != '' && this.value != undefined) {
        this.currentValue = this.value;
        this.hasValue = true;
      } else {
        this.currentValue = null;
        this.hasValue = false;
      }

      this.mountedRules = this.rules;

      this.inputName = this.placeholder.toLowerCase();

      if(this.options != undefined) {
        this.options.forEach(item => {
          item.isActive = false;
        });
      }
    },

    watch: {
      options() {
        this.hasOption = true;

        if(this.hasValue) {
          this.options.forEach(item => {
            if(this.currentValue == item.value) {
              this.selected = item;
              item.isActive = true;
              this.$emit('input', item.value);
            }
          });
        }
      },

      selected() {
        this.$refs.selectComponent.errors = [];

        if (this.selected != '') {
          this.mountedRules = '';
        }
      },

      openSelect() {
        this.open = true;
      }
    },

    methods: {
      cleanSearchQuery() {
        this.searchQuery = '';
      },

      activeOption(item) {
        if (this.oldItem != undefined) this.oldItem.isActive = false;

        item.isActive = true;

        this.oldItem = item;
      },

      toggleState() {
        if (!this.isDisabled) {
          this.open = !this.open;
        }
      },

      clickOutside() {
        this.open = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .select-container {
    width: 100%;

    .select-content {
      margin-bottom: 16px;
      position: relative;
      width: 100%;

      &.disabled {
        background: #F9F9F9;
        opacity: 0.8;

        .select {
          cursor: not-allowed;

          .selected {
            label {
              cursor: not-allowed;
            }
          }
        }
      }

      &.open {
        border-radius: 10px 10px 0 0;
      }

      .select {
        height: 48px;
        color: #333333;
        border: 1px solid #DADADA;
        align-items: center;
        font-size: 14px;
        display: flex;
        border-radius: 6px;
        padding: 0 1rem;
        cursor: pointer;
        user-select: none;

        .selected {
          white-space: nowrap;
          overflow: hidden;
          width: 100%;

          label {
            transition: 0.2s ease all;
            -moz-transition: 0.2s ease all;
            -webkit-transition: 0.2s ease all;
            cursor: pointer;

            &.placeholder {
              position: absolute;
              top: 5px;
              color: #8E8E8E;
              font-size: 12px;
            }

            &.option-selected {
              font-size: 14px;
              top: 22px;
              position: absolute;
              width: calc(100% - 53px);
              overflow: hidden;
            }
          }
        }

        .icon-container {
          align-items: center;
          margin-left: auto;
          display: flex;
          margin-left: 0.4rem;

          i {
            font-size: 0.3rem;
            color: #979797;
          }
        }

        &.error {
          border-color: $red-color;
          box-shadow: 0 0 0 1px $red-color, 0 0 0 3px $red-color-light;
        }

        &.open {
          border-bottom: 0;
          border-radius: 6px 6px 0 0;
          border-top: 2px solid $second-color;
          border-right: 2px solid $second-color;
          border-left: 2px solid $second-color;
          box-shadow: none;

          label {
            &.placeholder {
              top: 15px;
              color: #333;
              font-size: 14px;
            }
          }
        }
      }

      .options-container {
        color: #333333;

        .search-container {
          padding: 0.5rem 1rem;
          border-left: 2px solid $second-color;
          border-right: 2px solid $second-color;
          position: absolute;
          z-index: 1;
          width: 100%;
          background: $white-color;

          input {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 100%;
            height: 48px;
            padding: 0.5rem 2rem 0.5rem 0.5rem;
            border-radius: 6px;
            border: 1px solid #979797;

            &::-webkit-input-placeholder {
              color: #DADADA;
            }

            &:-moz-placeholder {
              color: #DADADA;
            }

            &::-moz-placeholder {
              color: #DADADA;
            }

            &:-ms-input-placeholder {
              color: #DADADA;
            }
          }

          .icon-container {
            position: relative;
            float: right;
            right: 10px;
            bottom: 32px;

            i {
              font-size: 18px;
              color: #979797;
              cursor: text;

              &.icon-close {
                cursor: pointer;
              }
            }
          }
        }

        .options {
          width: 100%;
          font-size: 14px;
          max-height: 295px;
          overflow-x: hidden;
          overflow-y: scroll;
          position: absolute;
          z-index: 10;
          cursor: pointer;
          border-left: 2px solid $second-color;
          border-right: 2px solid $second-color;
          border-bottom: 2px solid $second-color;
          border-radius: 0 0 6px 6px;
          background: $white-color;

          &.search {
            margin-top: 4rem;
          }

          &::-webkit-scrollbar-track {
            background: #EFEFEF;
          }

          &::-webkit-scrollbar {
            width: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: #979797;
            border-radius: 10px;
            height: 52px;
          }

          .options-background {
            background: $white-color;
          }

          .category {
            cursor: default;
            font-weight: 600;
            padding: 16px;
          }

          .option {
            padding: 16px 22px;

            &:hover {
              background: #F6F7F7;
            }

            &.disable {
              cursor: default;
              font-weight: 600;

              &:hover {
                background: $white-color;
              }
            }

            &.actived {
              background: $second-color;
              color: $white-color;
            }
          }
        }

        &.hide {
          display: none;
        }
      }

      span {
        color: #FF5A5A;
        font-size: 12px;
        margin-top: 0.5rem;
      }
    }
  }

  @media (max-width: 520px) {
    .select-container {
      .select-content {
        .options-container {
          .options {
            max-height: 245px;
          }
        }
      }
    }
  }
</style>