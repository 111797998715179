<template>
  <div
    v-if="isVisible"
    class="notification-card-container"
    :class="{ 'has-notice-card': hasNoticeCard }"
    :style="{ 'background': data.color }"
  >
    <div class="card">
      <p>{{ data.text }}</p>

       <i class="icon-close" @click="hideCard"/>
    </div>

    <div class="progress-bar"></div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'AlertCard',

    data() {
      return {
        isVisible: true,
        hasNoticeCard: false
      };
    },

    computed: {
      ...mapGetters([
        'getNoticeCardData'
      ])
    },

    created() {
      this.hasNoticeCard = !this.$service.isEmptyObject(this.getNoticeCardData);
    },

    watch: {
      getNoticeCardData() {
        this.hasNoticeCard = !this.$service.isEmptyObject(this.getNoticeCardData);
      }
    },

    props: {
      data: {
        type: Object,
        default() {
          return {
            text: 'Dados editados com sucesso.',
            color: '#08B978'
          };
        }
      },

      show: {
        type: Boolean,
        required: false
      }
    },

    mounted() {
      this.isVisible = !this.$service.isEmptyObject(this.data);

      this.alertCardTime();
    },

    methods: {
      hideCard() {
        this.isVisible = false;
      },

      alertCardTime() {
        setTimeout(() => {
          this.hideCard();
          this.$store.commit('setAlertCardStatus', false);
          this.$store.commit('setAlertCardData', {});
        }, 1800);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .notification-card-container {
    width: 250px;
    position: absolute;
    right: 0;
    margin-top: 100px;
    z-index: 1000;

    &.has-notice-card {
      margin-top: 145px;
    }

    .card {
      display: flex;
      min-height: 4rem;
      padding: 1rem;

      p {
        color: $white-color;
        margin-right: 1.5rem;
        font-weight: 400;
      }

      i {
        color: #FFFFFF80;
        margin-left: auto;
      }
    }

    .progress-bar {
      height: 6px;
      animation: progress 2s;
      background: #FFFFFF64;
    }

    @keyframes progress {
      from {
        width: 0%;
      }

      to {
        width: 100%;
      }
    }
  }

  @media (max-width: 670px) {
    .notification-card-container {
      &.has-notice-card {
        margin-top: 160px;
      }
    }
  }

  @media (max-width: 375px) {
    .notification-card-container {
      &.has-notice-card {
        margin-top: 180px;
      }
    }
  }
</style>