import 'regenerator-runtime';
import '@/services/rules';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vco from "v-click-outside";
import VueTheMask from 'vue-the-mask';
import VueMoney from 'v-money';
import VueMeta from 'vue-meta';
import Ripple from 'vue-ripple-directive';
import VCalendar from 'v-calendar';
import VueClipboard from 'vue-clipboard2';
import VueHtml2Canvas from 'vue-html2canvas';
import VueConfetti from 'vue-confetti';
import Theme from '@/plugins/theme';
import Service from '@/services/service';
import ptBR from 'vee-validate/dist/locale/pt_BR.json';
import { extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import * as Sentry from '@sentry/vue';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('pt_br', ptBR);

Vue.config.productionTip = false;

Vue.use(Theme);
Vue.use(VueConfetti);
Vue.use(VueMeta);
Vue.use(VueTheMask);
Vue.use(VCalendar);
Vue.use(VueHtml2Canvas);
Vue.use(VueMoney);
Vue.use(vco);
Vue.use(VueClipboard);

Vue.directive('ripple', Ripple);

Vue.prototype.$service = Service;

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['minhaconta.impay.com.br', /^\//],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');