<template>
  <div class="account-view-container">
    <default-view-component>
      <template v-slot:content>
        <div class="section">
          <header-component
            :data="header"
            class="header"
          />

        <div v-if="!hasRequest" class="status" :style="{ 'color': sellerStatus.color }">
          <i :class="sellerStatus.icon"/>

          {{ sellerStatus.text }}
        </div>

        <default-data-component v-else class="status"/>

        <div class="account-section">
          <account-card-component
            :items="itemsCard"
          />
        </div>
      </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Header from '@/components/Header';
  import DefaultData from '@/components/DefaultData';
  import DefaultView from '@/components/Views/DefaultView';
  import AccountSectionCard from '@/components/AccountSectionCard';

  export default {
    name: 'AccountView',

    data() {
      return {
        hasRequest: false,
        itemsCard: [
          {
            icon: 'icon-information-account',
            title: 'Informações da conta',
            text: 'Dados pessoais, negócio, contato...',
            redirectPagePath: '/account/informations/data',
          },
          {
            icon: 'icon-bank',
            title: 'Conta bacária',
            text: 'Escolha em qual conta quer receber o seu dinheiro',
            redirectPagePath: '/bank',
          },
          {
            icon: 'icon-security',
            title: 'Segurança e login',
            text: 'Atualize a sua senha e proteja a sua conta',
            redirectPagePath: '/account/security',
          },
          {
            icon: 'icon-default',
            title: 'Personalização',
            text: 'Personalize nossos recursos com a cara do seu negócio',
            redirectPagePath: '',
            isDisable: true,
          },
          {
            icon: 'icon-invite-user',
            title: 'Convide usuários',
            text: 'Compartilhe a operação de seu negócio com outras pessoas',
            redirectPagePath: '',
            isDisable: true,
          },
          {
            icon: 'icon-question',
            title: 'Perguntas frequentes',
            text: 'Visite a nossa central de ajuda',
            redirectPagePath: '',
            isDisable: true,
          },
        ],

        header: {
          title: 'Conta',
          button: {
            text: 'Nova Negócio'
          }
        },

        sellerStatus: {
          color: '',
          icon: '',
          text: ''
        }
      };
    },

    components: {
      'default-data-component': DefaultData,
      'account-card-component': AccountSectionCard,
      'default-view-component': DefaultView,
      'header-component': Header
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerStatus',
        'getCurrentSellerData',
        'getCurrentSellerId',
        'getNoticeCardData',
        'getSideBarState'
      ]),
    },

    watch: {
      getCurrentSellerId() {
        this.hasRequest = true;
      },

      getCurrentSellerData() {
        this.setSellerStatus();
      }
    },

    mounted() {
      this.setSellerStatus();
    },

    methods: {
      setSellerStatus() {
        switch (this.$service.toUpperCase(this.getCurrentSellerStatus)) {
          case 'ACTIVE':
            this.sellerStatus.color = '#08B978';
            this.sellerStatus.icon = 'icon-check-circle';
            this.sellerStatus.text = 'Conta verificada';
            break;
          case 'WAITINGDOCUMENTS':
            this.sellerStatus.color = '#1B87B1';
            this.sellerStatus.icon = 'icon-information-circle';
            this.sellerStatus.text = 'Aguardando documentos';
            break;
          case 'PENDING':
            this.sellerStatus.color = '#006EFF';
            this.sellerStatus.icon = 'icon-information-circle';
            this.sellerStatus.text = 'Conta pendente';
            break;
          case 'WAITING':
            this.sellerStatus.color = '#FCF3D9';
            this.sellerStatus.icon = 'icon-warning';
            this.sellerStatus.text = 'Aguardando análise';
            break;
        }

        this.hasRequest = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .account-view-container {
    .section {
      padding: 1.5rem;

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem !important;

        h2 {
          color: #333;
        }

        p {
          margin-left: auto;
          font-weight: 600;
          color: $second-color;
          font-size: 16px;
          cursor: pointer;
        }
      }

      .status {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        height: 1.5rem;
        width: 15rem;

        i {
          margin-right: 0.5rem;
        }
      }

      .account-section {
        display: flex;
        justify-content: center;
      }
    }
  }

  @media (max-width: 480px) {
    .account-view-container {
      .section {
        padding: 1rem;

        .status {
          font-size: 14px;

          i {
            font-size: 12px;
          }
        }
      }
    }
  }
</style>