<template>
  <div>
    <simple-default-view-component v-if="!hasRequest" class="simple-default-view-component">
      <template v-slot:header>
        <div class="title">
          <h2>Detalhes do saque</h2>

          <div class="more-details">
            <i class="icon-circle"/>

            <p>Data <span>{{ data.header.createdDate }}</span></p>
          </div>
        </div>

        <div class="icon-container">
          <i class="icon-close" @click="close"/>
        </div>
      </template>

      <template v-slot:content>
        <div class="content">
          <div class="column detail">
            <div class="details-card-container">
              <div class="details-card">
                <trasaction-status-component :status="data.content.status" />

                <div class="details-header">
                  <p>{{ data.content.type }}</p>

                  <p>{{ data.content.amount }}</p>
                </div>

                <div class="line"></div>

                <div class="items-container">
                  <div v-for="(column, index) in data.content.columns" :key="index" class="items-content">
                    <div v-for="(row, index) in column.rows" :key="index" class="item">
                      <div class="label">
                        <i v-if="row.icon" :class="row.icon"></i>

                        {{ row.label }}
                      </div>

                      <div class="value">
                        {{ row.value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column table">
            <div class="section-container">
              <h3>Transações</h3>

              <table-component v-if="!isManualPayout" class="table-component" :table="salesData" />

              <simple-message-component v-else class="simple-message-component" :data="manualPayoutData" />
            </div>
          </div>
        </div>
      </template>
    </simple-default-view-component>

    <div v-else class="spinner">
      <spinner-component color="#DADADA"/>
    </div>
  </div>
</template>

<script>
  import SimpleDefaultView from '@/components/Views/SimpleDefaultView';
  import TransactionStatus from '@/components/TransactionStatus';
  import SimpleMessage from '@/components/SimpleMessage';
  import Table from '@/components/Table/Table';
  import Spinner from '@/components/Spinner';

  export default {
    name: 'TransactionDetail',

    data() {
      return {
        hasRequest: false,
        isManualPayout: false,
        data: {
          header: {
            createdDate: '',
          },

          content: {
            status: '',
            type: '',
            amount: '',
            columns: []
          }
        },

        salesData: {
          headers: {
            hasBorder: true,
            items: [
              {
                label: 'Data',
                field: 'date',
                align: 'left',
              },

              {
                label: 'Descrição',
                field: 'description',
                align: 'center'
              },

              {
                label: 'Valor bruto',
                field: 'grossAmount',
                align: 'center'
              },

              {
                label: 'Taxa',
                field: 'fee',
                align: 'center'
              },

              {
                label: 'Valor líquido',
                field: 'amount',
                align: 'right'
              }
            ]
          },

          rows: []
        },

        manualPayoutData: {
          icon: 'icon-warning',
          text: 'Impossivel listar as transações na modalidade saque manual!'
        },
      };
    },

    components: {
      'simple-default-view-component': SimpleDefaultView,
      'trasaction-status-component': TransactionStatus,
      'simple-message-component': SimpleMessage,
      'table-component': Table,
      'spinner-component': Spinner,
    },

    props: {
      detailId: {
        type: String,
        required: true
      }
    },

    created() {
      this.fetchData();
    },

    mounted() {
      history.pushState({}, '', this.$router.history.current.path);

      window.onpopstate = () => {
        this.close();
      };
    },

    methods: {
      async fetchData() {
        this.hasRequest = true;

        await this.$store.dispatch('getAllBankTransferId', this.detailId)
          .then(res => {
            this.data = this.formatData(res.data);
            this.isManualPayout = this.$service.toUpperCase(res.data.type) == 'PAYOUT_MANUAL';
          })
          .catch(err => {
            console.log(err);
          });

        await this.$store.dispatch('getAllBankTransferHistoryId', this.detailId)
          .then(res => {
            this.salesData.rows = this.formatSalesTableRows(res.data);
          })
          .catch(err => {
            console.log(err);
          });

        this.hasRequest = false;
      },

      formatData(data) {
        return {
          header: {
            createdDate: this.$service.dateFormat(data.created_at, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YY - HH:mm'),
          },

          content: {
            status: data.status,
            type: 'Conta' + ' ' + this.formatAccountType(data.bank_account.type),
            amount: this.$service.formatBrlAmountSymbol(data.amount),
            columns: this.formatAccountData(data.bank_account)
          }
        };
      },

      formatSalesTableRows(data) {
        let formattedSalesTableRows = [];

        data.forEach(item => {
          formattedSalesTableRows.push({
            date: this.$service.dateFormat(item.date, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YY - HH:mm'),
            description: item.description,
            fee: this.$service.formatBrlAmountSymbol(item.gross_amount - item.amount),
            grossAmount: this.$service.formatBrlAmountSymbol(item.gross_amount),
            amount: this.$service.formatBrlAmountSymbol(item.amount)
          });
        });

        return formattedSalesTableRows;
      },

      formatAccountData(data) {
        return [
          {
            rows: [
              {
                label: 'Nome do banco',
                value: data.bank_name
              },

              {
                label: 'Agência',
                value: data.routing_number
              },

              {
                label: 'Titular',
                value: data.holder_name
              }
            ],
          },

          {
            rows: [
              {
                label: 'Cód. do banco',
                value: data.bank_code
              },

              {
                label: 'Nº da conta',
                value: this.$service.formatAccountNumber(data.account_number)
              }
            ]
          }
        ];
      },

      formatAccountType(type) {
        let formattedType = '';

        switch (this.$service.toUpperCase(type)) {
          case 'SAVINGS':
            formattedType = 'poupança';
            break;
          case 'CHECKING':
            formattedType = 'corrente';
            break;
        }

        return formattedType;
      },

      close() {
        this.$emit('close');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .spinner {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .simple-default-view-component {
    .button-component {
      width: 155px;
      height: 40px;
      background: #FF5A5A;

      &:hover {
        background: #e53c3c;
      }
    }

    .modal {
      .body {
        display: flex;
        align-items: center;
        flex-direction: column;

        h3 {
          color: #333;
        }

        p {
          text-align: center;

          b {
            font-weight: 500;
          }
        }

        i {
          font-size: 3.2rem;
          color: #8E8E8E;
          margin-top: 2rem;
          margin-bottom: 2rem;
        }

        .button-component {
          margin-top: 2rem;
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
      margin-right: 1rem;

      h2 {
        color: #333;
      }

      .more-details {
        display: flex;
        align-items: center;

        .authorization-code {
          display: flex;
          align-items: center;
        }

        i {
          font-size: 0.3rem;
          padding: 0 0.5rem;
          color: #DADADA
        }

        p {
          color: #6D6F6F;
          font-weight: 500;
          font-size: 16px;

          &.type {
            font-size: 14px;
          }

          span {
            color: #333;
          }
        }
      }
    }

    .content {
      padding: 1.5rem;
      display: flex;

      .column {
        .button-component {
          margin-bottom: 1rem;
        }

        &.table {
          width: 100%;
          overflow-x: hidden;

          .section-container {
            overflow-x: hidden;

            .table-component {
              overflow-x: auto;
              overflow-y: hidden;
            }

            .simple-message-component {
              margin-top: 4rem;
            }
          }
        }

        .details-card {
          border: 1px solid #EFEFEF;
          padding: 1rem;
          border-radius: 8px;
          width: 420px;

          .details-header {
            display: flex;
            padding: 0.7rem 0;
            margin-bottom: 0.3rem;

            p {
              font-weight: 600;
              color: #333;

              &:last-child {
                margin-left: auto;
              }
            }
          }

          .line {
            border-bottom: 1px solid #EFEFEF;
          }

          .items-container {
            display: flex;

            .items-content {
              .item {
                padding: 1rem 0;

                .label {
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  font-weight: 400;
                  color: #8E8E8E;
                  width: max-content;

                  img {
                    margin-right: 0.5rem;
                  }

                  i {
                    margin-right: 0.5rem;
                  }
                }

                .value {
                  margin-top: 0.3rem;
                  font-size: 14px;
                  color: #333;
                }
              }

              &:last-child {
                margin-left: auto;
                text-align: end;

                .label {
                  display: block;
                  margin-left: auto;
                }
              }
            }
          }
        }

        .section-container {
          h3 {
            color: #333;
            margin-bottom: 0.5rem;
          }

          &:last-child {
            margin-top: 2rem;
          }
        }

        &:last-child {
          margin-left: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 900px) {
    .simple-default-view-component {
      .container {
        .header {
          .title {
            h2 {
              font-size: 16px;
            }

            .more-details {
              p {
                font-size: 14px;
              }
            }
          }
        }

        .content {
          flex-direction: column;

          .column {
            &.detail {
              margin-bottom: 2rem;

              .details-card-container {
                display: flex;
                justify-content: center;

                .details-card {
                  width: 100%;
                  border: none;
                  padding: 0;
                }
              }
            }

            &.table {
              margin-left: unset;
            }
          }
        }
      }
    }
  }

  @media (max-width: 795px) {
    .simple-default-view-component {
      .container {
        .header {
          .title {
            .more-details {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .simple-default-view-component {
      .content {
        .column {
          .section-container {
            h3 {
              font-size: 14px;
            }
          }

          &.detail {
            .details-card-container {
              .details-card {
                .details-header {
                  p {
                    font-size: 12px;
                  }
                }

                .items-container {
                  .items-content {
                    .item {
                      .label {
                        font-size: 12px;
                      }

                      .value {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 457px) {
    .simple-default-view-component {
      .content {
        .column {
          &.detail {
            .details-card {
              width: 100%;

              .items-container {
                flex-direction: column;

                .items-content {
                  .item {
                    word-wrap: break-word;
                  }

                  &:last-child {
                    margin-left: unset;
                    text-align: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
