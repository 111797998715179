<template>
  <div class="simple-message-container">
    <div class="icon-container">
      <i :class="data.icon"/>
    </div>

    <p>{{ data.text }}</p>
  </div>
</template>

<script>
  export default {
    name: 'simple-message',

    props: {
      data: {
        type: Object,
        default() {
          return {
            text: 'text',
            icon: 'icon-dashboard'
          };
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .simple-message-container {
    justify-content: center;
    flex-direction: column;
    display: flex;

    .icon-container {
      text-align: center;
      color: #DADADA;
      margin-bottom: 1.5rem;

      i {
        font-size: 6rem;
      }
    }

    p {
      color: #6D6F6F;
      text-align: center;
    }
  }
</style>