const state = () => ({
  currentIndividualStep: 0,

  progress: [
    {
      name: 'Dados pessoais',
      current: true,
      completed: false,
    },

    {
      name: 'Endereço',
      current: false,
      completed: false,
    }
  ]
});

const mutations = {
  currentIndividualStep(state, step) {
    state.currentIndividualStep = step;
  },

  resetIndividualAccreditation(state) {
    state.currentIndividualStep = 0;

    state.progress = [
      {
        name: 'Dados pessoais',
        current: true,
        completed: false,
      },

      {
        name: 'Endereço',
        current: false,
        completed: false,
      }
    ];
  },

  progressCurrent(state, progressData) {
    state.progress[progressData.position].current = progressData.status;
  },

  progressCompleted(state, progressData) {
    state.progress[progressData.position].completed = progressData.status;
  },

  resetProgress(state) {
    state.progress = [
      {
        name: 'Dados pessoais',
        current: true,
        completed: false,
      },

      {
        name: 'Endereço',
        current: false,
        completed: false,
      }
    ];
  }
};

export default {
  namespaced: true,
  state,
  mutations
};