<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :name="inputName"
    :rules="rules"
    class="input-field-container"
    :class="{ 'disable-focus': disableFocusStyle }"
    v-slot="{ errors }"
  >
    <div>
      <input
        v-if="isMoneyInput()"
        :type="inputType"
        placeholder="R$"
        autocomplete="off"
        :value="value"
        :disabled="isDisabled"
        :maxlength="maxLength"
        :class="{ 'error': !!errors[0], 'disabled': isDisabled }"
        @input="checkMoney"
        v-model="innerValue"
      />

      <money
        v-else-if="isPercentInput()"
        :type="inputType"
        autocomplete="off"
        suffix="%"
        decimal=","
        :value="value"
        :disabled="isDisabled"
        :maxlength="maxLength"
        :class="{ 'error': !!errors[0], 'disabled': isDisabled }"
        @input="checkMinMax"
        v-model="innerValue"
      />

      <div v-if="!isDisabled" class="errors">
        <span>{{ errors[0] }}</span>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import { Money } from 'v-money';

  export default {
    name: 'InputCurrencyField',

    props: {
      type: {
        type: String,
        default: 'text',
        validator(value) {
          return [
            'money',
            'percent'
          ].includes(value);
        },
      },

      vid: {
        type: String,
        default: undefined
      },

      isDisabled: {
        type: Boolean,
        default: false,
      },

      placeholder: {
        type: String,
        default: 'Placeholder'
      },

      rules: {
        type: [Object, String],
        default: ''
      },

      value: {
        type: [String, Number],
        default: '',
      },

      maxLength: {
        type: Number,
        required: false,
      },

      disableFocusStyle: {
        type: Boolean,
        default: false
      },

      max:  {
        type: Number,
        required: false,
      },

      min: {
        type: Number,
        required: false,
      }
    },

    components: {
      ValidationProvider,
      Money
    },

    data() {
      return {
        innerValue: '',
        inputName: '',
        inputType: 'text'
      };
    },

    created() {
      if (this.value) {
        this.innerValue = this.parseInputValue(this.value);
      }
    },

    watch: {
      innerValue(value) {
        this.$emit('input', this.parseEmitValue(value));
      },

      value(value) {
        if (value !== this.innerValue) {
          this.innerValue = this.parseInputValue(value);
        }
      }
    },

    methods: {
      isMoneyInput() {
        return this.type == 'money';
      },

      isPercentInput() {
        return this.type == 'percent';
      },

      checkMinMax() {
        if (this.max && this.innerValue > this.max) {
          this.innerValue = this.max;
        }

        if (this.min && this.innerValue < this.min) {
          this.innerValue = this.min;
        }
      },

      checkMoney() {
        var isZeroAmount = this.$service.removeCharacter(this.innerValue) == 0;
        var parsedValue = this.$service.removeCharacter(this.innerValue) / 100;

        this.innerValue = !isZeroAmount ? this.$service.formatBrlAmountSymbol(parsedValue) : '';
      },

      parseInputValue(value) {
        var parsedMoneyValue = value ? this.$service.formatBrlAmountSymbol(value) : '';

        return this.isMoneyInput() ? parsedMoneyValue : value;
      },

      parseEmitValue(value) {
        return this.isMoneyInput() ? parseInt(this.$service.removeCharacter(value)) / 100 : value;
      }
    }
  };
</script>

<style lang="scss">
  .input-field-container {
    input {
      border: 1px solid #DADADA;
      border-radius: 6px;
      padding: 0.3rem 1rem;
      display: flex;
      align-items: center;
      color: #333;
      cursor: pointer;
      height: 48px;
      width: 100%;

      &:focus {
        outline: none;
        border-color: $second-color;
        box-shadow: 0 0 0 1px $second-color, 0 0 0 3px $second-color-light-1;
      }

      &.error {
        border-color: $red-color;
        box-shadow: 0 0 0 1px $red-color, 0 0 0 3px $red-color-light;
      }
    }

    &.disable-focus {
      input {
        &:focus {
          outline: none;
          border-color: #DADADA;
          box-shadow: none;
        }
      }
    }

    .errors {
      span {
        color: $red-color;
        font-size: 12px;
        margin-top: 0.5rem;
        font-weight: 400;
      }
    }
  }
</style>