<template>
  <div class="account-informations-container" :class="{ 'has-modal': hasModal(), 'has-sidebar': !getSideBarState.hideSideBar }">
   <alert-component
      v-if="!hasRequest && getAlertCardStatus"
      :data="getCurrentAlertCardData"
    />

    <modal-edit-autonomous-seller
      v-if="sellerType == 'Individual' && hasModalAutonomousEdit"
    />

    <modal-edit-company-seller
      v-if="sellerType == 'Company' && hasModalCompanyEdit"
    />

    <modal-edit-owner-seller
      v-if="sellerType == 'Company' && hasModalOwnerEdit"
    />

    <modal-edit-address-seller
      :sellerData="sellerData"
    />

    <notice-card-component
      :options="getNoticeCardData"
    />

    <div class="account-informations">
      <side-bar/>

      <div class="content">
        <nav-bar
          :hasSelect="true"
        />

        <div class="section-container">
          <div class="content">
            <header-component
              class="header"
              :data="header"
            />

            <div class="center">
              <div class="section">
                <navigation-informations
                  :styles="navigation"
                />

                <div class="line"></div>

                <div class="content">
                  <sort-data-component
                    v-if="currentSection == 'data'"
                    :data="formattedSellerData"
                  />

                  <sort-data-component
                    v-if="currentSection == 'address'"
                    :data="formattedAddressData"
                  />

                  <sort-data-component
                    v-if="currentSection == 'owners'"
                    :data="formattedOwnersData"
                  />

                  <documents-fields
                    v-if="currentSection == 'documents'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import NavBar from '@/components/NavBar';
  import SideBar from '@/components/SideBar';
  import Header from '@/components/Header';
  import AlertCard from '@/components/AlertCard';
  import NavigationInformations from '@/components/AccountInformationsNavigation';
  import DocumentField from '@/components/AccountSellerDocumentField';
  import AccountSortData from '@/components/AccountSortData';
  import NoticeCard from '@/components/NoticeCard';
  import ModalEditAutonomousSeller from '@/components/AccreditationSteps/Autonomous/ModalEditAutonomousSeller';
  import ModalEditCompanySeller from '@/components/AccreditationSteps/Company/ModalEditCompanySeller';
  import ModalEditAddress from '@/components/AccreditationSteps/ModalEditAddress';
  import ModalEditOwnerSeller from '@/components/AccreditationSteps/Company/ModalEditOwnerSeller';

  export default {
    name: 'AccountInformations',

    data() {
      return {
        currentSection: '',
        hasRequest: false,
        hasNotification: false,
        sellerType: '',
        header: {
          title: 'Informações da conta',
          to: '/account',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        formattedSellerData: [],
        formattedAddressData: [],
        formattedOwnersData: [],

        navigation: [
          {
            isEnable: true,
            to: '/account/informations/data',
            param: 'data',
            title: 'Dados'
          },

          {
            isEnable: true,
            to: '/account/informations/address',
            param: 'address',
            title: 'Endereço'
          },

          {
            isEnable: false,
            to: '/account/informations/owners',
            param: 'owners',
            title: 'Dados dos sócios'
          },

          // {
          //   isEnable: true,
          //   to: '/account/informations/documents',
          //   param: 'documents',
          //   title: 'Documentos'
          // }
        ]
      };
    },

    components: {
      'side-bar': SideBar,
      'documents-fields': DocumentField,
      'navigation-informations': NavigationInformations,
      'nav-bar': NavBar,
      'modal-edit-autonomous-seller': ModalEditAutonomousSeller,
      'modal-edit-company-seller': ModalEditCompanySeller,
      'modal-edit-address-seller': ModalEditAddress,
      'modal-edit-owner-seller': ModalEditOwnerSeller,
      'header-component': Header,
      'sort-data-component': AccountSortData,
      'alert-component': AlertCard,
      'notice-card-component': NoticeCard
    },

    computed: {
      ...mapGetters([
        'getNoticeCardData',
        'getAlertCardStatus',
        'getSideBarState',
        'getCurrentAlertCardData'
      ]),

      ...mapState({
        mcc: state => state.mcc,
        sellerData: state => state.sellerData,
        currentSellerId: state => state.currentSellerId,
        hasModalAutonomousEdit: state => state.hasModalAutonomousEdit,
        hasModalAddressEdit: state => state.hasModalAddressEdit,
        hasModalCompanyEdit: state => state.hasModalCompanyEdit,
        hasModalOwnerEdit: state => state.hasModalOwnerEdit,
        hasModalOwnerAddressEdit: state => state.hasModalOwnerAddressEdit,
      })
    },

    created() {
      this.setDefaultData();
      this.$store.commit('closeModalEdit');
      this.fetchCurrentSellerData();
    },

    mounted() {
      this.currentSection = this.$route.params.section;
    },

    watch: {
      $route(to) {
        this.currentSection = to.params.section;
      },

      currentSellerId() {
        this.setDefaultData();
      },

      sellerData() {
        this.sellerType = this.sellerData.type;

        if (this.sellerType == "Company") {
          this.navigation[2].isEnable = true; // enable navigation owner section

          this.formattedSellerData = [
            {
              sectionTitle: 'Dados do negócio',
              mutation: 'setToggleModalCompanyEdit',
              isEditable: true,
              columnLeft: [
                {
                  title: 'CNPJ',
                  text: this.$service.formatEin(this.sellerData.ein)
                },

                {
                  title: 'Razão social',
                  text: this.sellerData.business_name
                },

                {
                  title: 'Categoria do negócio',
                  text: this.getMccDescription(this.sellerData.mcc)
                },

                {
                  title: 'Nome fantasia',
                  text: this.sellerData.statement_descriptor
                },

                {
                  title: 'E-mail empresarial',
                  text: this.sellerData.business_email
                },
              ],

              columnRight: [
                {
                  title: 'Data de constituição',
                  text: this.$service.dateFormat(this.sellerData.business_opening_date, 'YYYY-MM-DD', 'DD/MM/YYYY')
                },

                {
                  title: 'Formato',
                  text: '-'
                },

                {
                  title: 'CNAE',
                  text: '-'
                },

                {
                  title: 'Receita méd. mensal',
                  text: '-'
                },

                {
                  title: 'Telefone empresarial',
                  text: this.$service.formatPhone(this.sellerData.business_phone)
                }
              ]
            }
          ];

          this.formattedAddressData = [
            {
              sectionTitle: 'Endereço do negócio',
              mutation: 'setToggleModalAddressEdit',
              isEditable: true,
              columnLeft: [
                {
                  title: 'CEP',
                  text: this.$service.formatPostalCode(this.sellerData.business_address_postal_code)
                },

                {
                  title: 'Rua',
                  text: this.sellerData.business_address_line1
                },

                {
                  title: 'Número',
                  text: this.sellerData.business_address_line2
                },

                {
                  title: 'Complemento',
                  text: this.sellerData.business_address_line3
                }
              ],

              columnRight: [
                {
                  title: 'Bairro',
                  text: this.sellerData.business_address_neighborhood
                },

                {
                  title: 'Cidade',
                  text: this.sellerData.business_address_city
                },

                {
                  title: 'Estado',
                  text: this.sellerData.business_address_state
                }
              ]
            }
          ],

          this.formattedOwnersData = [
            {
              sectionTitle: 'Dados do sócio',
              mutation: 'setToggleModalOwnerEdit',
              isEditable: true,
              columnLeft: [
                {
                  title: 'Nome completo',
                  text: this.sellerData.owner_first_name + ' ' + this.sellerData.owner_last_name
                },

                {
                  title: 'CPF',
                  text: this.$service.formatTaxpayerId(this.sellerData.owner_taxpayer_id)
                },

                {
                  title: 'Email',
                  text: this.sellerData.owner_email
                }
              ],

              columnRight: [
                {
                  title: 'Data de nascimento',
                  text: this.$service.dateFormat(this.sellerData.owner_birthdate, 'YYYY-MM-DD', 'DD/MM/YYYY')
                },

                {
                  title: 'Telefone',
                  text: this.$service.formatPhone(this.sellerData.owner_phone_number)
                }
              ]
            },

            {
              sectionTitle: 'Endereço',
              mutation: 'setToggleModalAddressEdit',
              isOwnerAddress: true,
              isEditable: true,
              columnLeft: [
                {
                  title: 'CEP',
                  text: this.$service.formatPostalCode(this.sellerData.owner_address_postal_code)
                },

                {
                  title: 'Rua',
                  text: this.sellerData.owner_address_line1
                },

                {
                  title: 'Número',
                  text: this.sellerData.owner_address_line2
                },

                {
                  title: 'Complemento',
                  text: this.sellerData.owner_address_line3
                }
              ],

              columnRight: [
                {
                  title: 'Bairro',
                  text: this.sellerData.owner_address_neighborhood
                },

                {
                  title: 'Cidade',
                  text: this.sellerData.owner_address_city
                },

                {
                  title: 'Estado',
                  text: this.sellerData.owner_address_state
                }
              ]
            },
          ];
        } else if (this.sellerType == "Individual") {
          this.navigation[2].isEnable = false; // disable navigation owner section

          if (this.$route.params.section == 'owners') {
            this.$router.push('/account/informations/data');
          }

          this.formattedSellerData = [
            {
              sectionTitle: 'Dados',
              mutation: 'setToggleModalIndividualEdit',
              isEditable: true,
              columnLeft: [
                {
                  title: 'CPF',
                  text: this.$service.formatTaxpayerId(this.sellerData.taxpayer_id)
                },

                {
                  title: 'Nome',
                  text: this.sellerData.first_name + ' ' + this.sellerData.last_name
                },

                {
                  title: 'Categoria do negócio',
                  text: this.getMccDescription(this.sellerData.mcc)
                },

                {
                  title: 'E-mail',
                  text: this.sellerData.email
                }
              ],

              columnRight: [
                {
                  title: 'Data de nascimento',
                  text: this.$service.dateFormat(this.sellerData.birthdate, 'YYYY-MM-DD', 'DD/MM/YYYY')
                },

                {
                  title: 'Nome fantasia',
                  text: this.sellerData.statement_descriptor
                },

                {
                  title: 'Receita méd. mensal',
                  text: '-'
                },

                {
                  title: 'Telefone',
                  text: this.$service.formatPhone(this.sellerData.phone_number)
                }
              ]
            }
          ],

          this.formattedAddressData = [
            {
              sectionTitle: 'Endereço',
              mutation: 'setToggleModalAddressEdit',
              isEditable: true,
              columnLeft: [
                {
                  title: 'CEP',
                  text: this.$service.formatPostalCode(this.sellerData.address_postal_code)
                },

                {
                  title: 'Rua',
                  text: this.sellerData.address_line1
                },

                {
                  title: 'Número',
                  text: this.sellerData.address_line2
                },

                {
                  title: 'Complemento',
                  text: this.sellerData.address_line3
                }
              ],

              columnRight: [
                {
                  title: 'Bairro',
                  text: this.sellerData.address_neighborhood
                },

                {
                  title: 'Cidade',
                  text: this.sellerData.address_city
                },

                {
                  title: 'Estado',
                  text: this.sellerData.address_state
                }
              ]
            }
          ];
        }

        this.navigation[0].title = this.formattedSellerData[0].sectionTitle; // set section title
        this.navigation[1].title = this.formattedAddressData[0].sectionTitle; // set section title
      }
    },

    methods: {
      setDefaultData() {
        this.defaultSellerData();
        this.defaultAddressData();
        this.defaultOwnersData();
      },

      fetchCurrentSellerData() {
        this.$store.dispatch('getSellerId');
      },

      defaultSellerData() {
        this.formattedSellerData = [
          {
            sectionTitle: 'Dados',
            isEditable: false,
            columnLeft: [
              {
                title: 'CNPJ',
                text: 'default'
              },

              {
                title: 'Razão social',
                text: 'default'
              },

              {
                title: 'Categoria do negócio',
                text: 'default'
              },


              {
                title: 'Nome fantasia',
                text: 'default'
              },

              {
                title: 'E-mail empresarial',
                text: 'default'
              },
            ],

            columnRight: [
              {
                title: 'Data de constituição',
                text: 'default'
              },

              {
                title: 'Formato',
                text: 'default'
              },

              {
                title: 'CNAE',
                text: 'default'
              },

              {
                title: 'Receita méd. mensal',
                text: 'default'
              },

              {
                title: 'Telefone empresarial',
                text: 'default'
              }
            ]
          }
        ];
      },

      defaultAddressData() {
        this.formattedAddressData = [
          {
            sectionTitle: 'Endereço',
            isEditable: false,
            columnLeft: [
              {
                title: 'CEP',
                text: 'default'
              },

              {
                title: 'Rua',
                text: 'default'
              },

              {
                title: 'Número',
                text: 'default'
              },

              {
                title: 'Complemento',
                text: 'default'
              }
            ],

            columnRight: [
              {
                title: 'Bairro',
                text: 'default'
              },

              {
                title: 'Cidade',
                text: 'default'
              },

              {
                title: 'Estado',
                text: 'default'
              }
            ]
          }
        ];
      },

      defaultOwnersData() {
        this.formattedOwnersData = [
          {
            sectionTitle: 'Dados dos sócios',
            isEditable: false,
            columnLeft: [
              {
                title: 'Nome completo',
                text: 'default'
              },

              {
                title: 'CPF',
                text: 'default'
              },

              {
                title: 'Email',
                text: 'default'
              }
            ],

            columnRight: [
              {
                title: 'Data de nascimento',
                text: 'default'
              },

              {
                title: 'Telefone',
                text: 'default'
              }
            ]
          },

          {
            sectionTitle: 'Endereço',
            isEditable: false,
            columnLeft: [
              {
                title: 'CEP',
                text: 'default'
              },

              {
                title: 'Rua',
                text: 'default'
              },

              {
                title: 'Cidade',
                text: 'default'
              }
            ],

            columnRight: [
              {
                title: 'Número',
                text: 'default'
              },

              {
                title: 'Bairro',
                text: 'default'
              },

              {
                title: 'Estado',
                text: 'default'
              }
            ]
          }
        ];
      },

      getMccDescription(id) {
        let description;

        this.mcc.forEach(item => {
          if (item.id === id) {
            description = item.description;
          }
        });

        return description;
      },

      hasModal() {
        return this.hasModalAutonomousEdit || this.hasModalAddressEdit || this.hasModalCompanyEdit || this.hasModalOwnerEdit;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .account-informations-container {
    height: 100vh;

    &.has-modal {
      overflow: hidden;
      position: fixed;
      width: 100vw;
    }

    &.has-modal > *:not(.modal) {
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }

    .account-informations {
      display: flex;

      .content {
        width: 100%;

        .header {
          margin-bottom: 3rem;
        }

        .section-container {
          .content {
            padding: 1.5rem;
          }

          .center {
            display: flex;
            justify-content: center;

            .section {
              display: flex;

              .line {
                width: 1px;
                background: #EFEFEF;
                margin-left: 2rem;
                margin-right: 2rem;
              }

              .content {
                padding: 0;
                width: 700px;

                .header {
                  margin-top: 1rem;
                  margin-bottom: 2rem;

                  p {
                    font-size: 16px;
                    font-weight: 600;
                    color: #333;
                  }

                  .button {
                    margin-left: auto;
                    color: #8E8E8E;
                    font-weight: 600;

                    i {
                      margin-right: 0.5rem;
                      color: #0052CC;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .account-informations-container {
      .account-informations {
        .content {
          .section-container {
            .center {
              .section {
                .content {
                  width: 500px;
                }
              }
            }
          }
        }
      }
    }
  }


  @media (max-width: 1100px) {
    .account-informations-container {
      .account-informations {
        .content {
          .section-container {
            .center {
              .section {
                width: 100%;

                .content {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 760px) {
    .account-informations-container {
      .account-informations {
        .content {
          .header {
            margin-bottom: 1rem;
          }

          .section-container {
            .center {
              display: block;

              .section {
                flex-direction: column;

                .line {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .account-informations-container {
      &.has-sidebar {
        overflow-y: hidden;
      }
    }
  }
</style>