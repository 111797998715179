<template>
  <modal
    ref="modal"
    title="Editar dados"
    :showModal="hasModalAutonomousEdit"
    @close="closeModalEdit"
  >
    <template v-slot:body>
      <ValidationObserver
        tag="div"
        class="body"
        ref="form"
        v-slot="{ passes, pristine }"
      >
        <form @input="toggleButtonState(pristine)" @submit.prevent="passes(submitIndividualFormData)">
          <div class="container">
            <div class="section">
              <div class="two-column">
                <input-field
                  placeholder="Nome completo"
                  name="name"
                  rules="required"
                  v-model="individualData.completedName"
                />

                <input-field
                  class="right"
                  placeholder="Data de nascimento"
                  name="date"
                  mask="##/##/####"
                  rules="required"
                  v-model="individualData.birthDate"
                />
              </div>

              <div class="column">
                <input-field
                  placeholder="Nome fantasia"
                  name="statmentDescriptor"
                  rules="required"
                  v-model="individualData.statementDescriptor"
                />
              </div>

              <div class="two-column">
                <input-field
                  placeholder="E-mail"
                  name="email"
                  rules="required"
                  v-model="individualData.email"
                />

                <input-field
                  class="right"
                   :mask="['(##) ####-####', '(##) #####-####']"
                  name="phone"
                  rules="required"
                  placeholder="Telefone empresarial"
                  v-model="individualData.phoneNumber"
                />
              </div>
            </div>

            <div class="button-container">
              <button-component
                scale="normal"
                size="large"
                v-ripple="'rgba(255, 139, 0, 0.2)'"
                styles="ghost"
                type="button"
                @click.native="closeModalEdit"
                text="Fechar"
              />

              <button-component
                class="right"
                scale="normal"
                size="large"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                styles="primary"
                :isDisabled="isButtonDisable"
                :hasLoader="hasRequest"
                text="Alterar e salvar"
              />
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  import { mapState } from 'vuex';
  import Modal from '@/components/Modal';
  import Button from '@/components/Button';
  import InputField from '@/components/InputField';

  export default {
    name: 'ModalEditAutonomousSeller',

    data() {
      return {
        hasRequest: false,
        isButtonDisable: true,
        individualData: {
          firstName: '',
          lastName: '',
          completedName: '',
          email: '',
          phoneNumber: '',
          birthDate: '',
          statementDescriptor: '',
          addressLine: '',
          addressNumber: '',
          addressComplement: '',
          addressNeighborhood: '',
          addressCity: '',
          addressState: '',
          addressPostalCode: '',
        },
      };
    },

    components: {
      ValidationObserver,
      'button-component': Button,
      'input-field': InputField,
      'modal': Modal
    },

    computed: {
      ...mapState({
        hasModalAutonomousEdit: state => state.hasModalAutonomousEdit,
        sellerData: state => state.sellerData,
      })
    },

    watch: {
      sellerData() {
        this.setDefaultIndividualValues();
      },

      hasModalAutonomousEdit() {
        this.setDefaultIndividualValues();
      }
    },

    mounted() {
      this.setDefaultIndividualValues();
    },

    methods: {
      submitIndividualFormData() {
        this.hasRequest = true;

        let formData = JSON.stringify({
          first_name: this.$service.splitFirstName(this.individualData.completedName),
          last_name: this.$service.splitLastName(this.individualData.completedName),
          email: this.individualData.email,
          phone_number: this.$service.removeCharacter(this.individualData.phoneNumber),
          birthdate: this.$service.dateFormat(this.individualData.birthDate, 'DD/MM/YYYY', 'YYYY-MM-DD'),
          statement_descriptor: this.individualData.statementDescriptor,
          address_line1: this.individualData.addressLine,
          address_line2: this.individualData.addressNumber,
          address_line3: this.individualData.addressComplement,
          address_neighborhood: this.individualData.addressNeighborhood,
          address_city: this.individualData.addressCity,
          address_state: this.individualData.addressState,
          address_postal_code: this.$service.removeCharacter(this.individualData.addressPostalCode),
        });

        this.$store.dispatch('putIndividualSeller', { formData })
          .then(() => {
            this.$store.dispatch('fetchSellerData')
              .then(() => {
                this.setSuccessAlertCard();
                this.$store.commit('setAlertCardStatus', true);
              })
              .catch(() => {
                this.setUpdateErrorAlertCard();
                this.$store.commit('setAlertCardStatus', true);
              })
              .finally(() => {
                this.closeModalEdit();
                this.hasRequest = false;
              });
          })
          .catch(() => {
            this.closeModalEdit();
            this.hasRequest = false;
            this.$store.commit('setAlertCardStatus', true);
            this.setErrorAlertCard();
          });

        this.isButtonDisable = true;
      },

      setDefaultIndividualValues() {
        this.individualData.completedName = this.sellerData.first_name + ' ' + this.sellerData.last_name;
        this.individualData.email = this.sellerData.email;
        this.individualData.phoneNumber = this.sellerData.phone_number;
        this.individualData.birthDate = this.$service.dateFormat(this.sellerData.birthdate, 'YYYY-MM-DD', 'DD/MM/YYYY');
        this.individualData.statementDescriptor = this.sellerData.statement_descriptor;
        this.individualData.mcc = this.sellerData.mcc;

        this.individualData.addressLine = this.sellerData.address_line1;
        this.individualData.addressNumber = this.sellerData.address_line2;
        this.individualData.addressComplement = this.sellerData.address_line3;
        this.individualData.addressNeighborhood = this.sellerData.address_neighborhood;
        this.individualData.addressCity = this.sellerData.address_city;
        this.individualData.addressState = this.sellerData.address_state;
        this.individualData.addressPostalCode = this.sellerData.address_postal_code;
      },

      closeModalEdit() {
        this.$store.commit('closeModalEdit');
      },

      setSuccessAlertCard() {
        this.$store.commit('setAlertCardData', {
          text: 'Dados editados com sucesso.',
          color: '#08B978'
        });
      },

      setErrorAlertCard() {
        this.$store.commit('setAlertCardData', {
          text: 'Erro ao editar os dados cadastrais.',
          color: '#FF5A5A'
        });
      },

      setUpdateErrorAlertCard() {
        this.$store.commit('setAlertCardData', {
          text: 'Erro ao atualizar os dados.',
          color: '#FF5A5A'
        });
      },

      toggleButtonState(pristine) {
        this.isButtonDisable = pristine;

        if (this.hasRequest) {
          this.isButtonDisable = true;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .modal {
    .body {
      .container {
        margin-top: 1.5rem;

        .section {
          .two-column {
            display: flex;

            .right {
              margin-left: 1rem;
              max-width: 225px;
            }
          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;

      .right {
        margin-left: 1rem;
        min-width: 156px;
      }
    }
  }

  @media (max-width: 767px) {
    .modal {
      h2 {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 520px) {
    .modal {
      .body {
        .container {
          overflow-y: scroll;
          max-height: 72vh;
          // margin-bottom: 4rem;

          &::-webkit-scrollbar {
            display: none;
          }

          .section {
            .two-column {
              flex-direction: column;

              .right {
                margin-left: 0;
                max-width: initial;
              }
            }

            .column {
              width: 100%;
            }
          }

          .button-container {
            justify-content: center;
          }
        }
      }
    }
  }
</style>