<template>
  <div class="default-card-container">
    <div class="default-card">
      <div class="icon-container">
        <i :class="data.icon"/>
      </div>

      <h3>{{ data.title }}</h3>

      <p>{{ data.text }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AccountDefaultCard',

    props: {
      data: {
        type: Object,
        default() {
          return {
            icon: 'icon-security',
            title: 'Title',
            text: 'Text',
          };
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .default-card-container {
    min-width: 250px;
    width: 250px;
    height: 300px;
    background: #F9F9F9;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    .default-card {
      color: #333;
      text-align: center;

      .icon-container {
        margin-bottom: 1.2rem;

        i {
          font-size: 3rem;
          color: $second-color;
        }
      }

      h3 {
        margin-bottom: 0.5rem;
      }
    }
  }
</style>
