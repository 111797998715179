<template>
  <div class="bank-view-container">
    <default-view-component :hasModal="hasRegisterAccountModal">
      <template v-slot:alert>
        <alert-component
          v-if="getAlertCardStatus"
          :data="getCurrentAlertCardData"
        />
      </template>

      <template v-slot:modal>
        <modal
          ref="modal"
          title="Adicionar conta bancária"
          :showModal="hasRegisterAccountModal"
          @close="closeModal"
        >
          <template v-slot:body>
            <ValidationObserver
              tag="div"
              class="body"
              ref="form"
              v-slot="{ passes }"
            >
              <form @submit.prevent="passes(submitNewAccount)">
                <div class="container">
                  <div class="section">
                    <div class="two-column">
                      <select-field
                        class="left"
                        placeholder="Tipo da conta"
                        :options="accountTypeOptions"
                        rules="required"
                        v-model="data.accountType"
                      />

                      <select-field
                        placeholder="Código do banco"
                        :options="formattedBankCodeItems"
                        rules="required"
                        :search="true"
                        :orderByCategory="true"
                        v-model="data.bankCode"
                      />
                    </div>

                    <div class="two-column">
                      <input-field
                        placeholder="Agência"
                        type="text"
                        mask="####"
                        rules="required|min:4"
                        v-model="data.routingNumber"
                      />

                      <input-field
                        class="right"
                        placeholder="Número da conta"
                        type="text"
                        :mask="['#####-#', '######-#', '#######-#', '########-#', '#########-#', '###########-#']"
                        rules="required|min:6"
                        v-model="data.accountNumber"
                      />
                    </div>

                    <div class="two-column">
                      <input-field
                        placeholder="Nome do titular"
                        :isDisabled="true"
                        type="text"
                        rules="required"
                        v-model="data.holderName"
                      />

                      <input-field
                        v-if="$service.isEquals(getCurrentSellerData.type, 'Individual')"
                        class="right"
                        placeholder="CPF"
                        :isDisabled="true"
                        type="text"
                        rules="required"
                        mask="###.###.###-##"
                        v-model="data.einOrTaxpayerId"
                      />

                      <input-field
                        v-else
                        class="right"
                        placeholder="CNPJ"
                        :isDisabled="true"
                        type="text"
                        rules="required"
                        mask="##.###.###/####-##"
                        v-model="data.einOrTaxpayerId"
                      />
                    </div>
                  </div>

                  <div class="button-container">
                    <button-component
                      scale="normal"
                      size="large"
                      v-ripple="'rgba(255, 139, 0, 0.2)'"
                      styles="ghost"
                      type="button"
                      @click.native="toggleModal"
                      text="Fechar"
                    />

                    <button-component
                      class="right"
                      scale="normal"
                      size="large"
                      v-ripple="'rgba(255, 255, 255, 0.35)'"
                      styles="primary"
                      :hasLoader="hasFetchNewAccount"
                      :isDisabled="hasFetchNewAccount"
                      text="Salvar"
                    />
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </modal>
      </template>

      <template v-slot:content>
        <div class="section">
          <header-component
            class="header"
            :data="header"
          />

          <div class="center">
            <default-card-component
              class="default-card-component"
              :data="cardData"
            />

            <div class="container">
              <div class="header">
                <div v-if="!getCurrentSellerData.is_banking && hasBankAccount && !hasFetchAccountsData && !hasRequest" class="withdraw-policy-container">
                  <toggle-switch-component
                    class="toggle-switch-component"
                    :value="getSellerReceivingPolicy"
                    @click.native="toggleReceivingPolicy()"
                  />

                  Saque automático

                  <tooltip-component class="tooltip-component">
                    <template v-slot:tooltipContent>
                      <div class="tooltip-text-container">
                        <div class="text">
                          <b>Transferência automática</b> - São realizadas automáticamente todo dia útil, quando há saldo disponível. Caso contrário, é necessário comandar o saque do valor desejado.
                        </div>
                      </div>
                    </template>

                    <template v-slot:content>
                      <i class="icon-information-circle" />
                    </template>
                  </tooltip-component>
                </div>

                <default-data-component v-else-if="hasFetchAccountsData || hasRequest" class="default-data-component"/>

                <button-component
                  class="button-component right"
                  scale="normal"
                  size="large"
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  styles="primary"
                  :isDisabled="hasFetchAccountsData || hasFetchBankCodes"
                  :hasLoader="hasFetchBankCodes"
                  text="Cadastrar conta"
                  @click.native="toggleModal"
                />
              </div>

              <div v-if="hasFetchAccountsData || hasRequest || hasFetchNewAccount" class="data">
                <default-data-component v-for="index in 3" :key="index" class="default"/>
              </div>

              <div v-else>
                <div v-if="hasBankAccount" class="data">
                  <div v-for="(item, index) in getAllBankAccountData" :key="index">
                    <div class="account-card-container">
                      <div class="account-card">
                        <div class="item">
                          <p v-if="isCheckingAccount(item.type)" class="label account-type">Conta corrente</p>

                          <p v-else class="label account-type">Conta poupança</p>
                        </div>

                        <div class="item">
                          <p class="label">Agência:</p>

                          <p>{{ item.routing_number }}</p>
                        </div>

                        <div class="item">
                          <p class="label">Número da conta:</p>

                          <p>{{ item.account_number }}</p>
                        </div>

                        <div class="item">
                          <p class="label">{{ isCompany(item.taxpayer_id) ? 'CNPJ:' : 'CPF:' }}</p>

                          <p>{{ formatTaxNumber(item.taxpayer_id) }}</p>
                        </div>

                        <div class="item">
                          <p class="label orange">{{ item.bank_name }}</p>
                        </div>
                      </div>

                      <div class="button-container">
                        <button-component
                          scale="normal"
                          v-ripple="'rgba(255, 139, 0, 0.2)'"
                          styles="ghost"
                          @click.native="removeAccount(item)"
                          :hasLoader="false"
                          type="button"
                          text="Remover"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else class="empty-data">
                  <div class="icon-container">
                    <i class="icon-bank-dollar"/>
                  </div>

                  <p>Nenhuma conta corrente cadastrada</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ValidationObserver } from 'vee-validate';
  import DefaultView from '@/components/Views/DefaultView';
  import Modal from '@/components/Modal';
  import Header from '@/components/Header';
  import Button from '@/components/Button';
  import SelectField from '@/components/SelectField';
  import InputField from '@/components/InputField';
  import AlertCard from '@/components/AlertCard';
  import DefaultData from '@/components/DefaultData';
  import AccountDefaultCard from '@/components/AccountDefaultCard';
  import ToggleSwitch from '@/components/ToggleSwitch';
  import Tooltip from '@/components/Tooltip';

  export default {
    name: 'BankAccountView',

    data() {
      return {
        hasBankAccount: false,
        hasRegisterAccountModal: false,
        isButtonDisable: true,

        hasRequest: false,
        hasFetchAccountsData: false,
        hasFetchNewAccount: false,
        // hasFetchReceivingPolicy: false,
        hasFetchBankCodes: false,

        accountTypeOptions: [],
        formattedBankCodeItems: [],

        data: {
          bankCode: '',
          routingNumber: '',
          accountNumber: '',
          sellerType: '',
          holderName: '',
          accountType: '',
          einOrTaxpayerId: ''
        },

        header: {
          title: 'Conta bancária para saques',
          to: '/account',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        cardData: {
          icon: 'icon-pay',
          title: 'Saque quando quiser',
          text: 'Mantenha sempre sua conta corrente atualizada'
        }
      };
    },

    components: {
      ValidationObserver,
      'modal': Modal,
      'select-field': SelectField,
      'input-field': InputField,
      'button-component': Button,
      'header-component': Header,
      'default-card-component': AccountDefaultCard,
      'default-data-component': DefaultData,
      'alert-component': AlertCard,
      'default-view-component': DefaultView,
      'toggle-switch-component': ToggleSwitch,
      'tooltip-component': Tooltip
    },

    computed: {
      ...mapGetters({
        getAllBankAccountData: 'bankAccountModule/getBankAccountData',
        getSellerReceivingPolicy : 'bankAccountModule/getReceivingPolicy',
        getAlertCardStatus: 'getAlertCardStatus',
        getCurrentAlertCardData: 'getCurrentAlertCardData',
        getCurrentSellerData: 'getCurrentSellerData',
        getCurrentSellerId: 'getCurrentSellerId',
        getCurrentSellerType: 'getCurrentSellerType',
        getBankCodesData: 'getBankCodesData'
      })
    },

    watch: {
      getCurrentSellerId() {
        this.$store.commit('bankAccountModule/resetBankAccountData');
        this.fetchData();
      },

      hasRegisterAccountModal() {
        if(this.hasRegisterAccountModal) {
          this.accountTypeOptions = [
            {
              category: 'BankAccount',
              value: 'Checking',
              label: 'Corrente'
            },

            {
              category: 'BankAccount',
              value: 'Savings',
              label: 'Poupança'
            }
          ];
        } else {
          this.accountTypeOptions = [];
        }
      },

      getCurrentSellerData() {
        this.setDefaultData();
      }
    },

    created() {
      this.fetchData();
      this.setDefaultData();
    },

    methods: {
      resetData() {
        this.data.accountNumber = '';
        this.data.accountType = '';
        this.data.bankCode = '';
        this.data.routingNumber = '';
      },

      setDefaultData() {
        this.data.sellerType = this.getCurrentSellerData.type;
        this.data.holderName = this.formatHolderName();
        this.data.einOrTaxpayerId = this.formatEinOrTaxpayerId();
      },

      async submitNewAccount() {
        let data;
        let isSucceeded = false;

        data = {
            bank_code: this.data.bankCode,
            routing_number: this.$service.removeCharacter(this.data.routingNumber),
            account_number: this.$service.removeCharacter(this.data.accountNumber),
            seller_type: this.data.sellerType,
            holder_name: this.data.holderName,
            type: this.data.accountType
        };

        if (this.$service.isEquals(this.getCurrentSellerType, 'Individual')) {
          data.taxpayer_id = this.$service.removeCharacter(this.data.einOrTaxpayerId);
        } else {
          data.ein = this.$service.removeCharacter(this.data.einOrTaxpayerId);
        }

        this.hasFetchNewAccount = true;

        await this.$store.dispatch('bankAccountModule/postBankAccount', JSON.stringify(data))
          .then(() => {
            isSucceeded = true;
            this.setSuccessAlertCard('Conta cadastrada com sucesso.');
          })
          .catch(() => {
            this.setErrorAlertCard('Erro ao cadatrar conta bancária.');
          });

        if (isSucceeded) {
          await this.fetchGetAllBankAccount();
        }

          this.closeModal();
          this.hasFetchNewAccount = false;
      },

      closeModal() {
        this.hasRegisterAccountModal = false;
      },

      async toggleModal() {
        if (this.$service.isNull(this.getBankCodesData)) {
          await this.fetchBankCodes();
        }

        this.resetData();
        this.formatBankCodes();
        this.hasRegisterAccountModal = !this.hasRegisterAccountModal;
      },

      formatBankCodes() {
        this.getBankCodesData.forEach(item => {
          this.formattedBankCodeItems.push({
            value: item.code,
            label: item.code + ' - ' + item.name
          });
        });
      },

      async fetchData() {
        this.hasRequest = true;

        if (this.$service.isNull(this.getSellerReceivingPolicy)) {
          this.fetchReceivingPolicy();
        }

        if (this.$service.isNull(this.getAllBankAccountData)) {
          await this.fetchGetAllBankAccount();
        }

        this.hasBankAccount = this.getAllBankAccountData && this.getAllBankAccountData.length > 0;
        this.hasRequest = false;
      },

      async fetchGetAllBankAccount() {
        await this.$store.dispatch('bankAccountModule/getAllBankAccount')
          .catch(err => {
            console.log(err);
          });

        this.hasBankAccount = this.getAllBankAccountData.length > 0;
      },

      async fetchReceivingPolicy() {
        await this.$store.dispatch('bankAccountModule/getOneReceivingPolicy');
      },

      toggleReceivingPolicy() {
        this.$store.dispatch('bankAccountModule/postReceivingPolicy', JSON.stringify({ 'transfer_enabled': !this.getSellerReceivingPolicy }));
      },

      formatHolderName() {
        return this.$service.isEquals(this.getCurrentSellerType, 'Individual') ?
          this.getCurrentSellerData.first_name + ' ' + this.getCurrentSellerData.last_name :
          this.getCurrentSellerData.business_name;
      },

      formatEinOrTaxpayerId() {
        return this.$service.isEquals(this.getCurrentSellerType, 'Individual') ?
          this.$service.formatTaxpayerId(this.getCurrentSellerData.taxpayer_id) :
          this.$service.formatEin(this.getCurrentSellerData.ein);
      },

      async fetchBankCodes() {
        this.hasFetchBankCodes = true;

        await this.$store.dispatch('getBankCodes')
          .then(res => {
            this.$store.commit('setBankCodes', res.data);
          })
          .catch(() => {
            this.setErrorAlertCard('Erro ao carregar os dados.');
          });

        this.hasFetchBankCodes = false;
      },

      async removeAccount(item) {
        let isSucceeded = false;
        this.hasFetchAccountsData = true;

        await this.$store.dispatch('bankAccountModule/deleteBankAccount', item.id)
          .then(() => {
            isSucceeded = true;
            this.setSuccessAlertCard('Conta removida com sucesso.');
          })
          .catch(() => {
            this.setErrorAlertCard('Erro ao remover. Tente novamente.');
          });

        if (isSucceeded) {
          await this.fetchGetAllBankAccount();
        }

        this.hasFetchAccountsData = false;
      },

      setSuccessAlertCard(text) {
        this.$store.commit('setAlertCardData', {
          text: text,
          color: '#08B978'
        });

        this.$store.commit('setAlertCardStatus', true);
      },

      setErrorAlertCard(text) {
        this.$store.commit('setAlertCardData', {
          text: text,
          color: '#FF5A5A'
        });

        this.$store.commit('setAlertCardStatus', true);
      },

      isCheckingAccount(type) {
        return this.$service.toUpperCase(type) === 'CHECKING';
      },

      isCompany(taxNumber) {
        return this.$service.isEin(taxNumber);
      },

      formatTaxNumber(taxNumber) {
        return this.isCompany(taxNumber) ? this.$service.formatEin(taxNumber) : this.$service.formatTaxpayerId(taxNumber);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .bank-view-container {
    .modal {
      .body {
        h2 {
          text-align: center;
          margin-bottom: 1.5rem;
        }

        .container {
          .section {
            h3 {
              color: #333;
              margin-bottom: 1rem;
            }

            .two-column {
              display: flex;

              .right {
                margin-left: 1rem;
                max-width: 225px;
              }

              .left {
                margin-right: 1rem;
              }
            }
          }
        }
      }

      .button-container {
        display: flex;
        justify-content: flex-end;

        .right {
          margin-left: 1rem;
          min-width: 156px;
        }
      }
    }

    .section {
      padding: 1.5rem;

      .header {
        margin-bottom: 2rem;
      }

      .center {
        display: flex;
        justify-content: center;

        .container {
          width: 40rem;
          margin-left: 2rem;

          .header {
            display: flex;
            align-items: center;

            .withdraw-policy-container {
              display: flex;
              font-weight: 500;

              .toggle-switch-component {
                margin-right: 0.5rem;
              }

              .tooltip-component {
                .tooltip-text-container {
                  width: 260px;

                  .text {
                    margin-bottom: 0.5rem;

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }

                i {
                  color: #1B87B1;
                  margin-left: 0.5rem;
                }
              }
            }

            .default-data-component {
              width: 205px;
              height: 20px;
            }
          }

          .button-component {
            min-width: 150px;
          }

          .right {
            margin-left: auto;
          }

          .empty-data {
            justify-content: center;
            display: flex;
            align-items: center;
            flex-direction: column;

            .icon-container {
              display: flex;
              justify-content: center;

              i {
                color: #DADADA;
                font-size: 5rem;
              }
            }

            p {
              margin-top: 2rem;
              color: #6D6F6F;
            }
          }

          .data {
            .default {
              margin-bottom: 1rem;
              border-radius: 6px;
              height: 192px;
            }

            .account-card-container {
              background-color: #F9F9F9;
              margin-bottom: 1rem;
              padding: 1rem;
              display: flex;
              border-radius: 6px;
              color: #333;

              .account-card {
                line-height: 2rem;

                .item {
                  display: flex;

                  .label {
                    font-weight: 500;
                    margin-right: 0.5rem;

                    &.orange {
                      color: $second-color;
                    }

                    &.account-type {
                      font-weight: 600;
                    }
                  }

                  p {
                    color: #333;
                  }
                }
              }

              .button-container {
                margin-left: auto;
                align-items: center;
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 976px) {
    .bank-view-container {
      .section {
        .center {
          .default-card-component {
            display: none;
          }

          .container {
            margin-left: 0;
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .bank-view-container {
      .section {
        .center {
          .container {
            .header {
              display: block;

              .withdraw-policy-container {
                margin-bottom: 1rem;
              }
            }

            .data {
              .account-card-container {
                flex-direction: column;

                .button-container {
                  margin-left: unset;
                  justify-content: center;
                  margin-top: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .bank-view-container {
      .modal {
        .body {
          h2 {
            font-size: 16px;
          }

          .container {
            .section {
              .two-column {
                flex-direction: column;

                .right {
                  max-width: unset;
                  margin-left: 0;
                }
              }
            }
          }
        }
      }

      .section {
        padding: 1rem;
      }
    }
  }
</style>