<template>
  <simple-default-view-component class="simple-default-view-component">
    <template v-slot:header>
      <div class="title">
        <h2>Nova venda</h2>
      </div>

      <div class="icon-container">
        <i class="icon-close" @click="close"/>
      </div>
    </template>

    <template v-slot:content>
      <div class="content">
        <img src="@/assets/images/check-image.png" />

        <h2>{{ title }}</h2>

        <p v-if="text">
          {{ text }}
        </p>

        <div class="link-container">
          <p ref="linkValue">
            {{ getUrl }}
          </p>

          <div class="icon-container">
            <i v-if="isCopied" class="icon-check-circle" />
            <i v-else class="icon-copy" @click="copy" />
          </div>
        </div>

        <button-component
          class="button-component"
          text="Minhas vendas"
          iconRight="icon-arrow-right"
          v-ripple="'rgba(255, 255, 255, 0.35)'"
          @click.native="toSales"
        />
      </div>
    </template>
  </simple-default-view-component>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SimpleDefaultView from '@/components/Views/SimpleDefaultView';
  import Button from '@/components/Button';

  export default {
    name: 'NewSaleSuccess',

    data() {
      return {
        isCopied: false,
        title: 'Link avulso gerado com sucesso!',
        text: 'Copie e envie o link ao seu cliente. Ele deverá preencher com os dados do cartão de crédito ou escanear o QR Code para que a venda seja efetivada.'
      };
    },

    components: {
      'simple-default-view-component': SimpleDefaultView,
      'button-component': Button
    },

    computed: {
      ...mapGetters({
        getConfettiConfig: 'getConfettiConfig',
        getCurrentSellerData: 'getCurrentSellerData',
        isSaleProgress: 'newSaleModule/getProgressStatus',
        getSaleData: 'newSaleModule/getData',
        getUrl: 'newSaleModule/getUrl'
      })
    },

    created() {
      if (!this.isSaleProgress && this.$route.params?.hasNewSale || !this.getCurrentSellerData.is_sale_enabled || !this.getUrl) {
        this.close();
      } else {
        this.$confetti.start(this.getConfettiConfig);

        if (this.isSlip()) {
          this.title = 'Boleto gerado com sucesso!';
          this.text = 'Copie e envie o link ao seu cliente. Você poderá acompanhar o status do pagamento na seção minhas vendas.';
        }

        setTimeout(() => {
          this.$confetti.stop();
        }, 2500);
      }
    },

    beforeDestroy() {
      this.$confetti.remove();
    },

    methods: {
      close() {
        this.$router.push('/dashboard');

        this.$store.commit('newSaleModule/resetData');
      },

      toSales() {
        let saleSection = this.$service.toUpperCase(this.getSaleData.paymentType) == 'CHECKOUT' ? 'checkouts' : 'transactions';

        this.$router.push('/sales');
        this.$store.commit('resetSaleNavigationData');
        this.$store.commit('setSaleNavigationByName', saleSection);
        this.$store.commit('newSaleModule/resetData');
      },

      isSlip() {
        return this.$service.toUpperCase(this.getSaleData.paymentType) == 'SLIP';
      },

      copy() {
        this.isCopied = true;

        setTimeout(() => {
          this.isCopied = false;
        }, 2500);

        this.$copyText(this.$refs.linkValue.innerText);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .simple-default-view-component {
    .title {
      display: flex;
      align-items: center;

      h2 {
        color: #333333;
      }
    }

    .content {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 1.5rem;

      img {
        width: 175px;
        height: 175px;
        margin-bottom: 2.5rem;
      }

      h2 {
        color: #333;
        margin-bottom: 1rem;
        text-align: center;
      }

      p {
        max-width: 625px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #006EFF;
      }

      .link-container {
        padding: 0 1rem;
        margin-top: 2.5rem;
        border: 2px solid rgb(8, 185, 120, 0.05);
        background: rgb(8, 185, 120, 0.05);
        border-radius: 6px;
        height: 56px;
        display: flex;
        max-width: 450px;
        width: 100%;
        align-items: center;

        p {
          font-size: 16px;
          font-weight: 400;
          color: #333;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .icon-container {
          margin-left: auto;

          i {
            font-size: 1.4rem;
            color: #8E8E8E;
            cursor: pointer;
            margin-left: 1rem;

            &:hover {
              color: #707070;
            }

            &.icon-check-circle {
              color: #08b978;
              cursor: not-allowed;
              font-size: 1.2rem;
            }
          }
        }
      }

      .button-component {
        margin-top: 2.5rem;
        height: 48px;
        width: 170px;
      }
    }
  }
</style>