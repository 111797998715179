<template>
  <div class="login-view-container">
    <image-view-component :image="require(`@/assets/images/image-login.png`)">
      <template v-slot:form>
        <ValidationObserver
          tag="div"
          class="form"
          ref="form"
          v-slot="{ passes }"
        >
          <form @submit.prevent="passes(login)">
            <h3>Acessar</h3>

            <input-field
              name="username"
              :rules="'required|' + hasTaxPayerId ? 'min:11' : 'mail'"
              :isDisabled="hasRequest"
              :maxLength="hasTaxPayerId ? 14 : 100"
              placeholder="CPF ou E-mail"
              @keydown.native.space.prevent
              v-model="data.username"
            />

            <input-field
              name="password"
              placeholder="Senha"
              rules="required|min:6"
              :isDisabled="hasRequest"
              v-model="data.password"
              type="password"
            />

            <button-component
              text="Acessar"
              styles="primary"
              size="large"
              :hasLoader="hasRequest"
              :isDisabled="hasRequest"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
            />
          </form>
        </ValidationObserver>
      </template>

      <template v-slot:options>
        <div class="options">
          <div class="top">
            <div class="checkbox-container">
              <checkbox-component
                class="checkbox-component"
                v-model="loggedInStatus"
              />

              Continuar conectado
            </div>

            <a @click="toForgotView">
              Esqueci senha
            </a>
          </div>

          <!-- <div class="line"></div>

          <div class="bottom">
            <p>Ainda não tem uma conta?</p>

            <button-component
              text="Abra sua conta"
              styles="ghost"
              size="large"
              pathView="/signup"
              v-ripple="'rgba(255, 139, 0, 0.2)'"
            />
          </div> -->
        </div>
      </template>
    </image-view-component>
  </div>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  import ImageView from '@/components/Views/ImageView';
  import InputField from '@/components/InputField';
  import Button from '@/components/Button';
  import Checkbox from '@/components/Checkbox';

  export default {
    name: 'LoginView',

    data() {
      return {
        data: {
          username: '',
          password: '',
        },

        hasRequest: false,
        loggedInStatus: true,
        hasTaxPayerId: false,
      };
    },

    components: {
      ValidationObserver,
      'image-view-component': ImageView,
      'checkbox-component': Checkbox,
      'button-component': Button,
      'input-field': InputField
    },

    watch: {
      'data.username'(value) {
        let regNumber = /[0-9]/;

        if (value != '') {
          this.hasTaxPayerId = regNumber.test(this.$service.removeCharacter(value)) && this.$service.removeCharacter(value).length === 11;

          if (this.hasTaxPayerId) {
            this.data.username = this.$service.formatTaxpayerId(value);
          }
        }
      }
    },

    methods: {
      async login() {
        let status = this.loggedInStatus;
        let isAuthenticated = false;
        let user = JSON.stringify({
          username: this.data.username,
          password: this.data.password,
        });

        this.hasRequest = true;

        this.$store.dispatch('logout');

        await this.$store.dispatch('postLogin', { user, status })
          .then(() => {
            isAuthenticated = true;
          })
          .catch(() => {
            this.$refs.form.setErrors({
              username: 'Usuário ou senha incorretos',
            });
          });

        if (isAuthenticated) {
          await this.fetchData();
        }

        this.hasRequest = false;
      },

      async fetchData() {
        await this.$store.dispatch('getUser')
          .catch(() => {
            this.logout();
            this.setOtherError();
          });

        await this.$store.dispatch('getAllSellers')
          .then(res => {
            this.$store.commit('setAllSellers', res.data);

            if (res.data.length > 0) {
              let currentSeller = res.data[res.data.length - 1];

              this.$store.commit('setCurrentSellerId', currentSeller.id);
              this.$router.push('/dashboard');
            } else {
              this.$router.push('/accreditation');
            }
          })
          .catch(() => {
            this.logout();
            this.setOtherError();
          });
      },

      toForgotView() {
        this.$router.push('/forgot');
      },

      setOtherError() {
        this.$refs.form.setErrors({
          username: 'Ocorreu um erro. Tente novamente',
        });
      },

      logout() {
        this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/').catch(() => {});
          });
      }
    }
  };
</script>

<style style lang="scss" scoped>
  .login-view-container {
    .form {
      color: #333;
      width: 100%;
      max-width: 395px;
      align-items: center;
      display: flex;

      form {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;

        h3 {
          margin-bottom: 16px;
        }
      }
    }

    .options {
      width: 100%;
      color: #333;
      margin-top: 2rem;
      max-width: 395px;

      .top {
        display: flex;
        margin-bottom: 2rem;
        font-size: 14px;

        .checkbox-container {
          display: flex;
          align-items: center;
        }

        a {
          cursor: pointer;
          margin-left: auto;
          color: #0052CC;
          text-decoration: underline;
        }
      }

      .line {
        width: 100%;
        height: 1px;
        background: #DADADA;
      }

      .bottom {
        margin-top: 2rem;
        text-align: center;

        p {
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .login-view-container{
      .options {
        .top {
          flex-direction: column;
          align-items: center;

          a {
            margin-top: 1.5rem;
            margin-left: 0;
          }
        }
      }
    }
  }
</style>