<template>
  <div class="range-field-container">
    <div class="label-container">
      <p>
        {{ label }}
      </p>
    </div>

    <div class="range-field-content" :class="{ 'disabled': isDisabled }">
      <input
        ref="range"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        v-model="innerValue"
        :disabled="isDisabled"
        :style="{ backgroundSize: backgroundSize }"
        @input="updateSlider"
      >

      <input
        type="number"
        :placeholder="min"
        v-model="inputValue"
        autocomplete="off"
        :disabled="isDisabled"
        @click="clearInput"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SliderField',

    props: {
      label: {
        type: String,
        default: ''
      },

      min: {
        type: Number,
        default: 1
      },

      max: {
        type: Number,
        default: 10
      },

      initialValue: {
        type: [String, Number],
        default: 0
      },

      step: {
        type: Number,
        default: 1
      },

      isDisabled: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        innerValue: 0,
        inputValue: 0,
        backgroundSize: '0% 100%'
      };
    },

    mounted() {
      if (this.initialValue) {
        this.innerValue = this.initialValue;
        this.inputValue = this.initialValue;
      } else {
        this.innerValue = this.min;
        this.inputValue = this.min;
      }

      this.updateBackground();
    },

    watch: {
      innerValue(value) {
        if (!value) {
          this.inputValue = this.min;
        } else {
          this.inputValue = value;
        }

        this.$emit('input', value ? value : this.min);
      },

      inputValue(value) {
        if (!value) {
          this.innerValue = this.min;
        } else {
          this.innerValue = value;

          if (value > this.max) {
            this.innerValue = this.max;
            this.inputValue = this.max;
          }

          if (value < this.min) {
            this.innerValue = this.min;
          }
        }

        this.updateBackground();
      }
    },

    methods: {
      updateSlider() {
        this.updateBackground();
      },

      updateBackground() {
        this.backgroundSize = (this.innerValue - this.min) * 100 / (this.max - this.min) + '% 100%';
      },

      syncValue(value) {
        if (!value) {
          this.innerValue = this.min;
          this.inputValue = this.min;
        } else {
          this.innerValue = value;
          this.inputValue = value;
        }
      },

      clearInput() {
        this.inputValue = '';
      }
    }
  };
</script>

<style lang="scss" scoped>
  .range-field-container {
    .label-container {
      display: flex;

      p {
        &.second-label {
          color: #6D6F6F;
        }

        &:last-child {
          margin-left: auto;
        }
      }
    }

    .range-field-content {
      width: 100%;
      display: flex;
      align-items: center;

      &.disabled {
        opacity: 0.8;
      }

      input {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type=number] {
          -moz-appearance: textfield;
          text-align: center;
          margin-left: 0.5rem;
          height: 35px;
          width: 35px;
          background: $white-color;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #DADADA;
          font-size: 14px;
        }

        &[type=range] {
          width: 100%;
          -webkit-appearance: none;
          height: 5px;
          border-radius: 5px;
          background: #EFEFEF;
          margin-left: 3px;
          margin-right: 3px;
          background-image: -webkit-gradient(linear, 20% 0%, 20% 100%, color-stop(0%, $second-color), color-stop(100%, $second-color));
          background-image: -webkit-linear-gradient(left, $second-color 0%,$second-color 100%);
          background-image: -moz-linear-gradient(left, $second-color 0%, $second-color 100%);
          background-image: -o-linear-gradient(to right, $second-color 0%,$second-color 100%);
          background-image: linear-gradient(to right, $second-color 0%,$second-color 100%);
          background-repeat: no-repeat;

          &:focus {
            outline: none;
          }

          &:focus::-webkit-slider-runnable-track {
            background: transparent;
          }

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 25px;
            width: 25px;
            border-radius: 50%;
            background: $white-color;
            border: 2.5px solid $second-color;
            cursor: ew-resize;
          }

          &::-webkit-slider-runnable-track  {
          -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
          }

        &:focus::-ms-fill-lower {
            background: transparent;
          }

          &:focus::-ms-fill-upper {
            background: transparent;
          }

          &::-moz-range-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
          }

          &::-moz-range-thumb {
            -webkit-appearance: none;
            height: 18px;
            width: 18px;
            border-radius: 50%;
            background: $second-color;
            border: 3px solid $white-color;
            cursor: ew-resize;
          }
        }
      }
    }
  }
</style>