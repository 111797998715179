<template>
  <div class="scroll-card-container" :class="{ 'disabled': isDisabled }" ref="scrollContainer">
    <div class="icon-container" @click="left">
      <i class="icon-keyboard-arrow-left"/>
    </div>

    <div class="items-container" ref="scroll">
      <div
        v-for="(item, index) in data"
        :key="index"
        :id="index"
        ref="item"
        class="item"
        :class="{ 'active': item.isActive }"
        @click="toggleState(item)"
      >
        {{ item.text }}
      </div>
    </div>

    <div class="icon-container" @click="right">
      <i class="icon-keyboard-arrow-right"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ScrollCard',

    props: {
      data: {
        type: Array,
        default: () => [
          {
            text: 'Jan',
            isActive: true,
            value: 1
          },

          {
            text: 'Fev',
            isActive: false,
            value: 2
          },

          {
            text: 'Mar',
            isActive: false,
            value: 3
          },

          {
            text: 'Abr',
            isActive: false,
            value: 4
          },

          {
            text: 'Mai',
            isActive: false,
            value: 5
          },

          {
            text: 'Jun',
            isActive: false,
            value: 6
          },

          {
            text: 'Jul',
            isActive: false,
            value: 7
          },

          {
            text: 'Ago',
            isActive: false,
            value: 8
          },

          {
            text: 'Set',
            isActive: false,
            value: 9
          },

          {
            text: 'Out',
            isActive: false,
            value: 10
          },

          {
            text: 'Nov',
            isActive: false,
            value: 11
          },

          {
            text: 'Dez',
            isActive: false,
            value: 12
          }
        ]
      },

      isDisabled: {
        type: Boolean,
        default: false
      }
    },

    mounted() {
      this.toggleState(this.data.filter(item => item.isActive)[0]);
    },

    methods: {
      left() {
        if (!this.isDisabled) {
          this.$refs.scroll.scrollLeft -= 30;
        }
      },

      right() {
        if (!this.isDisabled) {
          this.$refs.scroll.scrollLeft += 30;
        }
      },

      toggleState(item) {
        if (!this.isDisabled) {
          this.data.map(item => item.isActive = false);
          item.isActive = true;

          this.$store.commit('setExtractFilter', {
            type: 'allMonth',
            startDate: this.$service.getStartDateMonth(item.value, 'M'),
            endDate: this.$service.getEndDateMonth(item.value, 'M')
          });

          this.goToItem(item.value - 1);
        }
      },

      goToItem(value) {
        this.$nextTick(() => {
          this.$refs.item[value].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .scroll-card-container {
    max-width: 485px;
    border: solid 1px #DADADA;
    display: flex;
    border-radius: 6px;
    padding: 0.5rem 0;
    user-select: none;
    height: 56px;

    &.disabled {
      background: #EFEFEF;
      cursor: not-allowed;
      opacity: 0.6;

      .icon-container {
        cursor: not-allowed;
      }

      .items-container {
        .item {
          background: unset !important;
          color: #6D6F6F !important;
          cursor: not-allowed !important;
        }
      }
    }

    .icon-container {
      cursor: pointer;
      align-items: center;
      display: flex;
      font-size: 0.7rem;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      color: #8E8E8E;
    }

    .items-container {
      max-width: 420px;
      display: flex;
      align-items: center;
      overflow: hidden;

      &::-webkit-scrollbar {
        display: none;
      }

      .item {
        cursor: pointer;
        margin-left: 0.3rem;
        margin-right: 0.4rem;
        color: #6D6F6F;
        padding: 0.3rem 1rem;
        border-radius: 6px;
        font-weight: 400;

        &:hover {
          background: #FF8B006B;
          color: $white-color;
        }

        &.active {
          background: $second-color;
          color: $white-color;
        }
      }
    }
  }
</style>