<template>
  <div>
    <div class="input-date-container" @click="toggleCalendarCardState">
      <div class="input-date">
        <p class="label" :class="{ 'undated': !hasData }">Período</p>

        <p v-if="hasData" class="value">{{ rangeStr }}</p>
      </div>

      <div class="icon-container">
        <i class="icon-calendar"></i>
      </div>
    </div>

    <div v-if="hasCalendarField && calendarCardState" class="select-period-container" v-click-outside="toggleCalendarCardState">
      <div class="title-container">
        <p>{{ rangeStr }}</p>
      </div>

      <calendar-component
        :rangeProp="range"
      />
    </div>
  </div>
</template>

<script>
  import Calendar from '@/components/Calendar/CustomCalendar';

  export default {
    name: 'InputDate',

    data() {
      return {
        hasData: true,
        calendarCardState: false,
        rangeStr: 'Nenhum selecionado',

        range: {
          start: null,
          end: null
        },

        columnAttr: {
          default: 2
        },

        rowAttr: {
          default: 1
        }
      };
    },

    components: {
      'calendar-component': Calendar
    },

    props: {
      rangeProp: {
        type: Object,
        default() {
          return {
            start: null,
            end: null
          };
        }
      },

      hasCalendarField: {
        type: Boolean,
        default: true
      }
    },

    created() {
      this.setRange();
    },

    mounted() {
      this.onResize();

      window.addEventListener('resize', () => {
        this.onResize();
      });
    },

    watch: {
      'range.start'(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.rangeProp.start = value;
        this.setRange();
      },

      'range.end'(value) {
        this.calendarCardState = false;

        // eslint-disable-next-line vue/no-mutating-props
        this.rangeProp.end = value;

        this.setRange();
      },

      'rangeProp.start'(value) {
        this.range.start = value;
        this.setRange();
      },

      'rangeProp.end'(value) {
        this.range.end = value;
        this.setRange();
      }
    },

    methods: {
      toggleCalendarCardState() {
        this.calendarCardState = !this.calendarCardState;
      },

      onResize() {
        if (window.innerWidth < 720) {
          this.columnAttr.default = 1;
          this.rowAttr.default = 2;
        } else {
          this.columnAttr.default = 2;
          this.rowAttr.default = 1;
        }
      },

      setRange() {
        if (!this.$service.isNull(this.rangeProp.start) && !this.$service.isNull(this.rangeProp.end)) {
          this.range = this.rangeProp;
          this.rangeStr = this.$service.dateFormat(this.rangeProp.start, 'YYYY-MM-DD', 'DD/MM/YYYY') + ' - ' + this.$service.dateFormat(this.rangeProp.end, 'YYYY-MM-DD', 'DD/MM/YYYY');
        } else {
          this.rangeStr = 'Nenhum selecionado';
        }
      }
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    }
  };
</script>

<style lang="scss" scoped>
  .input-date-container {
    border: 1px solid #DADADA;
    border-radius: 6px;
    width: fit-content;
    padding: 0.3rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    cursor: pointer;
    min-height: 52px;

    .input-date {
      .label {
        font-size: 12px;
        color: #8E8E8E;
        font-weight: 400;

        &.undated {
          font-size: 14px;
          color: #333;
        }
      }

      .value {
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .icon-container {
      margin-left: 2.5rem;
      font-size: 1.3rem;
      color: #8E8E8E
    }
  }

  .select-period-container {
    position: absolute;
    background: $white-color;
    top: 25rem;
    z-index: 1;
    padding: 1rem;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 25%);
    border-radius: 6px;
    min-width: 320px;

    .title-container {
      border-bottom: 1px solid #DADADA;
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        margin-bottom: 1rem;
        color: $second-color;
        font-weight: 600;
      }
    }

    .vc-container {
      font-family: 'Poppins', sans-serif;
      border-color: transparent;
      background-color: transparent;
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    .select-period-container {
      top: 32rem;
    }
  }

  @media (max-width: 780px) {
    .select-period-container {
      top: 12rem;
    }
  }

  @media (max-width: 480px) {
    .input-date-container {
      .input-date {
        p {
          &.value {
            font-size: 12px;
          }
        }
      }
    }

    .select-period-container {
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1000;
    }
  }
</style>
