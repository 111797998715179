<template>
  <div class="accreditation-container">
    <navbar-component
      :hasLogo="true"
    />

    <div class="accreditation">
      <div class="description">
        <h1>
          Oi

          <span>
            {{ formattedUserData }},
          </span>

          <img src="@/assets/icons/hand.svg">
        </h1>

        <h2>Seu cadastro foi realizado com sucesso!</h2>

        <p>Sua conta <b>{{ $service.getPlatformTitle() }}</b> está em processo de verificação por nossa equipe, e em cerca de <b>48 horas</b> seu acesso será liberado.</p>

        <p>Em caso de dúvida consulte <b>nosso suporte</b></p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import NavBar from '@/components/NavBar';

  export default {
    name: 'AccreditationView',

    data() {
      return {
        formattedUserData: ''
      };
    },

    components: {
      'navbar-component': NavBar,
    },

    computed: {
      ...mapGetters([
        'getConfettiConfig'
      ]),

      ...mapState({
        userData: state => state.userData,
        sellers: state => state.sellers,
      }),
    },

    created() {
      this.$confetti.start(this.getConfettiConfig);

      setTimeout(() => {
        this.$confetti.stop();
      }, 2500);

      this.$store.dispatch('getAllSellers')
        .then(res => {
          this.$store.commit('setAllSellers', res.data);

          if (res.data.length > 0) {
            let currentSeller = res.data[res.data.length - 1];

            this.$store.commit('setCurrentSellerId', currentSeller.id);
          }
        });
    },

    mounted() {
      this.formattedUserData = this.splitName(this.userData.firstName);
    },

    beforeDestroy() {
      this.$confetti.remove();
    },

    methods: {
      splitName(completedName) {
        return completedName.split(' ')[0];
      }
    }
  };
</script>

<style lang="scss" scoped>
  .accreditation-container {
    background: linear-gradient(305.95deg, $first-color 0%, $first-color-dark-3 92.25%);
    min-height: 100vh;

    .accreditation {
      color: $white-color;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;

      .description {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        h1 {
          display: flex;
          justify-content: center;
          margin-bottom: 1rem;

          span {
            margin-left: 0.5rem;
            text-transform: capitalize;
          }

          img {
            margin-left: 0.7rem;
          }
        }

        h2 {
          font-size: 20px;
        }

        p {
          margin-top: 1rem;
          font-size: 16px;
          width: 600px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .accreditation-container {
      .accreditation {
        .description {
          padding: 0 1rem;
          p {
            width: 100%;
          }
        }
      }
    }
  }
</style>