import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';
import { instance } from '../config/axios';
import { encryptStorage } from '../config/encryptStorage';
import * as Sentry from '@sentry/vue';

import companyAccreditationModule from './modules/companyAccreditation';
import autonomousAccreditationModule from './modules/autonomousAccreditation';
import individualAccreditationModule from './modules/individualAccreditation';
import bankAccountModule from './modules/bankAccount';
import newSaleModule from './modules/newSale';
import authModule from './modules/auth';
import paymentModule from './modules/payment';
import receivableModule from './modules/receivable';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: encryptStorage
});

function initialState() {
  return {
    dashboardBalanceValueState: false,
    hasModalUserEdit: false,
    hasModalAutonomousEdit: false,
    hasModalAddressEdit: false,
    hasModalCompanyEdit: false,
    hasModalOwnerEdit: false,
    hasModalOwnerAddressEdit: false,
    isOwnerAddressEdit: false,

    mcc: [],
    bankCodes: null,
    noticeCardData: {},
    showAlertCard: false,
    alertData: {},

    sellers: [],
    sellerDocuments: [],
    sellerData: {},
    currentSellerId: '',
    currentSellerStatus: '',
    currentSellerType: '',

    workspaceData: {
      id: '',
      name: '',
    },

    userData: {
      name: '',
      firstName: '',
      taxpayerId: '',
      email: '',
      phone: ''
    },

    extractFilter: {
      type: '',
      startDate: null,
      endDate: null
    },

    confettiConfig: {
      particles: [
        {
          type: 'rect',
        }
      ],
      defaultColors: [
        '#78CDEA',
        '#FEC500',
        '#08B978',
        '#FF8B00'
      ],
      particlesPerFrame: 0.75
    },

    hasExtractPerTransactionFilter: false,
    hasExtractPerDayFilter: false,

    accountBalance: null,
    subBalance: null,

    saleNavigationData: {
      current: '',
      items: [
        {
          label: 'Transações',
          isActive: false,
          name: 'transactions',
          icon: 'icon-charges'
        },

        {
          label: 'Assinaturas',
          isActive: false,
          name: 'subscriptions',
          icon: 'icon-signature'
        },

        {
          label: 'Link avulso',
          isActive: false,
          name: 'checkouts',
          icon: 'icon-link'
        }
      ]
    },

    sidebar: {
      hideSideBar: false,
      disableToggleSidebar: false,
      columns: [
        {
          label: 'Painel',
          icon: 'icon-dashboard',
          path: '/dashboard',
          state: true,
        },

        {
          label: 'Digital pay',
          icon: 'icon-digitalpay',
          state: true,
          hasActive: false,
          openSection: false,
          rows: [
            {
              label: 'Extrato e saldo',
              icon: 'icon-statement',
              path: '/extract',
              state: false,
            },

            {
              label: 'Recebíveis',
              icon: 'icon-receivables',
              path: '/receivables',
              state: false,
            },

            {
              label: 'Vendas',
              icon: 'icon-charges',
              path: '/sales',
              state: false,
            },

            // {
            //   label: 'Pagamentos',
            //   icon: 'icon-payments',
            //   path: '',
            //   state: false,
            // },

            {
              label: 'Saques',
              icon: 'icon-transfer',
              path: '/transfers',
              state: false,
            }
          ]
        },

        {
          label: 'Serviços',
          icon: 'icon-services',
          state: true,
          hasActiveItem: false,
          openSection: false,
          rows: [
            {
              label: 'Clientes',
              icon: 'icon-client',
              path: '/customers',
              state: false,
            },

            // {
            //   label: 'Produtos',
            //   icon: 'icon-products',
            //   path: '/products',
            //   state: false,
            // },

            // {
            //   label: 'Serviços',
            //   icon: 'icon-products',
            //   path: '/services',
            //   state: false,
            // },

            // {
            //  label: 'Agenda',
            //  icon: 'icon-schedule',
            //  path: '',
            //  state: false,
            // },

            // {
            //    label: 'Despesas',
            //    icon: 'icon-expense',
            //    path: '',
            //    state: false,
            // }
          ]
        }
      ]
    }
  };
}

const actions = {
  createGetRequest(_, endpoint) {
    return axios.get(process.env.VUE_APP_BASE_API_URL + endpoint, {
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': encryptStorage.getItem('token')
      }
    });
  },

  createMultiplesRequests(_, requests) {
    return new Promise((resolve, reject) => {
      axios.all(requests)
        .then(responses => {
          resolve(responses);
        }).catch(errors => {
          reject(errors.response);
        });
    });
  },

  fetchSellerData({ dispatch, commit, state }) {
    return new Promise((resolve, reject) => {
      let getSellerData = dispatch('createGetRequest', '/sellers/' + state.currentSellerId);
      let getAllSellers = dispatch('createGetRequest', '/sellers?limit=500');

      dispatch('createMultiplesRequests', [getSellerData, getAllSellers])
        .then(res => {
          let responseSellerData = res[0].data;
          let responseAllSellersData = res[1].data;

          commit('setCurrentSeller', responseSellerData.data);
          commit('setCurrentSellerType', responseSellerData.data.type);

          commit('setCurrentSellerStatus');
          commit('setAllSellers', responseAllSellersData.data);

          resolve(res);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  setAuthorization({ commit }, data) {
    if (data.status) encryptStorage.setItem('token', data.token);

    commit('setWorkspaceData', {
      id: data.workspaceId,
      name: data.workspaceName,
    });
  },

  postLogin({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      instance().post('/auth/token', data.user)
        .then(response => {
          let res = response.data;
          let token = res.data.access_token;
          let workspaceName = res.data.workspaces[0].name;
          let workspaceId = res.data.workspaces[0].id;
          let tokenExpiresIn = res.data.expires_in + 's';
          let status = data.status;

          dispatch('setAuthorization', { token, workspaceName, workspaceId, tokenExpiresIn, status });

          resolve(response);
        })
        .catch(err => {
          encryptStorage.removeItem('token');

          reject(err.response);
        });
    });
  },

  postSignUp({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      instance().post('/users', data.user)
        .then(response => {
          let res = response.data;
          let token = res.data.access_token;
          let workspaceName = res.data.workspace_name;
          let workspaceId = res.data.workspace_id;
          let tokenExpiresIn = res.data.access_token_expires_in + 's';
          let status = true;

          dispatch('setAuthorization', { token, workspaceName, workspaceId, tokenExpiresIn, status });

          resolve(res);
        })
        .catch(err => {
          encryptStorage.removeItem('token');

          reject(err.response);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      encryptStorage.removeItem('token');

      Sentry.setUser(null);

      commit('setLogOut');
      resolve();
    });
  },

  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      instance().get('/users/self')
        .then(response => {
          const res = response.data;
          const workspaceIds = res.data.workspaces.map(workspace => workspace.id);
          const workspaceIdsString = workspaceIds.length > 1 ? workspaceIds.join(' | ') : workspaceIds[0];

          Sentry.setUser({
            email: res.data.email,
            name: res.data.name,
            workspaces: workspaceIdsString
          });

          commit('setUserData', res.data);
          commit('authModule/setIsResetPassword', res.data.is_reset_password);

          resolve(res);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  putUser(data) {
    return new Promise((resolve, reject) => {
      instance().get('/users/self', data.formData)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postCompany({ commit }, data) {
    return new Promise((resolve, reject) => {
      instance().post('/sellers/companies', data.company)
        .then(response => {
          let res = response.data;

          commit('setCurrentSellerId', res.data.id);

          resolve(res);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postIndividual({ commit }, data) {
    return new Promise((resolve, reject) => {
      instance().post('/sellers/companies', data.individual)
        .then(response => {
          let res = response.data;

          commit('setCurrentSellerId', res.data.id);

          resolve(res);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getMerchantCategoriesCode({ commit }) {
    return new Promise((resolve, reject) => {
      instance().get('/merchant-categories')
        .then(response => {
          let res = response.data;

          commit('setMerchantCategoriesCode', res.data);

          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAddress(_, postalCode) {
    return new Promise((resolve, reject) => {
      instance().get('/postal-codes/' + postalCode)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllSellers() {
    return new Promise((resolve, reject) => {
      instance().get('/sellers?limit=500')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  putIndividualSeller({ state }, data) {
    return new Promise((resolve, reject) => {
      instance().put('/sellers/individuals/' + state.currentSellerId, data.formData)
        .then(res => {
          resolve(res.data.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  putCompanySeller({ state }, data) {
    return new Promise((resolve, reject) => {
      instance().put('/sellers/companies/' + state.currentSellerId, data.formData)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getSellerId({ commit, state }) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId)
        .then(res => {
          let response = res.data;

          commit('setCurrentSeller', response.data);
          commit('setCurrentSellerType', response.data.type);
          commit('setCurrentSellerStatus');

          resolve(response);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postDocument({ state }, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url: process.env.VUE_APP_BASE_API_URL + '/sellers/' + state.currentSellerId + '/documents',
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Access-Token': encryptStorage.getItem('token')
        }
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getDocument({ state }, data) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/documents/' + data.documentId + '/' + data.type)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllDocuments({ commit, state }) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/documents')
        .then(res => {
          let response = res.data;

          commit('resetSellerDocuments');
          commit('setSellerDocuments', response.data);
          commit('setCurrentSellerStatus');

          resolve(response);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getBankCodes() {
    return new Promise((resolve, reject) => {
      instance().get('/banks?limit=500')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getExtract({ state }, params) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/reports/account-history' + `${params ? params : ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAccountOperation({ state }, params) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/reports/account-operation' + `${params ? params : ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getReceivablesTransaction({ state }, data) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/transactions/' + data.transactionId + '/receivables')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getSellerCheckouts({ state }, params) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/checkouts' + `${params ? params : ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getSellerBalances({ state, commit }) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/balances')
        .then(res => {
          let resData = res.data;

          commit('setAccountBalance', resData.data.account_balance);
          commit('setSubBalance', resData.data.sub_balance);

          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getTransactions({ state }, params) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/transactions' + `${params ? params : ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getTransactionCancelledLetter({ state }, transactionId) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/transactions/' + transactionId + '/cancelled-letter')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postTransactionRefund({ state }, transaction) {
    return new Promise((resolve, reject) => {
      instance().post('/sellers/' + state.currentSellerId + '/transactions/' + transaction.id + '/refund', transaction.data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getTransactionId({ state }, transactionId) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/transactions/' + transactionId)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getSubscriptions({ state }, params) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/subscriptions' + `${params ? params : ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getSubscriptionId({ state }, subscriptionId) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/subscriptions/' + subscriptionId)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllCustomers({ state }, params) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/customers' + `${params || ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllCustomersFind({ state }, search) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/customers/find?search=' + search)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getCustomerId({ state }, customerId) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/customers/' + customerId)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllCard({ state }, customerId) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/customers/' + customerId + '/cards')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getSubscriptionInvoices({ state }, subscriptionId) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/subscriptions/' + subscriptionId + '/invoices')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getPlanId({ state }, planId) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/plans/' + planId)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postPauseSubscription({ state }, subscriptionId) {
    return new Promise((resolve, reject) => {
      instance().post('/sellers/' + state.currentSellerId + '/subscriptions/' + subscriptionId + '/pause')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postCancelSubscription({ state }, subscriptionId) {
    return new Promise((resolve, reject) => {
      instance().delete('/sellers/' + state.currentSellerId + '/subscriptions/' + subscriptionId)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postResumeSubscription({ state }, subscriptionId) {
    return new Promise((resolve, reject) => {
      instance().post('/sellers/' + state.currentSellerId + '/subscriptions/' + subscriptionId + '/resume')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  putCustomer({ state }, customer) {
    return new Promise((resolve, reject) => {
      instance().put('/sellers/' + state.currentSellerId + '/customers/' + customer.id, customer.data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postCustomer({ state }, customer) {
    return new Promise((resolve, reject) => {
      instance().post('/sellers/' + state.currentSellerId + '/customers', customer.data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getCardDetails({ state }, cardTokenId) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/cards/' + cardTokenId)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postTransaction({ state }, data) {
    return new Promise((resolve, reject) => {
      instance().post('/sellers/' + state.currentSellerId + '/transactions', data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  postWithdraw({ state }, data) {
    return new Promise((resolve, reject) => {
      instance().post('/sellers/' + state.currentSellerId + '/withdraw', data)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllBankTransfers({ state }, params) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/bank-transfers' + `${params || ''}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllBankTransferId({ state }, transferId) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/bank-transfers/' + transferId)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getAllBankTransferHistoryId({ state }, transferId) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/bank-transfers/' + transferId + '/history')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getReceipt({ state }, transactionId) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + state.currentSellerId + '/transactions/' + transactionId + '/receipt')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  }
};

const getters = {
  getAccountBalance(state) {
    return state.accountBalance;
  },

  getSubBalance(state) {
    return state.subBalance;
  },

  getNoticeCardData(state) {
    return state.noticeCardData;
  },

  getBankCodesData(state) {
    return state.bankCodes;
  },

  getSellerDocuments(state) {
    return state.sellerDocuments;
  },

  getConfettiConfig(state) {
    return state.confettiConfig;
  },

  getUserData(state) {
    return state.userData;
  },

  getAlertCardStatus(state) {
    return state.showAlertCard;
  },

  getCurrentAlertCardData(state) {
    return state.alertData;
  },

  getCurrentSellerData(state) {
    return state.sellerData;
  },

  getCurrentSellerStatus(state) {
    return state.currentSellerStatus;
  },

  getCurrentSellerType(state) {
    return state.currentSellerType;
  },

  getCurrentSellerId(state) {
    return state.currentSellerId;
  },

  getDashboardBalanceValueState(state) {
    return state.dashboardBalanceValueState;
  },

  getExtractFilter(state) {
    return state.extractFilter;
  },

  getSellers(state) {
    return state.sellers;
  },

  getSideBarState(state) {
    return state.sidebar;
  },

  getExtractPerTransactionFilter(state) {
    return state.hasExtractPerTransactionFilter;
  },

  getSaleNavigationData(state) {
    return state.saleNavigationData;
  },

  getWorkspaceData(state) {
    return state.workspaceData;
  }
};

const mutations = {
  setExtractPerTransactionFilter(state, status) {
    state.hasExtractPerTransactionFilter = status;
  },

  toggleDashboardBalanceValueState(state) {
    state.dashboardBalanceValueState = !state.dashboardBalanceValueState;
  },

  resetSellerDocuments(state) {
    state.sellerDocuments = [];
  },

  setAccountBalance(state, amount) {
    state.accountBalance = amount;
  },

  setSubBalance(state, amount) {
    state.subBalance = amount;
  },

  setMerchantCategoriesCode(state, mcc) {
    state.mcc = mcc;
  },

  setCurrentSellerStatus(state) {
    if (state.sellerData.status === 'Active' || state.sellerData.status === 'Enabled') {
      state.currentSellerStatus = 'Active';
    } else if (state.sellerDocuments.length < 5) {
      state.currentSellerStatus = 'WaitingDocuments';
    } else if (state.sellerData.status === 'Pending') {
      state.currentSellerStatus = 'Pending';
    } else if (state.sellerData.status === 'Denied') {
      state.currentSellerStatus = 'Waiting';
    }
  },

  resetSaleNavigationData(state) {
    state.saleNavigationData.current = '';
    state.saleNavigationData.items.map(item => item.isActive = false);
  },

  setSaleNavigationByName(state, name) {
    let section = state.saleNavigationData.items.find(item => item.name == name);

    if (section) {
      state.saleNavigationData.current = section.name;
      section.isActive = true;
    }
  },

  setNoticeCardData(state, data) {
    state.noticeCardData = data;
  },

  setCurrentSellerType(state, type) {
    state.currentSellerType = type;
  },

  setToggleModalUserEdit(state) {
    state.hasModalUserEdit = !state.hasModalUserEdit;
  },

  setSellerDocuments(state, documents) {
    state.sellerDocuments = documents;
  },

  setToggleModalIndividualEdit(state) {
    state.hasModalAutonomousEdit = !state.hasModalAutonomousEdit;
  },

  setToggleModalCompanyEdit(state) {
    state.hasModalCompanyEdit = !state.hasModalCompanyEdit;
  },

  setToggleModalAddressEdit(state) {
    state.hasModalAddressEdit = !state.hasModalAddressEdit;
  },

  setToggleModalOwnerAddressEdit(state) {
    state.hasModalOwnerAddressEdit = !state.hasModalOwnerAddressEdit;
  },

  setToggleModalOwnerEdit(state) {
    state.hasModalOwnerEdit = !state.hasModalOwnerEdit;
  },

  setOwnerAddressEditStatus(state, status) {
    state.isOwnerAddressEdit = status;
  },

  closeModalEdit(state) {
    state.hasModalUserEdit = false;
    state.hasModalAutonomousEdit = false;
    state.hasModalAddressEdit = false;
    state.hasModalCompanyEdit = false;
    state.hasModalOwnerEdit = false;
    state.hasModalOwnerAddressEdit = false;
  },

  setAlertCardData(state, data) {
    state.alertData = data;
  },

  setAlertCardStatus(state, status) {
    state.showAlertCard = status;
  },

  setCurrentSellerId(state, id) {
    state.currentSellerId = id;
  },

  setUserData(state, data) {
    state.userData.name = data.name;
    state.userData.firstName = data.name.split(' ')[0];
    state.userData.email = data.email;
    state.userData.taxpayerId = data.taxpayer_id;
    state.userData.phone = data.phone_number;
    state.userData.isNewMerchantEnabled = data.is_new_merchant_enabled;
  },

  setAllSellers(state, sellers) {
    state.sellers = sellers;
  },

  setCurrentSeller(state, data) {
    state.sellerData = data;
  },

  setWorkspaceData(state, data) {
    state.workspaceData.id = data.id;
    state.workspaceData.name = data.name;
  },

  setBankCodes(state, data) {
    state.bankCodes = data;
  },

  setToggleDigitalSection(state) {
    state.hideDigitalSection = !state.hideDigitalSection;
  },

  setToggleServiceSection(state) {
    state.hideServiceSection = !state.hideServiceSection;
  },

  setExtractFilter(state, filter) {
    state.extractFilter = filter;
  },

  setLogOut(state) {
    const inital = initialState();

    Object.keys(inital).forEach(key => {
      state[key] = inital[key];
    });
  }
};

const store = new Vuex.Store({
  state: initialState(),
  actions,
  getters,
  mutations,
  modules: {
    companyAccreditation: companyAccreditationModule,
    individualAccreditation: individualAccreditationModule,
    autonomousAccreditation: autonomousAccreditationModule,
    bankAccountModule,
    newSaleModule,
    authModule,
    paymentModule,
    receivableModule
  },
  plugins: [
    vuexLocal.plugin
  ]
});

export default store;