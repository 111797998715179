<template>
  <div class="reset-view-container">
    <image-view-component :image="require(`@/assets/images/image-forgot.png`)">
      <template v-slot:form>
        <ValidationObserver
          tag="div"
          class="form"
          ref="form"
          v-slot="{ passes }"
        >
          <form @submit.prevent="passes(reset)">
            <h3>Redefinir senha</h3>

            <div v-if="data.email" class="information">
              <p>E-mail</p>

              <p>{{ data.email }}</p>
            </div>

            <input-field
              vid="pass"
              placeholder="Senha"
              :passwordValidation="true"
              rules="required|min:6|passwordCombination"
              :isDisabled="hasRequest"
              v-model="data.password"
              type="password"
            />

            <input-field
              placeholder="Confirmar senha"
              rules="required|confirmed:pass"
              :isDisabled="hasRequest"
              v-model="data.passwordRepeat"
              type="password"
            />

            <button-component
              text="Redefinir senha"
              styles="primary"
              size="large"
              :hasLoader="hasRequest"
              :isDisabled="hasRequest"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
            />
          </form>
        </ValidationObserver>
      </template>
    </image-view-component>
  </div>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  import ImageView from '@/components/Views/ImageView';
  import InputField from '@/components/InputField';
  import Button from '@/components/Button';

  export default {
    name: 'ResetView',

    data() {
      return {
        data: {
          token: '',
          email: '',
          password: ''
        },

        hasRequest: false
      };
    },

    components: {
      ValidationObserver,
      'image-view-component': ImageView,
      'button-component': Button,
      'input-field': InputField
    },

    created() {
      if (!this.$service.isEmptyObject(this.$route.query)) {
        this.data.token = this.$route.query.token;
        this.data.email = this.$route.query.email;
      } else {
        this.$router.push('/');
      }
    },

    methods: {
      async reset() {
        var isAuthenticated = false;
        let data = JSON.stringify({
          token: this.data.token,
          email: this.data.email,
          new_password: this.data.password
        });

        this.hasRequest = true;

        this.$store.dispatch('logout');

        await this.$store.dispatch('authModule/putResetPassword', data)
          .then(() => {
            isAuthenticated = true;
          })
          .catch(err => {
            console.log(err);
          });

        if (isAuthenticated) {
          await this.fetchData();
        }

        this.hasRequest = false;
      },

      async fetchData() {
        await this.$store.dispatch('getUser')
          .catch(() => {
            this.logout();
          });

        await this.$store.dispatch('getAllSellers')
          .then(res => {
            this.$store.commit('setAllSellers', res.data);

            if (res.data.length > 0) {
              let currentSeller = res.data[res.data.length - 1];

              this.$store.commit('setCurrentSellerId', currentSeller.id);
              this.$router.push('/dashboard');
            } else {
              this.$router.push('/accreditation');
            }
          })
          .catch(() => {
            this.logout();
          });
      },

      logout() {
        this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/').catch(() => {});
          });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .reset-view-container {
    .form {
      color: #333333;
      width: 100%;
      max-width: 395px;

      h3 {
        text-align: center;
        margin-bottom: 2rem;
      }

      .information {
        margin-bottom: 2rem;
      }
    }
  }
</style>