<template>
  <div
    v-if="data.show"
    class="dropdown-container"
    :class="{ 'margin-bottom': !data.hasNotBottomMargin }"
    v-click-outside="close"
  >
    <div
      v-for="(item, index) in data.options"
      :key="index"
      class="option"
      :class=" { 'active': item.isActive && data.hasSelect, 'bold': item.isBold, 'line': item.hasLine }"
      @click="toggleOption(item)"
    >
      <i :class="item.icon" />

      {{ item.label }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CustomDropdown',

    props: {
      data: {
        type: Object,
        default() {
          return {
            hasNotBottomMargin: true,
            show: true,
            hasSelect: true,
            options: [
              {
                label: 'Item',
                value: 'item1',
                icon: 'icon-close',
                isActive: false,
                isBold: true
              },

              {
                label: 'Item',
                value: 'item2',
                icon: 'icon-close',
                isActive: false,
                hasLine: true
              }
            ]
          };
        }
      },
    },

    methods: {
      toggleOption(current) {
        this.data.options.map(item => item.isActive = false);

        current.isActive = true;

        this.$emit('newOption');
      },

      close() {
        // eslint-disable-next-line vue/no-mutating-props
        this.data.show = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .dropdown-container {
    position: absolute;
    background: $white-color;
    padding-top: 0.5rem;
    min-width: 180px;
    border-radius: 6px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    font-size: 14px;

    &.margin-bottom {
      padding-bottom: 0.5rem;
    }

    .option {
      height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0 1rem;
      color: #333;

      &.bold {
        font-weight: 500;
      }

      &.line {
        border-bottom: 1px solid #DADADA;
      }

      &:hover {
        background: #F6F7F7;
      }

      &.active {
        background: $second-color;
        color: $white-color;

        i {
          color: $white-color;
        }
      }

      i {
        margin-right: 0.5rem;
        color: #8E8E8E;
      }
    }
  }
</style>
