<template>
  <div class="radio-card-container">
    <div v-for="(item, index) in data" :key="index" class="radio-card" :class="{ 'active': item.isChecked }">
      <radio
        :label="item.radioLabel"
        v-model="item.isChecked"
        :value="item.isChecked"
        @click.native.prevent="toggleRadioValue(index)"
      />

      <div class="items-container">
        <div v-for="(row, index) in item.rows" :key="index" class="row">
          <div v-for="(column, index) in row.columns" :key="index" class="column">
            <p class="item">
              {{ column.item }}
            </p>

            <p class="value">
              {{ column.value }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Radio from '@/components/Radio';

  export default {
    name: 'RadioCard',

    components: {
      'radio': Radio,
    },

    props: {
      data: {
        type: Array,
        default: () => [
          {
            isChecked: false,
            radioLabel: 'Label',
            rows: [
              {
                columns: [
                  {
                    item: 'Item',
                    value: 'Value'
                  },

                  {
                    item: 'Item',
                    value: 'Value'
                  }
                ]
              },

              {
                columns: [
                  {
                    item: 'Item',
                    value: 'Value'
                  }
                ]
              }
            ]
          },

          {
            isChecked: false,
            radioLabel: 'Label',
            rows: [
              {
                columns: [
                  {
                    item: 'Item',
                    value: 'Value'
                  },

                  {
                    item: 'Item',
                    value: 'Value'
                  }
                ]
              },

              {
                columns: [
                  {
                    item: 'Item',
                    value: 'Value'
                  }
                ]
              }
            ]
          }
        ]
      }
    },

    methods: {
      toggleRadioValue(index) {
        this.data.map(item => item.isChecked = false);

        // eslint-disable-next-line vue/no-mutating-props
        this.data[index].isChecked = true;

        this.$emit('toggleRadioValue');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .radio-card-container {
    padding: 0.2rem 0.5rem;
    overflow: auto;

    .radio-card {
      padding: 0.7rem;
      border: 1px solid #DADADA;
      border-radius: 6px;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        border: 1px solid $second-color;
        box-shadow: 0 0 0 1px $second-color, 0 0 0 3px $second-color-light-1;
      }

      .items-container {
        margin-left: 2rem;

        .row {
          display: flex;
          margin-top: 0.3rem;

          .column {
            width: 100%;
            display: flex;

            p {
              font-size: 12px;
            }

            .item {
              margin-right: 0.3rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .radio-card-container {
      .radio-card {
        .items-container {
          margin-top: 0.3rem;

          .row {
            flex-direction: column;
            margin-top: 0;
          }
        }
      }
    }
  }
</style>