import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import service from '@/services/service';

import { encryptStorage } from '../config/encryptStorage';

import SignUp from '@/views/SignUp';
import Login from '@/views/Login';
import NewAccreditation from '@/views/NewAccreditation';
import AccountInformation from '@/views/AccountInformation';
import AccountSecurity from '@/views/AccountSecurity';
import Account from '@/views/Account';
import BankAccount from '@/views/BankAccount';
import Dashboard from '@/views/Dashboard';
import Forgot from '@/views/Forgot';
import NotFound from '@/views/NotFound';
import Reset from '@/views/Reset';
import Extract from '@/views/Extract';
import Receivable from '@/views/Receivable';
import Sale from '@/views/Sale';
import NewSale from '@/views/NewSale/New';
import NewSaleResume from '@/views/NewSale/Resume';
import NewSaleSuccess from '@/views/NewSale/Success';
import Customer from '@/views/Customer';
import Transfer from '@/views/Transfer';

Vue.use(VueRouter);

const routes = [
  {
    path: '/franchisee',
    name: 'signup',
    component: SignUp,
    meta: {
      title: 'Criar conta',
      requiresAuth: false
    }
  },

  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      title: 'Entrar',
      requiresAuth: false
    }
  },

  {
    path: '/forgot',
    name: 'forgot',
    component: Forgot,
    meta: {
      title: 'Redefinir senha',
      requiresAuth: false
    }
  },

  {
    path: '/reset',
    name: 'reset',
    component: Reset,
    meta: {
      title: 'Redefinir senha',
      requiresAuth: false
    }
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Painel',
      requiresAuth: true
    }
  },

  {
    path: '/bank',
    name: 'bank-account',
    component: BankAccount,
    meta: {
      title: 'Conta bancária',
      requiresAuth: true
    }
  },

  {
    path: '/accreditation',
    name: 'accreditation',
    component: NewAccreditation,
    meta: {
      title: 'Credenciamento',
      isAccreditation: true,
      requiresAuth: true
    }
  },

  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      title: 'Conta',
      requiresAuth: true
    }
  },

  {
    path: '/account/informations/:section',
    name: 'account-informations',
    component: AccountInformation,
    meta: {
      title: 'Informações da conta',
      requiresAuth: true
    }
  },

  {
    path: '/account/security',
    name: 'account-security',
    component: AccountSecurity,
    meta: {
      title: 'Segurança e login',
      requiresAuth: true
    }
  },

  {
    path: '/extract',
    name: 'extract',
    component: Extract,
    meta: {
      title: 'Extrato e saldo',
      requiresAuth: true
    }
  },

  {
    path: '/receivables',
    name: 'receivables',
    component: Receivable,
    meta: {
      title: 'Recebíveis',
      requiresAuth: true
    }
  },

  {
    path: '/sales',
    name: 'sale',
    component: Sale,
    meta: {
      title: 'Vendas',
      requiresAuth: true
    }
  },

  {
    path: '/sales/new',
    name: 'new-sale',
    component: NewSale,
    meta: {
      title: 'Nova venda',
      requiresAuth: true
    }
  },

  {
    path: '/sales/resume',
    name: 'new-sale-resume',
    component: NewSaleResume,
    meta: {
      title: 'Resumo da venda',
      requiresAuth: true
    }
  },

  {
    path: '/sales/success',
    name: 'new-sale-success',
    component: NewSaleSuccess,
    meta: {
      title: 'Sucesso venda',
      requiresAuth: true
    }
  },

  {
    path: '/customers',
    name: 'customers',
    component: Customer,
    meta: {
      title: 'Clientes',
      requiresAuth: true
    }
  },

  {
    path: '/transfers',
    name: 'transfers',
    component: Transfer,
    meta: {
      title: 'Saques',
      requiresAuth: true
    }
  },

  {
    path: '*',
    name: 'not-found',
    component: NotFound,
    meta: {
      title: 'Erro 404',
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _, next) => {
  const hasSeller = store.getters.getSellers.length > 0;
  const hasToken = !!encryptStorage.getItem('token');

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} | ${service.getPlatformTitle()}`;
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (hasToken && !hasSeller && !to.meta.isAccreditation && to.path != '/accreditation') next('/accreditation');

    else if (!hasToken && to.path != '/') next('/');

    else if (hasSeller && to.path == '/accreditation') next('/dashboard');

    else next();
  } else {
    if (hasToken && to.path != '/dashboard') next('/dashboard');

    else next();
  }
});

export default router;