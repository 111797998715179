<template>
  <div class="navigation-bar-container" :class="{ 'disabled': isDisabled }">
    <div class="navigation-bar">
      <div class="item-container">
        <div
          v-for="(item, index) in data.items"
          :key="index"
          class="item-content"
          :class="{ 'active': item.isActive, 'has-icon': hasIcon(item.icon) }"
          @click="selectItem(index)"
        >
          <div v-if="hasIcon(item.icon)" class="icon-container">
            <i :class="item.icon" />
          </div>

          <div class="item">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NavigationBar',

    props: {
      data: {
        type: Object,
        default() {
          return {
            current: '',
            items: [
              {
                label: 'Option 1',
                isActive: false,
                name: 'firstOption',
                icon: 'icon-default'
              },

              {
                label: 'Option 2',
                isActive: true,
                name: 'secondOption',
                icon: 'icon-default'
              },

              {
                label: 'Option 3',
                isActive: false,
                name: 'thirdOption',
                icon: 'icon-default'
              },

              {
                label: 'Option 4',
                isActive: false,
                name: 'fourthOption',
                icon: 'icon-default'
              }
            ]
          };
        }
      },

      isDisabled: {
        type: Boolean,
        default: false
      }
    },

    mounted() {
      if (!this.data.items.some(item => item.isActive) || !this.data.current) {
        this.selectItem(0);
      }
    },

    methods: {
      selectItem(currentIndex) {
        if (!this.isDisabled) {
          let currentItem = this.data.items[currentIndex];

          this.data.items.map(item => item.isActive = false);

          // eslint-disable-next-line vue/no-mutating-props
          this.data.current = currentItem.name;

          currentItem.isActive = true;
        }
      },

      hasIcon(icon) {
        return !this.$service.isUndefined(icon);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .navigation-bar-container {
    font-size: 14px;
    color: #333;
    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &.disabled {
      .navigation-bar {
        .item-container {
          .item-content {
            cursor: not-allowed;
            opacity: 0.6;

            &.active {
              border-color: #8E8E8E;
              color: #8E8E8E;

              .icon-container {
                i {
                  color: #8E8E8E;
                }
              }
            }
          }
        }
      }
    }

    .navigation-bar {
      display: flex;
      width: fit-content;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid #EFEFEF;

      .item-container {
        justify-content: center;
        align-items: center;
        display: flex;

        .item-content {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.5rem 1rem;
          cursor: pointer;

          .icon-container {
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              margin-right: 0.5rem;
              font-size: 1.2rem;
              color: #8E8E8E;
            }
          }

          .item {
            font-weight: 300;
            width: fit-content;
            white-space: nowrap;
          }

          &.has-icon {
            padding: 0.5rem 0.2rem;
            margin-right: 2rem;

            &:last-child {
              margin-right: 0;
            }
          }

          &.active {
            border-bottom: 2px solid $second-color;
            color: $second-color;
            top: 1px;
            position: relative;

            .icon-container {
              i {
                color: $second-color;
              }
            }

            .item {
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .navigation-bar-container {
      .navigation-bar {
        // width: 100%;
        border: none;

        .item-container {
          width: 100%;
          justify-content: end;

          .item-content {
            width: 100%;
          }
        }
      }
    }
  }
</style>