/* eslint-disable no-empty-pattern */
import { instance } from '../../config/axios';
import store from '../index';

const actions = {
  getReceivables(_, params) {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + store.state.currentSellerId + '/receivables' + `${ params ? params : '' }`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  getFutureReceivables() {
    return new Promise((resolve, reject) => {
      instance().get('/sellers/' + store.state.currentSellerId + '/future-receivables')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
};

export default {
  namespaced: true,
  actions
};