const state = () => ({
  currentAutonomousStep: 0,
  apiError: '',
  pullRegistrationData: false,

  data: {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    taxpayerId: '',
    birthDate: '',
    statementDescriptor: '',
    addressLine1: '',
    addressNumber: '',
    addressComplement: '',
    ownerAddressNumber: '',
    ownerAddressComplement: '',
    addressNeighborhood: '',
    addressCity: '',
    addressState: '',
    addressPostalCode: '',
    mcc: '',

    cbo: '',
    motherName: '',
    monthlyIncome: '',
  },

  progress: [
    {
      name: 'Dados pessoais',
      current: true,
      completed: false,
    },

    {
      name: 'Endereço',
      current: false,
      completed: false,
    }
  ]
});

const mutations = {
  setApiError(state, error) {
    state.apiError = error;
  },

  resetApiError(state) {
    state.apiError = '';
  },

  currentStep(state, step) {
    state.currentAutonomousStep = step;
  },

  resetAccreditation(state) {
    state.currentAutonomousStep = 0;
    state.pullRegistrationData = false;
    state.apiError = false;
    state.data = {};

    state.progress = [
      {
        name: 'Dados pessoais',
        current: true,
        completed: false,
      },

      {
        name: 'Endereço',
        current: false,
        completed: false,
      }
    ];
  },

  progressCurrent(state, progressData) {
    state.progress[progressData.position].current = progressData.status;
  },

  progressCompleted(state, progressData) {
    state.progress[progressData.position].completed = progressData.status;
  },

  resetProgress(state) {
    state.progress = [
      {
        name: 'Dados pessoais',
        current: true,
        completed: false,
      },

      {
        name: 'Endereço',
        current: false,
        completed: false,
      }
    ];
  }
};

export default {
  namespaced: true,
  state,
  mutations
};