<template>
  <div class="table-header-content">
    <div v-for="(item, index) in data" :key="index" class="item-container" :class="{ 'request': hasRequest, 'last-child': item.hasLastChild}">
      <default-data-component v-if="hasRequest" class="default-data-component" />

      <div v-else class="item-content">
        {{ item.label }}

        <div class="value" v-if="item.value">
          {{ item.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultData from '@/components/DefaultData';

  export default {
    name: 'TableHeader',

    props: {
      hasRequest: {
        type: Boolean,
        default: false
      },

      data: {
        type: Array,
        default: () => [
          {
            label: 'label:',
            value: 'value'
          },

          {
            label: 'label:',
            value: 'value'
          },

          {
            label: 'label',
            hasLastChild: true
          }
        ]
      }
    },

    components: {
      'default-data-component': DefaultData
    },
  };
</script>

<style lang="scss" scoped>
  .table-header-content {
    display: flex;
    font-size: 16px;

    .item-container {
      margin-right: 1rem;
      display: flex;

      .default-data-component {
        height: 20px;
        width: 200px;
      }

      .item-content {
        display: flex;

        .value {
          margin-left: 0.5rem;
          font-weight: 500;
        }
      }

      &.last-child:last-child {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }

  @media (max-width: 770px) {
    .table-header-content {
      .item-container {
        &.request {
          width: 100%;

          .default-data-component {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 750px) {
    .table-header-content {
      font-size: 14px;
    }
  }

  @media (max-width: 635px) {
    .table-header-content {
      justify-content: space-between;

      .item-container {
        .item-content {
          flex-direction: column;
          text-align: center;
          justify-content: center;

          .value {
            margin-left: 0;
          }
        }

        &:last-child {
          margin-left: unset;
        }
      }
    }
  }

  @media (max-width: 520px) {
    .table-header-content {
      font-size: 12px;
    }
  }
</style>