<template>
  <div v-click-outside="close">
    <div class="input-checkbox-field" :class="{ 'active': isActive, 'disabled': isDisabled }" @click="toggleState">
      <div class="input-content">
        <label>Status</label>

        <p>{{ status }}</p>
      </div>

      <i v-if="isActive" class="icon-arrow-up-filled" />
      <i v-else class="icon-arrow-down-filled" />
    </div>

    <div class="container">
      <div v-if="isActive" class="content" >
        <div class="items">
          <div v-for="(item, index) in data.items" :key="index" class="item">
            <checkbox-component
              class="checkbox-component"
              :value="item.isChecked"
              @click.native="toggleValueCheckbox(index)"
            />

            {{ item.label }}
          </div>
        </div>

        <div class="footer">
          <div class="reset" @click="reset">
            <i class="icon-trash" />

            <p>Limpar</p>
          </div>

          <button-component
            class="button"
            text="Aplicar"
            @click.native="apply"
          />
        </div>
      </div>

      <div class="spacer"></div>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/Button';
  import Checkbox from '@/components/Checkbox';

  export default {
    data() {
      return {
        isActive: false,
        status: 'Todos',
        actions: []
      };
    },

    components: {
      'button-component': Button,
      'checkbox-component': Checkbox
    },

    props: {
      data: {
        type: Object,
        default() {
          return {
            query: '',
            items: [
              {
                label: 'Default',
                value: 'default',
                isChecked: false
              },

              {
                label: 'Defualt 2',
                value: 'default_2',
                isChecked: false
              }
            ]
          };
        }
      },

      isDisabled: {
        type: Boolean,
        default: false
      }
    },

    watch: {
      'status'(value) {
        if (this.$service.isBlank(value)) {
          this.status = 'Todos';
        }
      }
    },

    methods: {
      toggleState() {
        if (!this.isDisabled) {
          this.isActive = !this.isActive;
        }
      },

      close() {
        this.actions = [];
        this.isActive = false;
      },

      toggleValueCheckbox(index) {
        this.actions.push(this.data.items[index]);
      },

      formatStatus() {
        let formattedStatus = '';
        let formattedQuery = '';

        this.data.items.forEach(item => {
          if (item.isChecked) {
            formattedStatus = formattedStatus + item.label + ', ';
            formattedQuery = formattedQuery  + item.value + ',';
          }
        });

        this.status = formattedStatus.substring(0, formattedStatus.length - 2);

        // eslint-disable-next-line vue/no-mutating-props
        this.data.query = formattedQuery.substring(0, formattedQuery.length - 1);
      },

      reset() {
        this.data.items.forEach(item => {
          item.isChecked = false;
        });

        // eslint-disable-next-line vue/no-mutating-props
        this.status = 'Todos';

        // eslint-disable-next-line vue/no-mutating-props
        this.data.query = '';

        this.close();

        this.$emit('clickApplyFilter');
      },

      apply() {
        this.actions.forEach(item => {
          this.data.items.forEach(dataItem => {
            if (item.value == dataItem.value) {
              dataItem.isChecked = !dataItem.isChecked;
              this.formatStatus();
            }
          });
        });

        this.close();
        this.$emit('clickApplyFilter');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .input-checkbox-field {
    display: flex;
    height: 48px;
    width: 175px;
    border: 1px solid #DADADA;
    border-radius: 6px;
    align-items: center;
    padding: 0 1rem;
    margin-right: 1rem;
    cursor: pointer;
    color: #333;

    &.disabled {
      background: #EFEFEF;
      cursor: not-allowed;
      opacity: 0.6;
    }

    i {
      font-size: 0.3rem;
      margin-left: auto;
      color: #8E8E8E;
      padding: 0 0 0 0.2rem;
    }

    .input-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      label {
        font-size: 12px;
        color: #6D6F6F;
        font-weight: 400;
      }

      p {
        font-size: 14px;
      }
    }

    &.active {
      border-color: $second-color;
      box-shadow: 0 0 0 1px $second-color, 0 0 0 3px $second-color-light-1;
    }
  }

  .container {
    position: absolute;

    .content {
      background: $white-color;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      min-width: 250px;
      margin-top: 1rem;

      .items {
        padding: 1rem;

        .item {
          display: flex;
          align-items: center;
          margin-bottom: 0.7rem;

          .checkbox-component {
            margin-right: 10px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .footer {
        height: 56px;
        padding: 0.5rem 1rem;
        box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);
        display: flex;

        .reset {
          display: flex;
          align-items: center;
          color: #8E8E8E;
          cursor: pointer;
          margin-right: 3rem;

          p {
            margin-left: 0.5rem;
            font-weight: 600;
          }
        }

        .button {
          height: 100%;
        }
      }
    }

    .spacer {
      height: 3rem;
    }
  }
</style>