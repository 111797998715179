import axios from 'axios';
import router from '@/router';
import store from '@/store';
import * as Sentry from '@sentry/vue';
import { encryptStorage } from '../config/encryptStorage';

export const instance = () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      'X-Access-Token': encryptStorage.getItem('token')
    }
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        encryptStorage.removeItem('token');

        store.dispatch('logout');
        router.push('/').catch(() => {});
      }

      Sentry.setContext('extra', {
        'uri': error.response.config.url,
        'full_response': JSON.stringify(error.response.data)
      });

      Sentry.captureException(error);

      return Promise.reject(error);
    }
  );

  return instance;
};

export default instance;