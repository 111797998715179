<template>
  <modal
    ref="modal"
    class="customer-modal"
    title="Perfil do cliente"
    :showModal="true"
    @close="closeModal"
  >
    <template v-slot:body>
      <ValidationObserver
        v-slot="{ passes }"
        ref="form"
      >
        <form v-if="!hasFetchDataRequest" id="form" @submit.prevent="passes(customerData)">
          <div class="body">
            <div class="section">
              <div class="title">
                <h3 v-if="!hasEditButton">Perfil</h3>

                <navigation-bar-component
                  v-else
                  class="navigation-bar-component"
                  :data="customerNavigationData"
                  :isDisabled="hasUpdateCustomerRequest"
                />

                <div v-if="hasEditButton && isProfileSection" class="edit-button-container" :class="{ 'disable': hasFetchDataRequest, 'active': isEdit }" @click="activeEdit">
                  <i class="icon-pencil" />

                  Editar
                </div>
              </div>
            </div>

            <div v-if="isProfileSection" class="section-group">
              <div class="section">
                <div class="cards-container">
                  <div
                    v-for="(item, index) in customerTypeData"
                    :key="index"
                    class="card"
                    :class="{ 'active': item.isActive, 'disable': item.isDisabled || hasCustomer }"
                    @click="changeCustomerType(item.value)"
                  >
                    <p>
                      {{ item.label }}
                    </p>
                  </div>
                </div>

                <div class="fields">
                  <div class="field">
                    <input-field-component
                      class="input-field-component large"
                      placeholder="Nome completo"
                      :isDisabled="hasRequestOrIsEdit()"
                      v-model="data.customerName"
                      rules="required"
                    />
                  </div>

                  <div class="field">
                    <input-field-component
                      class="input-field-component large"
                      :placeholder="customerTypeDataItem('Individual').isActive ? 'CPF' : 'CNPJ'"
                      :mask="customerTypeDataItem('Individual').isActive ? '###.###.###-##' : '##.###.###/####-##'"
                      :isDisabled="hasCustomer"
                      v-model="data.taxpayerId"
                      rules="required"
                    />
                  </div>
                </div>
              </div>

              <div class="line"></div>

              <div class="section">
                <h3>Contato</h3>

                <div class="fields">
                  <div class="field">
                    <input-field-component
                      class="input-field-component large"
                      placeholder="E-mail"
                      :isDisabled="hasRequestOrIsEdit()"
                      v-model="data.customerEmail"
                    />

                    <input-field-component
                      class="input-field-component small"
                      placeholder="Celular"
                       :mask="['(##) ####-####', '(##) #####-####']"
                      :isDisabled="hasRequestOrIsEdit()"
                      v-model="data.phone"
                    />
                  </div>
                </div>
              </div>

              <div class="line"></div>

              <div class="section">
                <h3>Endereço</h3>

                <div class="fields address">
                  <div class="field">
                    <input-field-component
                      class="input-field-component large"
                      placeholder="CEP"
                      mask="#####-###"
                      @blur="requestPostalCode"
                      :isDisabled="hasRequestOrIsEdit()"
                      v-model="data.postalCode"
                      name="postalCode"
                      rules="required"
                    />
                  </div>

                  <div class="field">
                    <input-field-component
                      class="input-field-component large"
                      placeholder="Rua"
                      :isDisabled="hasRequestOrIsEdit() || addressNeighborhoodLine1FieldsDisabled"
                      v-model="data.line1"
                      rules="required"
                    />

                    <input-field-component
                      class="input-field-component small"
                      placeholder="Número"
                      :isDisabled="hasRequestOrIsEdit() || addressFieldsDisabled"
                      v-model="data.line2"
                      rules="required"
                    />
                  </div>

                  <div class="field">
                    <input-field-component
                      class="input-field-component large"
                      placeholder="Complemento"
                      :isDisabled="hasRequestOrIsEdit() || addressFieldsDisabled"
                      v-model="data.line3"
                    />

                    <input-field-component
                      class="input-field-component small"
                      placeholder="Bairro"
                      :isDisabled="hasRequestOrIsEdit() || addressNeighborhoodLine1FieldsDisabled"
                      v-model="data.neighborhood"
                      rules="required"
                    />
                  </div>

                  <div class="field">
                    <input-field-component
                      class="input-field-component small"
                      placeholder="Estado"
                      :isDisabled="addressCityStateFieldsDisabled"
                      :value="data.state"
                      rules="required"
                    />

                    <input-field-component
                      class="input-field-component large"
                      placeholder="Cidade"
                      :isDisabled="addressCityStateFieldsDisabled"
                      :value="data.city"
                      rules="required"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="section-group">
              <div v-if="allCardData.length > 0" class="section">
                <div v-for="(item, index) in allCardData" :key="index" class="card-detail-container">
                  <div class="card-detail">
                    <img class="card-brand-image" :src="identifyBrand(item.brand)">

                    <b>Nº do cartão:</b> {{ $service.toUpperCase(item.brand) === 'AMERICANEXPRESS' ? '**** ****** *' : '**** **** ****' }} {{ item.last4_digits }}

                    -

                    <b>Data de expiração:</b> {{ item.expiration_month }}/{{ item.expiration_year }}
                  </div>

                  <div v-if="index != (allCardData.length - 1)" class="line"></div>
                </div>
              </div>

              <simple-message-component
                v-else
                class="simple-message-component"
                :data="emptyCardsData"
              />
            </div>
          </div>
        </form>

        <div v-else class="body has-request">
          <spinner-component color="#DADADA" />
        </div>
      </ValidationObserver>
    </template>

    <template v-slot:footer>
      <div class="footer-slot-container" :class="{ 'has-shadow': isEdit }">
        <div v-if="isEdit || !hasCustomer" class="button-container">
          <button-component
            class="button-component"
            styles="ghost"
            text="Fechar"
            @click.native="closeModal"
            v-ripple="'rgba(255, 139, 0, 0.2)'"
          />

          <button-component
            class="button-component"
            text="Salvar"
            form="form"
            :isDisabled="hasUpdateCustomerRequest"
            :hasLoader="hasUpdateCustomerRequest"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '@/components/Modal';
  import InputField from '@/components/InputField';
  import Button from '@/components/Button';
  import Spinner from '@/components/Spinner';
  import NavigationBar from '@/components/NavigationBar';
  import SimpleMessage from '@/components/SimpleMessage';
  import { ValidationObserver } from 'vee-validate';
  import MasterDefault from '@/assets/brand/MasterDefault.png';
  import Master from '@/assets/brand/Master.png';
  import Elo from '@/assets/brand/Elo.png';
  import Visa from '@/assets/brand/Visa.png';
  import Aura from '@/assets/brand/Aura.png';
  import Jcb from '@/assets/brand/Jcb.png';
  import Amex from '@/assets/brand/Amex.png';
  import Hiper from '@/assets/brand/Hiper.png';
  import HiperCard from '@/assets/brand/HiperCard.png';
  import BanesCard from '@/assets/brand/BanesCard.png';

  export default {
    name: 'CustomerProfileModal',

    data() {
      return {
        isEdit: false,
        hasCustomer: false,
        hasFetchDataRequest: false,
        hasUpdateCustomerRequest: false,
        hasPostalCode: false,

        addressFieldsDisabled: true,
        addressCityStateFieldsDisabled: true,
        addressNeighborhoodLine1FieldsDisabled: true,

        customerTypeData: this.defaultCustomerTypeData(),

        isProfileSection: true,

        allCardData: [],

        data: {
          taxpayerId: '',
          customerName: '',
          customerEmail: '',
          phone: '',
          postalCode: '',
          state: '',
          line1: '',
          line2: '',
          line3: '',
          neighborhood: '',
          city: '',
        },

        emptyCardsData: {
          icon: 'icon-receipts',
          text: 'Nenhum cartão cadastrado'
        },

        customerNavigationData: {
          current: '',
          items: [
            {
              label: 'Perfil',
              isActive: false,
              name: 'profile',
            },

            {
              label: 'Cartões',
              isActive: false,
              name: 'card',
            }
          ]
        },

        master: Master,
        visa: Visa,
        elo: Elo,
        aura: Aura,
        jcb: Jcb,
        amex: Amex,
        hiper: Hiper,
        hiperCard: HiperCard,
        banesCard: BanesCard,
        masterDefault: MasterDefault,
      };
    },

    components: {
      ValidationObserver,
      'modal': Modal,
      'navigation-bar-component': NavigationBar,
      'simple-message-component': SimpleMessage,
      'input-field-component': InputField,
      'button-component': Button,
      'spinner-component': Spinner
    },

    props: {
      customerId: {
        type: String,
        required: false
      },

      hasEditButton: {
        type: Boolean,
        default: true
      }
    },

    created() {
      this.hasCustomer = !this.$service.isUndefined(this.customerId);

      if (this.hasCustomer) {
        this.fetchData();
      } else {
        this.isEdit = true;
        this.customerTypeDataItem('Individual').isActive = true;
      }
    },

    watch: {
      'data.postalCode'(value) {
        if (value.length == 0) {
          this.resetAddressFields();
        }
      },

      'customerNavigationData.current'(value) {
        this.setCustomerSection(value);
      }
    },

    computed: {
      brandImage() {
        return 'url(' + `${this.path}` + ') #FFFFFF no-repeat 95%';
      }
    },

    methods: {
      setCustomerSection(type) {
        switch (this.$service.toUpperCase(type)) {
          case 'PROFILE':
            this.isProfileSection = true;
            break;
          case 'CARD':
            this.isProfileSection = false;
            this.isEdit = false;
            this.fetchCustomerCard();
            break;
        }
      },

      changeCustomerType(type) {
        if (!this.hasCustomer) {
          switch (this.$service.toUpperCase(type)) {
            case 'COMPANY':
              this.customerTypeDataItem('Company').isActive = true;
              this.customerTypeDataItem('Individual').isActive = false;
              break;
            case 'INDIVIDUAL':
              this.customerTypeDataItem('Individual').isActive = true;
              this.customerTypeDataItem('Company').isActive = false;
              break;
          }
        }
      },

      requestPostalCode() {
        if (this.data.postalCode != '' && this.$service.removeCharacter(this.data.postalCode).length == 8) {
          this.fetchNewPostalCode(this.data.postalCode);
        }
      },

      async fetchCustomerCard() {
        this.hasFetchDataRequest = true;

        await this.$store.dispatch('getAllCard', this.customerId)
          .then(res => {
            this.allCardData = res.data;
          })
          .catch(err => {
            console.log(err);
          });
        this.hasFetchDataRequest = false;
      },

      async fetchData() {
        this.hasFetchDataRequest = true;
        this.$emit('initFetchData');

        await this.$store.dispatch('getCustomerId', this.customerId)
          .then(res => {
            let data = res.data;
            this.data.taxpayerId = data.taxpayer_id;
            this.data.customerName = !this.$service.isEin(this.data.taxpayerId) ? data.first_name + ' ' + data.last_name : data.first_name;
            this.data.customerEmail = data.email;
            this.data.phone = data.phone_number;
            this.data.postalCode = data.address.postal_code;
            this.data.line1 = data.address.line1;
            this.data.line2 = data.address.line2;
            this.data.line3 = data.address.line3;
            this.data.state = data.address.state_code;
            this.data.neighborhood = data.address.neighborhood;
            this.data.city = data.address.city;

            if (this.$service.isTaxpayerId(data.taxpayer_id)) {
              this.customerTypeDataItem('Individual').isActive = true;
              this.customerTypeDataItem('Company').isDisabled = false;
            } else {
              this.customerTypeDataItem('Company').isActive = true;
              this.customerTypeDataItem('Individual').isDisabled = false;
            }
          })
          .catch(err => {
            console.log(err);
          });

        this.hasFetchDataRequest = false;
        this.$emit('finishFetchData');
      },

      async customerData() {
        this.hasUpdateCustomerRequest = true;

        let data = JSON.stringify({
          taxpayer_id: this.$service.removeCharacter(this.data.taxpayerId),
          first_name: !this.$service.isEin(this.data.taxpayerId) ? this.$service.splitFirstName(this.data.customerName) : this.data.customerName,
          last_name: !this.$service.isEin(this.data.taxpayerId) ? this.$service.splitLastName(this.data.customerName) : null,
          email: this.data.customerEmail,
          phone_number: this.$service.removeCharacter(this.data.phone),
          address: {
            line1: this.data.line1,
            line2: this.data.line2,
            line3: this.data.line3,
            neighborhood: this.data.neighborhood,
            city: this.data.city,
            state_code: this.data.state,
            postal_code: this.$service.removeCharacter(this.data.postalCode),
            country_code: 'BR'
          }
        });

        if (this.hasCustomer) {
          await this.$store.dispatch('putCustomer', { id: this.customerId, data: data });
        } else {
          await this.$store.dispatch('postCustomer', { data: data });
        }

        this.hasUpdateCustomerRequest = false;
        this.$emit('finishRequest');
        this.closeModal();
      },

      fetchNewPostalCode(postalCode) {
        this.addressFieldsDisabled = true;
        this.addressCityStateFieldsDisabled = true;
        this.addressNeighborhoodLine1FieldsDisabled = true;
        this.resetAddressFields();

        this.$store.dispatch('getAddress', this.$service.removeCharacter(postalCode))
          .then(res => {
            let data = res.data;
            this.data.line1 = data.address_line_1;
            this.data.line2 = data.address_line_2;
            this.data.neighborhood = data.neighborhood;
            this.data.city = data.city;
            this.data.state = data.state_code;

            if (this.$service.isBlank(data.neighborhood) || this.$service.isBlank(data.address_line_1)) {
              this.addressNeighborhoodLine1FieldsDisabled = false;
            }
          })
          .catch(() => {
            this.addressCityStateFieldsDisabled = false;
            this.addressNeighborhoodLine1FieldsDisabled = false;
            this.$refs.form.setErrors({
              postalCode: 'CEP não encontrado',
            });
          })
          .finally(() => {
            this.addressFieldsDisabled = false;
          });
      },

      resetAddressFields() {
        this.data.state = '';
        this.data.city = '';
        this.data.line1 = '';
        this.data.line2 = '';
        this.data.line3 = '';
        this.data.neighborhood = '';
        },

      activeEdit() {
        this.isEdit = true;
      },

      hasRequestOrIsEdit() {
        return !this.isEdit || this.hasUpdateCustomerRequest;
      },

      closeModal() {
        this.$emit('close');
      },

      identifyBrand(brand) {
        switch (this.$service.toUpperCase(brand)) {
          case 'VISA':
            return this.visa;
          case 'MASTERCARD':
            return this.master;
          case 'ELO':
            return this.elo;
          case 'AURA':
            return this.aura;
          case 'JCB':
            return this.jcb;
          case 'AMERICANEXPRESS':
            return this.amex;
          case 'BANESCARD':
            return this.banesCard;
          case 'HIPER':
            return this.hiper;
          case 'HIPERCARD':
            return this.hiperCard;
          default:
            return this.masterDefault;
        }
      },

      defaultCustomerTypeData() {
        return [
          {
            label: 'Pessoa física',
            isActive: false,
            isDisabled: false,
            value: 'Individual'
          },

          {
            label: 'Pessoa jurídica',
            isActive: false,
            isDisabled: false,
            value: 'Company'
          }
        ];
      },

      customerTypeDataItem(type) {
        return this.customerTypeData.filter(item => this.$service.toUpperCase(item.value) == this.$service.toUpperCase(type))[0];
      }
    }
  };
</script>

<style lang="scss" scoped>
  .customer-modal {
    .body {
      padding: 0 1.5rem;
      margin-top: 1.5rem;
      height: 500px;
      width: 560px;
      overflow-y: auto;

      .line {
        height: 1px;
        width: 100%;
        background: #EFEFEF;
        margin-bottom: 1rem;
      }

      &.has-request {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }

      .section {
        .title {
          display: flex;
          align-items: center;

          .edit-button-container {
            margin-left: auto;
            font-size: 14px;
            display: flex;
            align-items: center;
            font-weight: 500;
            color: #8E8E8E;
            cursor: pointer;
            padding: 0.5rem;

            &.disable {
              cursor: not-allowed;
              color: #DADADA;

              i {
                color: #DADADA;
              }
            }

            &.active {
              color: #0052CC;
            }

            i {
              margin-right: 0.7rem;
              color: #0052CC;
            }
          }
        }

        .cards-container {
          display: flex;
          margin-top: 1rem;

          .card {
            height: 48px;
            padding: 1rem;
            margin-right: 1rem;
            border-radius: 6px;
            border: 1px solid #DADADA;
            display: flex;
            align-items: center;
            color: #333;
            cursor: pointer;
            text-align: center;

            &:last-child {
              margin-right: 0;
            }

            &.disable {
              cursor: not-allowed;
              background: #F9F9F9;
              opacity: 0.8;
            }

            &.active {
              border: 1px solid $second-color;
              background: $second-color;
              color: $white-color;
              opacity: 1.0;

              p {
                font-weight: 400;
              }
            }
          }
        }

        .fields {
          margin-top: 1rem;

          &.address {
            .field {
              &:last-child {
                .input-field-component {
                  &.large {
                    margin-right: 0;
                  }

                  &.small {
                    margin-right: 0.5rem;
                  }
                }
              }
            }
          }

          .field {
            display: flex;

            .input-field-component {
              &.large {
                width: 325px;
                margin-right: 0.5rem;
              }

              &.small {
                width: 215px;
              }
            }
          }
        }
      }

      .section-group {
        height: calc(100% - 64px);

        .simple-message-component {
          height: 100%;
        }
      }

      .card-detail-container {
        .card-detail {
          margin-top: 1rem;
          margin-bottom: 1rem;
          display: flex;
          align-items: center;
          color: #333;

          b {
            margin-left: 5px;
            margin-right: 5px;
            font-weight: 500;
          }

          .card-brand-image {
            margin-right: 0.5rem;
          }
        }
      }
    }

    .footer-slot-container {
      display: flex;
      justify-content: flex-end;
      padding: 0.75rem 1rem;

      &.has-shadow {
        box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);
      }

      .button-container {
        display: flex;

        .button-component {
          width: 100px;
          height: 48px;
          margin-left: 1rem;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .customer-modal {
      .body {
        width: 100%;
      }
    }
  }

  @media (max-width: 520px) {
    .customer-modal {
      .body {
        height: 400px;

        .section {
          .cards-container {
            .card {
              p {
                font-size: 12px;
              }
            }
          }

          .fields {
            .field {
              flex-direction: column;

              .input-field-component {
                width: 100%;

                &.large {
                  width: 100%;
                  margin-right: 0;
                }

                &.small {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
</style>