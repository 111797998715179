<template>
  <div class="radio-container">
    <label class="radio">
      <input
        type="radio"
        :name="name"
        v-model="status"
        :value="value"
      />

      <span class="circle"></span>
    </label>

    <div v-if="label" class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CustomRadio',

    data() {
      return {
        status: true,
      };
    },

    props: {
      value: {
        type: Boolean,
        default: true,
      },

      name: {
        type: String,
        required: false
      },

      label: {
        type: String,
        required: false
      }
    },

    watch: {
      status() {
        this.$emit('input', true);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .radio-container {
    align-items: center;
    display: flex;
    margin-right: 0.5rem;

    .radio {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100%;

      input {
        display: none;

        &:checked + .circle {
          border: 2.7px solid $second-color;

          &:after {
            content: "";
            height: 10px;
            width: 10px;
            background: $second-color;
            border-radius: 100%;
          }
        }
      }

      .circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2.7px solid $second-color;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }

    .label {
      align-items: center;
      margin-left: 0.7rem;
      font-weight: 300;
      font-size: 14px;
      display: flex;
    }
  }
</style>