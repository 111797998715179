<template>
  <modal
    title="Depósito via Boleto"
    class="deposit-modal"
    :showModal="true"
    :isTopOnMobile="true"
    @close="closeModal"
  >
    <template v-slot:body>
      <div class="body-content">
        <ValidationObserver
          v-if="!hasSuccess"
          v-slot="{ passes }"
          tag="div"
          class="body"
          ref="form"
        >
          <form id="form" @submit.prevent="passes(createBoleto)">
            <input-field
              class="input-field"
              placeholder="Valor do boleto"
              name="amount"
              type="money"
              :hasCaret="false"
              :maxLength="24"
              v-model="data.amount"
            />
          </form>
        </ValidationObserver>

        <div v-else class="body">
          <h4>Seu boleto foi gerado com sucesso!</h4>

          <div class="abstract">
            <div v-for="(item, index) in boletoAbstract" :key="index" class="item">
              <p class="label">
                {{ item.label }}:
              </p>

              <div class="value-container">
                <p class="value">
                  {{ item.value }}
                </p>

                <div v-if="item.hasCopy" class="icon-container">
                  <i v-if="!isCopyDigitableLine" class="icon-copy-outline" @click="copyBoletoDigitableLine"/>
                  <i v-else class="icon-check-circle" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="footer-slot-container">
        <div class="button-container">
          <button-component
            v-if="hasSuccess"
            class="button-component"
            styles="ghost"
            text="Fechar"
            v-ripple="'rgba(255, 139, 0, 0.2)'"
            @click.native="closeModal"
          />

          <button-component
            class="button-component"
            :text="hasSuccess ? 'Ver boleto' : 'Gerar'"
            form="form"
            :hasLoader="hasRequest"
            :isDisabled="hasRequest"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            @click.native="openBoleto"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ValidationObserver } from 'vee-validate';
  import Modal from '@/components/Modal';
  import InputField from '@/components/InputField';
  import Button from '@/components/Button';

  export default {
    data() {
      return {
        minAmount: 5,
        maxAmount: 5000,
        hasRequest: false,
        hasSuccess: false,
        currentBoletoUrl: '',
        currentBoletoDigitableLine: '',
        isCopyDigitableLine: false,
        boletoAbstract: [],
        data: {
          amount: ''
        }
      };
    },

    components: {
      ValidationObserver,
      'modal': Modal,
      'button-component': Button,
      'input-field': InputField
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerData'
      ])
    },

    methods: {
      async createBoleto() {
        if (this.data.amount >= this.minAmount && this.data.amount <= this.maxAmount) {
          let data = {
            amount: this.data.amount * 100,
            customer: this.formatCustomer(this.getCurrentSellerData),
            payment: {
              method: 'Boleto'
            }
          };

          this.hasRequest = true;

          await this.$store.dispatch('postTransaction', JSON.stringify(data))
            .then(res => {
              let data = res.data;

              this.currentBoletoDigitableLine = data.payment.boleto.digitable_line;
              this.boletoAbstract = this.formatAbstract(data);
              this.currentBoletoUrl = data.payment.boleto.url;

              this.hasSuccess = true;
            })
            .catch(() => {
              this.closeModal();
              this.setErrorAlertCard();
            });

          this.hasRequest = false;
        } else {
          this.$refs.form.setErrors({
            amount: `Valor mínimo ${this.$service.formatBrlAmountSymbol(this.minAmount)} e máximo ${this.$service.formatBrlAmountSymbol(this.maxAmount)}`
          });
        }
      },


      formatAbstract(data) {
        return [
          {
            label: 'Valor',
            value: this.$service.formatBrlAmountSymbol(data.amount)
          },

          {
            label: 'Emissão',
            value: this.$service.dateFormat(data.created_at, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY')
          },

          {
            label: 'Pagador',
            value: data.statement_descriptor
          },

          {
            label: 'Vencimento',
            value: this.$service.dateFormat(data.payment.boleto.due_date, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY')
          },

          {
            label: 'Código de barras',
            value: this.$service.formatBarCodeNumber(this.currentBoletoDigitableLine),
            hasCopy: true
          }
        ];
      },

      copyBoletoDigitableLine() {
        var currentElement = document.createElement('textarea');
        currentElement.value = this.currentBoletoDigitableLine;
        currentElement.setAttribute('readonly', '');
        currentElement.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(currentElement);
        currentElement.select();
        document.execCommand('copy');
        document.body.removeChild(currentElement);
        this.isCopyDigitableLine = true;

        setTimeout(() => {
          this.isCopyDigitableLine = false;
        }, 2000);
      },

      formatCustomer(sellerData) {
        let formattedCustomer = {
          first_name: '',
          last_name: '',
          taxpayer_id: '',
          address: {
            line1: '',
            line2: '',
            neighborhood: '',
            city: '',
            state_code: '',
            postal_code: '',
            country_code: 'BR'
          }
        };

        if (this.$service.isIndividual(sellerData.type)) {
          formattedCustomer.first_name = sellerData.first_name;
          formattedCustomer.last_name = sellerData.last_name;
          formattedCustomer.taxpayer_id = sellerData.taxpayer_id;
          formattedCustomer.address.line1 = sellerData.address_line1;
          formattedCustomer.address.line2 = sellerData.address_line2;
          formattedCustomer.address.neighborhood = sellerData.address_neighborhood;
          formattedCustomer.address.city = sellerData.address_city;
          formattedCustomer.address.state_code = sellerData.address_state;
          formattedCustomer.address.postal_code = sellerData.address_postal_code;
        } else {
          formattedCustomer.first_name = sellerData.business_name;
          formattedCustomer.last_name = null;
          formattedCustomer.taxpayer_id = sellerData.ein;
          formattedCustomer.address.line1 = sellerData.business_address_line1;
          formattedCustomer.address.line2 = sellerData.business_address_line2;
          formattedCustomer.address.neighborhood = sellerData.business_address_neighborhood;
          formattedCustomer.address.city = sellerData.business_address_city;
          formattedCustomer.address.state_code = sellerData.business_address_state;
          formattedCustomer.address.postal_code = sellerData.business_address_postal_code;
        }

        return formattedCustomer;
      },

      openBoleto() {
        if (this.hasSuccess) {
          window.open(this.currentBoletoUrl, 'Boleto');
        }
      },

      closeModal() {
        this.$emit('close');
      },

      setErrorAlertCard() {
        this.$store.commit('setAlertCardData', {
          text: 'Erro ao gerar boleto de cobraça.',
          color: '#FF5A5A'
        });

        this.$store.commit('setAlertCardStatus', true);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .deposit-modal {
    .body-content {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      display: flex;

      .body {
        margin-top: 1.5rem;
        max-width: 450px;

        h4 {
          text-align: center;
        }

        form {
          .input-field {
            margin-bottom: 0;
          }
        }

        .informations {
          margin-top: 0.5rem;
        }

        .abstract {
          margin-top: 1rem;

          .item {
            margin-top: 0.5rem;
            align-items: center;

            p {
              text-align: center;

              &.label {
                font-weight: 500;
              }
            }

            .value-container {
              display: flex;
              align-items: center;
              justify-content: center;

              .icon-container {
                i {
                  margin-left: 0.5rem;
                  border-radius: 100%;
                  color: #979797;
                  cursor: pointer;

                  &.icon-check-circle {
                    color: #08B978;
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer-slot-container {
      display: flex;
      padding: 0.75rem 1rem;
      box-shadow: 0px -1px 6px rgb(0 0 0 / 8%);

      .button-container {
        margin-left: auto;
        display: flex;

        .button-component {
          height: 48px;
          width: 110px;
          margin-left: 1rem;
        }
      }
    }
  }
</style>