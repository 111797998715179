<template>
  <div class="sales-view-container">
    <default-view-component v-if="!hasDetail" :hasModal="activeMoreFilter">
      <template v-slot:modal>
        <modal
          v-if="activeMoreFilter"
          ref="modal"
          class="modal"
          title="Filtros"
          :showModal="activeMoreFilter"
          @close="closeModal"
        >
          <template v-slot:body>
            <div class="filter-modal-container">
              <div class="filter-modal-content">
                <h3>Data</h3>

                <div class="field days">
                  <div class="day-container">
                    Últimos:

                    <simple-card-component
                      class="simple-card-component"
                      :data="lastDateFilter"
                      @new="setLastDateFilter"
                    />
                  </div>

                  <input-date-field
                    class="input-date-field"
                    :hasCalendarField="false"
                    :rangeProp="dateFilter"
                    @click.native="openCalendarField"
                  />

                  <calendar-field-component
                    v-if="showCalendarInputField"
                    :rangeProp="dateFilter"
                    class="calendar-field-component"
                    @close="closeCalendarField"
                    v-click-outside="closeCalendarField"
                  />
                </div>

                <div class="line"></div>

                <h3>Valor bruto</h3>

                <div class="field amount">
                  <input-field
                    class="input-field"
                    placeholder="Mínimo"
                    name="min"
                    type="money"
                    :hasCaret="false"
                    :maxLength="24"
                    v-model="amountFilter.min"
                  />

                  <input-field
                    class="input-field"
                    placeholder="Máximo"
                    name="max"
                    type="money"
                    :hasCaret="false"
                    :maxLength="24"
                    v-model="amountFilter.max"
                  />
                </div>

                <div v-if="isTransactionSection()">
                  <div class="line"></div>

                  <h3>Forma de pagamento</h3>

                  <div class="field payment-form">
                    <div v-for="(item, index) in cardPaymentMethod.items" :key="index" class="item">
                      <checkbox-component
                        class="checkbox-component"
                        :value="item.isChecked"
                        @click.native="toggleValuePaymentMethod(index)"
                      />

                      {{ item.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:footer>
            <div class="footer-slot-container">
              <div class="reset-button" @click="resetModalFilter">
                <i class="icon-trash"/>

                <p>
                  Limpar
                </p>
              </div>

              <button-component
                class="button-component"
                text="Aplicar"
                @click.native="applyModalFilter"
              />
            </div>
          </template>
        </modal>
      </template>

      <template v-slot:content>
        <div class="section">
          <header-component
            :data="header"
          />

          <div class="section-navigation">
            <navigation-bar-component
              class="navigation-bar-component"
              :data="getSaleNavigationData"
              :isDisabled="hasRequest"
            />

            <button-component
              class="button-component"
              text="Nova venda"
              :isDisabled="hasRequest || !isSellerActive() || !getCurrentSellerData.is_sale_enabled"
              pathView="/sales/new"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
            />
          </div>

          <div v-if="isTransactionSection()" class="filters-container">
            <div class="filters">
              <select-checkbox-field
                :data="filterStatusTransaction"
                :isDisabled="hasRequest"
                @clickApplyFilter="applyTransactionTypeFilter"
              />

              <filter-button-component
                @open="moreFilter"
                :data="filterButtonData"
                :isDisabled="hasRequest"
              />
            </div>
          </div>

          <table-header-component
            v-if="isTransactionSection()"
            class="table-header-component"
            :hasRequest="hasRequest"
            :data="tableHeaderData"
          />

          <table-component
            class="table-component"
            :table="tableData"
            :hasRequest="hasRequest && !isMoreData"
            @columnClick="selectAction"
            @actionClick="selectAction"
          />

          <div v-if="!isChangeSeller && !isFilterRequest">
            <pagination-table-component
              v-if="hasTableRows()"
              class="pagination-table-button-component"
              :isDisabled="!haveMoreData()"
              :data="paginationTableButtonData"
              :hasRequest="hasRequest"
              @onClick="moreData"
            />
          </div>
        </div>
      </template>
    </default-view-component>

    <div v-else>
      <transaction-detail-component v-if="isTransactionDetail" :detailId="currentDetailId" @close="closeDetail" />

      <subscription-detail-component v-else-if="isSubscriptionDetail" :detailId="currentDetailId" @close="closeDetail" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/Views/DefaultView';
  import Header from '@/components/Header';
  import NavigationBar from '@/components/NavigationBar';
  import Button from '@/components/Button';
  import SelectCheckboxField from '@/components/SelectCheckboxField';
  import Modal from '@/components/Modal';
  import InputDate from '@/components/InputDate';
  import InputField from '@/components/InputField';
  import SimpleCard from '@/components/Table/SimpleCard';
  import Checkbox from '@/components/Checkbox';
  import TableHeader from '@/components/Table/TableHeader';
  import Table from '@/components/Table/Table';
  import PaginationTableButton from '@/components/Table/PaginationTableButton';
  import CalendarField from '@/components/Calendar/CalendarField';
  import TransactionDetail from '@/components/TransactionDetail';
  import SubscriptionDetail from '@/components/SubscriptionDetail';
  import FilterButton from '@/components/FilterButton';

  export default {
    name: 'SaleView',

    data() {
      return {
        header: {
          title: 'Vendas',
          to: '/dashboard',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        hasRequest: false,
        hasDetail: false,

        isChangeSeller: false,
        isTransactionDetail: false,
        isSubscriptionDetail: false,

        currentDetailId: '',

        isLastDateFilter: false,
        isFilterRequest: false,

        activeMoreFilter: false,
        isMoreData: false,

        allValues: [],
        showCalendarInputField: false,

        filterButtonData: {
          text: 'Mais filtros'
        },

        cardPaymentMethod: {
          current: '',

          items: [
            {
              isChecked: true,
              value: 'Debit',
              label: 'Débito'
            },

            {
              isChecked: true,
              value: 'CreditCard',
              label: 'Crédito'
            },

            {
              isChecked: true,
              value: 'Pix',
              label: 'Pix'
            },

            {
              isChecked: true,
              value: 'Boleto',
              label: 'Boleto'
            }
          ]
        },

        dateFilter: this.initialRangeDateFilterData(),
        amountFilter: this.initialAmountFilterData(),
        lastDateFilter: this.initialLastDateFilterData(),
        tableHeaderData: this.initialTableHeaderData(),

        actionsCardPaymentMethod: [],

        paginationTableButtonData: {
          countItems: 'x',
          totalCountItems: '2x'
        },

        countItems: 0,
        totalCountItems: 0,

        filterStatusTransaction: {
          query: '',
          items: [
            {
              label: 'Aprovado',
              value: 'Succeeded',
              isChecked: false
            },
            {
              label: 'Cancelado',
              value: 'Canceled',
              isChecked: false
            },
            {
              label: 'Charged Back',
              value: 'ChargedBack',
              isChecked: false
            },
            {
              label: 'Disputa',
              value: 'Disputed',
              isChecked: false
            },
            {
              label: 'Estornado',
              value: 'Refunded',
              isChecked: false
            },
            {
              label: 'Falhada',
              value: 'Failed',
              isChecked: false
            },
            {
              label: 'Pendente',
              value: 'Pending',
              isChecked: false
            },
            {
              label: 'Novo',
              value: 'New',
              isChecked: false
            },
            {
              label: 'Pré-autorizada',
              value: 'PreAuthorized',
              isChecked: false
            }
          ]
        },

        tableData: {
          hasIcon: true,
          headers: {
            hasBorder: true,
            items: []
          },

          rows: []
        }
      };
    },

    components: {
      'default-view-component': DefaultView,
      'header-component': Header,
      'navigation-bar-component': NavigationBar,
      'button-component': Button,
      'select-checkbox-field': SelectCheckboxField,
      'input-date-field': InputDate,
      'input-field': InputField,
      'simple-card-component': SimpleCard,
      'modal': Modal,
      'checkbox-component': Checkbox,
      'table-header-component': TableHeader,
      'table-component': Table,
      'pagination-table-component': PaginationTableButton,
      'calendar-field-component': CalendarField,
      'transaction-detail-component': TransactionDetail,
      'subscription-detail-component': SubscriptionDetail,
      'filter-button-component': FilterButton
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerId',
        'getSideBarState',
        'getExtractPerTransactionFilter',
        'getSaleNavigationData',
        'getCurrentSellerStatus',
        'getCurrentSellerData'
      ])
    },

    mounted() {
      this.fetchData(this.getSaleNavigationData.current);
    },

    watch: {
      'getSaleNavigationData.current'(value) {
        this.fetchData(value);
      },

      'dateFilter.end'() {
        if (!this.isLastDateFilter) {
          this.lastDateFilter = this.initialLastDateFilterData();
        }

        this.isLastDateFilter = false;
      },

      getCurrentSellerId() {
        this.hasRequest = true;
        this.isChangeSeller = true;
      },

      getCurrentSellerData() {
        this.isChangeSeller = false;

        this.fetchData(this.getSaleNavigationData.current);
      },

      countItems(value) {
        this.paginationTableButtonData.countItems = value;
      },

      totalCountItems(value) {
        this.paginationTableButtonData.totalCountItems = value;
      },
    },

    methods: {
      fetchData(value) {
        this.countItems = 0;
        this.totalCountItems = 0;
        this.tableData.rows = [];
        this.allValues = [];
        this.tableHeaderData = this.initialTableHeaderData();

        switch (this.$service.toUpperCase(value)) {
          case 'SUBSCRIPTIONS':
            this.fetchSubscriptionsData();
            break;
          case 'CHECKOUTS':
            this.fetchCheckoutsData();
            break;
          default:
            this.fetchTransactionData();
        }
      },

      isSellerActive() {
        return this.$service.toUpperCase(this.getCurrentSellerStatus) == 'ACTIVE';
      },

      fetchTransactionData() {
        this.hasRequest = true;

        let queryParams = [
          {
            query: 'limit',
            param: '10'
          },

          {
            query: 'offset',
            param: this.countItems
          },

          {
            query: 'status',
            param:  this.filterStatusTransaction.query
          },

          {
            query: 'sort',
            param: '-created_at'
          },

          {
            query: 'date_range_start',
            param: this.dateFilter.start ? this.$service.dateFormat(this.dateFilter.start) : ''
          },

          {
            query: 'date_range_end',
            param: this.dateFilter.end ? this.$service.dateFormat(this.dateFilter.end) : ''
          },

          {
            query: 'amount_range_start',
            param: this.$service.formatAmount(this.amountFilter.min)
          },

          {
            query: 'amount_range_end',
            param: this.$service.formatAmount(this.amountFilter.max)
          },

          {
            query: 'payment_method',
            param: this.cardPaymentMethod.current
          }
        ];

        // if (this.getCurrentSellerData.is_sale_enabled) {
        //   queryParams.push({
        //     query: 'customer_detail',
        //     param: true
        //   });
        // }

        this.$store.dispatch('getTransactions', this.$service.formatQueryParam(queryParams))
          .then(res => {
            this.totalCountItems = res.total_count;

            if (!this.isMoreData) {
              this.allValues = [];
            }

            this.setArray(res.data, this.allValues);
            this.tableData = this.formatTransactionTableData(this.allValues);
            this.tableHeaderData = this.formatTableHeaderData();
            this.countItems = this.allValues.length;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.hasRequest = false;
            this.isFilterRequest = false;

            if (this.isMoreData) {
              this.$service.scrollToBottom();
              this.isMoreData = false;
            }
          });
      },

      fetchCheckoutsData() {
        this.hasRequest = true;

        let queryParams = [
          {
            query: 'limit',
            param: '10'
          },

          {
            query: 'offset',
            param: this.countItems
          },

          {
            query: 'sort',
            param: '-created_at'
          },
        ];

        this.$store.dispatch('getSellerCheckouts', this.$service.formatQueryParam(queryParams))
          .then(res => {
            this.totalCountItems = res.total_count;

            if (!this.isMoreData) {
              this.allValues = [];
            }

            this.setArray(res.data, this.allValues);
            this.tableData = this.formatCheckoutTableData(this.allValues);
            this.countItems = this.allValues.length;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.hasRequest = false;

            if (this.isMoreData) {
              this.$service.scrollToBottom();
              this.isMoreData = false;
            }
          });
      },

      fetchSubscriptionsData() {
        this.hasRequest = true;

        let queryParams = [
          {
            query: 'limit',
            param: '10'
          },

          {
            query: 'offset',
            param: this.countItems
          },

          {
            query: 'sort',
            param: '-created_at'
          },
        ];

        this.$store.dispatch('getSubscriptions', this.$service.formatQueryParam(queryParams))
          .then(res => {
            this.totalCountItems = res.total_count;

            if (!this.isMoreData) {
              this.allValues = [];
            }

            this.setArray(res.data, this.allValues);
            this.tableData = this.formatSubscriptionTableData(this.allValues);
            this.countItems = this.allValues.length;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.hasRequest = false;

            if (this.isMoreData) {
              this.$service.scrollToBottom();
              this.isMoreData = false;
            }
          });
      },

      closeDetail(lastItem) {
        if (this.isTransactionSection() && lastItem) {
          var allValuesCurrentItem = this.allValues.find(item => item.id == lastItem.id);

          allValuesCurrentItem.amount = lastItem.amount;
          allValuesCurrentItem.status = lastItem.status;
          allValuesCurrentItem.payment.fees_amount = lastItem.fee;
          allValuesCurrentItem.customer = {
            name: lastItem.customerName
          };

          this.tableData = this.formatTransactionTableData(this.allValues);
          this.tableHeaderData = this.formatTableHeaderData();
        }

        this.hasDetail = false;
        this.isTransactionDetail = false;
        this.isSubscriptionDetail = false;
      },

      hasTableRows() {
        return this.tableData.rows.length > 0;
      },

      openCalendarField() {
        this.showCalendarInputField = true;
      },

      closeCalendarField() {
        this.showCalendarInputField = false;
      },

      haveMoreData() {
        return this.countItems == this.totalCountItems;
      },

      isTransactionSection() {
        return this.$service.toUpperCase(this.getSaleNavigationData.current) == 'TRANSACTIONS';
      },

      setLastDateFilter() {
        this.isLastDateFilter = true;

        this.lastDateFilter.items.forEach(item => {
          if (item.isActive) {
            this.dateFilter.end = this.$service.toDate(this.$service.currentDateEN());
            this.dateFilter.start = this.$service.toDate(this.$service.getDateDay(this.dateFilter.end, item.value));
          }
        });
      },

      formatSubscriptionTableData(data) {
        let tableData = {
          hasIcon: true,
          headers: {
            hasBorder: true,
            items: [
              {
                label: 'Data de criação',
                field: 'createdDate',
                align: 'center'
              },

              {
                label: 'Data de vencimento',
                field: 'dueDate',
                align: 'center'
              },

              {
                label: 'Valor',
                field: 'amount',
                align: 'center'
              },

              {
                label: 'Status',
                field: 'status',
                align: 'center',
                isStatus: true
              },

              {
                label: 'Ações',
                align: 'center',
                field: 'actions',
                isAction: true
              }
            ]
          },

          rows: []
        };

        data.forEach(item => {
          tableData.rows.push({
            icon: {
              name: 'icon-signature'
            },
            createdDate: this.$service.dateFormat(item.created_at, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YY HH:mm'),
            dueDate: this.$service.dateFormat(item.due_date, 'YYYY-MM-DD', 'DD/MM/YY'),
            amount: item.amount ? this.$service.formatBrlAmountSymbol(item.grand_total) : '-',
            status: item.status,
            id: item.id,
            columnClick: 'subscriptionDetail',
            actions: {
              showAction: false,
              icon: 'icon-more',
              items: [
                {
                  id: item.id,
                  isDetail: true,
                  type: 'subscriptionDetail',
                  icon: 'icon-configure',
                  label: 'Opções',
                }
              ]
            }
          });
        });

        return tableData;
      },

      formatCheckoutTableData(data) {
        let tableData = {
          hasIcon: true,
          headers: {
            hasBorder: true,
            items: [
              {
                label: 'Data de criação',
                field: 'createdDate',
                align: 'center'
              },

              {
                label: 'Cliente',
                field: 'customerName',
                align: 'center'
              },

              {
                label: 'Expiração',
                field: 'expires',
                align: 'center',
              },

              {
                label: 'Valor',
                field: 'amount',
                align: 'center'
              },

              {
                label: 'Ações',
                align: 'center',
                field: 'actions',
                isAction: true
              }
            ]
          },

          rows: []
        };

        data.forEach(item => {
          let formatRow = {
            icon: {
              name: 'icon-link'
            },
            createdDate: this.$service.dateFormat(item.created_at, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YY HH:mm'),
            expires: item.is_expired ? { isStatus: true, status: 'Expired' } : this.$service.dateFormat(item.expires_at, 'YYYY-MM-DD', 'DD/MM/YY'),
            customerName: this.$service.toCapitalize(item.customer_name),
            amount: this.$service.formatBrlAmountSymbol(item.grand_total),
            id: item.transaction_id,
            columnClick: 'transactionDetail',
            actions: {
              showAction: false,
              icon: 'icon-more',
              items: [
                {
                  id: item.transaction_id,
                  isDetail: true,
                  type: 'transactionDetail',
                  icon: 'icon-file',
                  label: 'Ver detalhes',
                },

                {
                  value: item.url,
                  type: 'copy',
                  icon: 'icon-link',
                  label: 'Copiar link',
                }
              ]
            }
          };

          if (this.$service.isNull(item.transaction_id)) {
            formatRow.actions['isDisable'] = true;
          }

          tableData.rows.push(formatRow);
        });

        return tableData;
      },

      selectAction(item) {
        if (item.type == 'copy') {
          this.$copyText(item.value);
          return;
        }

        if (!this.$service.isNull(item.id)) {
          this.currentDetailId = item.id;
          this.hasDetail = true;

          switch (item.isDetail ? item.type : item.columnClick) {
            case 'transactionDetail':
              this.isTransactionDetail = true;
              break;
            case 'subscriptionDetail':
              this.isSubscriptionDetail = true;
              break;
          }
        }
      },

      formatTransactionTableData(data) {
        let tableData = {
          hasIcon: true,
          headers: {
            hasBorder: true,
            items: [
              {
                label: 'Data',
                field: 'date',
                align: 'center'
              },

              // {
              //   label: 'Cliente',
              //   field: 'customerName',
              //   align: 'center'
              // },

              {
                label: 'Forma',
                field: 'method',
                align: 'center'
              },

              {
                label: 'Valor bruto',
                field: 'grossAmount',
                align: 'center'
              },

              {
                label: 'Taxa',
                field: 'fee',
                align: 'center'
              },

              {
                label: 'Valor líquido',
                field: 'amount',
                align: 'center'
              },

              {
                label: 'Status',
                field: 'status',
                align: 'center',
                isStatus: true
              },

              {
                label: 'Ações',
                align: 'center',
                field: 'actions',
                isAction: true
              }
            ]
          },

          rows: []
        };

        // if (!this.getCurrentSellerData.is_sale_enabled) {
        //   tableData.headers.items = tableData.headers.items.filter(item => item.field != 'customerName');
        // }

        data.forEach(item => {
          tableData.rows.push({
            icon: {
              name: this.formatTransactionIcon(item.payment),
            },
            iconTooltip: {
              text: this.formatIconTooltip(item.payment),
              show: false
            },
            date: this.$service.dateFormat(item.created_at, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YY HH:mm'),
            method: this.formatTransactionMethod(item.payment),
            fee: this.$service.formatBrlAmountSymbol(item.payment.fees_amount),
            grossAmount: this.$service.formatBrlAmountSymbol(item.amount),
            customerName: item.customer ? item.customer.name : '-',
            id: item.id,
            amount: this.$service.formatBrlAmountSymbol(item.amount - item.payment.fees_amount),
            status: item.status,
            columnClick: 'transactionDetail',
            actions: {
              icon: 'icon-more',
              showAction: false,
              items: [
                {
                  id: item.id,
                  isDetail: true,
                  type: 'transactionDetail',
                  icon: 'icon-file',
                  label: 'Ver detalhes',
                }
              ]
            }
          });
        });

        return tableData;
      },

      formatTransactionIcon(payment) {
        let icon;

        switch (this.$service.toUpperCase(payment.method)) {
          case 'PIX':
            icon = 'icon-pix-outline';
            break;
          case 'BOLETO':
            icon = 'icon-barcode';
            break;
          case 'CREDITCARD':
            if (this.$service.toUpperCase(payment.point_of_sale.entry_mode) === 'MANUALLY_KEYED') {
              icon = 'icon-internet';
            } else {
              icon = 'icon-credit-card-machine';
            }
            break;
          case 'DEBIT':
            icon = 'icon-credit-card-machine';
            break;
          case 'COMMISSION':
            icon = 'icon-deposit';
            break;
          default:
            icon = 'icon-charges';
        }

        return icon;
      },

      formatIconTooltip(payment) {
        let formattedIconTooltip;

        switch (this.$service.toUpperCase(payment.method)) {
          case 'PIX':
            formattedIconTooltip = 'Pix';
            break;
          case 'BOLETO':
            formattedIconTooltip = 'Boleto';
            break;
          case 'CREDITCARD':
            if (this.$service.toUpperCase(payment.point_of_sale.entry_mode) === 'MANUALLY_KEYED') {
              formattedIconTooltip = 'Online';
            } else {
              formattedIconTooltip = 'Maquininha';
            }
            break;
          case 'DEBIT':
            formattedIconTooltip = 'Maquininha';
            break;
          case 'COMMISSION':
            formattedIconTooltip = 'Comissão';
            break;
          default:
            formattedIconTooltip = 'Outro';
        }

        return formattedIconTooltip;
      },

      formatTransactionMethod(payment) {
        let formattedPaymentMethod;

        switch (this.$service.toUpperCase(payment.method)) {
          case 'PIX':
            formattedPaymentMethod = 'Pix';
            break;
          case 'BOLETO':
            formattedPaymentMethod = 'Boleto';
            break;
          case 'CREDITCARD':
            formattedPaymentMethod = 'Cartão de crédito';
            break;
          case 'DEBIT':
            formattedPaymentMethod = 'Débito';
            break;
          case 'COMMISSION':
            formattedPaymentMethod = 'Comissão';
            break;
          default:
           formattedPaymentMethod = 'Transação';
        }

        return formattedPaymentMethod;
      },

      formatTableHeaderData() {
        let salesData = this.formatSalesData(this.allValues);

        return [
          {
            label: 'Total bruto:',
            value: this.$service.formatBrlAmountSymbol(salesData.grossAmount)
          },

          {
            label: 'Total líquido:',
            value: this.$service.formatBrlAmountSymbol(salesData.amount)
          },

          {
            label: salesData.salesCount + ' vendas',
            hasLastChild: true
          }
        ];
      },

      formatSalesData(data) {
        let salesCount = 0;
        let grossAmount = 0;
        let amount = 0;

        data.forEach(item => {
          if (this.$service.toUpperCase(item.status) === 'SUCCEEDED') {
            salesCount += 1;
            grossAmount += item.amount;
            amount += item.amount - item.payment.fees_amount;
          }
        });

        return {
          salesCount: salesCount,
          grossAmount: grossAmount,
          amount: amount
        };
      },

      setArray(arr, receiveArr) {
        arr.forEach(item => {
          receiveArr.push(item);
        });
      },

      moreFilter() {
        this.activeMoreFilter = true;
        this.resetModalFilter();
      },

      closeModal() {
        this.actionsCardPaymentMethod = [];
        this.activeMoreFilter = false;
      },

      applyModalFilter() {
        this.isFilterRequest = true;
        this.allValues = [];
        this.countItems = this.allValues.length;

        this.actionsCardPaymentMethod.forEach(index => {
          this.cardPaymentMethod.items[index].isChecked = !this.cardPaymentMethod.items[index].isChecked;
        });

        this.cardPaymentMethod.current = this.formatCardPaymentMethodQuery();
        this.fetchTransactionData();
        this.closeModal();
      },

      formatCardPaymentMethodQuery() {
        let formattedQuery = '';

        this.cardPaymentMethod.items.forEach(item => {
          if (item.isChecked) {
            formattedQuery = formattedQuery + item.value + ',';
          }
        });

        return formattedQuery.substring(0, formattedQuery.length - 1);
      },

      moreData() {
        if (!this.haveMoreData()) {
          this.isMoreData = true;

          switch (this.$service.toUpperCase(this.getSaleNavigationData.current)) {
            case 'SUBSCRIPTIONS':
              this.fetchSubscriptionsData();
              break;
            case 'CHECKOUTS':
              this.fetchCheckoutsData();
              break;
            default:
              this.fetchTransactionData();
          }
        }
      },

      applyTransactionTypeFilter() {
        this.countItems = 0;
        this.isFilterRequest = true;
        this.fetchTransactionData();
      },

      toggleValuePaymentMethod(index) {
        this.actionsCardPaymentMethod.push(index);
      },

      resetModalFilter() {
        this.amountFilter = this.initialAmountFilterData();
        this.dateFilter = this.initialRangeDateFilterData();
        this.lastDateFilter = this.initialLastDateFilterData();
      },

      initialAmountFilterData() {
        return {
          min: '',
          max: ''
        };
      },

      initialRangeDateFilterData() {
        return {
          start: null,
          end: null
        };
      },

      initialTableHeaderData() {
        return [
          {
            label: 'Total bruto:',
            value: 'R$ 0,00'
          },

          {
            label: 'Total líquido:',
            value: 'R$ 0,00'
          },

          {
            label: '0 vendas',
            hasLastChild: true
          }
        ];
      },

      initialLastDateFilterData() {
        return {
          isUnique: true,
          items: [
            {
              label: '3 dias',
              value: -3,
              isActive: false
            },

            {
              label: '7 dias',
              value: -7,
              isActive: false
            },

            {
              label: '30 dias',
              value: -30,
              isActive: false
            },

            {
              label: '45 dias',
              value: -45,
              isActive: false
            }
          ]
        };
      }
    }
  };
</script>

<style lang="scss">
  .sales-view-container {
    .modal {
      .filter-modal-container {
        h2 {
          text-align: center;
        }

        h3 {
          margin-bottom: 1rem;
        }

        .line {
          margin-bottom: 1.5rem;
          margin-top: 1.5rem;
          height: 1px;
          background: #EFEFEF;
          width: 100%;
        }

        .filter-modal-content {
          margin-top: 1rem;

          .field {
            display: flex;

            .day-container {
              margin-right: 1.5rem;
            }

            .input-field-container {
              margin-bottom: 0;
            }

            .installments-container {
              margin-left: auto;
            }

            .simple-card-component {
              margin-top: 0.5rem;
            }

            .item {
              display: flex;
              margin-bottom: 1rem;
              align-items: center;

              .checkbox-component {
                margin-right: 0.5rem;
              }
            }

            .input-field {
              margin-right: 1rem;

              &:last-child {
                margin-right: 0;
              }
            }

            .input-date-field {
              margin-left: auto;
              align-items: center;
              display: flex;
            }

            &.payment-form {
              flex-direction: column;
            }
          }
        }
      }

      .footer-slot-container {
        display: flex;
        padding: 0.75rem 1rem;
        box-shadow: 0px -1px 6px rgb(0 0 0 / 8%);

        .reset-button {
          display: flex;
          align-items: center;
          color: #8E8E8E;
          cursor: pointer;
          margin-right: 3rem;

          p {
            margin-left: 0.5rem;
            font-weight: 600;
          }
        }

        .button-component {
          height: 48px;
          width: 110px;
          margin-left: auto;
        }
      }
    }

    .section {
      padding: 1.5rem;

      .section-navigation {
        display: flex;

        .navigation-bar-component {
          margin-right: 1.5rem;
        }

        .button-component {
          width: 160px;
          margin-left: auto;
        }
      }

      .filters-container {
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
        width: 100%;

        .filters {
          display: flex;
        }
      }

      .table-header-component {
        margin-top: 1.5rem;
      }

      .table-component {
        margin-top: 1.5rem;
        overflow-x: auto;
        overflow-y: hidden;
      }

      .pagination-table-button-component {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
      }
    }
  }

  @media (max-width: 1120px) {
    .sales-view-container {
      .section {
        .section-navigation {
          flex-direction: column-reverse;

          .navigation-bar-component {
            margin-right: 0;
          }

          .button-component {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  @media (max-width: 775px) {
    .sales-view-container {
      .modal {
        .filter-modal-container {
          .filter-modal-content {
            .field {
              &.days {
                .calendar-field-component {
                  padding: 1rem;

                  .custom-calendar-content {
                    height: 380px;
                    overflow: auto;

                    &::-webkit-scrollbar {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 635px) {
    .sales-view-container {
      .modal {
        .filter-modal-container {
          .filter-modal-content {
            .field {
              &.days {
                flex-direction: column;

                .day-container {
                  margin-right: 0;
                }

                .input-date-field {
                  margin-top: 1rem;
                  margin-left: unset;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    .sales-view-container {
      .modal {
        .filter-modal-container {
          .filter-modal-content {
            .field {
              &.payment-form {
                .installments-container {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }


  @media (max-width: 480px) {
    .sales-view-container {
      .modal {
        .filter-modal-container {
          .filter-modal-content {
            .field {
              &.days {
                .calendar-field-component {
                  .custom-calendar-content {
                    height: auto;
                    margin-bottom: 5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    .sales-view-container {
      .modal {
        .filter-modal-container {
          .filter-modal-content {
            height: 400px;
            overflow-y: auto;
            padding: 0.5rem;

            .field {
              &.amount {
                flex-direction: column;

                .input-field {
                  &:last-child {
                    margin-top: 1rem;
                  }
                }
              }
            }
          }
        }
      }

      .section {
        .filters-container {
          .filters {
            justify-content: space-between;
            width: 100%;
          }
        }
      }
    }
  }
</style>