<template>
  <div>
    <div v-if="hasRequest" class="spinner">
      <spinner-component color="#DADADA"/>
    </div>

    <transaction-detail-component v-else-if="isTransactionDetail" :detailId="currentDetailId" @close="closeDetail" />

    <simple-default-view-component v-else :hasModal="signatureFeedbackModal || customerProfileModal && !hasCustomerRequest" class="simple-default-view-component">
      <template v-slot:modal>
        <modal
          v-if="signatureFeedbackModal"
          ref="modal"
          class="modal"
          :title="signatureFeedbackModalData.actionName + ' assinatura ?'"
          :showModal="signatureFeedbackModal"
          :isTopOnMobile="true"
          @close="closeSignatureFeedbackModal"
        >
          <template v-slot:body>
            <div class="body">
              <i :class="signatureFeedbackModalData.icon" />

              <p>
                Você deseja <span>{{ signatureFeedbackModalData.actionName }}</span> a assinatura do cliente <b>{{ data.customerName }}</b> ?
              </p>

              <button-component
                class="button-component"
                :class="signatureFeedbackModalData.color"
                :hasLoader="hasSignatureActionRequest"
                :isDisabled="hasSignatureActionRequest"
                :text="signatureFeedbackModalData.actionName + ' assinatura'"
                @click.native="signatureAction"
              />
            </div>
          </template>
        </modal>

        <customer-profile-modal-component
          v-else-if="customerProfileModal"
          :customerId="currentCustomerId"
          :hasEditButton="hasDropdownAndEditCustomer"
          @close="closeCustomerProfileModal"
          @initFetchData="initCustomerFetchData"
          @finishFetchData="finishCustomerFetchData"
        />
      </template>

      <template v-slot:header>
        <div class="header">
          <h2>{{ data.customerName }}</h2>

          <i class="icon-circle"/>

          <p>Assinatura</p>

          <div v-if="hasDropdownAndEditCustomer" class="options-container">
            <i class="icon-configure" @click="openOption" />

            <dropdown-component class="dropdown-component" :data="optionsData" @newOption="subscriptionAction" />
          </div>
        </div>

        <div class="icon-container">
          <i class="icon-close" @click="close"/>
        </div>
      </template>

      <template v-slot:content>
        <div class="content">
          <div class="column">
            <h3>Cobrança</h3>

            <div class="details-card-container">
              <div class="details-card-header">
                Próximo vencimento

                <h2>{{ formattedData.dueDateSubscription }}</h2>
              </div>

              <div class="line"></div>

              <div v-if="!!data.cardDetails.columns.length" class="items-container">
                <div v-for="(column, index) in data.cardDetails.columns" :key="index" class="items-content">
                  <div v-for="(row, index) in column.rows" :key="index" class="item">
                    <div class="label">
                      <i v-if="row.icon" :class="row.icon"></i>
                      <!-- <img v-if="row.icon" :src="require(`@/assets/brand/Hiper.svg`)" alt=""> -->

                      {{ row.label }}
                    </div>

                    <div class="value">
                      {{ row.value }}
                    </div>
                  </div>
                </div>
              </div>

              <div v-else class="empty-container">
                <i class="icon-credit-card"></i>

                Nenhum cartão vinculado!
              </div>
            </div>
          </div>

          <div class="column details">
            <div class="title">
              <div class="flex">
                <h3>Detalhes da assinatura</h3>

                <trasaction-status-component class="trasaction-status-component" :status="data.status" />
              </div>

              <button-component
                class="button-component"
                styles="ghost"
                text="Perfil do cliente"
                :hasLoader="hasCustomerRequest"
                :isDisabled="hasCustomerRequest"
                buttonFontSize="1.5rem"
                @click.native="showCustomerProfileModal"
              />
            </div>

            <div class="details-section-container">
              <div class="items-container">
                <div v-for="(column, index) in data.details.columns" :key="index" class="items-content">
                  <div v-for="(row, index) in column.rows" :key="index" class="item">
                    <div class="label">
                      <i v-if="row.icon" :class="row.icon"></i>
                      {{ row.label }}
                    </div>

                    <div class="value">
                      {{ row.value }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="line"></div>

              <div class="invoices-section">
                <div class="title">
                  <h3>Fatura</h3>

                  <div class="invoices">
                    Valor total pago: <b>{{ formattedData.totalAmountInvoices }}</b>
                  </div>
                </div>

                <table-component
                  class="table-component"
                  :table="tableData"
                  @columnClick="showTransactionDetail"
                  @actionClick="showTransactionDetail"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </simple-default-view-component>
  </div>
</template>

<script>
  import SimpleDefaultView from '@/components/Views/SimpleDefaultView';
  import CustomerProfileModal from '@/components/CustomerProfileModal';
  import TransactionStatus from '@/components/TransactionStatus';
  import TransactionDetail from '@/components/TransactionDetail';
  import Table from '@/components/Table/Table';
  import Button from '@/components/Button';
  import Spinner from '@/components/Spinner';
  import Dropdown from '@/components/Dropdown';
  import Modal from '@/components/Modal';

  export default {
    name: 'SubscriptionDetail',

    data() {
      return {
        hasRequest: false,
        isTransactionDetail: false,
        hasCustomerRequest: false,
        currentDetailId: '',
        currentCustomerId: '',
        signatureFeedbackModal: false,
        customerProfileModal: false,
        hasSignatureActionRequest: false,
        hasDropdownAndEditCustomer: true,
        signatureFeedbackModalData: {
          icon: '',
          actionName: '',
          color: ''
        },

        formattedData: {
          cardNumber: '',
          holderName: '',
          createdDateSubscription: '',
          expirationDateSubscription: '',
          dueDateSubscription: '',
          intervalType: '',
          planName: '',
          amount: '',
          totalAmountInvoices: '',
        },

        data: {
          customerName: '',
          status: '',

          cardDetails: {
            columns: []
          },

          details: {
            columns: [
              {
                rows: [
                  {
                    label: 'Produto',
                    value: 'test'
                  }
                ]
              },

              {
                rows: [
                  {
                    label: 'Data de início e fim',
                    value: '05/28'
                  },

                  {
                    label: 'Periocidade',
                    value: '05/28'
                  },

                  {
                    label: 'Valor',
                    value: '05/28'
                  }
                ]
              }
            ]
          },
        },

        optionsData: {
          show: false,
          hasActive: false,
          options: this.initialDropdownOptions()
        },

        tableData: {
          hasIcon: false,
          headers: {
            hasBorder: true,
            items: [
              {
                label: 'Vencimento',
                field: 'dueDate',
                align: 'center',
              },

              {
                label: 'Valor',
                field: 'amount',
                align: 'center'
              },

              {
                label: 'Status',
                field: 'status',
                align: 'center',
                isStatus: true
              },

              {
                label: 'Ações',
                align: 'center',
                field: 'actions',
                isAction: true
              }
            ]
          },

          rows: []
        }
      };
    },

    components: {
      'simple-default-view-component': SimpleDefaultView,
      'trasaction-status-component': TransactionStatus,
      'transaction-detail-component': TransactionDetail,
      'customer-profile-modal-component': CustomerProfileModal,
      'table-component': Table,
      'button-component': Button,
      'spinner-component': Spinner,
      'dropdown-component': Dropdown,
      'modal': Modal
    },

    props: {
      detailId: {
        type: String,
        required: true
      }
    },

    created() {
      this.fetchData();
    },

    mounted() {
      history.pushState({}, '', this.$router.history.current.path);

      window.onpopstate = () => {
        this.close();
      };
    },

    methods: {
      showTransactionDetail(item) {
        if (this.$service.toUpperCase(item.status) != 'FAILED' || this.$service.toUpperCase(item.status) != 'PENDING') {
          this.currentDetailId = item.transactionId;
          this.isTransactionDetail = true;
        }
      },

      formatCardDetails(data) {
        return [
          {
            rows: [
              {
                icon: 'icon-credit-card',
                label:  data.card_brand,
                value: data.first4_digits + ' **** **** ' + data.last4_digits
              },

              {
                label: 'Titular do cartão',
                value: data.holder_name
              }
            ]
          },

          {
            rows: [
              {
                label: 'Validade',
                value: this.$service.dateFormat(data.expiration_month + '/' + data.expiration_year, 'M/YYYY', 'MM/YY')
              }
            ]
          }
        ];
      },

      initCustomerFetchData() {
        this.hasCustomerRequest = true;
      },

      finishCustomerFetchData() {
        this.hasCustomerRequest = false;
      },

      closeDetail() {
        this.isTransactionDetail = false;
      },

      initialDropdownOptions() {
        return [
          {
            label: 'Ativar',
            value: 'resume',
            apiStatus: 'active',
            icon: 'icon-check-circle-outline',
            isActive: false
          },

          {
            label: 'Suspender',
            value: 'pause',
            apiStatus: 'paused',
            icon: 'icon-pause-circle-outline',
            isActive: false
          },

          {
            label: 'Cancelar',
            apiStatus: 'canceled',
            value: 'cancel',
            icon: 'icon-close-circle-outline',
            isActive: false
          }
        ];
      },

      showCustomerProfileModal() {
        this.customerProfileModal = true;
      },

      closeCustomerProfileModal() {
        this.customerProfileModal = false;
      },

      subscriptionAction() {
        let action = this.optionsData.options.find(item => item.isActive);

        switch (this.$service.toUpperCase(action.value)) {
          case 'RESUME':
            this.signatureFeedbackModalData.actionName = 'Ativar';
            this.signatureFeedbackModalData.color = 'green';
            break;
          case 'PAUSE':
            this.signatureFeedbackModalData.actionName = 'Pausar';
            this.signatureFeedbackModalData.color = 'yellow';
            break;
          default:
            this.signatureFeedbackModalData.actionName = 'Cancelar';
            this.signatureFeedbackModalData.color = 'red';
        }

        this.signatureFeedbackModalData.icon = action.icon;
        this.signatureFeedbackModal = true;
        this.optionsData.show = false;
      },

      async signatureAction() {
        let action = this.optionsData.options.find(item => item.isActive);
        this.hasSignatureActionRequest = true;

        switch (this.$service.toUpperCase(action.value)) {
          case 'RESUME':
            await this.$store.dispatch('postResumeSubscription', this.detailId);
            break;
          case 'PAUSE':
            await this.$store.dispatch('postPauseSubscription', this.detailId);
            break;
          default:
            await this.$store.dispatch('postCancelSubscription', this.detailId);
        }

        this.optionsData.options.map(item => item.isActive = false);
        this.hasSignatureActionRequest = false;
        this.signatureFeedbackModal = false;
        this.fetchData();
      },

      closeSignatureFeedbackModal() {
        this.signatureFeedbackModal = false;
      },

      isCanceledStatus(status) {
        return this.$service.toUpperCase(status) == 'CANCELED';
      },

      async fetchData() {
        this.hasRequest = true;
        let planId = '';
        let cardTokenId = '';

        this.optionsData.options = this.initialDropdownOptions();

        await this.$store.dispatch('getSubscriptionId', this.detailId)
          .then(res => {
            let data = res.data;
            this.hasDropdownAndEditCustomer = !this.isCanceledStatus(data.status);

            let indexCurrentStatus = this.optionsData.options.findIndex(item => {
              return this.$service.toUpperCase(item.apiStatus).indexOf(this.$service.toUpperCase(data.status)) > -1;
            });

            this.optionsData.options.splice(indexCurrentStatus, 1);

            planId = data.plan_id;
            this.currentCustomerId = data.customer_id;
            this.data.status = data.status;
            this.formattedData.createdDateSubscription = this.$service.dateFormat(data.created_at, 'YYYY-MM-DD', 'DD/MM/YY');
            this.formattedData.expirationDateSubscription = this.$service.isNull(data.expiration_date) ? 'Indeterminado' : this.$service.dateFormat(data.due_date, 'YYYY-MM-DD', 'DD/MM/YY');
            this.formattedData.dueDateSubscription = this.$service.dateFormat(data.due_date, 'YYYY-MM-DD', 'DD/MM/YY');
            this.formattedData.amount = data.amount;
          })
          .catch(err => {
            console.log(err);
          });

        await this.$store.dispatch('getPlanId', planId)
          .then(res => {
            let data = res.data;
            this.formattedData.planName = data.name;
            this.formattedData.amount = this.$service.isNull(data.amount) ? this.formattedData.amount : data.amount;
            this.formattedData.intervalType = data.interval_type;
          })
          .catch(err => {
            console.log(err);
          });

        await this.$store.dispatch('getCustomerId', this.currentCustomerId)
          .then(res => {
            let data = res.data;

            this.data.customerName = `${data.first_name} ${data.last_name ?? ''}`;

            cardTokenId = data.default_debit;
          })
          .catch(err => {
            console.log(err);
          });

        await this.$store.dispatch('getSubscriptionInvoices', this.detailId)
          .then(res => {
            let data = res.data;

            let invoicesArr = [];
            let retryInvoicesArr = [];

            data.reverse().forEach(item => {
              if (this.$service.isNull(item.description)) {
                invoicesArr.push(item);
              } else {
                retryInvoicesArr.push(item);
              }
            });

            this.formattedData.totalAmountInvoices = this.$service.formatBrlAmountSymbol(this.formatTotalAmountInvoices(data));

            retryInvoicesArr.forEach(item => {
              let signatureRetryId = item.description.split(' ')[2];

              let retryItem = invoicesArr.find(item => {
                return signatureRetryId.indexOf(item.id) > -1;
              });

              if (!retryItem.append) retryItem['append'] = [];
              retryItem.append.push(this.formattedInvoicesTableRow(item));
            });

            this.tableData.rows = this.formattedInvoicesTableRows(invoicesArr);
          })
          .catch(err => {
            console.log(err);
          });

        if (!this.$service.isNull(cardTokenId)) {
          await this.$store.dispatch('getCardDetails', cardTokenId)
            .then(res => {
              let resData = res.data;
              this.data.cardDetails.columns = this.formatCardDetails(resData);
            })
            .catch(err => {
              console.log(err);
            });
        }

        this.data.details = this.formatDetailsData(this.formattedData);
        this.hasRequest = false;
      },

      openOption() {
        this.optionsData.show = true;
      },

      formatTotalAmountInvoices(data) {
        let totalAmount = 0;

        data.forEach(item => {
          if (this.$service.toUpperCase(item.status) == 'PAID') {
            totalAmount += item.amount;
          }
        });

        return this.$service.formatIntAmountToFloat(totalAmount);
      },

      formatDetailsData(data) {
        return {
          columns: [
            {
              rows: [
                {
                  label: 'Produto',
                  value: data.planName
                }
              ]
            },

            {
              rows: [
                {
                  label: 'Data de início e fim',
                  value: data.createdDateSubscription + ' - ' + data.expirationDateSubscription
                },

                {
                  label: 'Periocidade',
                  value: this.formattedInterval(data.intervalType)
                },

                {
                  label: 'Valor',
                  value: this.$service.formatBrlAmountSymbol(this.$service.formatIntAmountToFloat(data.amount))
                }
              ]
            },
          ]
        };
      },

      formattedInterval(intervalName) {
        switch (this.$service.toUpperCase(intervalName)) {
          case 'MONTH':
            return 'Mensal';
        }
      },

      formattedInvoicesTableRow(item) {
        let formattedTableRow = {
          id: item.id,
          transactionId: !this.$service.isEmptyArray(item.transactions) ? item.transactions[0].id : null,
          dueDate: this.$service.dateFormat(item.due_date, 'YYYY-MM-DD', 'DD/MM/YY'),
          amount: this.$service.formatBrlAmountSymbol(this.$service.formatIntAmountToFloat(item.amount)),
          status: item.status,
          actions: {},
        };

        if (item.append) {
          formattedTableRow['append'] = {
            showAppend: false,
            items: []
          };

          formattedTableRow.append.showAppend = false;
          formattedTableRow.append.items = item.append;

          item.append.forEach(item => {
            item.actions = {
              isStatus: true,
              status: 'retry'
            };
          });
        } else {
          formattedTableRow.actions.icon = 'icon-more';
          formattedTableRow.actions.showAction = false;

          formattedTableRow.actions.items = [
            {
              transactionId: !this.$service.isEmptyArray(item.transactions) ? item.transactions[0].id : null,
              isDetail: true,
              status: item.status,
              type: 'transactionDetail',
              icon: 'icon-file',
              label: 'Ver detalhes',
            }
          ];
        }

        if (this.$service.toUpperCase(item.status) == 'FAILED' || this.$service.toUpperCase(item.status) == 'PENDING') {
          formattedTableRow.actions.isDisable = true;
        }

        return formattedTableRow;
      },

      formattedInvoicesTableRows(data) {
        let formattedRows = [];

        data.forEach(item => {
          formattedRows.push(this.formattedInvoicesTableRow(item));
        });

        return formattedRows;
      },

      close() {
        this.$emit('close');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .spinner {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .simple-default-view-component {
    .modal {
      .body {
        display: flex;
        align-items: center;
        flex-direction: column;

        h3 {
          color: #333;
        }

        p {
          text-align: center;

          b {
            font-weight: 500;
          }

          span {
            text-transform: lowercase;
            font-weight: 500;
          }
        }

        i {
          font-size: 3.2rem;
          color: #8E8E8E;
          margin-top: 2rem;
          margin-bottom: 2rem;
        }

        .button-component {
          margin-top: 2rem;
          width: 190px;
          height: 40px;

          &.green {
            background: #08B978;

            &:hover {
              background: #0da970;
            }
          }

          &.yellow {
            background: #BA8C03;

            &:hover {
              background: #a98004;
            }
          }


          &.red {
            background: #FF5A5A;

            &:hover {
              background: #e53c3c;
            }
          }
        }
      }
    }

    .header {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      padding: 0 !important;

      h2 {
        color: #333;
      }

      p {
        color: #6D6F6F;
        font-size: 16px;
        font-weight: 500;
        margin-right: 0.5rem;
      }

      i {
        color: #DADADA;

        &.icon-configure {
          color: #8E8E8E;
          cursor: pointer;

          &:hover {
            background: #E4E4E4;
            border-radius: 100%;
          }
        }

        &.icon-circle {
          font-size: 0.3rem;
          padding: 0.5rem;
        }

        &.icon-configure {
          font-size: 1.2rem;
          padding: 0.5rem;
        }
      }

      .options-container {
        display: flex;
        justify-content: center;
        padding: 0.5rem;
        border-radius: 100%;

        .dropdown-component {
          top: 1rem;
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
    }

    .label {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #8E8E8E;

      img {
        margin-right: 0.5rem;
      }

      i {
        margin-right: 0.5rem;
      }
    }

    .value {
      margin-top: 0.3rem;
      font-size: 14px;
      color: #333;
    }

    .content {
      padding: 1.5rem;
      display: flex;

      .column {
        margin-right: 4rem;

        &.details {
          width: 100%;

          .title {
            .flex {
              display: flex;
              justify-content: center;
            }

            .button-component {
              margin-left: auto;
              max-width: 150px;
              height: 40px;
            }
          }

          .line {
            border-bottom: 1px solid #EFEFEF;
            margin-bottom: 2rem;
            margin-top: 2rem;
          }

          .details-section-container {
            margin-top: 1rem;

            .items-container {
              .items-content {
                display: flex;
                margin-bottom: 2rem;

                .item {
                  margin-right: 5rem;
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            .invoices-section {
              width: 100%;
              overflow-x: hidden;

               .table-component {
                overflow-x: auto;
                overflow-y: hidden;
              }

              .invoices {
                margin-left: auto;
                font-size: 14px;

                b {
                  font-weight: 500;
                  margin-left: 0.5rem;
                }
              }

              .table-component {
                margin-top: 1rem;
              }
            }
          }
        }

        h3 {
          color: #333;
        }

        &:last-child {
          margin-right: 0;
        }

        .trasaction-status-component {
          margin-left: 1rem;
        }

        .details-card-container {
          border: 1px solid #EFEFEF;
          border-radius: 8px;
          width: 395px;
          padding: 0.7rem 1rem;
          margin-top: 1rem;

          .details-card-header {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #EFEFEF;
            border-radius: 8px;
            padding: 1rem;
            font-size: 14px;
            font-weight: 400;

            h2 {
              margin-left: 0.5rem;
            }
          }

          .line {
            margin-top: 0.7rem;
            border-bottom: 1px solid #EFEFEF;
          }

          .items-container {
            display: flex;

            .items-content {
              .item {
                padding: 1rem 0;
              }

              &:last-child {
                margin-left: auto;
                text-align: end;

                .label {
                  display: block;
                }
              }
            }
          }

          .empty-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: 0.5rem;
            font-weight: 400;
            font-size: 14px;
            color: #6D6F6F;
            padding: 1rem;

            i {
              font-size: 2.5rem;
              margin-bottom: 1rem;
              color: #DADADA;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1075px) {
    .simple-default-view-component {
      .content {
        .column {
          margin-right: 2rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media (max-width: 1015px) {
    .simple-default-view-component {
      .header {
        h2 {
          font-size: 16px;
        }

        p {
          font-size: 14px;
        }
      }

      .content {
        flex-direction: column;

        .column {
          margin-right: 0;
          margin-bottom: 2rem;

          .details-card-container {
            width: 100%;
            border: none;
            padding: 0;
          }
        }
      }
    }
  }

  @media (max-width: 625px) {
    .simple-default-view-component {
      .content {
        .column {
          &.details {
            .details-section-container {
              .items-container {
                .items-content {
                  flex-direction: column;
                  margin-bottom: 1rem;

                  .item {
                    margin-bottom: 1rem;

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .simple-default-view-component {
      .header {
        h2 {
          font-size: 14px;
        }

        p {
          font-size: 12px;
        }
      }

      .content {
        .column {
          .details-card-container {
            .details-card-header {
              font-size: 12px;

              h2 {
                font-size: 16px;
              }
            }

            .items-container {
              .items-content {
                .item {
                  .label {
                    font-size: 12px;
                  }

                  .value {
                    font-size: 12px;
                  }
                }
              }
            }

            .empty-container {
              font-size: 12px;
            }
          }

          &.details {
            .title {
              flex-direction: column-reverse;
              align-items: unset;

              .flex {
                justify-content: unset;
              }

              .button-component {
                margin-bottom: 2rem;
              }
            }

            .details-section-container {
              .items-container {
                .items-content {
                  .item {
                    .label {
                      font-size: 12px;
                    }

                    .value {
                      font-size: 12px;
                    }
                  }
                }
              }

              .invoices-section {
                .title {
                  flex-direction: column;

                  .invoices {
                    margin-top: 1rem;
                    font-size: 12px;
                    margin-left: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 362px) {
    .simple-default-view-component {
      .flex {
        align-items: center;
      }
    }
  }
</style>