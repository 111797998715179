<template>
  <modal
    title="Sacar"
    class="withdraw-modal"
    :showModal="true"
    @close="closeModal"
  >
      <template v-slot:body>
        <ValidationObserver
          v-slot="{ passes }"
          ref="form"
        >
          <form class="body-content" id="form" @submit.prevent="passes(withdraw)">
            <div class="body">
              <p>
                Saldo disponível <span>{{ accountBalance }}</span>
              </p>

              <input-field
                placeholder="Valor"
                type="money"
                name="money"
                :maxLength="24"
                :isDisabled="hasRequest || !hasBankAccount()"
                v-model="data.amount"
              />

              <input-field
                placeholder="Descrição"
                rules="required"
                :isDisabled="hasRequest || !hasBankAccount()"
                v-model="data.description"
              />

              <p>Escolha uma conta cadastrada ou adicione uma</p>

              <ValidationProvider
                v-slot="{ errors }"
                ref="radioCard"
                name="bank"
                v-model="data.id"
              >
                <div class="account-container">
                  <div v-if="!hasRequest" class="account-content">
                    <radio-card-component
                      v-if="hasBankAccount()"
                      class="radio-card-component"
                      :data="formattedBankAccounts"
                      @toggleRadioValue="setBankId"
                    />

                    <div v-else class="empty-bank-accounts">
                      <simple-message-component
                        :data="emptyBankAccountData"
                      />

                      <button-component
                        class="button-component"
                        text="Adicionar nova"
                        v-ripple="'rgba(255, 255, 255, 0.35)'"
                        pathView="/bank"
                      />
                    </div>
                  </div>

                  <div v-else>
                    <spinner-component color="#DADADA" />
                  </div>
                </div>

                <span class="error">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </form>
        </ValidationObserver>
      </template>

      <template v-slot:footer>
        <div class="footer-slot-container">
          <button-component
            class="button-component"
            text="Sacar"
            :isDisabled="hasRequest || !hasBankAccount()"
            :isLoader="hasRequest"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            form="form"
          />
        </div>
      </template>
  </modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import Modal from '@/components/Modal';
  import Button from '@/components/Button';
  import RadioCard from '@/components/RadioCard';
  import InputField from '@/components/InputField';
  import SimpleMessage from '@/components/SimpleMessage';
  import Spinner from '@/components/Spinner';

  export default {
    name: 'WithdrawModal',

    data() {
      return {
        accountBalance: '',
        data: {
          amount: '',
          description: 'Saque manual',
          bankAccountId: '',
        },
        hasRequest: false,
        formattedBankAccounts: [],
        emptyBankAccountData: {
          icon: 'icon-receipts',
          text: 'Nenhuma conta bancária cadastrada'
        }
      };
    },

    components: {
      ValidationProvider,
      ValidationObserver,
      'modal': Modal,
      'button-component': Button,
      'input-field': InputField,
      'radio-card-component': RadioCard,
      'spinner-component': Spinner,
      'simple-message-component': SimpleMessage,
    },

    computed: {
      ...mapGetters({
        getCurrentSellerData: 'getCurrentSellerData',
        getCurrentSellerId: 'getCurrentSellerId',
        getAccountBalance: 'getAccountBalance',
        getSubBalance: 'getSubBalance',
        getAllBankAccountData: 'bankAccountModule/getBankAccountData',
        getDashboardBalanceValueState: 'getDashboardBalanceValueState'
      })
    },

    created() {
      if (this.$service.isNull(this.getAllBankAccountData)) {
        this.fetchBankAccount();
      } else {
        this.formatBankAccount();
      }
    },

    watch: {
      getCurrentSellerId() {
        this.$store.commit('bankAccountModule/resetBankAccountData');
        this.fetchBankAccount();
      }
    },

    mounted() {
      this.setCurrentBalance();
    },

    methods: {
      formatBankAccount() {
        this.getAllBankAccountData.forEach(item => {
          this.formattedBankAccounts.push(this.formatBankAccountData(item));
        });
      },

      closeModal() {
        this.$emit('close');
      },

      setCurrentBalance() {
        this.accountBalance = this.$service.formatBrlAmountSymbol(this.getCurrentSellerData?.is_banking ? this.getAccountBalance : this.getSubBalance);
      },

      async fetchBankAccount() {
        this.hasRequest = true;

        await this.$store.dispatch('bankAccountModule/getAllBankAccount')
          .then(() => {
            this.formatBankAccount();
          })
          .catch(err => {
            console.log(err);
          });

        this.hasRequest = false;
      },

      hasBankAccount() {
        return this.getAllBankAccountData.length > 0;
      },

      formatBankAccountData(item) {
        return {
          id: item.id,
          isChecked: false,
          radioLabel: item.bank_name,
          rows: [
            {
              columns: [
                {
                  item: 'Agência',
                  value: item.routing_number
                },

                {
                  item: 'Conta',
                  value: this.$service.formatAccountNumber(item.account_number)
                }
              ]
            },

            {
              columns: [
                {
                  item: 'Titular',
                  value: item.holder_name
                },

                {
                  item: this.$service.isEin(item.taxpayer_id) ? 'CNPJ' : 'CPF',
                  value: this.$service.isEin(item.taxpayer_id) ? this.$service.formatEin(item.taxpayer_id) : this.$service.formatTaxpayerId(item.taxpayer_id)
                }
              ]
            }
          ]
        };
      },

      async withdraw() {
        let hasError = false;

        if (this.data.amount < 10) {
          hasError = true;

          this.$refs.form.setErrors({
            money: 'Valor mínimo para saque - 10 reais',
          });
        }

        if (this.$service.isBlank(this.data.bankAccountId)) {
          hasError = true;

          this.$refs.form.setErrors({
            bank: 'Campo obrigatório',
          });
        }

        if (!hasError) {
          let data = JSON.stringify({
            amount: this.data.amount * 100,
            description: this.data.description,
            resource: this.getCurrentSellerData?.is_banking ? 'Banking' : 'Sub',
            bank_account_id: this.data.bankAccountId
          });

          this.hasRequest = true;

          await this.$store.dispatch('postWithdraw', data)
            .then(() => {
              this.$store.commit('setAlertCardData', {
                text: 'Saque realizado com sucesso!',
                color: '#08B978'
              });
            })
            .catch(() => {
              this.$store.commit('setAlertCardData', {
                text: 'Falha no saque! Verifique os dados e tente novamente.',
                color: '#FF5A5A'
              });
            });

          this.closeModal();

          if (!this.getDashboardBalanceValueState) {
            this.$store.commit('toggleDashboardBalanceValueState');
          }

          this.$store.commit('setAlertCardStatus', true);

          this.hasRequest = false;
        }
      },

      setBankId() {
        this.$refs.form.validate();
        this.data.bankAccountId = this.formattedBankAccounts.filter(item => item.isChecked)[0].id;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .withdraw-modal {
    .body-content {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      display: flex;

      .body {
        margin-top: 1.5rem;
        width: 395px;

        p {
          text-align: center;
          margin-bottom: 1rem;

          span {
            font-weight: 500;
          }
        }

        .account-container {
          height: 240px;
          align-items: center;
          justify-content: center;
          display: flex;

          .account-content {
            height: 100%;
            width: 100%;

            .radio-card-component {
              height: 100%;
              width: 100%;
              overflow: auto;
            }

            .empty-bank-accounts {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-top: 2rem;

              .button-component {
                margin-top: 1.5rem;
                max-width: 160px;
                height: 48px;
              }
            }
          }
        }

        span {
          .error {
            color: #FF5A5A;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }

    .footer-slot-container {
      display: flex;
      padding: 0.75rem 1rem;
      box-shadow: 0px -1px 6px rgb(0 0 0 / 8%);

      .button-component {
        height: 48px;
        width: 110px;
        margin-left: auto;
      }
    }
  }

  @media (max-width: 767px) {
    .withdraw-modal {
      .body-content {
        .body {
          padding: 0 1.5rem;

          .radio-card-component {
            height: 175px;
          }
        }
      }
    }
  }
</style>