<template>
  <div class="forgot-view-container">
    <image-view-component :image="require(`@/assets/images/image-forgot.png`)">
      <template v-slot:form>
        <ValidationObserver
          v-if="!sentEmail"
          tag="div"
          class="form"
          ref="form"
          v-slot="{ passes }"
        >
          <form @submit.prevent="passes(forgot)">
            <h3>Redefinir senha</h3>

            <p>Informe o e-mail utilizado no cadastro, vamos te encaminhar um link para redefinição de senha.</p>

            <input-field
              placeholder="E-mail"
              rules="required|mail"
              name="mail"
              :isDisabled="hasRequest"
              v-model="data.email"
            />

            <button-component
              text="Enviar link"
              size="large"
              styles="primary"
              :hasLoader="hasRequest"
              :isDisabled="hasRequest"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
            />

            <a @click="back">
              <p>Voltar</p>
            </a>
          </form>
        </ValidationObserver>

        <div v-else class="success-container">
          <h3>Pronto!</h3>

          <p>Em alguns minutos você receberá um e-mail com as instruções para redefinir sua senha.</p>

          <a @click="resent">
            <p>Voltar</p>
          </a>
        </div>
      </template>
    </image-view-component>
  </div>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  import ImageView from '@/components/Views/ImageView';
  import InputField from '@/components/InputField';
  import Button from '@/components/Button';

  export default {
    name: 'ForgotView',

    data() {
      return {
        data: {
          email: ''
        },

        hasRequest: false,
        sentEmail: false,
      };
    },

    components: {
      ValidationObserver,
      'image-view-component': ImageView,
      'button-component': Button,
      'input-field': InputField,
    },

    methods: {
      async forgot() {
        this.hasRequest = true;

        await this.$store.dispatch('authModule/putRequestResetPassword', JSON.stringify(this.data))
          .then(res => {
            this.sentEmail = res.data.is_email_sent;

            if (process.env.NODE_ENV !== 'production') console.log('http://localhost:8080/reset?' + res.data.url.split('?')[1]);
          })
          .catch(err => {
            this.sentEmail = err?.data.status != 404;

            if (!this.sentEmail) {
              this.$refs.form.setErrors({
                mail: 'E-mail não encontrado'
              });
            }
          });

        this.hasRequest = false;
      },

      resent() {
        this.sentEmail = false;
      },

      back() {
        this.$router.back();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .forgot-view-container {
    a {
      cursor: pointer;
      margin-top: 2rem;
      color: #0052CC;
      text-decoration: underline;
    }

    .form {
      color: #333333;
      width: 100%;
      max-width: 395px;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;

      h3 {
        text-align: center;
      }

      p {
        text-align: center;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }

    .success-container {
      text-align: center;

      h3 {
        color: #08B978;
      }

      p {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }
</style>