<template>
  <div class="signup-view-container">
    <image-view-component :image="require(`@/assets/images/image-signup.png`)">
      <template v-slot:form>
        <ValidationObserver
          tag="div"
          ref="formSignUp"
          class="form"
          v-slot="{ passes }"
        >
          <form @submit.prevent="passes(signUp)">
            <h3>Olá, vamos iniciar seu cadastro!</h3>

            <input-field
              placeholder="Nome"
              rules="required|min:3"
              :isDisabled="hasRequest"
              v-model="data.name"
            />

            <input-field
              placeholder="CPF"
              name="taxpayer_id"
              rules="required|min:14"
              mask="###.###.###-##"
              :isDisabled="hasRequest"
              v-model="data.taxpayerId"
            />

            <input-field
              placeholder="Telefone"
              name="phone_number"
              rules="required|phone"
              :mask="['(##) ####-####', '(##) #####-####']"
              :isDisabled="hasRequest"
              v-model="data.phoneNumber"
            />

            <input-field
              placeholder="E-mail"
              name="email"
              @keydown.native.space.prevent
              rules="required|mail"
              :isDisabled="hasRequest"
              v-model="data.email"
            />

            <textarea-component
              class="textearea-component"
              placeholder="Franquia(s)"
              type="text"
              name="franchiseeName"
              rules="required"
              :isDisabled="hasRequest"
              v-model="data.franchisee"
              maxHeight="120px"
              minHeight="48px"
              maxWidth="395px"
              minWidth="375px"
            />

            <input-field
              vid="pass"
              type="password"
              name="password"
              placeholder="Senha"
              :passwordValidation="true"
              rules="required|min:6|passwordCombination"
              :isDisabled="hasRequest"
              v-model="data.password"
            />

            <input-field
              placeholder="Confirmar senha"
              type="password"
              name="passwordRepeat"
              rules="required|confirmed:pass"
              :isDisabled="hasRequest"
              v-model="data.passwordRepeat"
            />

            <checkbox-component
              class="checkbox-component"
              rules="checkboxRequired"
              :value="checkboxValue"
            >
              <template v-slot:label>
                <p>
                  Ao continuar, você aceita os <span @click="openTerms">termos de uso</span> e <span @click="openPolicy">políticas de privacidade</span> do {{ $service.getPlatformTitle() }}.
                </p>
              </template>
            </checkbox-component>

            <button-component
              text="Continuar"
              styles="primary"
              size="large"
              :hasLoader="hasRequest"
              :isDisabled="hasRequest"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
            />
          </form>
        </ValidationObserver>
      </template>

      <template v-slot:options>
        <div class="options">
          <div class="line"></div>

          <div class="bottom">
            <p>Já tem uma conta?</p>

            <button-component
              text="Acessar sua conta"
              styles="ghost"
              size="large"
              @click.native="toLogin"
              v-ripple="'rgba(255, 139, 0, 0.2)'"
            />
          </div>
        </div>
      </template>
    </image-view-component>
  </div>
</template>

<script>
  import ImageView from '@/components/Views/ImageView';
  import Button from '@/components/Button';
  import InputField from '@/components/InputField';
  import Checkbox from '@/components/Checkbox';
  import { ValidationObserver } from 'vee-validate';
  import TextArea from '@/components/TextAreaField';

  export default {
    name: 'SignUpView',

    data() {
      return {
        hasRequest: false,
        checkboxValue: false,
        data: {
          name: null,
          taxpayerId: null,
          email: null,
          phoneNumber: null,
          password: null,
          franchisee: null,
          passwordRepeat: null
        }
      };
    },

    components: {
      ValidationObserver,
      'image-view-component': ImageView,
      'checkbox-component': Checkbox,
      'button-component': Button,
      'input-field': InputField,
      'textarea-component': TextArea
    },

    methods: {
      async signUp() {
        var hasError = false;
        let user = JSON.stringify({
          taxpayer_id: this.$service.removeCharacter(this.data.taxpayerId),
          name: this.data.name,
          email: this.data.email,
          phone_number: this.$service.removeCharacter(this.data.phoneNumber),
          description: this.data.franchisee,
          password: this.data.password,
          passwordRepeat: this.data.passwordRepeat
        });

        this.hasRequest = true;

        this.$store.dispatch('logout');

        await this.$store.dispatch('postSignUp', { user })
          .catch(err => {
            hasError = true;
            this.errors = err.data.error;

            Object.keys(this.errors).forEach(item => {
              this.errors[item].forEach(error => {
                let errorObj = {};
                errorObj[item] = error;

                this.$refs.formSignUp.setErrors(errorObj);
              });
            });
          });

        if (!hasError) {
          await this.fetchData();
        }

        this.hasRequest = false;
      },

      async fetchData() {
        // await this.$store.dispatch('getMerchantCategoriesCode')
        //   .catch(() => {
        //     this.logout();
        //   });

        await this.$store.dispatch('getUser')
          .catch(() => {
            this.logout();
          });

        this.$router.push('/accreditation').catch(() => {});
      },

      openPolicy() {
        window.open('https://www.impay.com.br/politicas-de-privacidade/', 'Policy',  'width=750, height=750');
      },

      openTerms() {
        window.open('https://www.impay.com.br/termos-de-uso/', 'Terms',  'width=750, height=750');
      },

      toLogin() {
        this.$router.push('/').catch(() => {});
      },

      logout() {
        this.$store.dispatch('logout');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .signup-view-container {
    .form {
      color: #333;
      width: 100%;
      max-width: 395px;
      font-size: 14px;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;

      h3 {
        margin-bottom: 1rem;
        text-align: center;
      }

      .checkbox-component {
        margin-bottom: 1.5rem;

        p {
          font-weight: 400;

          span {
            cursor: pointer;
            color: #0052CC;

            &:hover {
              color: #03398b;
            }
          }
        }
      }

      .textearea-component {
        width: 100%;
      }
    }

    .options {
      margin-top: 2rem;
      color: #333;
      width: 100%;
      max-width: 395px;

      .line {
        width: 100%;
        height: 1px;
        background: #DADADA;
      }

      .bottom {
        margin-top: 2rem;
        text-align: center;

        p {
          margin-bottom: 1rem;
        }
      }
    }
  }
</style>