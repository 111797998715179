<template>
  <div class="simple-card-container">
    <div
      v-for="(item, index) in data.items"
      :key="index"
      class="simple-card-content"
      :class="{ 'active': item.isActive }"
      @click="applyToggle(index)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SimpleCard',

    data() {
      return {
        currentCard: false
      };
    },

    props: {
      data: {
        type: Object,
        default() {
          return {
            isUnique: true,
            items: [
              {
                label: '1px',
                value: 1,
                isActive: true
              },

              {
                label: '2px',
                value: 2,
                isActive: false
              },

              {
                label: '3px',
                value: 3,
                isActive: false
              }
            ]
          };
        }
      },
    },

    methods: {
      applyToggle(index) {
        if (this.data.isUnique) {
          this.data.items.forEach(item => {
            item.isActive = false;
         });
        }

        // eslint-disable-next-line vue/no-mutating-props
        this.data.items[index].isActive = !this.data.items[index].isActive;

        this.$emit('new');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .simple-card-container {
    display: flex;

    .simple-card-content {
      border: 1px solid #DADADA;
      border-radius: 6px;
      min-height: 30px;
      min-width: 30px;
      display: flex;
      align-items: center;
      margin-right: 1rem;
      text-align: center;
      cursor: pointer;
      justify-content: center;
      padding: 0 0.5rem;
      overflow: hidden;
      white-space: nowrap;

      &.active {
        background: $second-color;
        border: 1px solid $second-color;
        color: $white-color;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>