<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import packageJson from '../package.json';

  export default {
    name: 'App',

    data() {
      return {
        options: {}
      };
    },

    metaInfo() {
      return {
        link: [
          {
            rel: 'icon',
            href: '/favicon.ico'
          }
        ]
      };
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerStatus',
        'getCurrentSellerData',
        'getCurrentSellerId',
        'getSideBarState'
      ])
    },

    watch: {
      getCurrentSellerId() {
        this.options = {};
        this.$store.commit('setNoticeCardData', this.options);
      },

      getCurrentSellerData() {
        this.options = {};
        this.$store.commit('setNoticeCardData', this.options);
        this.setSellerNotice();
      }
    },

    mounted() {
      console.log(
        `${this.$service.getPlatformTitle()} %c(v${packageJson.version}) %c- %c${process.env.NODE_ENV}`,
        'color: yellow;',
        'color: white;',
        'color: red;'
      );

      if (process.env.NODE_ENV === 'production') {
        this.loadClarityScript();
      }

      this.$store.commit('setAlertCardStatus', true);
    },

    methods: {
      loadClarityScript() {
        if (process.env.VUE_APP_CLARITY_ID) {
          const scriptContent = `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);
                t.async=1;
                t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];
                y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${process.env.VUE_APP_CLARITY_ID}");
          `;

          let script = document.createElement('script');
          script.textContent = scriptContent;
          document.head.appendChild(script);
        }
      },

      setSellerNotice() {
        switch (this.$service.toUpperCase(this.getCurrentSellerStatus)) {
          case 'ACTIVE':
            this.options = {};
            break;
          case 'WAITINGDOCUMENTS':
            this.options = {};
            this.options.backgroundColor = '#DFF0F7';
            this.options.textColor = '#1B87B1';
            this.options.iconName = 'icon-information-circle';
            this.options.title = 'Aguardando documentos.';
            this.options.text = 'Por favor, envie a documentação para a análise.';
            this.options.button = {
              action: '/account/informations/documents',
              text: 'Enviar'
            };
            break;
          case 'PENDING':
            this.options = {};
            this.options.backgroundColor = '#D9E9FF';
            this.options.textColor = '#006EFF';
            this.options.iconName = 'icon-information-circle';
            this.options.title = 'Conta pendente.';
            this.options.text = 'Enquanto a conta não for verificada seu acesso poderá ser limitado.';
            break;
          case 'WAITING':
            this.options = {};
            this.options.backgroundColor = '#BA8C03';
            this.options.textColor = '#FCF3D9';
            this.options.iconName = 'icon-warning';
            this.options.title = 'Aguardando análise.';
            this.options.text = 'Enquanto a conta não for verificada seu acesso poderá ser limitado.';
            break;
          default:
            this.options = {};
        }

        this.$store.commit('setNoticeCardData', this.options);
        this.$service.scrollToTop();
      }
    }
  };
</script>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    letter-spacing: 0.2px;
    box-sizing: border-box;
  }

  ::selection {
    color: $white-color;
    background: $second-color;
  }

  html {
    scroll-behavior: smooth;
    -webkit-text-size-adjust: none;
    height: -webkit-fill-available;
  }

  body, input, select, button {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-style: normal;
  }

  body {
    background: $white-color;
    min-width: 320px;
    padding: 0;
    overflow-x: hidden;
    min-height: 100vh;
  }

  button {
    font-size: 17px;
    font-weight: 600;
    line-height: 100%;
  }

  img {
    -webkit-user-drag: none;
  }

  div {
    font-weight: 300;
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
  }

  h4 {
    font-size: 14px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-weight: 300;
    user-select: none;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: #8E8E8E #EFEFEF;
  }

  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #EFEFEF;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #8E8E8E;
    height: 35px;
    width: 10px;
  }
</style>