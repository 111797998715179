var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{ref:"modal",staticClass:"modal",attrs:{"title":" ","showModal":true},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"content"},[_c('h3',[_vm._v("Data")]),_c('div',{staticClass:"field"},[_c('div',{staticClass:"day-container"},[_vm._v(" Últimos: "),_c('simple-card-component',{staticClass:"simple-card-component",attrs:{"data":_vm.lastDateFilter},on:{"new":_vm.setLastDateFilter}})],1),_c('input-date-field',{staticClass:"input-date-field",attrs:{"hasCalendarField":false,"rangeProp":_vm.data.dateRange},nativeOn:{"click":function($event){return _vm.openCalendarField.apply(null, arguments)}}}),(_vm.showCalendarInputField)?_c('calendar-field-component',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeCalendarField),expression:"closeCalendarField"}],staticClass:"calendar-field-component",attrs:{"rangeProp":_vm.data.dateRange},on:{"close":_vm.closeCalendarField}}):_vm._e()],1),_c('div',{staticClass:"line"}),_c('h3',[_vm._v("Valor bruto")]),_c('div',{staticClass:"field"},[_c('input-field',{staticClass:"input-field",attrs:{"placeholder":"Mínimo","name":"min","type":"money","hasCaret":false,"maxLength":24},model:{value:(
            // eslint-disable-next-line
            _vm.data.amountRange.min
          ),callback:function ($$v) {_vm.$set(// eslint-disable-next-line
            _vm.data.amountRange, "min", $$v)},expression:"\n            // eslint-disable-next-line\n            data.amountRange.min\n          "}}),_c('input-field',{staticClass:"input-field",attrs:{"placeholder":"Máximo","name":"max","type":"money","hasCaret":false,"maxLength":24},model:{value:(
            // eslint-disable-next-line
            _vm.data.amountRange.max
          ),callback:function ($$v) {_vm.$set(// eslint-disable-next-line
            _vm.data.amountRange, "max", $$v)},expression:"\n            // eslint-disable-next-line\n            data.amountRange.max\n          "}})],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"footer-slot-container"},[_c('div',{staticClass:"reset-button",on:{"click":_vm.resetModalFilter}},[_c('i',{staticClass:"icon-trash"}),_c('p',[_vm._v(" Limpar ")])]),_c('button-component',{staticClass:"button-component",attrs:{"text":"Filtrar"},nativeOn:{"click":function($event){return _vm.applyFilter.apply(null, arguments)}}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }