const state = () => ({
  partners: [],
  partnerId: 0,

  currentPartners: [],
  currentOwnerName: '',

  cepError: null,
  modalComeBack: false,
  modalUploadFiles: false,
  currentCompanyStep: 0,
  pullRegistrationData: false,
  pullAddressData: false,

  companyData: {
    workspaceId: '',
    businessName: '',
    ein: '',
    businessPhoneNumber: '',
    businessEmail: '',
    businessAddressLine1: '',
    businessAddressNumber: '',
    businessAddressComplement: '',
    businessAddressNeighborhood: '',
    businessAddressCity: '',
    businessAddressState: '',
    businessAddressPostalCode: '',
    businessOpeningDate: '',
    ownerName: '',
    ownerFirstName: '',
    ownerLastName: '',
    ownerTaxPayerId: '',
    ownerBirthDate: '',
    ownerEmail: '',
    ownerPhoneNumber: '',
    ownerAddressLine1: '',
    ownerAddressNumber: '',
    ownerAddressComplement: '',
    ownerAddressNeighborhood: '',
    ownerAddressCity: '',
    ownerAddressPostalCode: '',
    ownerAddressState: '',
    statementDescriptor: '',
    mcc: '',
  },

  companyAccreditationProgress: [
    {
      name: 'Dados do negócio',
      current: true,
      completed: false,
    },

    {
      name: 'Endereço do negócio',
      current: false,
      completed: false,
    },

    {
      name: 'Dados dos sócios',
      current: false,
      completed: false,
    }
  ],
});

const mutations = {
  currentCompanyStep(state, step) {
    state.currentCompanyStep = step;
  },

  setCurrentPartnersOwner(state, data) {
    state.currentPartners = data.partners;
    state.currentOwnerName = data.ownerName;
  },

  addPartner(state) {
    state.partners.push({
      id: state.partnerId,
      partnerName: '',
      partnerTaxpayerId: '',
      partnerBirthDate: '',
      partnerEmail: '',
      partnerPhoneNumber: '',
      partnerAddressLine1: '',
      partnerNeighborhood: '',
      partnerCity: '',
      partnerPostalCode: '',
      partnerAddresState: '',
    });

    state.partnerId++;
  },

  removePartner(state, partner) {
    state.partners.forEach((item, index) => {
      if (partner.id == item.id) {
        state.partners.splice(index, 1);
      }
    });
  },

  resetCompanyAccreditation(state) {
    state.companyData = {},
    state.partners = [],
    state.partnerId = 0,
    state.currentCompanyStep = 0;
    state.pullRegistrationData = false;
    state.pullAddressData = false;
    state.modalUploadFiles = false;
    state.modalComeBack = false;
    state.companyAccreditationProgress = [
      {
        name: 'Dados do negócio',
        current: true,
        completed: false,
      },

      {
        name: 'Endereço do negócio',
        current: false,
        completed: false,
      },

      {
        name: 'Dados dos sócios',
        current: false,
        completed: false,
      }
    ];
  },

  progressCurrent(state, progressData) {
    state.companyAccreditationProgress[progressData.position].current = progressData.status;
  },

  progressCompleted(state, progressData) {
    state.companyAccreditationProgress[progressData.position].completed = progressData.status;
  },

  setPullResgistrationData(state, status) {
    state.pullRegistrationData = status;
  },

  setPullAddressData(state, status) {
    state.pullAddressData = status;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
};