<template>
  <div class="select-period-container">
    <div class="header-container">
      <div class="header-content">
        <p>{{ rangeStr }}</p>

        <div class="icon-container">
          <i class="icon-close" @click="close"/>
        </div>
      </div>
    </div>

    <calendar-component
      :rangeProp="range"
    />
  </div>
</template>

<script>
  import Calendar from '@/components/Calendar/CustomCalendar';

  export default {
    name: 'InputDate',

    data() {
      return {
        rangeStr: '',
        range: {
          start: '',
          end: ''
        }
      };
    },

    props: {
      rangeProp: {
        type: Object,
        default() {
          return {
            start: null,
            end: null
          };
        }
      }
    },

    components: {
      'calendar-component': Calendar
    },

    created() {
      this.range = this.rangeProp;
    },

    watch: {
      'range.start'(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.rangeProp.start = value;
        this.setRange();
      },

      'range.end'(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.rangeProp.end = value;
        this.setRange();
      },

      'rangeProp.end'() {
        this.close();
      }
    },

    methods: {
      close() {
        this.$emit('close');
      },

      setRange() {
        if (!this.$service.isNull(this.rangeProp.start) && !this.$service.isNull(this.rangeProp.end)) {
          this.range = this.rangeProp;
          this.rangeStr = this.$service.dateFormat(this.rangeProp.start, 'YYYY-MM-DD', 'DD/MM/YYYY') + ' - ' + this.$service.dateFormat(this.rangeProp.end, 'YYYY-MM-DD', 'DD/MM/YYYY');
        } else {
          this.rangeStr = 'Nenhum selecionado';
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .select-period-container {
    position: absolute;
    background: $white-color;
    z-index: 1;
    padding: 1rem;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 25%);
    border-radius: 6px;
    min-width: 320px;

    .header-container {
      border-bottom: 1px solid #DADADA;
      margin-bottom: 1rem;

      .header-content {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;

        p {
          color: $second-color;
          font-weight: 600;
          text-align: center;
          width: 100%;
        }

        .icon-container {
          margin-left: auto;
          color: #979797;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }

    .vc-container {
      font-family: 'Poppins', sans-serif;
      border-color: transparent;
      background-color: transparent;
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    .input-date-container {
      .input-date {
        p {
          &.value {
            font-size: 12px;
          }
        }
      }
    }

    .select-period-container {
      padding: 1rem 1rem 5rem 1rem;
      height: 100%;
      overflow: auto;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1000;
    }
  }
</style>