<template>
  <div class="image-view-container">
    <div class="image-container">
      <img :src="image">
    </div>

    <div class="form-container">
      <div class="logo-container">
        <img :src="$service.getPlatformImagesPath('logo.svg')">
      </div>

      <slot name="form" />

      <slot name="options" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ImageView',

    props: {
      image: {
        type: String,
        default: '@/assets/images/image-login.png'
      }
    }
  };
</script>

<style lang="scss" scoped>
  .image-view-container {
    grid-template-columns: 65% 35%;
    display: grid;
    height: 100vh;

    .image-container {
      background: linear-gradient(305.95deg, $first-color 0%, $first-color-dark-3 99.04%);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        width: 60rem;
      }
    }

    .form-container {
      background: $white-color;
      padding: 1.5rem;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;

      .logo-container {
        margin-bottom: 2rem;
      }
    }
  }

  @media (max-width: 1400px) {
    .image-view-container {
      .image-container {
        img {
          width: 55rem;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .image-view-container {
      grid-template-columns: 1fr 100%;

      .form-container {
        border-top: 10px solid $first-color;

        .form {
          width: 60%;
        }

        .options {
          width: 60%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .image-view-container {
      .form-container {
        .form {
          width: 100%;
        }

        .options {
          width: 100%;
        }
      }
    }
  }
</style>