<template>
  <simple-default-view-component class="simple-default-view-component" :hasModal="customerModalState">
    <template v-slot:header>
      <div class="title">
        <h2>Nova venda</h2>
      </div>

      <div class="icon-container">
        <i class="icon-close" @click="close"/>
      </div>
    </template>

    <template v-slot:modal>
      <customer-profile-modal-component
        v-if="customerModalState"
        :hasEditButton="false"
        @finishRequest="getAllCustomers"
        @close="closeCustomerModal"
      />
    </template>

    <template v-slot:content>
      <ValidationObserver
        tag="div"
        class="content"
        ref="form"
        v-slot="{ passes }"
      >
        <form id="form" @submit.prevent="passes(toResume)">
          <div class="column">
            <div class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>Como você quer vender?</h3>

                  <span class="required">*</span>
                </div>
              </div>

              <div class="row">
                <div class="items">
                  <payment-type-card-component
                    class="payment-type-card-component"
                    v-for="(item, index) in paymentMethodData"
                    :key="index"
                    :data="item"
                    @click.native="togglePaymentMethodCard(item)"
                  />
                </div>
              </div>
            </div>

            <div class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>Quem é o cliente?</h3>

                  <span class="required">*</span>
                </div>
              </div>

              <div class="row">
                <select-fetch-field
                  class="select-fetch-field"
                  placeholder="Selecione o cliente"
                  rules="required"
                  :options="customerArr"
                  :hasSearch="true"
                  :hasFetch="true"
                  :hasButton="true"
                  :hasRequest="hasCustomerRequest"
                  @fetch="getCustomerByQuery"
                  @buttonClick="showCustomerModal"
                  v-model="customerId"
                />
              </div>
            </div>

            <div class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>O que você está vendendo?</h3>

                  <span class="required">*</span>
                </div>

                <div class="information ml-auto">
                  Valor total: <span>{{ $service.formatBrlAmountSymbol(getTotalItemsPrice()) }}</span>
                </div>
              </div>

              <div class="row">
                <div v-for="(item, index) in items" :key="index" class="items">
                  <div class="item-content">
                    <input-field
                      placeholder="Descreva o produto/serviço..."
                      class="input-field description"
                      rules="required"
                      v-model="item.description"
                    />

                    <input-currency-field
                      placeholder="Valor"
                      type="money"
                      class="input-field amount"
                      rules="required"
                      v-model="item.price"
                    />
                  </div>

                  <div class="options-container">
                    <i
                      :class="item.isAddItem ? 'icon-add' : 'icon-remove'"
                      @click="item.isAddItem ? addNewItem() : removeItem(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="vertical-line">
            <div class="line"></div>
          </div>

          <div class="column">
            <div v-if="isCheckoutPaymentMethod()" class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>Qual o tipo pagamento?</h3>

                  <span class="required">*</span>
                </div>
              </div>

              <div class="row">
                <div class="items">
                  <payment-type-card-component
                    class="payment-type-card-component"
                    v-for="(item, index) in paymentTypeData"
                    :key="index"
                    :data="item"
                    @click.native="togglePaymentTypeCard(item)"
                  />
                </div>
              </div>
            </div>

            <div v-if="isCheckoutPaymentMethod() && isCreditPaymentEnabled()" class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>Aceita parcelar no cartão?</h3>
                </div>

                <div class="information">
                  Até <span>{{ maxInstallments + 'x ' + $service.formatBrlAmountSymbol(getTotalItemsPrice() / maxInstallments) }}</span>
                </div>
              </div>

              <div class="row">
                <range-field
                  class="range-field"
                  :min="1"
                  :max="12"
                  :initialValue="maxInstallments"
                  v-model="maxInstallments"
                />
              </div>
            </div>

            <div v-if="isCheckoutPaymentMethod() || isSlipPaymentMethod()" class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>Adicionar instruções de cobrança?</h3>
                </div>
              </div>

              <div class="row">
                <div class="item">
                  Cobrar multa de

                  <input-currency-field
                    class="input-field percentage"
                    :min="0"
                    :max="2"
                    type="percent"
                    v-model="lateFeeRate"
                  />
                </div>

                <div class="item">
                  Cobrar juros de

                  <input-currency-field
                    class="input-field percentage"
                    :min="0"
                    :max="1"
                    type="percent"
                    v-model="dailyInterestRate"
                  />

                  ao mês
                </div>

                <div v-if="isSlipPaymentMethod()" class="item">
                  Conceder desconto de

                  <input-currency-field
                    class="input-field amount"
                    :min="0"
                    :max="2"
                    :rules="inputMoneyRules()"
                    type="money"
                    v-model="discount"
                  />

                </div>
              </div>
            </div>

            <div class="section">
              <div class="title">
                <div class="d-flex">
                  <h3>Quando vai vencer?</h3>

                  <span class="required">*</span>
                </div>
              </div>

              <div class="row">
                <input-date-field
                  class="input-field date"
                  type="date"
                  rules="required"
                  :minDate="new Date()"
                  placeholder="Vencimento"
                  v-model="dueDate"
                />
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>

    <template v-slot:footer>
      <div class="footer-container">
        <button-component
          class="button-component"
          text="Próximo"
          form="form"
          :hasLoader="hasRequest"
          :isDisabled="hasRequest"
          v-ripple="'rgba(255, 255, 255, 0.35)'"
        />
      </div>
    </template>
  </simple-default-view-component>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SimpleDefaultView from '@/components/Views/SimpleDefaultView';
  import PaymentTypeCard from '@/components/PaymentTypeCard';
  import Button from '@/components/Button';
  import SelectFetchField from '@/components/SelectFetchField';
  import InputField from '@/components/InputField';
  import InputDateField from '@/components/InputDateField';
  import InputCurrencyField from '@/components/InputCurrencyField';
  import RangeField from '@/components/RangeField';
  import { ValidationObserver } from 'vee-validate';
  import CustomerProfileModal from '@/components/CustomerProfileModal';

  export default {
    name: 'NewSale',

    data() {
      return {
        hasRequest: false,
        hasCustomerRequest: false,
        customerModalState: false,

        customerArr: [],

        paymentMethodData: [
          {
            icon: 'icon-link',
            text: 'Link avulso',
            value: 'checkout',
            isActive: true
          },

          {
            icon: 'icon-barcode',
            text: 'Boleto',
            value: 'slip',
            isActive: false
          }
        ],

        paymentTypeData: this.initialPaymentTypeData(),

        items: [
          {
            description: '',
            price: '',
            isAddItem: true,
          }
        ],

        customerData: {
          firstName: '',
          lastName: '',
          email: '',
          taxpayerId: '',
          addressLine1: '',
          addressCity: '',
          addressNeighborhood: '',
          addressPostalCode: '',
          addressStateCode: ''
        },

        customerId: '',
        maxInstallments: '',
        dueDate: '',
        discount: '',
        dailyInterestRate: '',
        lateFeeRate: '',
      };
    },

    components: {
      ValidationObserver,
      'simple-default-view-component': SimpleDefaultView,
      'payment-type-card-component': PaymentTypeCard,
      'button-component': Button,
      'select-fetch-field': SelectFetchField,
      'input-field': InputField,
      'range-field': RangeField,
      'input-date-field': InputDateField,
      'input-currency-field': InputCurrencyField,
      'customer-profile-modal-component': CustomerProfileModal
    },

    computed: {
      ...mapGetters({
        getCurrentSellerData: 'getCurrentSellerData',
        getProgressStatus: 'newSaleModule/getProgressStatus',
        getSaleData: 'newSaleModule/getData'
      })
    },

    created() {
      if (!this.getCurrentSellerData.is_sale_enabled) {
        this.close();
      } else {
        if (this.getProgressStatus && this.$route.params?.hasNewSale) {
          this.syncData();
        } else {
          this.getAllCustomers();
        }
      }
    },

    methods: {
      close() {
        this.$router.push('/dashboard');

        this.$store.commit('newSaleModule/resetData');
      },

      closeCustomerModal() {
        this.customerModalState = false;
      },

      syncData() {
        this.maxInstallments = this.getSaleData.maxInstallments;
        this.items = this.getSaleData.items;
        this.dueDate = this.getSaleData.dueDate;
        this.dailyInterestRate = this.getSaleData.dailyInterestRate;
        this.lateFeeRate = this.getSaleData.lateFeeRate;
        this.discount = this.getSaleData.discount;
        this.paymentTypeData = this.getSaleData.paymentTypeData;
        this.paymentMethodData = this.getSaleData.paymentMethodData;
        this.customerId = this.getSaleData.selectedCustomer?.id;
        this.customerArr = this.getSaleData.customerArr;
      },

      async getAllCustomers() {
        this.hasCustomerRequest = true;

        await this.$store.dispatch('getAllCustomers')
          .then(res => {
            this.customerArr = [];
            this.customerArr = this.formatSelectArr(res.data);
          })
          .catch(err => {
            console.log(err);
          });

        this.hasCustomerRequest = false;
      },

      async getCustomerByQuery(customerSearch) {
        this.hasCustomerRequest = true;

        await this.$store.dispatch('getAllCustomersFind', customerSearch)
          .then(res => {
            this.customerArr = this.formatSelectArr(res.data);
          })
          .catch(err => {
            console.log(err);
          });

        this.hasCustomerRequest = false;
      },

      formatSelectArr(data) {
        return data.map(item => ({
          isActive: false,
          label: `${item.first_name} ${item.last_name ?? ''}`,
          value: item.id
        }));
      },

      addNewItem() {
        this.items.map(item => item.isAddItem = false);

        this.items.unshift({
          description: '',
          price: '',
          isAddItem: true
        });
      },

      removeItem(index) {
        if (this.items.length > 1) {
          this.items.splice(index, 1);
        }

        this.items[0].isAddItem = true;
      },

      showCustomerModal() {
        this.customerModalState = true;
      },

      togglePaymentMethodCard(item) {
        this.paymentMethodData.map(item => item.isActive = false);

        item.isActive = true;

        this.paymentTypeData = this.initialPaymentTypeData();

        if (this.isSlipPaymentMethod()) {
          this.dailyInterestRate = 1;
          this.lateFeeRate = 2;
        } else {
          this.dailyInterestRate = '';
          this.lateFeeRate = '';
        }
      },

      togglePaymentTypeCard(item) {
        var activeItems = this.getPaymentType();

        if (activeItems.length > 1 || activeItems.length == 1 && activeItems[0].value != item.value) {
          item.isActive = !item.isActive;
        }
      },

      getPaymentMethod() {
        return this.paymentMethodData.find(item => item.isActive);
      },

      isCheckoutPaymentMethod() {
        return this.getPaymentMethod().value == 'checkout';
      },

      isSlipPaymentMethod() {
        return this.getPaymentMethod().value == 'slip';
      },

      getPaymentType() {
        return this.paymentTypeData.filter(item => item.isActive);
      },

      isCreditPaymentEnabled() {
        return this.paymentTypeData.find(item => item.value == 'credit').isActive;
      },

      initialPaymentTypeData() {
        return [
          {
            icon: 'icon-credit-card',
            text: 'Crédito',
            value: 'credit',
            isActive: true
          },

          {
            icon: 'icon-pix-outline',
            text: 'Pix',
            value: 'pix',
            isActive: true
          }
        ];
      },

      inputMoneyRules() {
        return 'maxMoney:' + this.getTotalItemsPrice() + ':' + this.discount;
      },

      getTotalItemsPrice() {
        return this.items.reduce((total, item) => {
          return total + (item.price || 0);
        }, 0);
      },

      getCurrentCustomer() {
        return this.customerArr.find(item => item.value == this.customerId);
      },

      async toResume() {
        this.hasRequest = true;

        var currentCustomer = this.getCurrentCustomer();

        await this.$store.dispatch('getCustomerId', currentCustomer?.value)
          .then(res => {
            this.customerData.firstName = res.data.first_name,
            this.customerData.lastName = res.data.last_name,
            this.customerData.email = res.data.email,
            this.customerData.taxpayerId = res.data.taxpayer_id,
            this.customerData.addressLine1 = res.data.address.line1,
            this.customerData.addressCity = res.data.address.city,
            this.customerData.addressNeighborhood = res.data.address.neighborhood,
            this.customerData.addressPostalCode = res.data.address.postal_code,
            this.customerData.addressStateCode = res.data.address.state_code;

            this.setData();
            this.$store.commit('newSaleModule/setProgressStatus', true);
          })
          .catch(err => {
            console.log(err);
          });

        if (this.getProgressStatus) {
          this.$router.push({
            name: 'new-sale-resume',
            params: {
              hasNewSale: true
            }
          });
        }

        this.hasRequest = false;
      },

      setData() {
        var currentCustomer = this.getCurrentCustomer();

        this.$store.commit('newSaleModule/setData', {
          dueDate: this.dueDate,
          maxInstallments: this.maxInstallments,
          totalAmount: this.getTotalItemsPrice(),
          dailyInterestRate: this.dailyInterestRate,
          lateFeeRate: this.lateFeeRate,
          discount: this.discount,
          paymentType: this.getPaymentMethod().value,
          paymentTypeData: this.paymentTypeData,
          paymentMethodData: this.paymentMethodData,
          items: this.items,
          selectedCustomer: {
            id: currentCustomer?.value,
            name: currentCustomer?.label
          },
          customer: this.customerData,
          customerArr: this.customerArr
        });
      },
    },

    beforeDestroy() {
      this.setData();
    }
  };
</script>

<style lang="scss" scoped>
  .simple-default-view-component {
    .title {
      display: flex;
      align-items: center;

      .d-flex {
        display: flex;

        h2 {
          color: #333333;
        }
      }

      .information {
        font-size: 14px;
        margin-left: 1rem;

        &.ml-auto {
          margin-left: auto;
        }

        span {
          font-weight: 500;
        }
      }
    }

    .content {
      padding: 1.5rem;
      display: flex;
      overflow-y: auto;
      height: calc(100vh - 160px);

      form {
        display: flex;
        width: 100%;

        .column {
          width: 100%;

          .section {
            margin-bottom: 3rem;

            &:last-child {
              margin-bottom: 0;
              padding-bottom: 1rem;
            }

            .title {
              color: #333333;

              .required {
                color: $red-color;
                margin-left: 5px;
              }
            }

            .row {
              margin-top: 1rem;

              .select-fetch-field {
                max-width: 450px;
                width: 100%;
              }

              .input-field {
                &.date {
                  width: 180px;
                }
              }

              .range-field {
                max-width: 500px;
              }

              .item {
                display: flex;
                gap: 10px;
                align-items: center;
                font-size: 14px;
                margin-bottom: 1rem;

                &:last-child {
                  margin-bottom: 0;
                }

                .input-field {
                  &.percentage {
                    width: 75px;
                  }

                  &.amount {
                      width: 100%;
                      max-width: 150px;
                  }
                }
              }

              .items {
                display: flex;
                margin-bottom: 1rem;
                max-width: 675px;
                gap: 1rem;

                .item-content {
                  width: 100%;
                  gap: 1rem;
                  display: flex;

                  .input-field {
                    margin-bottom: 0;

                    &.description {
                      max-width: 480px;
                      width: 100%;
                    }

                    &.amount {
                      min-width: 120px;
                    }
                  }
                }

                .options-container {
                  justify-content: center;
                  align-items: center;
                  display: flex;
                  height: 48px;

                  i {
                    cursor: pointer;

                    &.icon-add {
                      color: $second-color;
                    }

                    &.icon-remove {
                      color: $red-color;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .vertical-line {
        padding: 0 2rem;

        .line {
          width: 1px;
          height: calc(100% - 210px);
          position: absolute;
          background: #EFEFEF;
        }
      }
    }

    .footer-container {
      height: 80px;
      display: flex;
      padding: 1rem;
      align-items: center;
      box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.08);
      width: 100%;
      background: $white-color;
      bottom: 0;
      position: absolute;
      margin: 0;

      .button-component {
        height: 48px;
        width: 180px;
        margin-left: auto;
      }

      .button-back-component {
        height: 48px;
      }
    }
  }

  @media (max-width: 950px) {
    .simple-default-view-component {
      .content {
        form {
          flex-direction: column;

          .vertical-line {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .simple-default-view-component {
      .content {
        form {
          .column {
            .section {
              .title {
                flex-direction: column;
                align-items: unset;

                .information {
                  margin-top: 0.5rem;
                  margin-left: auto;
                }
              }

              .row {
                .item {
                  font-size: 12px;
                }

                .items {
                  align-items: center;

                  .item-content {
                    flex-direction: column;

                    .input-field {
                      &.amount {
                        max-width: 140px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>