<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :name="inputName"
    :rules="rules"
    class="text-area-container"
    v-slot="{ errors }"
  >
    <textarea
      :class="{ 'error': errors[0] }"
      :placeholder="placeholder"
      :style="{ 'max-height': maxHeight, 'min-height': minHeight, 'max-width': maxWidth, 'min-width': minWidth }"
      v-model="innerValue"
    ></textarea>

    <div v-if="!isDisabled" class="errors">
      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>
<script>
  import { ValidationProvider } from 'vee-validate';

  export default {
    name: 'TextAreaField',

    props: {
      placeholder: {
        type: String,
        required: false
      },

      value: {
        type: [String, Number],
        default: ''
      },

      vid: {
        type: String,
        default: undefined
      },

      name: {
        type: String,
        required: false
      },

      rules: {
        type: [Object, String],
        default: ''
      },

      isDisabled: {
        type: Boolean,
        default: false
      },

      maxHeight: {
        type: String,
        required: false
      },

      minHeight: {
        type: String,
        required: false
      },

      maxWidth: {
        type: String,
        required: false
      },

      minWidth: {
        type: String,
        required: false
      }
    },

    components: {
      ValidationProvider,
    },

    data() {
      return {
        innerValue: '',
        inputName: ''
      };
    },

    mounted() {
      if(this.name) this.inputName = this.name;
      else this.inputName = this.placeholder.toLowerCase();
    },

    watch: {
      innerValue(value) {
        this.$emit('input', value);
      },

      value(value) {
        if (value !== this.innerValue) {
          this.innerValue = value;
        }
      }
    },

    created() {
      if (this.value) {
        this.innerValue = this.value;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .text-area-container {
    width: 100%;
    margin-bottom: 1rem;

    textarea {
      font-family: Poppins, sans-serif;
      font-weight: 300;
      border-radius: 6px;
      padding: 1rem;
      color: #333;
      border: 1px solid #DADADA;
      width: 100%;

      &:focus {
        outline: none;
        border-color: $second-color;
        box-shadow: 0 0 0 1px $second-color, 0 0 0 3px $second-color-light-1;
      }

      &.error {
        border-color: $red-color;
        box-shadow: 0 0 0 1px $red-color, 0 0 0 3px $red-color-light;
      }
    }

    .errors {
      span {
        color: $red-color;
        font-size: 12px;
        margin-top: 0.5rem;
        font-weight: 400;
      }
    }
  }
</style>