/* eslint-disable no-empty-pattern */
import { instance } from '../../config/axios';
import store from '../index';

function initialState() {
  return {
    isResetPassword: false
  };
}

const actions = {
  putRequestResetPassword({}, data) {
    return new Promise((resolve, reject) => {
      instance().put('/auth/request-password-reset', data)
        .then(response => {
          let res = response.data;

          resolve(res);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },

  putResetPassword(_, data) {
    return new Promise((resolve, reject) => {
      instance().put('/auth/reset-password', data)
        .then(response => {
          let res = response.data;
          let token = res.data.access_token;
          let workspaceName = res.data.workspaces[0].name;
          let workspaceId = res.data.workspaces[0].id;
          let tokenExpiresIn = res.data.access_token_expires_in + 's';
          let status = true;

          store.dispatch('setAuthorization', { token, workspaceName, workspaceId, tokenExpiresIn, status});

          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  putChangePassword({commit}, data) {
    return new Promise((resolve, reject) => {
      instance().put('/auth/change-password', data)
        .then(res => {
          let resData = res.data;

          commit('setIsResetPassword', resData.data.is_reset_password);

          resolve(resData);
        })
        .catch(err => {
          reject(err.response);
        });
    });
  },
};

const getters = {
  getIsResetPassword(state) {
    return state.isResetPassword;
  }
};

const mutations = {
  setIsResetPassword(state, status) {
    state.isResetPassword = status;
  }
};

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations,
};