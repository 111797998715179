function initialState() {
  return {
    hasProgress: false,
    url: '',
    resumeDescription: '',
    data: {
      dueDate: '',
      maxInstallments: '',
      totalAmount: '',
      dailyInterestRate: '',
      lateFeeRate: '',
      discount: '',
      paymentType: '',
      selectedCustomer: {},
      customerArr: [],
      items: [],
      paymentTypeData: [],
      paymentMethodData: []
    }
  };
}

const mutations = {
  setProgressStatus(state, status) {
    state.hasProgress = status;
  },

  setData(state, data) {
    state.data = data;
  },

  setUrl(state, url) {
    state.url = url;
  },

  setResumeDescription(state, description) {
    state.resumeDescription = description;
  },

  resetData(state) {
    const inital = initialState();

    Object.keys(inital).forEach(key => {
      state[key] = inital[key];
    });
  }
};

const getters = {
  getProgressStatus(state) {
    return state.hasProgress;
  },

  getData(state) {
    return state.data;
  },

  getUrl(state) {
    return state.url;
  },

  getResumeDescription(state) {
    return state.resumeDescription;
  }
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations
};