<template>
  <modal
    ref="modal"
    title="Editar dados"
    :showModal="hasModalOwnerEdit"
    @close="closeModalEdit"
  >
    <template v-slot:body>
      <ValidationObserver
        tag="div"
        class="body"
        ref="form"
        v-slot="{ passes, pristine }"
      >
        <form @input="toggleButtonState(pristine)" @submit.prevent="passes(submitCompanyFormData)">
          <div class="container">
            <div class="section">
              <div class="column">
                <input-field
                  placeholder="CPF"
                  mask="###.###.###-##"
                  rules="required"
                  v-model="companyData.ownerTaxpayerId"
                />
              </div>

              <div class="two-column">
                <input-field
                  placeholder="Nome completo"
                  rules="required"
                  v-model="companyData.ownerCompletedName"
                />

                <input-field
                  class="right"
                  placeholder="Data de nascimento"
                  mask="##/##/####"
                  rules="required"
                  v-model="companyData.ownerBirthDate"
                />
              </div>

              <div class="two-column">
                <input-field
                  placeholder="E-mail"
                  rules="required|mail"
                  v-model="companyData.ownerEmail"
                />

                <input-field
                  class="right"
                  placeholder="Telefone"
                  name="phone"
                   :mask="['(##) ####-####', '(##) #####-####']"
                  rules="required"
                  v-model="companyData.ownerPhoneNumber"
                />
              </div>
            </div>

            <div class="button-container">
              <button-component
                scale="normal"
                size="large"
                v-ripple="'rgba(255, 139, 0, 0.2)'"
                styles="ghost"
                type="button"
                @click.native="closeModalEdit"
                text="Fechar"
              />

              <button-component
                class="right"
                scale="normal"
                size="large"
                v-ripple="'rgba(255, 255, 255, 0.35)'"
                styles="primary"
                :isDisabled="isButtonDisable"
                :hasLoader="hasRequest"
                text="Alterar e salvar"
              />
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
  import { ValidationObserver } from 'vee-validate';
  import { mapState } from 'vuex';
  import Modal from '@/components/Modal';
  import Button from '@/components/Button';
  import InputField from '@/components/InputField';

  export default {
    name: 'ModalEditAutonomousSeller',

    data() {
      return {
        hasRequest: false,
        isButtonDisable: true,
        companyData: {
          ownerCompletedName: '',
          ownerFirstData: '',
          ownerLastData: '',
          ownerTaxpayerId: '',
          ownerBirthDate: '',
          ownerEmail: '',
          ownerPhoneNumber: '',
          businessAddressLine: '',
          businessAddressNumber: '',
          businessAddressComplement: '',
          businessAddressNeighborhood: '',
          businessAddressCity: '',
          businessAddressState: '',
          businessAddressPostalCode: '',
          ownerAddressLine: '',
          ownerAddressNumber: '',
          ownerAddressComplement: '',
          ownerAddressNeighborhood: '',
          ownerAddressCity: '',
          ownerAddressState: '',
          ownerAddressPostalCode: '',
        },
      };
    },

    components: {
      ValidationObserver,
      'button-component': Button,
      'input-field': InputField,
      'modal': Modal
    },

    computed: {
      ...mapState({
        hasModalOwnerEdit: state => state.hasModalOwnerEdit,
        sellerData: state => state.sellerData,
      })
    },

    watch: {
      sellerData() {
        this.setDefaultCompanyValues();
      },

      hasModalOwnerEdit() {
        this.setDefaultCompanyValues();
      }
    },

    mounted() {
      this.setDefaultCompanyValues();
    },

    methods: {
      submitCompanyFormData() {
        this.hasRequest = true;

        let formData = JSON.stringify({
          owner_first_name: this.$service.splitFirstName(this.companyData.ownerCompletedName),
          owner_last_name: this.$service.splitLastName(this.companyData.ownerCompletedName),
          owner_phone_number: this.$service.removeCharacter(this.companyData.ownerPhoneNumber),
          owner_taxpayer_id: this.$service.removeCharacter(this.companyData.ownerTaxpayerId),
          owner_birthdate: this.$service.dateFormat(this.companyData.ownerBirthDate, 'DD/MM/YYYY', 'YYYY-MM-DD'),
          owner_email: this.companyData.ownerEmail,
          business_address_line1: this.companyData.businessAddressLine,
          business_address_line2: this.companyData.businessAddressNumber,
          business_address_line3: this.companyData.businessAddressComplement,
          business_address_neighborhood: this.companyData.businessAddressNeighborhood,
          business_address_city: this.companyData.businessAddressCity,
          business_address_state: this.companyData.businessAddressState,
          business_address_postal_code: this.$service.removeCharacter(this.companyData.businessAddressPostalCode),
          owner_address_line1: this.companyData.ownerAddressLine,
          owner_address_line2: this.companyData.ownerAddressNumber,
          owner_address_line3: this.companyData.ownerAddressComplement,
          owner_address_neighborhood: this.companyData.ownerAddressNeighborhood,
          owner_address_city: this.companyData.ownerAddressCity,
          owner_address_state: this.companyData.ownerAddressState,
          owner_address_postal_code: this.$service.removeCharacter(this.companyData.ownerAddressPostalCode),
        });

        this.$store.dispatch('putCompanySeller', { formData })
          .then(() => {
            this.$store.dispatch('fetchSellerData')
              .then(() => {
                this.setSuccessAlertCard();
                this.$store.commit('setAlertCardStatus', true);
              })
              .catch(() => {
                this.setUpdateErrorAlertCard();
                this.$store.commit('setAlertCardStatus', true);
              })
              .finally(() => {
                this.closeModalEdit();
                this.hasRequest = false;
              });
          })
          .catch(() => {
            this.closeModalEdit();
            this.hasRequest = false;
            this.$store.commit('setAlertCardStatus', true);
            this.setErrorAlertCard();
          });

        this.isButtonDisable = true;
      },

      setDefaultCompanyValues() {
        this.companyData.ownerCompletedName = this.sellerData.owner_first_name + ' ' + this.sellerData.owner_last_name;
        this.companyData.ownerTaxpayerId = this.sellerData.owner_taxpayer_id;
        this.companyData.ownerBirthDate = this.$service.dateFormat(this.sellerData.owner_birthdate, 'YYYY-MM-DD', 'DD/MM/YYYY');
        this.companyData.ownerEmail = this.sellerData.owner_email;
        this.companyData.ownerPhoneNumber = this.sellerData.owner_phone_number;

        this.companyData.ownerAddressLine = this.sellerData.owner_address_line1;
        this.companyData.ownerAddressNumber = this.sellerData.owner_address_line2;
        this.companyData.ownerAddressComplement = this.sellerData.owner_address_line3;
        this.companyData.ownerAddressNeighborhood = this.sellerData.owner_address_neighborhood;
        this.companyData.ownerAddressCity = this.sellerData.owner_address_city;
        this.companyData.ownerAddressState = this.sellerData.owner_address_state;
        this.companyData.ownerAddressPostalCode = this.sellerData.owner_address_postal_code;

        this.companyData.businessAddressLine = this.sellerData.business_address_line1;
        this.companyData.businessAddressNumber = this.sellerData.business_address_line2;
        this.companyData.businessAddressComplement = this.sellerData.business_address_line3;
        this.companyData.businessAddressNeighborhood = this.sellerData.business_address_neighborhood;
        this.companyData.businessAddressCity = this.sellerData.business_address_city;
        this.companyData.businessAddressState = this.sellerData.business_address_state;
        this.companyData.businessAddressPostalCode = this.sellerData.business_address_postal_code;
      },

      closeModalEdit() {
        this.$store.commit('closeModalEdit');
      },

      setSuccessAlertCard() {
        this.$store.commit('setAlertCardData', {
          text: 'Dados editados com sucesso.',
          color: '#08B978'
        });
      },

      setErrorAlertCard() {
        this.$store.commit('setAlertCardData', {
          text: 'Erro ao editar os dados cadastrais.',
          color: '#FF5A5A'
        });
      },

      setUpdateErrorAlertCard() {
        this.$store.commit('setAlertCardData', {
          text: 'Erro ao atualizar os dados.',
          color: '#FF5A5A'
        });
      },

      toggleButtonState(pristine) {
        this.isButtonDisable = pristine;

        if (this.hasRequest) {
          this.isButtonDisable = true;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .modal {
    .body {
      .container {
        margin-top: 1.5rem;
        .section {
          .two-column {
            display: flex;

            .right {
              margin-left: 1rem;
              max-width: 225px;
            }
          }

          .column {
            max-width: 305px;
          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;

      .right {
        margin-left: 1rem;
        min-width: 156px;
      }
    }
  }

  @media (max-width: 767px) {
    .modal {
      h2 {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 520px) {
    .modal {
      .body {
        .container {
          overflow-y: scroll;
          max-height: 72vh;
          margin-bottom: 4rem;

          &::-webkit-scrollbar {
            display: none;
          }

          .section {
            .two-column {
              flex-direction: column;

              .right {
                margin-left: 0;
                max-width: initial;
              }
            }

            .column {
              width: 100%;
              max-width: unset;
            }
          }

          .button-container {
            justify-content: center;
          }
        }
      }
    }
  }
</style>