<template>
  <div
    class="card"
    :style="{
      'background': formatObjStatus(status).background,
      'color': formatObjStatus(status).color,
      'border-color': formatObjStatus(status).borderColor
    }"
  >
    {{ formatObjStatus(status).label }}
  </div>
</template>

<script>
  export default {
    name: 'TransactionStatus',

    props: {
      status: {
        type: String,
        default: ''
      },

      customStatus: {
        type: Object,
        default() {
          return {
            label: 'Custom',
            background: '#FFFFFF',
            color: '#333333',
            borderColor: '#DADADA'
          };
        }
      }
    },

    methods: {
      formatObjStatus(status) {
        let formattedObjStatus = {
          background: '',
          color: '',
          label: ''
        };

        switch (this.$service.toUpperCase(status)) {
          case 'PAID':
            formattedObjStatus.background = '#08B9781C';
            formattedObjStatus.color = '#08B978';
            formattedObjStatus.label = 'Pago';
            break;
          case 'ACTIVE':
            formattedObjStatus.background = '#08B9781C';
            formattedObjStatus.color = '#08B978';
            formattedObjStatus.label = 'Ativa';
            break;
          case 'SUCCEEDED':
            formattedObjStatus.background = '#08B9781C';
            formattedObjStatus.color = '#08B978';
            formattedObjStatus.label = 'Aprovado';
            break;
          case 'PENDING':
            formattedObjStatus.background = '#BA8C031C';
            formattedObjStatus.color = '#BA8C03';
            formattedObjStatus.label = 'Pendente';
            break;
          case 'PAUSED':
            formattedObjStatus.background = '#BA8C031C';
            formattedObjStatus.color = '#BA8C03';
            formattedObjStatus.label = 'Pausada';
            break;
          case 'SCHEDULED':
            formattedObjStatus.background = '#FF8B001C';
            formattedObjStatus.color = '#FF8B00';
            formattedObjStatus.label = 'Programada';
            break;
          case 'RETRY':
            formattedObjStatus.background = '#FF8B001C';
            formattedObjStatus.color = '#FF8B00';
            formattedObjStatus.label = 'Retentativa';
            break;
          case 'CANCELED':
            formattedObjStatus.background = '#FF5A5A1C';
            formattedObjStatus.color = '#FF5A5A';
            formattedObjStatus.label = 'Cancelado';
            break;
          case 'DELETED':
            formattedObjStatus.background = '#FF5A5A1C';
            formattedObjStatus.color = '#FF5A5A';
            formattedObjStatus.label = 'Deletado';
            break;
          case 'FAILED':
            formattedObjStatus.background = '#FF5A5A1C';
            formattedObjStatus.color = '#FF5A5A';
            formattedObjStatus.label = 'Falhado';
            break;
          case 'REFUNDED':
            formattedObjStatus.background = '#FF5A5A1C';
            formattedObjStatus.color = '#FF5A5A';
            formattedObjStatus.label = 'Estornada';
            break;
          case 'NEW':
            formattedObjStatus.background = '#DFF0F7';
            formattedObjStatus.color = '#1B87B1';
            formattedObjStatus.label = 'Novo';
            break;
          case 'REVERSED':
            formattedObjStatus.background = '#3333331C';
            formattedObjStatus.color = '#333333';
            formattedObjStatus.label = 'Revertida';
            break;
          case 'EXPIRED':
            formattedObjStatus.background = '#3333331C';
            formattedObjStatus.color = '#333333';
            formattedObjStatus.label = 'Expirado';
            break;
          case 'SUSPENDED':
            formattedObjStatus.background = '#3333331C';
            formattedObjStatus.color = '#333333';
            formattedObjStatus.label = 'Suspensa';
            break;
          case 'DISABLED':
            formattedObjStatus.background = '#3333331C';
            formattedObjStatus.color = '#333333';
            formattedObjStatus.label = 'Desabilitada';
            break;
          case 'CUSTOM':
            formattedObjStatus.borderColor = this.customStatus.borderColor;
            formattedObjStatus.background = this.customStatus.background;
            formattedObjStatus.color = this.customStatus.color;
            formattedObjStatus.label = this.customStatus.label;
            break;
          default:
            formattedObjStatus.background = '#3333331C';
            formattedObjStatus.color = '#333333';
            formattedObjStatus.label = 'Em análise';
        }

        return formattedObjStatus;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card {
    font-weight: 500;
    border-radius: 6px;
    padding: 0.2rem 0.7rem;
    width: fit-content;
    font-size: 14px;
    border: 1px solid transparent;
  }

  @media (max-width: 520px) {
    .card {
      font-size: 12px;
    }
  }
</style>