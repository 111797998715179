<template>
  <div class="dashboard-view-container">
    <default-view-component :hasModal="withdrawModal || depositModal">
      <template v-slot:alert>
        <alert-component
          v-if="getAlertCardStatus"
          :data="getCurrentAlertCardData"
        />
      </template>

      <template v-slot:modal>
        <withdraw-modal-component
          v-if="withdrawModal"
          @close="closeWithdrawModal"
        />

        <deposit-modal-component
          v-if="depositModal"
          @close="closeDepositModal"
        />
      </template>

      <template v-slot:content>
        <div class="section" :class="{ 'hide-sidebar': getSideBarState.hideSideBar }">
          <div class="image-container">
            <div class="image-background">
              <div class="image" :style="{ 'background-image': 'url(' + $service.getPlatformImagesPath('dashboard-banner.png') + ')' }"></div>
            </div>
          </div>

          <div class="dashboard-header">
            <button-component
              class="button-component"
              scale="normal"
              text="Nova venda"
              :isDisabled="currentBalanceRequest || !isSellerActive() || !getCurrentSellerData.is_sale_enabled"
              pathView="/sales/new"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
            />

            <div class="cards-container">
              <div class="cards-content">
                <dashboard-button-action-component
                  class="dashboard-button-action-component"
                  v-for="(item, index) in buttonsData"
                  :key="index" :data="item"
                  :isDisabled="currentBalanceRequest || !isSellerActive() || item.isDisabled"
                  @click.native="showActionModal(item.value)"
                />
              </div>

              <div class="balance-card">
                <div class="content">
                  <h3>Saldo <br> disponível</h3>

                  <div class="value">
                    <default-data
                      v-if="currentBalanceRequest"
                      :hasDashboard="true"
                      class="default"
                    />

                    <div
                      v-else
                      :class="{ 'blur': getDashboardBalanceValueState }"
                    >
                      <h2 v-if="getDashboardBalanceValueState">R$ *****</h2>

                      <h2 v-else>{{ $service.formatBrlAmountSymbol(getCurrentSellerData.is_banking ? getAccountBalance: getSubBalance) }}</h2>
                    </div>

                    <i :class="getDashboardBalanceValueState ? 'icon-eye' : 'icon-eye-slash'" @click="toggleBalanceStatus"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="transactions-card-container">
            <transaction-card-component
              class="card-component"
              :data="salesCard"
              :hasRequest="hasTransactionRequest"
            />

            <transaction-card-component
              class="card-component"
              :data="receivablesCard"
              :hasRequest="hasReceivableRequest"
            />
          </div>
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/Views/DefaultView';
  import DefaultData from '@/components/DefaultData';
  import TransactionCard from '@/components/TransactionCard';
  import DashboardButtonAction from '@/components/DashboardButtonAction';
  import WithdrawModal from '@/components/WithdrawModal';
  import DepositModal from '@/components/DepositModal';
  import Button from '@/components/Button';
  import AlertCard from '@/components/AlertCard';

  export default {
    name: 'DashboardView',

    data() {
      return {
        hasReceivableRequest: false,
        hasTransactionRequest: false,
        hasCurrentSellerRequest: false,
        currentBalanceRequest: true,

        withdrawModal: false,
        depositModal: false,
        accountBalance: '',
        currentDate: '',
        salesCard: this.initialSaleCardData(),
        receivablesCard: this.initialReceivablesCard(),
        buttonsData: [
          {
            icon: 'icon-bank-dollar',
            label: 'Adicionar conta',
            value: 'new_bank_account',
            isDisabled: false
          },

          {
            icon: 'icon-deposit',
            label: 'Depositar dinheiro',
            // value: 'deposit',
            isDisabled: true
          },

          {
            icon: 'icon-pay',
            label: 'Sacar dinheiro',
            value: 'withdraw'
          }
        ]
      };
    },

    components: {
      'default-data': DefaultData,
      'button-component': Button,
      'transaction-card-component': TransactionCard,
      'default-view-component': DefaultView,
      'withdraw-modal-component': WithdrawModal,
      'dashboard-button-action-component': DashboardButtonAction,
      'deposit-modal-component': DepositModal,
      'alert-component': AlertCard,
    },

    computed: {
      ...mapGetters([
        'getNoticeCardData',
        'getCurrentSellerData',
        'getCurrentSellerId',
        'getDashboardBalanceValueState',
        'getAccountBalance',
        'getSubBalance',
        'getSideBarState',
        'getCurrentSellerStatus',
        'getAlertCardStatus',
        'getCurrentAlertCardData',
        'getUserData'
      ])
    },

    watch: {
      getCurrentSellerId() {
        this.$store.commit('bankAccountModule/resetBankAccountData');

        this.currentBalanceRequest = true;
        this.salesCard = this.initialSaleCardData();
        this.receivablesCard = this.initialReceivablesCard();

        this.fetchData();
      }
    },

    created() {
      this.fetchCurrentSellerData();
      this.fetchData();
    },

    methods: {
      showActionModal(value) {
        if (!this.hasRequestOrSellerNotActive() && !this.$service.isUndefined(value)) {
          switch (this.$service.toUpperCase(value)) {
            case 'WITHDRAW':
              this.showWithdrawModal();
              break;
            case 'DEPOSIT':
              this.showDepositModal();
              break;
            case 'NEW_BANK_ACCOUNT':
              this.$router.push('/bank');
              break;
          }
        }
      },

      showDepositModal() {
        this.depositModal = true;
      },

      closeDepositModal() {
        this.depositModal = false;
      },

      showWithdrawModal() {
        this.withdrawModal = true;
      },

      closeWithdrawModal() {
        this.withdrawModal = false;
      },

      hasRequestOrSellerNotActive() {
        return this.hasReceivableRequest || this.hasTransactionRequest || !this.isSellerActive();
      },

      async toggleBalanceStatus() {
        if (this.getDashboardBalanceValueState) {
          await this.fetchBalance();
        }

        this.$store.commit('toggleDashboardBalanceValueState');
      },

      isSellerActive() {
        return this.$service.toUpperCase(this.getCurrentSellerStatus) == 'ACTIVE';
      },

      fetchData() {
        this.fetchTransactionsData();
        this.fetchReceivableData();
        this.fetchBalance();
      },

      fetchCurrentSellerData() {
        this.$store.dispatch('getSellerId');
      },

      async fetchBalance() {
        this.currentBalanceRequest = true;

        await this.$store.dispatch('getSellerBalances');

        this.currentBalanceRequest = false;
      },

      async fetchReceivableData() {
        let startDate = this.$service.currentDateEN('YYYY-MM-DD');
        let endDate = this.$service.getDateDay(startDate, 5);
        let queryParams = [
          {
            query: 'grouped',
            param: '1'
          },

          {
            query: 'expected_on_start',
            param: this.$service.getDateDay(startDate, -1)
          },

          {
            query: 'expected_on_end',
            param: endDate
          },

          {
            query: 'status',
            param: 'pending'
          }
        ];

        this.hasReceivableRequest = true;

        await this.$store.dispatch('receivableModule/getReceivables', this.$service.formatQueryParam(queryParams))
          .then(res => {
            this.receivablesCard.body.items = [];

            for (var i = 0; i < 5; i++) {
              let currentDate = this.$service.getDateDay(startDate, i);
              let currentResponse = res.data[currentDate];
              let hasCurrentResponse = !this.$service.isUndefined(currentResponse);
              let itemsArr = [];

              if (hasCurrentResponse) {
                currentResponse.items.forEach(item => {
                  itemsArr.push({
                    text: 'Criado em ' + this.$service.dateFormat(item.created_at, 'YYYY-MM-DD', 'DD/MM/YY'),
                    amount: this.$service.formatBrlAmountSymbol(item.amount)
                  });
                });
              }

              this.receivablesCard.body.items.push({
                isActive: false,
                textDate: this.$service.dateFormat(currentDate, 'YYYY-MM-DD', 'DD/MM'),
                date: currentDate,
                totalAmount: hasCurrentResponse ? this.$service.formatBrlAmountSymbol(currentResponse.total_amount) : this.$service.formatBrlAmountSymbol(0),
                items: itemsArr
              });

              this.receivablesCard.body.items[0].isActive = true;
            }
          })
          .catch(err => {
            console.log(err);
          });

        this.hasReceivableRequest = false;
      },

      async fetchTransactionsData() {
        let startDate = this.$service.currentDateEN('YYYY-MM-DD');
        let endDate = this.$service.getDateDay(startDate, 1);
        let queryParams = [
          {
            query: 'date_range_start',
            param: startDate
          },

          {
            query: 'date_range_end',
            param: endDate
          },

          {
            query: 'limit',
            param: 10
          }
        ];

        this.hasTransactionRequest = true;

        await this.$store.dispatch('getTransactions', this.$service.formatQueryParam(queryParams))
          .then(res => {
            let resData = res.data;
            let lastTransactionsArr = resData.reverse().slice(0, 5);

            this.salesCard.body.header = [
              {
                label: 'Aprovados',
                amount: this.$service.formatBrlAmountSymbol(this.sumAmount(resData, 'succeeded'))
              },
              {
                label: 'Cancelados',
                amount: this.$service.formatBrlAmountSymbol(this.sumAmount(resData, 'canceled'))
              },
              {
                label: 'Pendentes',
                amount: this.$service.formatBrlAmountSymbol(this.sumAmount(resData, 'pending'))
              }
            ];

            lastTransactionsArr.forEach(item => {
              this.salesCard.body.items.push({
                status: item.status,
                amount: this.$service.formatBrlAmountSymbol(item.amount),
                hour: this.$service.dateFormat(item.created_at, 'YYYY-MM-DD HH:mm:ss', 'HH:mm')
              });
            });
          })
          .catch(err => {
            console.log(err);
          });

          this.hasTransactionRequest = false;
      },

      sumAmount(arr, status) {
        return arr.reduce((total, item) =>
          this.$service.toUpperCase(item.status) == this.$service.toUpperCase(status) ? total + parseFloat(item.amount) : total, 0
        );
      },

      initialSaleCardData() {
        return {
          isSalesCard: true,

          header: {
            fistTitle: 'Vendas do dia',
            secondTitle: this.$service.currentDateEN('DD/MM')
          },

          body: {
            header: [],
            items: [],

            button: {
              path: 'sales',
              text: 'Ver todas as vendas'
            }
          },

          empty: {
            icon: 'icon-sale',
            text: 'Nenhuma venda hoje'
          }
        };
      },

      initialReceivablesCard() {
        return {
          isReceivablesCard: true,

          header: {
            fistTitle: 'Recebimentos previstos'
          },

          body: {
            items: [],
            button: {
              path: 'receivables',
              text: 'Ver todos os recebimentos'
            }
          },

          empty: {
            icon: 'icon-sale',
            text: 'Nenhuma venda hoje'
          }
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .dashboard-view-container {
    .section {
      .image-container {
        background: rgba(67, 89, 112, 0.6);

        .image-background {
          background: linear-gradient(273.78deg, $first-color 0%, $first-color-dark-3 92.25%);
          opacity: 0.8;
          width: 100%;

          .image {
            width: 100%;
            opacity: 0.5;
            height: 120px;
            background-size: cover;
          }
        }
      }

      .dashboard-header {
        margin-top: -6.5rem;

        .button-component {
          z-index: 2;
          margin-left: auto;
          margin-right: 1.5rem;
          margin-bottom: 1rem;
          height: 40px;
        }

        .cards-container {
          z-index: 1;
          display: flex;
          justify-content: center;
          user-select: none;
          margin-left: 1.5rem;
          margin-right: 1.5rem;

          .cards-content {
            z-index: 1;
            display: flex;
          }

          .balance-card {
            background: $white-color;
            z-index: 1;
            height: 92px;
            border-radius: 6px;
            border: 1px solid #EFEFEF;
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 1rem;
            width: 385px;

            .content {
              display: flex;
              align-items: center;

              .value {
                display: flex;
                align-items: center;
                margin-left: auto;

                .default {
                  height: 30px;
                  width: 150px;
                  margin-right: 1rem;
                }
              }

              h3 {
                user-select: none;
                color: #333333;
              }

              h2 {
                color: $first-color;
                margin-left: 3rem;
                margin-right: 1rem;
              }

              i {
                font-size: 20px;
                color: #8E8E8E;
                cursor: pointer;
              }

              .blur {
                -webkit-filter: blur(10px);
                filter: blur(10px);
              }
            }
          }
        }
      }

      .transactions-card-container {
        display: flex;
        justify-content: center;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;

        .card-component {
          width: 480px;
          margin-top: 1.5rem;

          &:first-child {
            margin-right: 1.5rem;
          }
        }
      }
    }
  }


  @media (max-width: 1270px) {
    .dashboard-view-container {
      .section {
        .image-container {
          .image-background {
            .image {
              height: 145px;
            }
          }
        }

        .dashboard-header {
          margin-top: -4rem;

          .button-component {
            top: -3.7rem;
          }

          .cards-container {
            flex-direction: column-reverse;
            margin-top: -8.5rem;

            .dashboard-button-action-component:last-child {
              margin-right: 0;
            }

            .balance-card {
              padding: 0;
              width: auto;
              background: transparent;
              border: none;

              .content {
                flex-direction: column;
                align-items: unset;

                h3 {
                  color: $white-color;

                  br {
                    display: none;
                  }
                }

                .value {
                  margin-left: 0;

                  h2 {
                    color: $white-color;
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }

        .transactions-card-container {
          flex-direction: column;
          align-items: center;
          display: flex;

          .card-component {
            width: 600px;

            &:first-child {
              margin-right: 0
            }
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .dashboard-view-container {
      .section {
        .transactions-card-container {
          .card-component {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    .dashboard-view-container {
      .section {
        .dashboard-header {
          .button-component {
            margin-right: 1rem;
          }

          .cards-container {
            margin-left: 1rem;
            margin-right: 1rem;

            .cards-content {
              .card {
                width: 100px;

                i {
                  width: 30px;
                  height: 30px;
                  font-size: 16px;

                  &.icon-deposit:before {
                    margin-top: 4px;
                  }

                  &.icon-pay:before {
                    margin-top: 4px;
                  }
                }

                p {
                  font-size: 12px;

                  br {
                    display: flex;
                  }
                }
              }
            }
          }
        }

        .transactions-card-container {
          margin-left: 1rem;
          margin-right: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .dashboard-view-container {
      .section {
        .dashboard-header {
          .button-component {
            height: 35px;
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    .dashboard-view-container {
      .section {
        .dashboard-header {
          .cards-container {
            .cards-content {
              justify-content: center;
            }

            .balance-card {
              .content {
                .value {
                  .default {
                    width: 115px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>