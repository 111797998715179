<template>
  <div class="navbar-container">
    <div class="navbar" :class="{ 'select': hasSelect }">
      <div v-if="!isAccreditation" class="icon-sidebar-container">
        <i class="icon-sidebar-open"
          @click="toggleSideBarStatus"
        />
      </div>

      <div v-if="hasLogo" class="logo-container">
        <img :src="$service.getPlatformImagesPath('logo.svg')">
      </div>

      <select-field
        v-if="hasSelect"
        class="select-field"
        placeholder="Negócio"
        :options="sellers"
        :optionId="currentSellerId"
      />

      <div class="avatar-container" @click="open = !open">
        <div class="avatar">
          {{ workspaceData.name }}
        </div>

        <i v-if="open" class="icon-keyboard-arrow-up"/>
        <i v-else class="icon-keyboard-arrow-down"/>
      </div>
    </div>

    <div v-if="open" class="dropdown-container" v-click-outside="clickOutside">
      <div class="dropdown">
        <div :class="showOptions ? 'spacing' : 'empty'">
          <router-link
            v-if="showOptions"
            to="/account"
          >
            <div :class="currentView == 'account' ? 'option selected' : 'option'">
              <p class="account">Conta</p>
            </div>
          </router-link>

          <div class="option" :class="{ 'empty': !showOptions }" @click="logout">
            <p>Sair</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import SelectField from '@/components/SelectSellerField';

  export default {
    name: 'CustomHeader',

    props: {
      hasNotification: {
        type: Boolean,
        default: false,
      },

      tabindex: {
        type: Number,
        required: false,
        default: 0,
      },

      hasLogo: {
        type: Boolean,
        default: false,
      },

      hasSelect: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        open: false,
        workspace: {},
        currentView: '',
        isAccreditation: false,
        isAccreditationStep: '',
        showOptions: false
      };
    },

    components: {
      'select-field': SelectField,
    },

    computed: {
      ...mapState({
        workspaceData: state => state.workspaceData,
        currentSellerId: state => state.currentSellerId,
        sellers: state => state.sellers,
        sidebar: state => state.sidebar
      }),
    },

    watch: {
      sellers() {
        this.showOptions = this.hasSellers();
      }
    },

    mounted() {
      this.currentView = this.$router.history.current.name;

      this.isAccreditation = this.isAccreditationView();

      this.showOptions = this.hasSellers();
    },

    methods: {
      toggleSideBarStatus() {
        this.sidebar.hideSideBar = !this.sidebar.hideSideBar;
      },

      clickOutside() {
        this.open = false;
      },

      hasSellers() {
        return this.sellers.length > 0;
      },

      isAccreditationView() {
        let splitCurrentView = this.currentView.split('-')[0];

        return splitCurrentView == 'accreditation';
      },

      logout() {
        this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/');
          });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .navbar-container {
    max-height: 80px;

    .navbar {
      border-bottom: 1px solid #EFEFEF;
      background: #F9F9F9;
      display: flex;
      align-items: center;
      padding: 1.5rem;

      .icon-sidebar-container {
        display: none;
      }

      &.select {
        padding: 1rem 1.5rem;
      }

      .select-field {
        width: 15rem;
        z-index: 3;
      }

      .logo-container {
        display: flex;

        img {
          width: 115.65px;
          height: 35px;
        }
      }

      .avatar-container {
        margin-left: auto;
        align-items: center;
        display: flex;
        cursor: pointer;

        .avatar {
          height: 2rem;
          width: 2rem;
          font-size: 14px;
          border-radius: 2rem;
          margin-right: 0.5rem;
          color: $white-color;
          background: $first-color;
          border: 1px solid #f9f9f9;
          box-shadow: 0px 0px 0px 1px $second-color;

          justify-content: center;
          align-items: center;
          display: flex;
        }

        i {
          font-size: 0.6rem;
          color: #979797;
        }
      }
    }

    .dropdown-container {
      position: relative;
      bottom: 0.7rem;
      z-index: 100;

      .dropdown {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        background: $white-color;
        border-radius: 6px;
        margin-left: auto;
        cursor: pointer;
        width: 180px;
        right: 1.5rem;
        position: absolute;

        a {
          text-decoration: none;
        }

        .spacing {
          padding: 10px 0 0 0;
        }

        .empty {
          padding: 0;
        }

        .option {
          color: #333;
          display: flex;

          p {
            padding: 12px 0;
            margin-left: 16px;
            display: flex;

            &.account {
              font-weight: 500;
            }
          }

          .notification {
            font-size: 0.7rem;
            margin-top: 10px;
            color: #FF5A5A;
          }

          &:hover {
            background: #F6F7F7;
          }

          &:last-child:hover {
            border-radius: 0 0 6px 6px;
          }

          &.selected {
            background: $second-color;
            color: $white-color;

            i {
              color: $white-color;
            }
          }
        }

        .line {
          border-bottom: 2px solid #DADADA;
        }
      }
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.2s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
  }

  @media (max-width: 767px) {
    .navbar-container {
      .navbar {
        padding: 10px;

        .logo-container {
          img {
            width: 95px;
          }
        }

        .avatar-container {
          i {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .navbar-container {
      .navbar {
        .logo-container {
          width: 100%;
          margin-left: 2rem;
          justify-content: center;
        }
      }

      .dropdown-container {
        .dropdown {
          margin-top: 0.5rem;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .navbar-container {
      .navbar {
        .icon-sidebar-container {
          display: flex;
          font-size: 14px;
          color: #8E8E8E;
          cursor: pointer;
        }

        .select-field {
          margin-left: 1.5rem;
          margin-right: 1.5rem;
        }
      }
    }
  }
</style>