<template>
  <modal
    ref="modal"
    :showModal="hasModalAddressEdit"
    title="Editar endereço"
    @close="closeModalEdit"
  >
    <template v-slot:body>
      <address-modal-form
        :data="formattedAddressData"
        mutation="get"
        :sellerType="dataSellerType"
      />
    </template>
  </modal>
</template>

<script>
  import { mapState } from 'vuex';
  import Modal from '@/components/Modal';
  import AddressModalForm from '@/components/AccreditationSteps/AddressModalForm';

  export default {
    name: 'modalEditAddress',

    data() {
      return {
        formattedAddressData: {
          postalCode: '',
          neighborhood: '',
          street: '',
          city: '',
          state: '',
          complement: '',
          number: '',
        },

        dataSellerType: '',
      };
    },

    components: {
      'modal': Modal,
      'address-modal-form': AddressModalForm,
    },

    computed: {
      ...mapState({
        currentSellerId: state => state.currentSellerId,
        isOwnerAddressEdit: state => state.isOwnerAddressEdit,
        hasModalAddressEdit: state => state.hasModalAddressEdit,
      })
    },

    props: {
      sellerData: {
        type: Object,
        required: true,
      }
    },

    watch: {
      sellerData() {
        this.dataSellerType = this.sellerData.type;

        this.setAddressData(this.dataSellerType);
      },

      hasModalAddressEdit() {
        this.setAddressData(this.dataSellerType);
      }
    },

    mounted() {
      this.setAddressData(this.dataSellerType);
    },

    methods: {
      closeModalEdit() {
        this.$store.commit('closeModalEdit');
      },

      setDefaultIndividualAddressData() {
        this.formattedAddressData.postalCode = this.sellerData.address_postal_code;
        this.formattedAddressData.neighborhood = this.sellerData.address_neighborhood;
        this.formattedAddressData.street = this.sellerData.address_line1;
        this.formattedAddressData.city = this.sellerData.address_city;
        this.formattedAddressData.state = this.sellerData.address_state;
        this.formattedAddressData.complement = this.sellerData.address_line3;
        this.formattedAddressData.number = this.sellerData.address_line2;
      },

      setDefaultCompanyAddressData() {
        this.formattedAddressData.postalCode = this.sellerData.business_address_postal_code;
        this.formattedAddressData.neighborhood = this.sellerData.business_address_neighborhood;
        this.formattedAddressData.street = this.sellerData.business_address_line1;
        this.formattedAddressData.city = this.sellerData.business_address_city;
        this.formattedAddressData.state = this.sellerData.business_address_state;
        this.formattedAddressData.complement = this.sellerData.business_address_line3;
        this.formattedAddressData.number = this.sellerData.business_address_line2;
      },

      setDefaultCompanyOwnerData() {
        this.formattedAddressData.postalCode = this.sellerData.owner_address_postal_code;
        this.formattedAddressData.neighborhood = this.sellerData.owner_address_neighborhood;
        this.formattedAddressData.street = this.sellerData.owner_address_line1;
        this.formattedAddressData.city = this.sellerData.owner_address_city;
        this.formattedAddressData.state = this.sellerData.owner_address_state;
        this.formattedAddressData.complement = this.sellerData.owner_address_line3;
        this.formattedAddressData.number = this.sellerData.owner_address_line2;
      },

      setAddressData(type) {
        if(type == 'Individual') {
          this.setDefaultIndividualAddressData();
        } else if (type == 'Company' && this.isOwnerAddressEdit) {
          this.setDefaultCompanyOwnerData();
        } else if (type == 'Company') {
          this.setDefaultCompanyAddressData();
        }
      }
    }
  };
</script>