<template>
  <div
    class="view-container"
    :class="{ 'has-modal': hasModal || getIsResetPassword && !hideForceResetPassword, 'has-sidebar': !getSideBarState.hideSideBar }"
  >
    <slot name="alert"/>

    <slot name="modal"/>

    <force-reset-password-modal-component v-if="getIsResetPassword && !hideForceResetPassword"/>

    <div class="view-content">
      <side-bar-component/>

      <div class="content" :class="{ 'hide-sidebar': getSideBarState.hideSideBar, 'show-sidebar': !getSideBarState.hideSideBar}">
        <nav-bar-component
          :hasSelect="true"
        />

        <div class="section-container">
          <slot name="content"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SideBar from '@/components/SideBar';
  import NavBar from '@/components/NavBar';
  import ForceResetPasswordModal from '@/components/ForceResetPasswordModal';

  export default {
    name: 'DefaultView',

    computed: {
      ...mapGetters({
        getSideBarState: 'getSideBarState',
        getNoticeCardData: 'getNoticeCardData',
        getIsResetPassword: 'authModule/getIsResetPassword'
      })
    },

    components: {
      'side-bar-component': SideBar,
      'nav-bar-component': NavBar,
      'force-reset-password-modal-component': ForceResetPasswordModal
    },

    props: {
      hasModal: {
        type: Boolean,
        default: false
      },

      hideForceResetPassword: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style lang="scss" scoped>
  .view-container {
    min-height: 100vh;
    min-width: 320px;
    overflow-y: hidden;
    font-size: 14px;
    color: #333;

    &.has-modal {
      overflow: hidden;
      position: fixed;
      width: 100vw;
      min-width: 320px;
    }

    &.has-modal > *:not(.modal) {
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }

    .view-content {
      display: flex;

      .content {
        width: 100%;

        &.hide-sidebar {
          width: calc(100% - 80px);
        }

        &.show-sidebar {
          width: calc(100% - 240px);
        }
      }
    }
  };

  @media (max-width: 480px) {
    .view-container {
      &.has-sidebar {
        overflow: hidden;
        height: 100vh;
      }

      .view-content {
        .content {
          &.hide-sidebar {
            width: 100%;
          }

          &.show-sidebar {
            width: 100%;
          }
        }
      }
    }
  }
</style>