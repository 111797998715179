<template>
  <modal
    ref="modal"
    class="force-reset-modal"
    :showModal="true"
  >
      <template v-slot:body>
        <div class="body">
          <div class="body-icon">
            <i class="icon-security"/>
          </div>

          <h2>Redefinição de senha!</h2>

          <div class="description">
            <p>Para sua segurança em nosso ambiente, precisamos que você altere a sua senha de acesso {{ $service.getPlatformTitle() }}.</p>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="footer">
          <button-component
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            class="button-component"
            styles="primary"
            @click.native="toSecurity"
            text="Continuar"
          />
        </div>
      </template>
  </modal>
</template>

<script>
  import Modal from '@/components/Modal';
  import Button from '@/components/Button';

  export default {
    name: 'CustomerProfileModal',

    components: {
      'modal': Modal,
      'button-component': Button,
    },

    methods: {
      toSecurity() {
        this.$router.push({
          name: 'account-security',
          params: { showResetPasswordModal: true }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .force-reset-modal {
    .body {
      color: #333;
      text-align: center;

      .body-icon {
        color: $second-color;
        margin-top: 2rem;

        i {
          font-size: 10rem;
        }
      }

      h2 {
        padding: 1rem;
      }

      .description {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 14px;
          width: 510px;
        }
      }
    }

    .footer {
      width: 100%;
      display: flex;
      margin-top: 2rem;
      float: right;
      padding: 1.5rem;

      .button-component {
        height: 48px;
        width: auto;
        margin-left: auto;
      }
    }
  }
</style>