<template>
  <div class="document-field-container">
    <div v-if="currentSellerIsActive" class="is-active">
      <div class="image-container">
        <img src="@/assets/images/check-image.png"/>
      </div>

      <h2>Conta ativa!</h2>

      <p>Sua conta já está ativa, não é necessário alterar e/ou enviar novos documentos!</p>
    </div>

    <div v-else>
      <p class="title">Documentos</p>

      <div>
        <default-data
          v-if="hasRequestFile"
          class="dafault-data"
        />

        <p v-else class="message">Os documentos solicitados são simples, garantem a sua segurança e são exigidos pelo BACEN.</p>
      </div>

      <div class="document-field-content">
        <input-file-field
          accept="image/png, image/jpeg, application/pdf"
          rules="required"
          name="cartão CNPJ"
          class="file-field"
          label="Cartão CNPJ"
          type="ein"
          :currentFile="formattedCurrentEinDocument"
          v-model="einDocument"
          :hasRequest="hasRequestFile"
        />
      </div>

      <div class="document-field-content">
        <input-file-field
          accept="image/png, image/jpeg, application/pdf"
          class="file-field"
          name="comprovante de atividade"
          rules="required"
          label="Comprovante de atividade"
          subLabel="Ex.: Contrato social, NF de compra, alvará ou foto da fachada"
          :currentFile="formattedCurrentOperatingAgreementDocument"
          v-model="operatingAgreement"
          :hasRequest="hasRequestFile"
        />
      </div>

      <div class="document-field-content">
        <input-file-field
          accept="image/png, image/jpeg, application/pdf"
          class="file-field"
          rules="required"
          name="comprovante de endereço"
          label="Comprovante de endereço da empresa"
          subLabel="conta de água, energia, telegone gás..."
          :currentFile="formattedCurrentProofOfResidenceDocument"
          v-model="proofOfResidence"
          :hasRequest="hasRequestFile"
        />
      </div>

      <div class="file-field-container">
        <div>
          <default-data
            v-if="hasRequestFile"
            class="dafault-data"
          />

          <div v-else>
            Documento do sócio (CNH, RG...)
          </div>
        </div>

        <div class="file-field">
          <div>
            <default-data
              v-if="hasRequestFile"
              class="dafault-data"
            />

            <p v-else>{{ currentOwnerName }}</p>
          </div>

          <div class="document-field-content">
            <div class="inputs">
              <input-file-field
                accept="image/png, image/jpeg, application/pdf"
                class="input-file"
                rules="required"
                name="documento (frente)"
                side="frente"
                :currentFile="formattedCurrentIdFrontDocument"
                v-model="idDocumentFront"
                :hasRequest="hasRequestFile"
              />

              <input-file-field
                accept="image/png, image/jpeg, application/pdf"
                class="input-file"
                rules="required"
                name="documento (verso)"
                side="verso"
                :currentFile="formattedCurrentIdBackDocument"
                v-model="idDocumentBack"
                :hasRequest="hasRequestFile"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import InputFileField from '@/components/InputFileField';
  import DefaultData from '@/components/DefaultData';

  export default {
    data() {
      return {
        requests: [],
        documentsArr: [],
        hasRequestFile: true,
        currentOwnerName: '',
        hasMountedFetch: false,
        currentSellerIsActive: false,

        einDocument: null,
        operatingAgreement: null,
        proofOfResidence: null,
        idDocumentFront: null,
        idDocumentBack: null,

        currentEinDocument: null,
        currentOperatingAgreementDocument: null,
        currentProofOfResidenceDocument: null,
        currentIdFrontDocument: null,
        currentIdBackDocument: null,

        formattedCurrentEinDocument: null,
        formattedCurrentOperatingAgreementDocument: null,
        formattedCurrentProofOfResidenceDocument: null,
        formattedCurrentIdFrontDocument: null,
        formattedCurrentIdBackDocument: null,
      };
    },

    components: {
      'input-file-field': InputFileField,
      'default-data': DefaultData
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerId',
        'getSellerDocuments',
        'getCurrentSellerData',
        'getCurrentSellerStatus'
      ])
    },

    mounted() {
      this.hasRequestFile = true;
      this.hasMountedFetch = true;

      this.$store.dispatch('getAllDocuments')
        .then(() => {
          this.fetchDocuments();
        })
        .finally(() => {
          this.hasMountedFetch = false;
        });

      this.resetDocuments();
    },

    watch: {
      einDocument() {
        let type = 'Ein';
        let description = 'ein';
        let document = this.einDocument;

        this.documentUpload(type, document, description);
      },

      operatingAgreement() {
        let type = 'OperatingAgreement';
        let description = 'operatingAgreement';
        let document = this.operatingAgreement;

        this.documentUpload(type, document, description);
      },

      proofOfResidence() {
        let type = 'ProofOfResidence';
        let description = 'proofOfResidence';
        let document = this.proofOfResidence;

        this.documentUpload(type, document, description);
      },

      idDocumentFront() {
        let type = 'Id';
        let description = 'id-frontSide';
        let document = this.idDocumentFront;

        this.documentUpload(type, document, description);
      },

      idDocumentBack() {
        let type = 'Id';
        let description = 'id-backSide';
        let document = this.idDocumentBack;

        this.documentUpload(type, document, description);
      },

      getCurrentSellerId() {
        this.hasRequestFile = true;
        this.currentSellerIsActive = false;

        this.resetDocuments();
      },

      getSellerDocuments() {
        this.hasRequestFile = true;

        if (!this.hasMountedFetch) this.fetchDocuments();
      },

      getCurrentSellerData() {
        this.setCurrentOwnerName();
      }
    },

    methods: {
      hasSellerDocuments() {
        return this.getSellerDocuments.length > 0;
      },

      isActiveSeller() {
        return this.getCurrentSellerStatus === 'Active';
      },

      resetDocuments() {
        this.documentsArr = [];
        this.currentEinDocument = null;
        this.currentOperatingAgreementDocument = null;
        this.currentProofOfResidenceDocument = null;
        this.currentIdFrontDocument = null;
        this.currentIdBackDocument = null;
        this.formattedCurrentEinDocument = null;
        this.formattedCurrentOperatingAgreementDocument = null;
        this.formattedCurrentProofOfResidenceDocument = null;
        this.formattedCurrentIdFrontDocument = null;
        this.formattedCurrentIdBackDocument = null;
      },

      documentUpload(type, document, description) {
        let formData = new FormData();

        formData.append('type', type);
        formData.append('description', description);
        formData.append('file', document);

        this.$store.dispatch('postDocument', formData)
          .then(() => {
            this.hasRequestFile = true;

            this.$store.dispatch('getAllDocuments')
              .then(() => {
                this.fetchDocuments();
              });
          }).catch(err => {
            console.log(err);
          });
      },

      fetchDocuments() {
        this.resetDocuments();

        if (this.hasSellerDocuments()) {
          this.getSellerDocuments.forEach(document => {
            let documentType = this.splitDocumentType(document.description);

            if (documentType === 'ein') {
              this.currentEinDocument = document;
            } else if (documentType === 'operatingAgreement') {
              this.currentOperatingAgreementDocument = document;
            } else if (documentType === 'proofOfResidence') {
              this.currentProofOfResidenceDocument = document;
            } else if (documentType === 'id') {
              let side = this.splitIdSide(document.description);

              if (side === 'frontSide') {
                this.currentIdFrontDocument = document;
              } else if (side === 'backSide') {
                this.currentIdBackDocument = document;
              }
            }
          });

          this.addDocumentsArr(this.currentEinDocument);
          this.addDocumentsArr(this.currentOperatingAgreementDocument);
          this.addDocumentsArr(this.currentProofOfResidenceDocument);
          this.addDocumentsArr(this.currentIdFrontDocument);
          this.addDocumentsArr(this.currentIdBackDocument);

          this.$store.dispatch('createMultiplesRequests', this.requests)
            .then(responses => {
              responses.forEach(item => {
                let documentId = item.config.url.split('documents/')[1].split('/content')[0];
                let resItem = item.data;
                let b64 = resItem.data.body;

                this.documentsArr.forEach(document => {
                  if (document.id == documentId) {
                    let documentObj = {
                      fileSize: this.$service.bytesToSize(document.size),
                      status: document.status,
                      fileName: document.name + '.' + this.$service.splitFileFormat(document.mime_type),
                      fileType: this.$service.splitFileFormat(document.mime_type),
                      b64File: b64,
                      isPdf: this.$service.isPdf(b64),
                      modifiedDate: this.$service.dateFormat(document.updated_at, 'YYYY-MM-DD', 'DD/MM/YYYY')
                    };

                    let documentType = this.splitDocumentType(document.description);

                    if (documentType === 'ein') {
                      this.formattedCurrentEinDocument = documentObj;
                    } else if (documentType === 'operatingAgreement') {
                      this.formattedCurrentOperatingAgreementDocument = documentObj;
                    } else if (documentType === 'proofOfResidence') {
                      this.formattedCurrentProofOfResidenceDocument = documentObj;
                    } else if (documentType === 'id') {
                      let side = this.splitIdSide(document.description);

                      if (side === 'frontSide') {
                        this.formattedCurrentIdFrontDocument = documentObj;
                      } else if (side === 'backSide') {
                        this.formattedCurrentIdBackDocument = documentObj;
                      }
                    }
                  }
                });
              });

              this.currentSellerIsActive = this.isActiveSeller();
            }).catch(errors => {
              console.log(errors);
            }).finally(() => {
              this.hasRequestFile = false;
            });
        } else {
          this.hasRequestFile = false;
        }
      },

      addDocumentsArr(document) {
        if (document != null) {
          this.requests.push(this.createRequest(document.id));
          this.documentsArr.push(document);
        }
      },

      createRequest(documentId) {
        return this.$store.dispatch('createGetRequest', '/sellers/' + this.getCurrentSellerId + '/documents/' + documentId + '/content');
      },

      splitDocumentType(documentName) {
        return documentName != null ? documentName.split('-')[0] : null;
      },

      splitIdSide(documentName) {
        let length = documentName.split('-').length;

        return documentName.split('-')[length-1];
      },

      isEquals(str1, str2) {
        return str1 === str2;
      },

      setCurrentOwnerName() {
        if (this.getCurrentSellerData.type === 'Company') {
          if (this.isEquals(this.getCurrentSellerData.owner_first_name, this.getCurrentSellerData.owner_last_name)) {
            this.currentOwnerName = this.getCurrentSellerData.owner_first_name;
          } else {
            this.currentOwnerName = this.getCurrentSellerData.owner_first_name + ' ' + this.getCurrentSellerData.owner_last_name;
          }
        } else if (this.getCurrentSellerData.type === 'Individual') {
          if(this.isEquals(this.getCurrentSellerData.first_name, this.getCurrentSellerData.last_name)) {
            this.currentOwnerName = this.getCurrentSellerData.first_name;
          } else {
            this.currentOwnerName = this.getCurrentSellerData.first_name + ' ' + this.getCurrentSellerData.last_name;
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .document-field-container {
    .dafault-data {
      width: 100%;
      height: 16px;
    }

    .title {
      color: #333;
      margin-bottom: 1.5rem;
      font-weight: 600;
      font-size: 16px;
    }

    .message {
      color: #279ECC;
    }

    .file-field {
      margin-top: 1.5rem;
    }

    .is-active {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 4rem;

      .image-container {
        img {
          width: 145px;
          height: 145px;
        }
      }

      h2 {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        color: #333;
      }

      p {
        max-width: 400px;
        text-align: center;
      }
    }

    .document-field-content {
      .new-document-container {
        margin-top: 1rem;

        .new-document {
          color: $second-color;
          font-weight: 600;
          cursor: pointer;
          text-transform: unset;

          &:hover {
            color: #ec8101;
          }
        }
      }
    }

    .file-field-container {
      margin-top: 2rem;
      margin-bottom: 3rem;
      font-size: 14px;
      color: #333;

      .file-field {
        margin-top: 1rem;

        p {
          text-transform: uppercase;
          margin: 0;
          color: #333;
          font-weight: 600;
        }

        .inputs {
          display: flex;
          min-width: 70px;

          .input-file {
            width: 100%;
            min-width: 270px;

            &:last-child {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .document-field-container {
      .file-field-container {
        .file-field {
          .inputs {
            .input-file {
              min-width: 240px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1100px) {
    .document-field-container {
      .file-field-container {
        .file-field {
          .inputs {
            flex-direction: column;

            .input-file {
              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
</style>