<template>
  <ValidationObserver
    tag="div"
    class="form-container"
    ref="form"
    v-slot="{ passes }"
  >
    <form @submit.prevent="passes(submitAddressFormData)">
      <h2>{{ formTitle }}</h2>

      <div class="container">
        <div class="section">
          <div class="column">
            <input-field
              class="input-field"
              placeholder="CEP"
              name="postalCode"
              rules="cep|required"
              mask="#####-###"
              @blur="requestPostalCode"
              v-model="addressData.postalCode"
            />
          </div>

          <div class="two-column">
            <input-field
              class="input-field"
              placeholder="Rua"
              rules="required"
              :isDisabled="disableStreetAndNeighborhood"
              v-model="addressData.street"
            />

            <input-field
              class="input-field right"
              placeholder="Número"
              rules="required"
              :isDisabled="disableNumberAndComplement"
              v-model="addressData.number"
            />
          </div>

          <div class="two-column">
            <input-field
              class="input-field"
              placeholder="Complemento"
              :isDisabled="disableNumberAndComplement"
              v-model="addressData.complement"
            />

            <input-field
              class="input-field right"
              placeholder="Bairro"
              rules="required"
              :isDisabled="disableStreetAndNeighborhood"
              v-model="addressData.neighborhood"
            />
          </div>

          <div class="two-column">
            <input-field
              class="input-field left"
              placeholder="Estado"
              rules="required"
              :isDisabled="disableStateAndCity"
              v-model="addressData.state"
            />

            <input-field
              class="input-field"
              placeholder="Cidade"
              rules="required"
              :isDisabled="disableStateAndCity"
              v-model="addressData.city"
            />
          </div>
        </div>

        <div class="button-container">
          <button-component
            scale="normal"
            size="large"
            v-ripple="'rgba(255, 139, 0, 0.2)'"
            styles="ghost"
            type="button"
            @click.native="closeModalEdit"
            text="Fechar"
          />

          <button-component
            class="right"
            scale="normal"
            size="large"
            :isDisabled="isButtonDisable"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            styles="primary"
            :hasLoader="hasRequest"
            text="Alterar e salvar"
          />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import { mapState } from 'vuex';
  import Button from '@/components/Button';
  import { ValidationObserver } from 'vee-validate';
  import InputField from '@/components/InputField';

  export default {
    data() {
      return {
        addressData: {
          postalCode: '',
          neighborhood: '',
          street: '',
          city: '',
          state: '',
          complement: '',
          number: '',
        },
        hasRequest: false,
        isButtonDisable: true,
        disableStateAndCity: true,
        disableStreetAndNeighborhood: true,
        disableNumberAndComplement: true,
      };
    },

    props: {
      formTitle: {
        type: String,
        required: false
      },

      mutation: {
        type: String,
        deafault: null
      },

      sellerType: {
        type: String,
        required: true,
      },

      data: {
        type: Object,
        default() {
          return {
            postalCode: '',
            neighborhood: '',
            street: '',
            city: '',
            state: '',
            complement: '',
            number: '',
          };
        }
      }
    },

    components: {
      ValidationObserver,
      'input-field': InputField,
      'button-component': Button
    },

    computed: {
      ...mapState({
        sellerData: state => state.sellerData,
        currentSellerId: state => state.currentSellerId,
        isOwnerAddressEdit: state => state.isOwnerAddressEdit,
      }),
    },

    watch: {
      'addressData.postalCode'() {
        if(this.addressData.postalCode) {
          if(this.addressData.postalCode.length == 0) {
            this.defaultAddressData();
            this.disableAllFields();
          }
        }
      },

      'sellerData'() {
        this.setDefaultAddressValues();
      }
    },

    mounted() {
      this.setDefaultAddressValues();

      this.isButtonDisable = true;
    },

    methods: {
      submitAddressFormData() {
        let formData;

        if(this.sellerType === 'Individual') {
          formData = JSON.stringify({
            address_line1: this.addressData.street,
            address_line2: this.addressData.number,
            address_line3: this.addressData.complement,
            address_neighborhood: this.addressData.neighborhood,
            address_city: this.addressData.city,
            address_state: this.addressData.state,
            address_postal_code: this.$service.removeCharacter(this.addressData.postalCode),
          });

          this.sendRequestToEditSeller('updateIndividualSeller', formData);
        } else if (this.sellerType == 'Company' && this.isOwnerAddressEdit) {
          formData = JSON.stringify({
            business_address_line1: this.sellerData.business_address_line1,
            business_address_line2: this.sellerData.business_address_line2,
            business_address_line3: this.sellerData.business_address_line3,
            business_address_neighborhood: this.sellerData.business_address_neighborhood,
            business_address_city: this.sellerData.business_address_city,
            business_address_state: this.sellerData.business_address_state,
            business_address_postal_code: this.sellerData.business_address_postal_code,

            owner_address_line1: this.addressData.street,
            owner_address_line2: this.addressData.number,
            owner_address_line3: this.addressData.complement,
            owner_address_neighborhood: this.addressData.neighborhood,
            owner_address_city: this.addressData.city,
            owner_address_state: this.addressData.state,
            owner_address_postal_code: this.$service.removeCharacter(this.addressData.postalCode),
          });

          this.sendRequestToEditSeller('updateCompanySeller', formData);
        } else if(this.sellerType == 'Company') {
          formData = JSON.stringify({
            business_address_line1: this.addressData.street,
            business_address_line2: this.addressData.number,
            business_address_line3: this.addressData.complement,
            business_address_neighborhood: this.addressData.neighborhood,
            business_address_city: this.addressData.city,
            business_address_state: this.addressData.state,
            business_address_postal_code: this.$service.removeCharacter(this.addressData.postalCode),

            owner_address_line1: this.sellerData.owner_address_line1,
            owner_address_line2: this.sellerData.owner_address_line2,
            owner_address_line3: this.sellerData.owner_address_line3,
            owner_address_neighborhood: this.sellerData.owner_address_neighborhood,
            owner_address_city: this.sellerData.owner_owner_address_city,
            owner_address_state: this.sellerData.owner_address_state,
            owner_address_postal_code: this.sellerData.owner_address_postal_code,
          });

          this.sendRequestToEditSeller('updateCompanySeller', formData);
        }
      },

      requestPostalCode() {
        this.defaultAddressData();
        this.disableAllFields();
        this.isButtonDisable = true;
        this.hasRequest = true;

        let postalCode = this.$service.removeCharacter(this.addressData.postalCode);

        if (postalCode.length == 8) {
          this.$store.dispatch('getAddress', postalCode)
            .then(res => {
              this.addressData.city = res.data.city;
              this.addressData.state = res.data.state_code;

              if (res.data.neighborhood != '' && res.data.address_line_1 != '') {
                this.addressData.neighborhood = res.data.neighborhood;
                this.addressData.street = res.data.address_line_1;

                this.enableNumberAndComplement();
              } else {
                this.enableAllFields();
              }

              this.isButtonDisable = false;
              this.hasRequest = false;
            })
            .catch(() => {
              this.hasRequest = false;

              this.$refs.form.setErrors({
                postalCode: 'CEP não encontrado',
              });
            });
        }
      },

      sendRequestToEditSeller(action, formData) {
        this.hasRequest = true;

        this.$store.dispatch(action, { formData })
          .then(() => {
            this.$store.dispatch('fetchSellerData')
              .then(() => {
                this.setSuccessAlertCard();
                this.$store.commit('setAlertCardStatus', true);
              })
              .catch(() => {
                this.setUpdateErrorAlertCard();
                this.$store.commit('setAlertCardStatus', true);
              })
              .finally(() => {
                this.closeModalEdit();
                this.hasRequest = false;
              });
          })
          .catch(() => {
            this.closeModalEdit();
            this.$store.commit('setAlertCardStatus', true);
            this.setErrorAlertCard();
            this.hasRequest = false;
          });
      },

      enableAllFields() {
        this.disableStreetAndNeighborhood = false;
        this.disableNumberAndComplement = false;
      },

      enableNumberAndComplement() {
        this.disableNumberAndComplement = false;
      },

      disableAllFields() {
        this.disableStreetAndNeighborhood = true;
        this.disableNumberAndComplement = true;
      },

      defaultAddressData() {
        this.addressData.neighborhood = '';
        this.addressData.street = '';
        this.addressData.city = '';
        this.addressData.state = '';
        this.addressData.complement = '';
        this.addressData.number = '';
      },

      setDefaultAddressValues() {
        this.addressData.postalCode = this.data.postalCode;
        this.addressData.neighborhood = this.data.neighborhood;
        this.addressData.street = this.data.street;
        this.addressData.city = this.data.city;
        this.addressData.state = this.data.state;
        this.addressData.complement = this.data.complement;
        this.addressData.number = this.data.number;
      },

      setSuccessAlertCard() {
        this.$store.commit('setAlertCardData', {
          text: 'Dados editados com sucesso.',
          color: '#08B978'
        });
      },

      setErrorAlertCard() {
        this.$store.commit('setAlertCardData', {
          text: 'Erro ao editar os dados cadastrais.',
          color: '#FF5A5A'
        });
      },

      setUpdateErrorAlertCard() {
        this.$store.commit('setAlertCardData', {
          text: 'Erro ao atualizar os dados.',
          color: '#FF5A5A'
        });
      },

      closeModalEdit() {
        this.$store.commit('closeModalEdit');
      },
    }
  };
</script>

<style lang="scss" scoped>
  .form-container {
    h2 {
      text-align: center;
      margin-bottom: 1.5rem;
    }

    .container {
      .section {
        .two-column {
          display: flex;

          .input-field {
            max-width: 325px;

            &.left {
              margin-right: 1rem;
              max-width: 215px;
            }

            &.right {
              margin-left: 1rem;
              max-width: 215px;
            }
          }
        }

        .column {
          width: 305px;
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;

      .right {
        margin-left: 1rem;
        min-width: 156px;
      }
    }
  }

  @media (max-width: 767px) {
    .form-container {
      h2 {
        font-size: 16px;
      }

      .container {
        .section {
          .two-column {
            .input-field  {
              max-width: unset;

              &.right {
                max-width: unset;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .form-container {
      .container {
        overflow-y: scroll;
        max-height: 72vh;
        margin-bottom: 4rem;

        &::-webkit-scrollbar {
          display: none;
        }

        .section {
          .column {
            width: 100%;
          }

          .two-column {
            flex-direction: column;

            .input-field {
              max-width: unset;

              &.right {
                max-width: unset;
                margin-left: 0;
              }

              &.left {
                max-width: unset;
                margin-right: 0;
              }
            }
          }
        }

        .button-container {
          justify-content: center;
        }
      }
    }
  }
</style>
