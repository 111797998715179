<template>
  <div class="second-custom-calendar-content">
    <div v-if="hasRequest" class="calendar-request-container">
      <spinner-component class="spinner" color="#DADADA"/>
    </div>

    <v-date-picker
      v-show="!hasRequest"
      :masks="masks"
      ref="calendar"
      :first-day-of-week="1"
      :attributes="attributes"
      @update:from-page="pageChange"
      v-model="currentDate"
      is-required
      is-expanded
    />
  </div>
</template>

<script>
  import Spinner from '@/components/Spinner';

  export default {
    name: 'SecondCustomCalendar',

    data() {
      return {
        masks: {
          title: 'MMMM/YYYY',
          weekdays: 'W'
        },

        currentDate: null,

        range: {
          start: null,
          end: null
        },

        currentStartEndDate: {
          start: null,
          end: null
        },

        attributes: [
          {
            key: 0,
            dot: {
              style: {
                'background-color': '#0000FF'
              }
            },
            dates: this.$service.toDate('2021-08-19'),
          },
        ]
      };
    },

    props: {
      rangeProp: {
        type: Object,
        default() {
          return {
            start: null,
            end: null
          };
        }
      },

      hasRequest: {
        type: Boolean,
        default: false
      },

      calendarStartEndDate: {
        type: Object,
        default() {
          return {
            start: null,
            end: null
          };
        }
      },

      data: {
        type: Array,
        require: false
      }
    },

    components: {
      'spinner-component': Spinner
    },

    watch: {
      currentDate(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.rangeProp.start = value;

        // eslint-disable-next-line vue/no-mutating-props
        this.rangeProp.end = value;
      },

      hasRequest(value) {
        if (!value) {
          this.attributes = this.formatAttributes(this.data);
        }
      }
    },

    mounted() {
      this.attributes = this.formatAttributes(this.data);
      this.currentStartEndDate = this.calendarStartEndDate;
      this.currentDate = this.rangeProp.start;

      this.range = {
        start: this.$service.toDate(this.$service.currentDateEN()),
        end: this.$service.toDate(this.$service.currentDateEN())
      };

      this.setRangeProp();
      this.setCurrentStartEndDateMonth();
    },

    methods: {
      pageChange() {
        this.setCurrentStartEndDateMonth();
      },

      setCurrentStartEndDateMonth() {
        if (!this.$service.isUndefined(this.$refs.calendar)) {
          let date = this.$refs.calendar.$refs.calendar.lastPage.year + '-' + this.$refs.calendar.$refs.calendar.lastPage.month;

          this.currentStartEndDate.start = this.$service.getStartDateMonth(date, 'YYYY-MM');
          this.currentStartEndDate.end = this.$service.getEndDateMonth(date, 'YYYY-MM');

          // eslint-disable-next-line vue/no-mutating-props
          this.calendarStartEndDate = this.currentStartEndDate;
        }
      },

      formatAttributes(data) {
        let attributesArr = [];

        if (!this.$service.isEmptyArray(data)) {
          data.forEach((item, index) => {
            attributesArr.push({
              key: index,
              dot: {
                style: {
                  'background-color': this.formatStatusCalendar(item.status)
                }
              },
              dates: this.$service.toDate(item.date),
            });
          });
        }

        return attributesArr;
      },

      setRangeProp() {
        // eslint-disable-next-line vue/no-mutating-props
        this.rangeProp.start = this.range.start;

        // eslint-disable-next-line vue/no-mutating-props
        this.rangeProp.end = this.range.end;
      },

      formatStatusCalendar(status) {
        let color;

        switch (this.$service.toUpperCase(status)) {
          case 'PAID':
            color = '#08B978';
            break;
          case 'PENDING':
            color = '#BA8C03';
            break;
          case 'SCHEDULED':
            color = 'FF8B00';
            break;
          case 'CANCELED':
            color = '#FF5A5A';
            break;
          case 'REFUNDED':
            color = '#006EFF';
            break;
          default:
            color = '#1B87B1';
        }

        return color;
      }
    }
  };
</script>

<style lang="scss">
  .second-custom-calendar-content {
    width: 100%;

    .calendar-request-container {
      display: flex;
      justify-content: center;

      .spinner {
        margin-top: 1rem;
      }
    }

    .vc-container {
      font-family: 'Poppins', sans-serif;
      border: 1px solid #DADADA;

      .vc-pane-container {
        .vc-header {
          .vc-title {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
          }
        }

        .vc-weeks {
          .vc-weekday {
            color: #6D6F6F;
            font-weight: 400;
          }

          .vc-day {
            .vc-day-content  {
              background-color: unset;
            }

            .vc-highlights {
              .vc-day-layer {
                &.vc-day-box-center-center {
                  .vc-highlight {
                    border: 2px solid $second-color !important;
                    color: $white-color !important;
                    background-color: $second-color-light-1 !important;
                    border-radius: 0.3rem !important;

                    &.vc-highlight-base-middle {
                      background-color: $second-color-light-1 !important;
                      border-radius: 0 !important;
                      border: none !important;
                    }
                  }
                }

                &.vc-day-box-right-center {
                  .vc-highlight-base-start {
                    background-color: $second-color-light-1 !important;
                  }

                  .vc-highlight-base-end {
                    background-color: $second-color-light-1 !important;
                  }
                }

                &.vc-day-box-left-center {
                  .vc-highlight-base-start {
                    background-color: $second-color-light-1 !important;
                  }

                  .vc-highlight-base-end {
                    background-color: $second-color-light-1 !important;
                  }
                }
              }
            }

            span {
              font-weight: 300 !important;
              font-size: 14px !important;
              color: #333 !important;
            }
          }
        }
      }
    }
  }
</style>