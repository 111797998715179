<template>
  <div class="calendar-content">
    <div v-if="hasRequest" class="calendar-request-container">
      <spinner-component class="spinner" color="#DADADA"/>
    </div>

    <v-date-picker
      v-show="!hasRequest"
      :masks="masks"
      ref="calendar"
      color="gray"
      @update:from-page="pageChange"
      :attributes="attributes"
      :first-day-of-week="1"
      v-model="range"
      is-range
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="day-details" @click="setDay(day)">
          <span>{{ day.day }}</span>

          <div v-if="!!attributes && attributes.length != 0">
            <div v-for="(attr, index) in attributes" :key="index">
              <div v-if="!!attr.customData" class="date-container">
                <div v-if="!!attr.customData.value" class="value">
                  <div class="real">
                    R$
                  </div>

                  {{ attr.customData.value }}
                </div>

                <div v-if="!!attr.customData.status" class="status">
                  <i :class="attr.customData.status.icon.name" :style="{ 'color': attr.customData.status.icon.color }"/>

                  {{ attr.customData.status.label }}
                </div>
              </div>
            </div>
          </div>

          <div v-else class="date-container">
            -
          </div>
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
  import Spinner from '@/components/Spinner';

  export default {
    name: 'CustomCalendar',

    data() {
      return {
        masks: {
          title: 'MMMM/YYYY',
          weekdays: 'WWW'
        },

        range: {
          start: '',
          end: ''
        },

        currentStartEndDate: {
          start: '',
          end: ''
        },

        attributes: [
          {
            key: 1,
            customData: {
              value: '320,00',
              status: {
                icon: {
                  name: 'icon-close',
                  color: '#DADADA'
                },
                label: 'Default',
              }
            },
            dates: this.$service.toDate('2021-12-01'),
          }
        ]
      };
    },

    props: {
      rangeProp: {
        type: Object,
        default() {
          return {
            start: null,
            end: null
          };
        }
      },

      hasRequest: {
        type: Boolean,
        default: false
      },

      calendarStartEndDate: {
        type: Object,
        default() {
          return {
            start: null,
            end: null
          };
        }
      },

      data: {
        type: Array,
        require: false
      }
    },

    components: {
      'spinner-component': Spinner
    },

    watch: {
      hasRequest(value) {
        if (!value) {
          this.attributes = this.formatAttributes(this.data);
        }
      }
    },

    mounted() {
      this.attributes = this.formatAttributes(this.data);
      this.currentStartEndDate = this.calendarStartEndDate;

      this.range = {
        start: this.$service.toDate(this.$service.currentDateEN()),
        end:  this.$service.toDate(this.$service.currentDateEN())
      };

      this.setRangeProp();
      this.setCurrentStartEndDateMonth();
    },

    methods: {
      pageChange() {
        this.setCurrentStartEndDateMonth();
      },

      setCurrentStartEndDateMonth() {
        if (!this.$service.isUndefined(this.$refs.calendar)) {
          let date = this.$refs.calendar.$refs.calendar.lastPage.year + '-' + this.$refs.calendar.$refs.calendar.lastPage.month;

          this.currentStartEndDate.start = this.$service.getStartDateMonth(date, 'YYYY-MM');
          this.currentStartEndDate.end = this.$service.getEndDateMonth(date, 'YYYY-MM');

        // eslint-disable-next-line vue/no-mutating-props
          this.calendarStartEndDate = this.currentStartEndDate;
        }
      },

      formatAttributes(data) {
        let attributesArr = [];

        if (!this.$service.isEmptyArray(data)) {
          data.forEach((item, index) => {
            attributesArr.push({
              key: index,
              customData: {
                value: item.totalAmount,
                status: this.formatStatusCalendar(item.status)
              },

              dates: this.$service.toDate(item.date),
            });
          });
        }

        return attributesArr;
      },

      setDay(day) {
        if (!this.$service.isEqual(day.id, this.$service.dateFormat(this.range.start))) {
          this.range = {
            start: this.$service.toDate(day.id),
            end: this.$service.toDate(day.id)
          };

          this.setRangeProp();
        }
      },

      setRangeProp() {
        // eslint-disable-next-line vue/no-mutating-props
        this.rangeProp.start = this.range.start;

        // eslint-disable-next-line vue/no-mutating-props
        this.rangeProp.end = this.range.end;
      },

      formatStatusCalendar(status) {
        let obj = {
          icon: {
            name: '',
            color: ''
          },
          label: '',
        };

        switch (this.$service.toUpperCase(status)) {
          case 'PAID':
            obj.icon.name = 'icon-check';
            obj.icon.color = '#08B978';
            obj.label = 'Pago';
            break;
          case 'PENDING':
            obj.icon.name = 'icon-warning';
            obj.icon.color = '#BA8C03';
            obj.label = 'Pendente';
            break;
          case 'SCHEDULED':
            obj.icon.name = 'icon-calendar';
            obj.icon.color = '#FF8B00';
            obj.label = 'Programado';
            break;
          case 'CANCELED':
            obj.icon.name = 'icon-cancel-circle';
            obj.icon.color = '#FF5A5A';
            obj.label = 'Cancelado';
            break;
          case 'REFUNDED':
            obj.icon.name = 'icon-information-circle';
            obj.icon.color = '#006EFF';
            obj.label = 'Devolvido';
            break;
          default:
            obj.icon.name = 'icon-spinner';
            obj.icon.color = '#1B87B1';
            obj.label = 'Processando';
        }

        return obj;
      }
    }
  };
</script>

<style lang="scss">
  .calendar-content {
    .calendar-request-container {
      min-width: 750px;
      display: flex;
      justify-content: center;

      .spinner {
        margin-top: 1rem;
      }
    }

    .vc-container {
      font-family: 'Poppins', sans-serif;
      min-width: 750px;

      .vc-pane-container {
        .vc-header {
          padding: 0.5rem;
          border-bottom: 1px solid #DADADA;

          .vc-title {
            color: #333;
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
          }
        }

        .vc-weeks {
          padding: 0;

          .vc-weekday {
            text-transform: capitalize;
            color: #6D6F6F;
            font-weight: 400;
            border-bottom: 1px solid #DADADA;
            padding: 0.5rem;
          }

          .vc-day {
            color: #6D6F6F;
            height: 100px;
            background: transparent;

            &.vc-day-box-center-center {
              display: block;
            }

            .vc-highlights {
              background-color: $second-color-light-1;

              .vc-day-layer {
                .vc-highlight {
                  height: 100%;
                  width: 100%;
                  background-color: transparent !important;
                  border-radius: unset !important;
                  border: 2px solid $second-color !important;

                  &.vc-highlight-base-start {
                    width: 100% !important;
                  }

                  &.vc-highlight-base-end {
                    width: 100% !important;
                  }

                  &.vc-highlight-base-middle {
                    background-color: $second-color-light-1 !important;
                    border-radius: 0 !important;
                    border: none !important;
                  }
                }
              }
            }

            .day-details {
              height: 100%;
              cursor: pointer;

              .date-container {
                padding: 0 0.5rem;
                color: #333;

                .value {
                  display: flex;
                  align-items: center;
                  font-weight: 400;
                  font-size: 14px;

                  .real {
                    font-size: 10px;
                    margin-right: 3px;
                  }
                }

                .status {
                  font-size: 10px;
                  font-weight: 400;
                  display: flex;
                  align-items: center;

                  i {
                    margin-right: 5px;
                    color: #08B978;
                  }
                }
              }

              span {
                border-radius: unset;
                font-size: 16px;
                font-weight: 400 !important;
                width: 100%;
                background: transparent;
                display: block;
                padding: 0.5rem;
                color: #6D6F6F !important;
              }
            }

            &:not(.on-bottom) {
              border-bottom: 1px solid #DADADA;

              &.weekday-1 {
                border-bottom: 1px solid #DADADA;
              }
            }

            &:not(.on-right) {
              border-right: 1px solid #DADADA;
            }
          }
        }
      }
    }
  }
</style>