<template>
  <div class="account-card-container">
    <div v-for="(item, index) in items" :key="index" v-ripple="'rgba(255, 255, 255, 0.2)'">
      <div class="card" :class="{ 'disabled': item.isDisable }" @click="to(item.redirectPagePath)">
        <i :class="item.icon"/>

        <p class="title">{{ item.title }}</p>

        <p class="text">{{ item.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AccountSectionCard',

    props: {
      items: {
        type: Array,
        required: false,
      }
    },

    methods: {
      to(path) {
        this.$router.push(path);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .account-card-container {
    cursor: pointer;
    display: grid;
    grid-gap: 1.5rem;
    margin-top: 50px;
    grid-template-columns: 315px 315px 315px;

    a {
      text-decoration: none;
    }

    .card {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      padding: 1.4rem;
      border-radius: 6px;
      height: 155px;

      i {
        font-size: 25px;
        color: $second-color;
      }

      .title {
        margin-top: 1rem;
        font-weight: 600;
        color: #333;
      }

      .text {
        margin-top: 0.5rem;
        color: #6D6F6F;
      }

      &.disabled {
        opacity: 0.6;

        &:hover {
          background: unset;

          i {
            color: $second-color;
          }

          .title {
            color: #333;
          }

          .text {
            color: #6D6F6F;
          }
        }
      }

      &:hover {
        background: $second-color;

        i {
          color: $white-color;
        }

        .title {
          color: $white-color;
        }

        .text {
          color: $white-color;
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .account-card-container {
      grid-template-columns: 315px 315px;
    }
  }

  @media (max-width: 945px) {
    .account-card-container {
      grid-template-columns: none;
    }
  }

  @media (max-width: 645px) {
    .account-card-container {
      .card {
        height: auto;
      }
    }
  }
</style>