<template>
  <div class="card-container" :class="{ 'has-request': hasRequest }">
    <div class="header">
      <div class="title">
        {{ data.header.fistTitle }}

        <div v-if="!!data.header.secondTitle" class="second">
          <i class="icon-circle"/>

          {{ data.header.secondTitle }}
        </div>
      </div>
    </div>

    <div v-if="!hasRequest" class="body">
      <div v-if="hasBodyItems()">
        <div v-if="data.isReceivablesCard" class="content">
          <div class="cards">
            <div
              v-for="(item, index) in data.body.items"
              :key="index"
              class="card"
              :class="{ 'active': item.isActive }"
              @click="toggleDate(index)"
            >
              <div class="date">
                {{ item.textDate }}
              </div>

              <div class="total-amount">
                {{ item.totalAmount }}
              </div>
            </div>
          </div>

          <div v-if="hasItemOnActiveItem()">
            <div class="rows items">
              <div v-for="(item, index) in activeItem().items" :key="index" class="row">
                <div class="text">
                  {{ item.text }}
                </div>

                <div class="line-container">
                  <div class="line"></div>
                </div>

                <div class="value">
                  {{ item.amount }}
                </div>
              </div>
            </div>

            <div class="rows">
              <div class="row total">
                <div class="text">
                  Total previsto
                </div>

                <div class="line-container">
                  <div class="line"></div>
                </div>

                <div class="value">
                  {{ activeItem().totalAmount }}
                </div>
              </div>

              <div class="button-container">
                <button-component
                  class="button"
                  styles="ghost"
                  @click.native="redirect"
                  :text="data.body.button.text"
                />
              </div>
            </div>
          </div>

          <simple-message-component
            v-else
            class="empty-data-container"
            :data="emptyReceivables"
          />
        </div>

        <div v-else-if="data.isSalesCard" class="content">
          <div class="sales-resume-container">
            <div class="resume-header">
              <div v-for="(item, index) in data.body.header" :key="index" class="item">
                <p class="amount">{{ item.amount }}</p>

                <p class="label">{{ item.label }}</p>
              </div>
            </div>

            <div class="rows">
              <div v-for="(item, index) in data.body.items" :key="index" class="row">
                <i :class="formatStatusIcon(item.status)" />

                <div class="text">
                  {{ item.hour }}
                </div>

                <div class="line-container">
                  <div class="line"></div>
                </div>

                <div class="value">
                  {{ item.amount }}
                </div>
              </div>
            </div>

            <div class="button-container">
              <button-component
                class="button"
                styles="ghost"
                @click.native="redirect"
                :text="data.body.button.text"
              />
            </div>
          </div>
        </div>
      </div>

      <simple-message-component
        v-else
        class="empty-data-container"
        :data="emptyAllReceivables"
      />
    </div>

    <div v-else class="request-container">
      <spinner-component color="#DADADA" />
    </div>
  </div>
</template>

<script>
  import Button from '@/components/Button';
  import SimpleMessage from '@/components/SimpleMessage';
  import Spinner from '@/components/Spinner';

  export default {
    name: 'TransactionCard',

    data() {
      return {
        emptyAllReceivables: {
          icon: 'icon-receipts',
          text: 'Não foram encontrados registros'
        },

        emptyReceivables: {
          icon: 'icon-receipts',
          text: 'Não foram encontrados recebiveis para esse dia'
        },

        currentDate: ''
      };
    },

    props: {
      data: {
        type: Object,
        default() {
          return {
            isReceivablesCard: false,
            isSalesCard: true,

            header: {
              fistTitle: 'title',
              secondTitle: ''
            },

            body: {
              header: [
                {
                  label: 'Item',
                  amount: 'R$ 10.000,00'
                }
              ],

              items: [
                {
                  status: 'succeeded',
                  amount: 'R$ 300,00',
                  hour: '13:00'
                }
              ]
            },

            empty: {
              icon: 'icon-error-circle',
              text: 'Empty text'
            }
          };
        }
      },

      hasRequest: {
        type: Boolean,
        default: false
      }
    },

    components: {
      'simple-message-component': SimpleMessage,
      'button-component': Button,
      'spinner-component': Spinner
    },

    methods: {
      hasBodyItems() {
        return this.data.body.items.length > 0;
      },

      activeItem() {
        let ativeItemsArr = [];

        this.data.body.items.find(item => {
          if (item.isActive) {
            ativeItemsArr = item;
            this.currentDate = item.date;
          }
        });

        return ativeItemsArr;
      },

      hasItemOnActiveItem() {
        return this.activeItem().items.length > 0;
      },

      redirect() {
        if (this.data.isReceivablesCard) {
          this.$router.push({
            name: 'receivables',
            params: {
              date: this.currentDate
            }
          });
        } else {
          this.$router.push(this.data.body.button.path).catch(() => {});
        }
      },

      toggleDate(index) {
        this.data.body.items.forEach(item => {
          item.isActive = false;
        });

        // eslint-disable-next-line vue/no-mutating-props
        this.data.body.items[index].isActive = true;
        this.currentDate = this.data.body.items[index].date;
      },

      formatStatusIcon(status) {
        let formattedIcon  = '';

        switch (this.$service.toUpperCase(status)) {
          case 'SUCCEEDED':
            formattedIcon = 'icon-check-circle';
            break;
          case 'PENDING':
            formattedIcon = 'icon-warning';
            break;
          case 'CANCELED':
            formattedIcon = 'icon-close-circle';
            break;
          case 'FAILED':
            formattedIcon = 'icon-close-circle';
            break;
          default:
            formattedIcon = 'icon-information-circle';
            break;
        }

        return formattedIcon;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .card-container {
    border: 1px solid #EFEFEF;
    flex-direction: column;
    border-radius: 6px;
    height: auto;
    display: flex;
    min-height: 460px;

    .header {
      background: rgba(249, 249, 249, 0.45);
      border-bottom: 1px solid #EFEFEF;
      padding: 1rem;

      .title {
        color: #333;
        display: flex;
        font-weight: 600;

        .second {
          margin-left: 0.3rem;
          display: flex;
          align-items: center;
          font-weight: 600;

          i {
            margin-right: 0.3rem;
            color: #DADADA;
            font-size: 5px;
          }
        }
      }
    }

    .body {
      background: $white-color;
      padding: 1rem;
      height: 100%;
      display: flex;
      flex-direction: column;

      .empty-data-container {
        justify-content: center;
        align-items: center;
        padding: 2rem;
        display: flex;
        height: 100%;
      }

      .content {
        height: 100%;

        &.empty {
          padding: 2rem;
        }

        .sales-resume-container {
          .resume-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;

            .item {
              margin-right: 1rem;

              &:last-child {
                margin-right: 0;
              }

              p {
                &.amount {
                  font-size: 16px;
                  font-weight: 500;
                  font-weight: #333;
                }

                &.label {
                  font-size: 14px;
                  font-weight: 400;
                  color: #6D6F6F;
                }
              }
            }
          }

          .rows {
            max-height: unset;
            min-height: 235px;

            .row {
              padding: 0.7rem 0;
            }
          }
        }

        .cards {
          display: flex;
          justify-content: center;
          margin-bottom: 1.5rem;

          &::-webkit-scrollbar {
            height: 0px;
          }

          .card {
            padding: 0.3rem 0rem;
            margin-right: 0.5rem;
            border-radius: 6px;
            border: 1px solid $second-color-light-1;
            cursor: pointer;
            color: #6D6F6F;
            min-width: 85px;
            text-align: center;

            &:hover {
              background: $second-color-light-1;
            }

            &.active {
              background: $second-color-light-1;
              color: #333;
            }

            &:last-child {
              margin-right: unset;
            }

            .date {
              font-weight: 500;
              font-size: 14px;
            }

            .total-amount {
              font-weight: 400;
              font-size: 10px;
            }
          }
        }

        .rows {
          max-height: 180px;
          overflow: auto;

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
          }

          &.items {
            min-height: 180px;
          }

          .row {
            display: flex;
            padding: 0.7rem;

            i {
              font-size: 0.9rem;
              margin-right: 1rem;
              display: flex;
              align-items: center;

              &.icon-check-circle {
                color: #08B978;
              }

              &.icon-warning {
                color: #BA8C03;
              }

              &.icon-close-circle {
                color: #FF5A5A;
              }

              &.icon-information-circle {
                color: #DADADA;
              }
            }

            &.total {
              .text {
                overflow: unset;
                font-weight: 500;
              }

              .value {
                font-weight: 500;
              }
            }

            .text {
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .line-container {
              width: 100%;
              justify-content: center;
              display: flex;
              align-items: center;
              padding: 0.5rem;

              .line {
                width: 100%;
                height: 1px;
                background: #EFEFEF;
              }
            }

            .value {
              white-space: nowrap;
            }
          }
        }

        .button-container {
          display: flex;
          justify-content: center;
          margin-top: 1rem;
          margin-bottom: 1rem;

          .button {
            height: 40px;
            max-width: 250px;
          }
        }
      }

      i {
        font-size: 5rem;
        color: #DADADA;
      }
    }

    .request-container {
      justify-content: center;
      align-items: center;
      display: flex;
      height: calc(100% - 54px);
      min-height: 400px;
    }
  }

  @media (max-width: 1270px) {
    .card-container {
      min-height: unset;
    }
  }

  @media (max-width: 590px) {
    .card-container {
      .body {
        .content {
          .cards {
            width: calc(100vw - 156px);
            overflow-x: auto;
            justify-content: unset;
          }

          .rows {
            .row {
              .text {
                font-size: 12px;
              }

              .value {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 520px) {
    .card-container {
      .body {
        .content {
          .sales-resume-container  {
            .resume-header {
              .item {
                p {
                  &.amount {
                    font-size: 14px;
                  }

                  &.label {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .card-container {
      .body {
        .content {
          .cards {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    .card-container {
      .body {
        p {
          font-size: 14px;
        }
      }
    }
  }
</style>