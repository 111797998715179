<template>
  <div class="customers-view-container">
    <default-view-component :hasModal="customerModalState">
      <template v-slot:modal>
        <customer-profile-modal-component
          v-if="customerModalState"
          :customerId="currentCustomerId"
          :hasEditButton="hasCurrentCustomerId()"
          @close="closeCustomerModal"
          @finishRequest="changeCustomer()"
        />
      </template>

      <template v-slot:content>
        <div class="section">
          <header-component
            :data="header"
          />

          <div class="header-options">
            <input-field
              class="input-field"
              lastIcon="icon-search"
              placeholder="Nome, documento..."
              @iconClick="fetchData"
              :isDisabled="hasRequest"
              :hasIconFetch="true"
              :disableFloatingLabel="true"
              v-model="search"
            />

            <button-component
              class="button-component"
              text="Novo cliente"
              v-ripple="'rgba(255, 255, 255, 0.35)'"
              :isDisabled="hasRequest"
              @click.native="showCustomerModal"
            />
          </div>

          <table-header-component
            class="table-header-component"
            :hasRequest="hasRequest"
            :data="[{
              label: `${totalCountItems}
                      ${$service.formatStringPlural(totalCountItems, 'cliente')}
                      ${isSearch ? $service.formatStringPlural(totalCountItems, 'encontrado') : $service.formatStringPlural(totalCountItems, 'cadastrado')}`,
              hasLastChild: false
            }]"
          />

          <table-component
            class="table-component"
            :table="tableData"
            :hasRequest="hasRequest && !isMoreData"
            @columnClick="showCustomerModal"
          />

           <pagination-table-component
              v-if="hasTableRows() && !isSearch"
              class="pagination-table-button-component"
              :isDisabled="!haveMoreData()"
              :data="paginationTableButtonData"
              :hasRequest="hasRequest"
              @onClick="moreData"
            />
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/Views/DefaultView';
  import Button from '@/components/Button';
  import InputField from '@/components/InputField';
  import Header from '@/components/Header';
  import Table from '@/components/Table/Table';
  import TableHeader from '@/components/Table/TableHeader';
  import CustomerProfileModal from '@/components/CustomerProfileModal';
  import PaginationTableButton from '@/components/Table/PaginationTableButton';

  export default {
    name: 'CustomerView',

    data() {
      return {
        header: {
          title: 'Clientes',
          to: '/dashboard',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        isMoreData: false,
        isSearch: false,

        totalCountItems: 0,
        countItems: 0,
        allValues: [],
        hasRequest: false,
        currentCustomerId: '',
        customerModalState: false,
        search: '',

        paginationTableButtonData: {
          countItems: 'x',
          totalCountItems: '2x'
        },

        tableData: this.initialTableData()
      };
    },

    components: {
      'default-view-component': DefaultView,
      'input-field': InputField,
      'header-component': Header,
      'button-component': Button,
      'table-component': Table,
      'table-header-component': TableHeader,
      'pagination-table-component': PaginationTableButton,
      'customer-profile-modal-component': CustomerProfileModal
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerId',
        'getCurrentSellerData',
      ])
    },

    created() {
      this.fetchData();
    },

    watch: {
      totalCountItems(value) {
        this.paginationTableButtonData.totalCountItems = value;
      },

      countItems(value) {
        this.paginationTableButtonData.countItems = value;
      },

      getCurrentSellerId() {
        this.tableData = this.initialTableData();
        this.fetchAllCustomers();
      }
    },

    methods: {
      fetchData() {
        this.isSearch = !this.$service.isBlank(this.search);
        this.tableData.rows = [];

        if (this.isSearch) {
          this.fetchFindCustomers();
        } else {
          this.fetchAllCustomers();
        }
      },

      async fetchAllCustomers() {
        if (!this.isMoreData) {
          this.allValues = [];
        }

        this.hasRequest = true;

        let queryParams = [
          {
            query: 'limit',
            param: '10'
          },

          {
            query: 'offset',
            param: this.allValues.length
          },

          {
            query: 'sort',
            param: '-created_at'
          }
        ];

        await this.$store.dispatch('getAllCustomers', this.$service.formatQueryParam(queryParams))
          .then(res => {
            this.formatFetch(res);
          })
          .catch(err => {
            console.log(err);
          });

        this.hasRequest = false;
        this.isMoreData = false;
      },

      async fetchFindCustomers() {
         this.hasRequest = true;

          await this.$store.dispatch('getAllCustomersFind', this.search)
            .then(res => {
              this.allValues = [];

              this.formatFetch(res);
            })
            .catch(err => {
              console.log(err);
            });

          this.hasRequest = false;
          this.isMoreData = false;
      },

      formatFetch(response) {
        this.totalCountItems = response.total_count;

        this.$service.setArray(response.data, this.allValues);
        this.tableData.rows = this.formatTableAllRows(this.allValues);

        this.countItems = this.allValues.length;
      },

      formatTableRow(item) {
        return {
          id: item.id,
          name: `${item.first_name} ${item.last_name ?? ''}`,
          email: item?.email ? item.email : '-',
          phone: item?.phone_number ? this.$service.formatPhone(item.phone_number) : '-',
          taxpayerId: this.$service.formatTaxpayerId(item.taxpayer_id)
        };
      },

      formatTableAllRows(arr) {
        let currentArr = [];

        arr.forEach(item => {
          currentArr.push(this.formatTableRow(item));
        });

        return currentArr;
      },

      showCustomerModal(item) {
        this.currentCustomerId = item.id;
        this.customerModalState = true;
      },

      closeCustomerModal() {
        this.customerModalState = false;
      },

      moreData() {
        if(!this.haveMoreData()) {
          this.isMoreData = true;

          this.fetchAllCustomers();
        }
      },

      changeCustomer() {
        this.tableData.rows = [];

        this.fetchAllCustomers();
      },

      haveMoreData() {
        return this.countItems == this.totalCountItems;
      },

      hasCurrentCustomerId() {
        return !!this.currentCustomerId;
      },

      hasTableRows() {
        return this.tableData.rows.length != 0;
      },

      initialTableData() {
        return {
          hasIcon: false,
          headers: {
            hasBorder: true,
            items: [
              {
                label: 'Nome',
                field: 'name',
                align: 'center'
              },

              {
                label: 'CPF/CNPJ',
                field: 'taxpayerId',
                align: 'center'
              },

              {
                label: 'E-mail',
                field: 'email',
                align: 'center'
              },

              {
                label: 'Contato',
                field: 'phone',
                align: 'center'
              }
            ]
          },

          rows: []
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .customers-view-container {
    .section {
      padding: 1.5rem;

      .header-options {
        display: flex;
        margin-bottom: 1.5rem;

        .input-field {
          max-width: 255px;
        }

        .button-component {
          margin-left: auto;
          height: 48px;
          max-width: 160px;
        }
      }

      .table-component {
        margin-top: 1.5rem;
        overflow-x: auto;
        overflow-y: hidden;
      }

      .pagination-table-button-component {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
      }
    }
  }

  @media (max-width: 605px) {
    .customers-view-container {
      .section {
        .header-options {
          flex-direction: column-reverse;

          .input-field {
            width: 100%;
            max-width: unset;
          }

          .button-component {
            margin-bottom: 1rem;
            height: 32px;
          }
        }
      }
    }
  }
</style>